<template lang="pug">
div
  h3.mb-3(v-show="saleItems.length > 0" style="color: #7498f1;") Repuestos utilizados
  v-row(v-for="item in saleItems" :key="`${item.piece ? item.piece.id : ''} ${item.new_piece_name}`" dense)
    v-col
      v-text-field(v-if="item.piece" v-model="item.piece.name" outlined :disabled="true")
      v-text-field(v-if="item.new_piece_name" v-model="item.new_piece_name" outlined disabled label="Nombre del repuesto")
    v-col(cols=2)
      v-text-field(v-model="item.quantity" outlined type="number" min="1" :disabled="true")
    v-col(cols=3)
      v-text-field(v-model="item.total_price" outlined type="number" prefix="$" disabled)
</template>

<script>

export default {
  props: {
    saleItems: Array
  }
}
</script>
