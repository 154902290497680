<template lang="pug">
  .list-sale
    v-container.white(fluid)
      v-row.pl-1.pt-1(justify="space-between")
        v-col(cols="4")
          v-text-field(v-model="search" placeholder="Busca por ID/Cliente/Rut/Patente..." prepend-inner-icon="mdi-magnify" dense outlined hide-details)
        v-col(cols="auto")
          v-btn.mr-2(large color="primary" depressed @click="$refs.saleForm.open()") + Nueva venta
          //- v-btn(large depressed outlined color="#A3A2A1" @click="$refs.OTForm.open()") + OT
      v-data-table(
        :headers="table.headers"
        :items="sales"
        hide-default-footer
        no-data-text="No hay ventas"
        :loading="table.loading"
        loading-text="Cargando Ventas..."
        )
        template(v-slot:item.actions="{ item }")
          a.mr-2(@click="$refs.saleDetail.open(item.id)") Ver detalle
          //- label Editar
    v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
    SaleDetail(ref="saleDetail")
    SaleForm(ref="saleForm" @save="listSales")
    OTForm(ref="OTForm" @save="listSales")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SaleDetail from './SaleDetail.vue'
import SaleForm from './SaleForm.vue'
import OTForm from './OTForm.vue'

export default {
  components: {
    SaleDetail,
    SaleForm,
    OTForm
  },
  data () {
    return {
      search: null,
      page: 1,
      rowCount: 0,
      table: {
        loading: false,
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Cliente', value: 'customer_name' },
          { text: 'Total', value: 'formated_total_price' },
          { text: 'Fecha', value: 'date' },
          { text: 'Aciones', value: 'actions' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('sale', ['sales']),
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  methods: {
    ...mapActions('sale', ['listSales']),
    sd () {
    },
    openSaleDetail (sale) {
      this.$refs.saleDetail.open(sale)
    },
    async fetchData () {
      this.table.loading = true
      const response = await this.listSales({ params: { page: this.page, search: this.search } })
      if (response.status >= 200 && response.status < 300) {
        this.rowCount = response.data.count
      }
      this.table.loading = false
    }
  },
  watch: {
    page () {
      this.fetchData()
    },
    search () {
      this.page = 1
      this.fetchData()
    }
  },
  async created () {
    this.table.loading = true
    const response = await this.listSales()
    if (response.status >= 200 && response.status < 300) {
      this.rowCount = response.data.count
    }
    this.table.loading = false
  }
}
</script>

<style lang="scss" scoped>
td {
  border: 1px black solid !important;
}
</style>
