<template lang="pug">
  v-dialog(v-model="isOpen" transition="dialog-bottom-transition" content-class="side-modal" :max-width="maxWidth" persistent)
    v-container(fluid)
      v-btn.close-btn(fab color="white" small depressed @click="isOpen = false")
        v-icon mdi-close-thick
      slot(name="default" syle="height: 100%")
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: [Number, String],
      default: 650
    }
  },
  data () {
    return {
      isOpen: this.value
    }
  },
  watch: {
    isOpen () {
      this.$emit('input', this.isOpen)
      this.$emit('change', this.isOpen)
    },
    value () {
      this.isOpen = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: white;
  min-height: 100vh;
  border-radius: 16px 0 0 16px;
  padding: 24px;
}
.close-btn {
  position: fixed;
  top: 12px;
  right: 12px;
}
@media (max-width: 600px) {
  .container {
    border-radius: 0;
  }
}
</style>

<style lang="scss">
.side-modal {
  border-radius: 16px 0 0 16px !important;
  position: fixed !important;
  margin: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  overflow-y: auto !important;
  min-height: 100% !important;
  @media (max-width: 600px) {
    border-radius: 0 !important;
  }
}
</style>
