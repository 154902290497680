<template lang="pug">
v-container.pa-0
  v-card
    //- v-card-subtitle.pb-0.sharkish--text {{ today }}
    v-card-title.py-2.my-0 Citas para aprobar
    v-card-subtitle.pt-0
      v-checkbox(v-model="allCheckboxes" label="Seleccionar todo" @change="changeAllCheckboxes")
      
      v-card.px-3.py-2.mb-6(v-for="appointment in requestedAppointments" 
        color="#F7F7F7" 
        style="border-left: solid 5px #1A1796; border-radius: 5px; cursor: pointer" 
        flat
        
      )
        v-row.ma-0.pa-0(style="justify-content: center")
          v-col.ma-0.px-0(cols="1")
            v-checkbox.ma-0(style="justify-content: center;" 
              v-model="checkboxes[appointment.id]" 
              hide-details="auto" 
              @change="handleSelected($event, appointment)")
          v-col.ma-0.pa-0(@click="showEvent({ $event, appointment })")
            v-card-title.pt-0.text-h7 {{ appointment.name }}
            v-card-subtitle.pb-0.text-caption {{ appointment.service.name }}

      v-btn(:disabled="!selectedCounter > 0" 
        color="#1A1796" :dark="selectedCounter > 0" 
        tile 
        block
        :loading="tryingToApprove"
        @click="approveSelected"
      ) Aprobar {{ selectedCounter }} {{ selectedCounter === 1? 'cita' : 'citas' }}    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '../../plugins/axios';

export default {
  data: () => ({
    allCheckboxes: false,
    selectedCounter: 0,
    tryingToApprove: false,
    checkboxes: [],
    requestedAppointments: [],
    selectedToApprove: [],
    today: new Date().toLocaleDateString("es-CL", { year: "numeric", month: "long", day: "numeric" }),
    
  }),

  computed: {
    ...mapGetters('schedule', ['appointments']),          
  },

  methods: {
    ...mapActions('schedule', ['listAppointments']),

    handleSelected ($event, appointment) {
      if($event) {
        // console.log('sumando', $event)
        this.selectedToApprove.push(appointment)
        this.selectedCounter++ 
      }else {
        // console.log('restando', $event)
        this.selectedToApprove = this.selectedToApprove.filter(el => el.id !== appointment.id)
        this.selectedCounter--
        this.allCheckboxes = false
      }
      // console.log('seleccionados', this.selectedToApprove) 
    },

    changeAllCheckboxes (value) {
      for(const appointment of this.requestedAppointments) {
        if(value && !this.checkboxes[appointment.id]) {
          this.selectedCounter++
          this.selectedToApprove.push(appointment)
        }
        else if(!value) {
          this.selectedCounter = 0
          this.selectedToApprove = []
        }
        this.checkboxes[appointment.id] = value
      }
      // console.log('allselect', this.selectedToApprove)
    },

    async approveSelected () {
      this.tryingToApprove = true;
      for (const appointment of this.selectedToApprove) {
        // console.log('aprobando...', appointment.id)
        const id = appointment.id;    
        const response = await axios.post(`/schedule/approve_appointment/${id}/`);
        
        // mejorar esta lógica para errores 400 a 500, quizá usar la store 
        if(response) {
          console.log('evento aprobado', id)
          this.selectedToApprove = this.selectedToApprove.filter(el => el.id !== id)
          this.selectedCounter--
        }
        else {
          console.log('No se pudo aprobar la cita')
        }
      }
      await this.listAppointments();
      this.tryingToApprove = false;
      
    },

    showEvent ({ $event, appointment }) {
      this.$emit('showAppointment', { $event, appointment })
    },

    resetToApproveList () {
      this.allCheckboxes = false
      this.selectedCounter = 0
      this.tryingToApprove = false
      this.checkboxes = []
      this.selectedToApprove = []
    }
  },
  
  watch: {
    appointments: {
      handler (value) {
        if(value) {
          this.requestedAppointments = this.appointments.filter(el => el.state === 'REQUESTED')
          for (const appointment of this.requestedAppointments) {
            this.checkboxes[appointment.id] = false
          }
        }
        this.resetToApproveList();
      },
      deep: true,
    },

    selectedCounter: {
      handler (value) {
        if(this.selectedCounter === this.requestedAppointments.length) {
          this.allCheckboxes = true;
        } else {
          this.allCheckboxes = false;
        }
      },
      deep: true
    },

    
  },
}
</script>