<template lang="pug">
v-card.d-flex.justify-end.align-end.subtotal-items(v-if="OT !== null" elevation="1")
  p.budgetTitle Total del presupuestos aprobados:
    span {{ OT.total_price | currency }}
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('ot', ['OT']),
    ...mapGetters('service', ['services']),
    estimateData () {
      return this.OT ? this.OT.estimate : []
    }
  }
}
</script>
<style lang="scss" scoped>
.subtotal-items  {
  margin: 0 10px 20px 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.budgetTitle {
  color: #3658D3 !important;
  font-weight: 600;
  font-size: larger;
}
</style>
<style scoped>
.subtotal-items {
  padding-right: 20px;
  @media (width < 600px) {
    background-color: #3658D3;
    bottom: 0;
    border-radius: 0 !important;
    left: 0;
    height: 80px;
    margin: 0;
    position: fixed;
    right: 0;
    z-index: 1000;
    .budgetTitle {
      color: white !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
</style>
