import axios from '../../plugins/axios'
import settings from '../../../settings'

export const getVehicle = ({ commit }, { vehicleId, params } = {}) => {
  return axios.get(`order/vehicle/${vehicleId}/`, { params })
    .then(res => {
      commit('SET_VEHICLE', res.data)
      return res
    })
    .catch(err => err.response)
}

export const listVehicles = ({ commit }, { params } = {}) => {
  return axios.get('order/vehicle/', { params })
    .then(res => {
      commit('SET_VEHICLES', res.data)
      return res
    })
    .catch(err => err.response)
}

export const createVehicle = (_, { data } = {}) => {
  return axios.post('order/vehicle/', data)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const editVehicle = (_, { vehicleID, data } = {}) => {
  return axios.put(`order/vehicle/${vehicleID}/`, data)
    .catch(err => err.response)
}

export const listModels = ({ commit }, { params } = {}) => {
  return axios.get('vehicle/vehicle_model/', { params })
    .then(res => {
      commit('SET_MODELS', res.data)
      return res
    })
    .catch(err => err.response)
}

export const carEstimates = ({ commit }, { params } = {}) => {
  return axios.get('inventory/car_estimates/', { params })
    .then(res => {
      commit('SET_CAR_ESTIMATES', res.data.results)
      return res
    })
    .catch(err => err.response)
}


export const searchPatent = (_, { patente } = {}) => {
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'X-API-KEY': settings.PATENTES_API_KEY
    }
  }
  return fetch(`${settings.PATENTES_API_URL}/vehicle/${patente}.json?include=owner`, options)
    .then(response => response.json())
    .then(response => response)
    .catch(err => console.error(err))
}
// Crear Marca
export const createBrand = (_, { data } = {}) => {
  return axios.post('vehicle/brand/', data)
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}

// Crear Modelo
export const createVehicleModel = ({ commit }, { data } = {}) => {
  return axios.post('vehicle/vehicle_model/', data)
    .then(res => {
      commit('ADD_VEHICLE_MODEL', res.data)
      return res.data
    })
    .catch(err => err.data)
}


export const customerVehicles = ({ commit }, { id } = {}) => {
  return axios.get(`order/vehicle?customer=${id}`)
    .then(res => {
      commit('SET_VEHICLES', res.data)
      return res
    })
    .catch(err => err.response)
}
