<template lang="pug">
.flex-row.justify-space-between.align-center
  v-text-field.search-field(
    v-model="filters.search"
    placeholder="Rut o nombre de cliente"
    clearable
    outlined dense hide-details
  )

  .buttons.flex-row.gap-2
    v-btn(color="primary" @click="showNewCustomerModal = true")
      v-icon.mr-1 mdi mdi-plus
      span Cliente
    v-btn(color="primary" text @click="exportXls")
      v-icon.mr-1 mdi mdi-microsoft-excel
      span Excel

  SideModal(v-model="showNewCustomerModal" max-width="600")
    v-container
      h2.mb-5 Crear nuevo cliente
      CustomerForm(
        :customerSelected="emptyCustomer"
        noFeedback
        @save="onCustomerCreated"
        @error="onErrorSaving"
      )
</template>

<script>
import { mapActions, mapWritableState } from 'pinia'
import { useCustomerListStore } from '@/stores/views/useCustomerListStore'
import { debounce } from 'lodash'
import SideModal from '@/components/shared/SideModal.vue'
import CustomerForm from '@/components/customer/CustomerForm.vue'

export default {
  components: { SideModal, CustomerForm },

  data() {
    return {
      showNewCustomerModal: false,
      emptyCustomer: {},
    }
  },

  computed: {
    ...mapWritableState(useCustomerListStore, ['filters', 'tableOptions']),
  },

  watch: {
    'filters.search'(search) {
      this.onSearchChange(search)
    },
  },

  methods: {
    ...mapActions(useCustomerListStore, ['getCustomers', 'getCustomersExcel']),

    async exportXls() {
      const success = await this.getCustomersExcel()
      if (!success) {
        this.$toast.error('Error al exportar')
      }
    },

    onCustomerCreated() {
      this.emptyCustomer = {
        id: null,
        name: null,
        address: null,
        email: null,
        contact: '9',
      }
      this.showNewCustomerModal = false
      this.getCustomers()
      this.$toast.success('Cliente creado con éxito')
    },

    onErrorSaving(data) {
      this.$toast.error(`Error al guardar (${data?.error || 'Error desconocido'})`)
    },
  },

  created() {
    this.onSearchChange = debounce(async function (search) {
      this.tableOptions.page = 1
      await this.getCustomers()
    }, 500)
  },
}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 260px;
  &::v-deep .v-input__slot {
    min-height: 48px !important;
  }
}

.buttons {
  .v-btn {
    width: 110px;
    height: 48px;
  }
}
</style>
