<template lang="pug">
v-container(fluid)
  div
    v-row
      v-col
        v-btn.float-right.mt-3(color="primary" @click="isQuotation()") + Nueva Cotización
    v-row
      v-col(cols="12")
        v-data-table(
          :headers="headers"
          class="elevation-0"
          hide-default-footer
          :items="quotation"
          no-data-text="No hay cotizaciones"
          :loading="table.loading"
          loading-text="Cargando Cotizaciones..."
          )
          template(v-slot:item.name="{item}")
            v-text-field.mt-5(dense v-model="item.name" :disabled="item.disabled" :ref="item.id" :append-icon="!item.disabled ? 'mdi-content-save-check' : ''" @click:append="saveChange(item)")
          template(v-slot:item.created_at="{item}")
            p.mt-3 {{ formatDate(item.created_at) }}
          template(v-slot:item.total_price="{item}")
            p.mt-3 {{ item.total_price | currency }}
          template(v-slot:item.actions="{item}")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="openModalQuotation(item)")
                  v-icon mdi-eye-outline
              span Ver detalles
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="quotationToWorkOrder(item)")
                  v-icon mdi-car-wrench
              span Pasar a OT
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="handleDownloadPdf(item.id)")
                  v-icon mdi-download
              span Descargar
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="editName(item)")
                  v-icon mdi-pencil
              span Editar nombre Cotización
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  ModalQuotation(ref="modalQuotation" @use="useQuotation" :quotationSelected="quotationSelected")
  OTForm(ref="OTForm" :formData="form" @save="refresh" @quotationCreated="fetchAndShowQuotation($event)")
  alert(ref="alert")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ModalQuotation from './ModalQuotation.vue'
import OTForm from '../sale/OTForm.vue'
import Alert from '../shared/alert.vue'
export default {
  data() {
    return {
      table: {
        loading: false,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Presupuestos', value: 'name' },
        { text: 'Cliente', value: 'work_order.customer.full_name' },
        { text: 'Vehículo', value: 'work_order.vehicle.model_display' },
        { text: 'Fecha de Creación', value: 'created_at' },
        { text: 'Monto', value: 'total_price' },
        { text: 'Acciones', value: 'actions' },
      ],
      quotation: [],
      page: 1,
      rowCount: 0,
      searchTerm: '',
      quotationSelected: {},
      form: null,
    }
  },
  props: {
    searchParam: {
      type: String,
      default: '',
    },
  },
  components: {
    ModalQuotation,
    OTForm,
    Alert,
  },
  methods: {
    ...mapActions('ot', ['listQuotation', 'getQuotation', 'updateQuotation']),
    ...mapActions('service', ['listServices']),
    ...mapActions('inventory', ['listPieces', 'downloadPdf']),
    openModalQuotation(item) {
      this.formatQuotationSelected(item)
      this.$refs.modalQuotation.open()
    },
    async fetchData(search = '') {
      this.table.loading = true
      this.searchTerm = search !== '' ? search : ''
      await this.listQuotation({ params: { page: this.page, search } }).then(
        (response) => {
          this.quotation = response.data.results.map((d) => {
            return {
              ...d,
              disabled: true,
            }
          })
          this.rowCount = response.data.count
        }
      )
      this.table.loading = false
      await this.listServices()
      await this.listPieces()
    },
    formatQuotationSelected(item) {
      this.quotationSelected = {}
      this.quotationSelected = item
      this.quotationSelected.services = item.estimate_services.map((ts) => {
        return {
          ...this.services.filter((s) => s.id === ts.service)[0],
          service_quantity: ts.service_quantity,
          saleItems: ts.sale_items.map((tp) => {
            const totalPrice = tp.total_price || (this.pieces.filter((p) => p.id === tp.piece)?.[0]?.price || tp.unit_price) * tp.quantity
            
            return {
              piece: this.pieces.filter((p) => p.id === tp.piece)[0],
              new_piece_name: tp.new_piece_name,
              quantity: tp.quantity,
              price: tp.unit_price,
              total_price: totalPrice,
            }
          })          
        }
      })
    },
    quotationToWorkOrder(item) {
      this.formatQuotationSelected(item)
      this.useQuotation()
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('cl-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date))
    },
    editName(item) {
      item.disabled = false
      this.$nextTick(() => {
        this.$refs[item.id].focus()
      })
    },
    saveChange(item) {
      this.updateQuotation({ id: item.id, data: item }).then((response) => {
        if (response.status === 200) {
          item.disabled = true
          this.$refs.alert.handleShowAlert(
            'success',
            'El nombre de la cotización ha sido editado exitosamente.',
            true,
            'green'
          )
        } else {
          this.$refs.alert.handleShowAlert(
            'danger',
            'Ha ocurrido un error al editar el nombre de la cotización.',
            true,
            'red'
          )
        }
      })
    },
    useQuotation() {
      this.form = null
      this.form = this.quotationSelected
      this.form.vehicle_data = this.quotationSelected.work_order.vehicle
      this.form.customer_data = this.quotationSelected.work_order.customer
      this.form.clientComments = this.quotationSelected.client_comments
      this.$refs.OTForm.open(this.form)
    },
    handleDownloadPdf(id) {
      this.downloadPdf({ id }).then((response) => {
        const blob = new Blob([response.data], {
          type: {
            type: response.headers['content-type'],
          },
        })
        const blobUrl = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobUrl
        tempLink.setAttribute('download', `Presupuesto - ${id}.pdf`)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      })
    },
    isQuotation() {
      this.$refs.OTForm.open({
        newQuotation: true,
      })
    },
    async fetchAndShowQuotation(quotationId) {
      await this.listServices()
      const res = await this.getQuotation({ quotationId })
      const quotation = { ...res.data, disabled: true }
      this.openModalQuotation(quotation)
    },
    refresh() {
      this.fetchData()
    },
  },
  computed: {
    ...mapGetters('service', ['services']),
    ...mapGetters('inventory', ['pieces']),
    pageCount() {
      return Math.ceil(this.rowCount / 10)
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    page(newVal) {
      if (newVal !== 1) {
        this.fetchData(this.searchTerm)
      }
    },
    searchParam(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1
      }
      this.fetchData(newVal)
    },
  },
}
</script>
<style lang="scss" scoped>
.v-data-table::v-deep th {
  font-size: 14px !important;
  font-weight: 400;
  background-color: #e4eaff;
}
.v-icon:hover {
  color: #0000ff;
}
</style>
