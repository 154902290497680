<template lang="pug">
v-form(ref="form" :disabled="readOnly" @submit.prevent="onSubmit")
    alert(ref="alert" v-show="!noFeedback")
    v-row(dense)
      v-col(cols=12 v-if="vehicle.id === null")
        label.primary--text Selecciona un cliente
          v-autocomplete.mt-2(
            label="Cliente"
            placeholder="Busca al cliente"
            hint="Busca por celular, nombre o apellido del cliente"
            v-model="vehicle.customer"
            outlined
            return-object
            :hide-no-data="hideData"
            hide-selected
            no-data-text="Cliente no encontrado"
            item-text="name"
            :items="items"
            :search-input.sync="search"
            :loading="isLoading"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            :rules="[v => !!v || 'El cliente es requerido']"
          )
            template(v-slot:item="{ item }")
              span {{ item.contact }} - {{ item.name }} {{ item.last_name }}
            template(v-slot:selection="{ item }")
              span {{ item.name }} {{ item.last_name }}
            template(#append-item)
              div.mr-2.ml-2
                v-btn(color="primary" block @click="$emit('newClient')") Crear nuevo Cliente
      v-col(cols=12)
        label.primary--text Detalles del vehículo
          v-row.mt-2(cols=12)
            v-col(:cols="vehicle.id === null ? '6' : '12'")
              v-autocomplete(
                label="Modelo"
                v-model="vehicle.model"
                outlined
                item-text="model_display"
                return-object
                :items="models"
                :rules="[v => !!v || 'El modelo es requerido']")
            v-col(cols=6)
              v-text-field(
                v-model="vehicle.patent" label="Patente" outlined :rules="[v => !!v || 'La patente es requerida']"
                v-maska:[patentMaskFormat]
              )
          v-row(cols=12)
            v-col(cols=6)
              v-text-field(v-model="vehicle.engine_number" label="Número de motor" outlined)
            v-col(cols=6)
              v-text-field(v-model="vehicle.chassis_number" label="Número de chasis" outlined)
          v-row
            v-col(cols=6)
              v-select(v-model="vehicle.fuel_type" label="Combustible" outlined :items="fuelTypeOptions")
            v-col(cols="6")
              v-text-field(v-model="vehicle.color" label="Color" outlined)
    v-btn(
      v-if="!readOnly" block color="primary" x-large type="submit"
      :loading="isSaving"
    ) {{ vehicle.id !== null ? 'Editar Vehículo' : '+ Crear Vehículo' }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vMaska, Mask } from 'maska'
import alert from '@/components/shared/alert.vue'
import { patentMaskFormat, rutMaskFormat } from '@/utils/mask'

export default {
  directives: { maska: vMaska },
  components: { alert },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    vehicleSelected: {
      type: Object,
      default: () => {
        return {
          model: null,
          fuel_type: 2,
          id: null,
          customer: null,
          patent: null,
          engine_number: null,
          chassis_number: null,
          color: null
        }
      }
    },
    patent: {
      type: String
    },
    dataVehicle: {
      type: Object
    },
    // Deshabilita las alertas de error/success
    noFeedback: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      vehicle: {
        model: this.vehicleSelected.id ? this.vehicleSelected.model_display : null,
        fuel_type: this.vehicleSelected.id ? this.vehicleSelected.fuel_type : 2,
        id: this.vehicleSelected.id ? this.vehicleSelected.id : null,
        customer: this.vehicleSelected.id ? this.vehicleSelected.customer : null,
        patent: this.vehicleSelected.id ? this.vehicleSelected.patent : null,
        engine_number: this.vehicleSelected.id ? this.vehicleSelected.engine_number : null,
        chassis_number: this.vehicleSelected.id ? this.vehicleSelected.chassis_number : null,
        color: this.vehicleSelected.id ? this.vehicleSelected.color : null
      },
      fuelTypeOptions: [
        {
          text: 'Bencina',
          value: 2
        },
        {
          text: 'Diesel',
          value: 1
        }
      ],
      modelo: {},
      search: null,
      isLoading: false,
      isSaving: false,
      clientResults: [],
      hideData: true,
      maskaRut: { unmasked: null },
    }
  },
  methods: {
    ...mapActions('customer', ['listCustomers']),
    ...mapActions('vehicle', ['createVehicle', 'editVehicle', 'listModels']),
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      const actions = this.vehicle.id ? this.editVehicle : this.createVehicle
      const myData = { ...this.vehicle, customer: this.vehicle.customer.id, model: this.vehicle.model.id }
      // this.vehicle.model = this.vehicle?.model?.id
      // const data = this.vehicle.id ? { vehicleID: this.vehicle.id, data: this.vehicle } : { data: this.vehicle }
      const data = this.vehicle.id ? { vehicleID: this.vehicle.id, data: myData } : { data: myData }

      this.isSaving = true
      const response = await actions(data)
      if (response.status === 201 || response.status === 200) {
        this.$emit('save', response.data)
        this.$emit('input', response.data)
        this.$emit('change', response.data)
      } else {
        this.$refs.alert.handleShowAlert('error', 'Ha ocurrido un error al guardar el vehículo.', true, 'red')
        this.$emit('error', response.data)
      }
      this.isSaving = false
    },
    maskRutText(rut) {
      const mask = new Mask(rutMaskFormat)
      return mask.masked(rut).toUpperCase()
    },
  },
  computed: {
    ...mapGetters('customer', ['customers']),
    ...mapGetters('vehicle', ['models']),
    patentMaskFormat: () => patentMaskFormat,
    items () {
      return this.clientResults.map(client => client)
    },
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.$emit('input', this.vehicle)
        this.$emit('change', this.vehicle)
      }
    },
    search (val) {
      if (val === '' || val === null) {
        this.hideData = true
        this.clientResults = []
      } else {
        if (this.items.length > 0) return

        if (this.isLoading) return

        this.isLoading = true
        this.hideData = false
        this.listCustomers({ params: { query: val } }).then(
          response => {
            this.clientResults = response.data
          }
        )
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
    },
    patent:{
      handler(val) {
        this.vehicle.patent = val
      },
      immediate: true,
    },
    dataVehicle(val) {
      if (val) {
        this.vehicle.engine_number = val.engine_number
        this.vehicle.chassis_number = val.chassis_number
        this.vehicle.color = val.color
        this.vehicle.patent = val.patente
        this.vehicle.customer = val.cliente
        if (typeof val.modelo === 'object') {
          this.vehicle.model = val.modelo
        } else {
          this.vehicle.model = this.models.find(model => model.id === val.modelo)
        }
      } else if (!val && this.vehicle.id === null) {
        this.vehicle.engine_number = null
        this.vehicle.chassis_number = null
        this.vehicle.color = null
        this.vehicle.patent = null
      }
      this.$refs.form.resetValidation()
    },
  },

  async created () {
    await this.listModels()
  }
}
</script>
