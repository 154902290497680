<template lang="pug">
v-card.main-card.pa-3
  VehicleListFilterBar
  VehicleListTable
  VehicleListPagination

  VehicleDetailModal
</template>

<script>
import VehicleListFilterBar from '@/components/vehicle/VehicleList/VehicleListFilterBar.vue'
import VehicleListTable from '@/components/vehicle/VehicleList/VehicleListTable.vue'
import VehicleListPagination from '@/components/vehicle/VehicleList/VehicleListPagination.vue'
import VehicleDetailModal from '@/components/vehicle/VehicleList/VehicleDetailModal.vue'
import { useVehicleListStore } from '@/stores/views/useVehicleListStore'
import { mapStores } from 'pinia'

export default {
  components: {
    VehicleListFilterBar,
    VehicleListTable,
    VehicleListPagination,
    VehicleDetailModal,
  },

  computed: {
    ...mapStores(useVehicleListStore),
  },

  beforeDestroy() {
    this.vehicleListStore.$reset()
  },
}
</script>

<style lang="scss" scoped>
.main-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
