<template lang="pug">
  div
    v-dialog(v-model="isOpen" max-width="601")
      .white(style="padding: 20px")
        h2.mb-5 Nuevo Cliente
        CustomerForm(v-bind="$attrs" @save="isOpen=false" v-on="$listeners")
</template>

<script>
import CustomerForm from './CustomerForm.vue'

export default {
  inheritAttrs: false,
  data () {
    return {
      isOpen: false
    }
  },
  components: {
    CustomerForm
  },
  methods: {
    open () {
      this.isOpen = true
    }
  }
}
</script>
