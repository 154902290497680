<template lang="pug">
SideModal(v-model="showCustomerDetailModal"  max-width="946")
  h2.primary--text.mb-5 Detalle

  .flex-col.gap-2(v-if="selectedCustomer")
    CustomerInfo

    OTHistory(:params="otListParams")

    v-tabs(v-model="tab")
      v-tab Vehículos asociados
      v-tab Detalles de cuentas por cobrar
    v-tabs-items(v-model="tab")
      v-tab-item
        CustomerVehicleList
      v-tab-item
        CustomerDebtList
</template>

<script>
import { mapWritableState } from 'pinia'
import { useCustomerListStore } from '../../../stores/views/useCustomerListStore'
import SideModal from '@/components/shared/SideModal.vue'
import CustomerInfo from './DetailModal/CustomerInfo.vue'
import OTHistory from '@/components/shared/OTHistory.vue'
import CustomerVehicleList from './DetailModal/CustomerVehicleList.vue'
import CustomerDebtList from './DetailModal/CustomerDebtList.vue'

export default {
  components: {
    SideModal,
    CustomerInfo,
    CustomerVehicleList,
    CustomerDebtList,
    OTHistory,
  },

  data() {
    return {
      tab: 0,
    }
  },

  computed: {
    ...mapWritableState(useCustomerListStore, [
      'showCustomerDetailModal',
      'selectedCustomer',
    ]),

    otListParams() {
      return {
        customer: this.selectedCustomer?.id,
      }
    },
  },

  watch: {
    async showCustomerDetailModal(val) {
      if (!val) {
        await this.$nextTick()
        this.selectedCustomer = null
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
