<template lang="pug">
v-card.main-card.pa-3
  CustomerListFilterBar
  CustomerListTable
  CustomerListPagination

  CustomerDetailModal
</template>

<script>
import CustomerListFilterBar from '@/components/customer/CustomerList/CustomerListFilterBar.vue'
import CustomerListTable from '@/components/customer/CustomerList/CustomerListTable.vue'
import CustomerListPagination from '@/components/customer/CustomerList/CustomerListPagination.vue'
import CustomerDetailModal from '@/components/customer/CustomerList/CustomerDetailModal.vue'
import { useCustomerListStore } from '@/stores/views/useCustomerListStore'
import { mapStores } from 'pinia'

export default {
  components: {
    CustomerListFilterBar,
    CustomerListTable,
    CustomerListPagination,
    CustomerDetailModal,
  },

  computed: {
    ...mapStores(useCustomerListStore),
  },

  beforeDestroy() {
    this.customerListStore.$reset()
  },
}
</script>

<style lang="scss" scoped>
.main-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
