<template lang="pug">
v-form(ref="form" @submit.prevent="onSubmit")
    alert(ref="alert")
    //- .mb-2.primary--text Datos del cliente
    v-row(dense)
      //- v-col(cols=12 md=6)
      //-   v-text-field(v-model="customer.name" label="Nombre" outlined :rules="[v => !!v || 'El nombre es requerido']")
      //- v-col(cols=12 md=6)
      //-   v-text-field(v-model="customer.last_name" label="Apellido" outlined :rules="[v => !!v || 'El apellido es requerido']")
      //- v-col(cols=12 md=6)
      //-   v-text-field(
      //-     v-model="customer.rut" label="Rut" outlined :rules="[v => !!v || 'El rut es requerido', ...errors.rut]"
      //-     v-maska="maskaRut" data-maska="1.11#-V" data-maska-tokens="1:[0-9]:repeated|V:[0-9Kk]" data-maska-reversed
      //-   )
      //- v-col(cols=12 md=6)
      //-   v-text-field(
      //-     v-model="customer.contact" label="Celular" outlined :rules="[...errors.contact]" type="tel"
      //-     v-maska="maskaContact" data-maska="(##) # #### #### ####"
      //-     @focus="$event.target.selectionStart = 7; $event.target.selectionEnd = -1"
      //-   )
      //- v-col(cols=12 md=6)
      //-   v-text-field(v-model="customer.email" label="Correo" outlined :rules="[v => !!v || 'El correo es requerido']")
      //- v-col(cols=12 md=6)
      //-   v-text-field(v-model="customer.address" label="Dirección" outlined :rules="[v => !!v || 'La dirección es requerida']")

      //- v-col(cols=12 md=6)
      //-   v-autocomplete(
      //-     label="Cliente"
      //-     placeholder="Busca por Rut del cliente"
      //-     v-model="vehicle.customer"
      //-     outlined
      //-     item-text="rut"
      //-     return-object
      //-     :hide-no-data="hideData"
      //-     hide-selected
      //-     no-data-text="Cliente no encontrado"
      //-     :items="items"
      //-     :search-input.sync="search"
      //-     :loading="isLoading"
      //-     :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
      //-     :rules="[v => !!v || 'El cliente es requerido']"
      //-   )
      //-     template(#item="{ item }")
      //-       span {{ maskRutText(item.rut) }} - {{ item.name }}
      //-     template(#selection="{ item }")
      //-       span {{ maskRutText(item.rut) }} - {{ item.name }}
      //-     template(#append-item)
      //-       div.mr-2.ml-2
      //-         v-btn(color="primary" block @click="$emit('newClient')") Crear nuevo Cliente




      v-col(cols=12)
        v-autocomplete(
          v-model="form.vehicle"
          @change="onChangeVehicle"
          outlined
          :placeholder="loadingSearchPatent ? 'Buscando...' : 'Busca el vehículo por patente. Por ejemplo: ABCD12'"
          item-text="patent"
          return-object

          no-data-text="Escribe la patente del nuevo vehículo"
          :search-input.sync="search"
          :loading="isLoading"
          :items="items"

          :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          hide-details
          @keyup.enter="() => { if (search && search.length == 6) { handleCreateNewVehicle(), searchPatentInPatenteChile(), search = '' } }"
          :disabled="loadingSearchPatent"
        )
          template(#append-item)
            div.mr-2.ml-2
              //- v-btn(color="primary" block @click="handleCreateNewVehicle(), searchPatentInPatenteChile()" :disabled="!search || search.length < 6") Agregar Vehículo

      v-col(cols=12 md=12)
        .mb-2.primary--text Datos del vehículo
      v-col(cols=12 md=6)
        v-autocomplete(
          label="Modelo*"
          v-model="vehicle.model"
          outlined
          item-text="model_display"
          return-object
          :items="models"
          :rules="[v => !!v || 'El modelo es requerido']")
      v-col(cols=12 md=6)
        v-text-field(
          v-model="vehicle.patent" label="Patente*" outlined :rules="[v => !!v || 'La patente es requerida']"
          v-maska:[patentMaskFormat]
        )
      v-col(cols=12 md=6)
        v-text-field(v-model="vehicle.engine_number" label="Número de motor" outlined)
      v-col(cols=12 md=6)
        v-text-field(v-model="vehicle.chassis_number" label="Número de chasis" outlined)
      v-col(cols=12 md=6)
        v-select(v-model="vehicle.fuel_type" label="Combustible" outlined :items="fuelTypeOptions")
    v-btn(block color="primary" x-large type="submit" :loading="isSaving") + Agregar Vehículo
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vMaska } from 'maska'
import { patentMaskFormat } from '@/utils/mask'
import alert from '@/components/shared/alert.vue'

export default {
  directives: { maska: vMaska },
  components: { alert },
  props: {
    patent: {
      type: String,
    },
    dataVehicle: {
      type: Object,
    },
    creatingNewCustomer: {
      type: Boolean,
      default: true,
    },
    customerSelected: {
      type: Object,
    },
  },
  data() {
    return {
      vehicle: {
        model: this.dataVehicle ? this.dataVehicle.modelo : null,
        fuel_type: 2,
        id: null,
        customer: null,
        patent: this.dataVehicle ? this.dataVehicle.patente : null,
        engine_number: this.dataVehicle ? this.dataVehicle.numero_motor : null,
        chassis_number: this.dataVehicle
          ? this.dataVehicle.numero_chasis
          : null,
      },
      customer: {
        name: null,
        last_name: null,
        rut: null,
        contact: '569',
        email: null,
        address: null,
      },
      fuelTypeOptions: [
        {
          text: 'Bencina',
          value: 2,
        },
        {
          text: 'Diesel',
          value: 1,
        },
      ],
      errors: {
        rut: [],
        contact: [],
      },
      maskaRut: { unmasked: null },
      maskaContact: { unmasked: null },
      isSaving: false,


      // new data

      form: {
        name: '',
        estimated_duration: '',
        services: [{
          id: null,
          name: '',
          price: 0,
          commission: 0.0,
          estimate: [],
          service_quantity: 1
        }],
        vehicle: this.formScheduleOT || null,
        saleItems: [],
        customer: null,
        clientComments: null,
        requires_evaluation: true,
        discount: null,
        agreement: null,
        deposit: 50
      },
      hideData: true,
      loadingSearchPatent: false,
      search: null,
      isLoading: false,
      vehicleResults: [],
      acceptSearchPatent: true,
      messageSearchPatent: null,
      searchPatentFinished: false,

    }
  },
  methods: {
    ...mapActions('customer', ['listCustomers', 'createCustomer']),
    ...mapActions('vehicle', ['createVehicle', 'listModels']),
    async onSubmit() {
      // this.errors = { rut: [], contact: [] }
      // if (!this.$refs.form.validate()) return

      // let data = {
      //   ...this.customer,
      //   rut: this.maskaRut.unmasked,
      //   contact: this.maskaContact.unmasked,
      // }

      // let response = await this.createCustomer({ data })
      // if (response.status !== 201) {
      //   this.errors = {
      //     rut: response.data.rut ? response.data.rut : [],
      //     contact: response.data.contact ? response.data.contact : [],
      //   }
      //   return
      // }

      const data = { ...this.vehicle }
      data.customer = this.customerSelected.id
      data.model = this.vehicle.model.id

      this.isSaving = true
      const response = await this.createVehicle({ data })
      if (response.status === 201) {
        this.$emit('save', response.data)
        // this.$emit('input', response.data)
        // this.$emit('change', response.data)
      } else {
        this.$refs.alert.handleShowAlert(
          'error',
          'Ha ocurrido un error al guardar el vehículo.',
          true,
          'red'
        )
      }
      this.isSaving = false
    },

    handleResetVehicleForm () {
      this.vehicle = {
        model: this.dataVehicle ? this.dataVehicle.modelo : [],
        fuel_type: 2,
        id: null,
        customer: null,
        patent: this.dataVehicle ? this.dataVehicle.patente : [],
        engine_number: this.dataVehicle ? this.dataVehicle.numero_motor : null,
        chassis_number: this.dataVehicle ? this.dataVehicle.numero_chasis : null,
      }
    },

    async onChangeVehicle (vehicle) {
      this.form.vehicle = vehicle
      this.selectVehicleStatus = 'SELECTED'
      if (this.form.vehicle?.customer) {
        const response = await this.getCustomer({ customerId: this.form.vehicle.customer })
        this.form.customer = response.data
      }
      this.messageSearchPatent = null
    },

    handleCreateNewVehicle () {
      this.selectVehicleStatus = 'ON_CREATE'
      this.form.customer = null
    },

    async searchPatentInPatenteChile () {
      this.dataVehicle = null
      this.messageSearchPatent = null
      this.searchPatentFinished = false
      if (!this.form.vehicle) {
        this.dataVehicle = null
        console.log('Buscando patente')
        this.loadingSearchPatent = true
        const res = await this.searchPatent({ patente: this.search })
        if (res.status === 200 && this.acceptSearchPatent) {
          // Crea marca y modelo
          const marca = await this.createBrand({ data: { name: res.data.marca } })
          const modelo = await this.createVehicleModel({ data: { name: res.data.modelo, brand: marca.id, year: res.data.anio } })
          res.data.modelo = modelo
          this.patent = res.data.patente = res.data.patente.substr(0, res.data.patente.length - 2)
          this.dataVehicle = res.data
          this.loadingSearchPatent = false
          // this.selectVehicleStatus = 'ON_CREATE_CUSTOMER'
          this.searchPatentFinished = true
        } else if (res.status !== 200 && this.acceptSearchPatent) {
          this.messageSearchPatent = res.data
          this.searchPatentFinished = true
          this.dataVehicle = null
          // this.selectVehicleStatus = 'ON_CREATE'
        }
        this.loadingSearchPatent = false
        this.acceptSearchPatent = true
        console.log('Busqueda finalizada')
      }
    },

    cancelSearchPatent () {
      this.dataVehicle = null
      this.acceptSearchPatent = false
      this.loadingSearchPatent = false
    },
  },
  computed: {
    ...mapGetters('customer', ['customers']),
    ...mapGetters('vehicle', ['models']),
    patentMaskFormat: () => patentMaskFormat,

    // newData
    items () {
      return this.vehicleResults.map(vehicle => vehicle)
    },
  },
  created() {
    // this.listCustomers()
    this.listModels()
    if (!this.dataVehicle) {
      this.vehicle.model = null
      this.vehicle.engine_number = null
      this.vehicle.chassis_number = null
      this.customer.name = null
      this.customer.rut = null
      this.maskaRut.unmasked = null
    }
    if (this.patent) {
      this.vehicle.patent = this.patent
    }
  },
  
  watch: {
    patent:{
      handler(val) {
        this.vehicle.patent = val
      },
      immediate: true,
    },
    dataVehicle(val) {
      this.vehicle.patent = this.dataVehicle.patente
      this.vehicle.model = this.dataVehicle.modelo
      this.vehicle.engine_number = this.dataVehicle.numero_motor
      this.vehicle.chassis_number = this.dataVehicle.numero_chasis
    },
  },
}
</script>
