<template lang="pug">
v-expansion-panels(accordion flat :disabled="!form.customer || !form.vehicle")
  v-expansion-panel
    v-expansion-panel-header.px-0.py-0
      v-icon(:style="!form.customer || !form.vehicle ? 'color: rgba(0, 0, 0, 0.12); flex: 0 0 auto' : 'color: #462DFA; flex: 0 0 auto'") mdi-bookmark
      p.mt-3.ml-5(:style="!form.customer || !form.vehicle ? 'color: rgba(0, 0, 0, 0.12); flex: 0 0 auto' : 'color: #462DFA; flex: 0 0 auto'") ¿Te gustaría guardar esta presupuesto como plantilla?
    v-expansion-panel-content
      v-row
        v-col(cols="6")
          v-text-field(style="color: #462DFA" v-model="templateName" placeholder="Nombre de la Plantilla")
        v-col(cols="6")
          v-btn.mt-3(style="background-color: #DED8FD; color: #462DFA" block @click="handleSave" :disabled="disabledButton" elevation="0") Guardar como Plantilla
      v-row(v-show="saveFavorite")
          v-icon.mr-3.ml-2.mb-3(style="color: #462DFA") mdi-check-circle
          p.mr-5(style="color: #462DFA") Plantilla guardada con éxito
          router-link.ml-5(:to="{ path: '/budgets/templates' }" @click.native="saveToLocalStorage") Ver todas las Plantillas
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      saveFavorite: false,
      options: false,
      templateName: ''
    }
  },
  props: {
    form: Object,
    saveToLocalStorage: Function
  },
  methods: {
    ...mapActions('ot', ['createTemplate']),
    formatData () {
      if (!Object.prototype.hasOwnProperty.call(this.form, 'saleItems')) {
        this.form.saleItems = this.form.sale_items
      }
      return {
        name: this.templateName,
        template_service: this.form.services.map(el => {
          return {
            service: el.service.id,
            quantity: Number.parseInt(el.service.service_quantity),
            template_pieces: el.saleItems.filter((e) => e.piece).map(el => {
              if (el.piece) {
                return {
                  piece: el.piece.id,
                  quantity: el.quantity
                }
              }
            }),
          }
        })
      }
    },
    handleSave () {
      this.saveFavorite = false
      if (this.form.services.length > 0) {
        this.createTemplate({ data: this.formatData() }).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              this.saveFavorite = true
            }
          }
        )
      }
    }
  },
  computed: {
    disabledButton () {
      if (this.form.services || this.form.saleItems) {
        if (this.form.services?.length > 0 || this.form.saleItems?.length > 0) {
          return this.templateName === ''
        }
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
