<template lang="pug">
v-container(v-if="OT").pt-0
  .external-card-wrapper
    v-card.rounded-xl
      .card-wrapper
        div.client-img.graphics
        .card-details.flex-grow-1
          v-card-title.pl-0 Datos del Cliente
            v-icon.ml-auto(@click="openCustomerForm = !openCustomerForm") mdi-square-edit-outline
          v-card-text.py-0.pl-0 {{ customerData.full_name }}
          v-card-text.py-0.pl-0 {{ customerData.address }}
          v-card-text.py-0.pl-0 (+56) {{ customerData.contact | cellFilter}}
          v-card-text.py-0.pl-0 {{ customerData.email}}
    v-card.rounded-xl
      .card-wrapper
        div.vehicle-img.graphics
        .card-details.flex-grow-1
          v-card-title.pl-0 Datos del Vehículo
            v-icon.ml-auto(@click="openModalVehicle") mdi-square-edit-outline
          v-card-text.py-0.pl-0 {{ vehicleData.patent }}
          v-card-text.py-0.pl-0 {{ vehicleData.model_display }}
          v-card-text.py-0.pl-0 N° motor: {{ vehicleData.engine_number }}
          v-card-text.py-0.pl-0 N° chasis: {{ vehicleData.chassis_number }}
          v-card-text.py-0.pl-0 Color: {{ vehicleData.color ? vehicleData.color : 'No registrado' }}
          v-card-text.py-0.pl-0(v-if="vehicle_milage") {{ vehicle_milage }} Kms.
          v-card-text.py-0.pl-0(v-if="!haveMilage") Kilometraje no registrado.
    v-card.rounded-xl
      .card-wrapper
        div.workers-img.graphics
        .card-details.flex-grow-1
          v-card-title.pl-3 Asignado a
            .ml-auto
              //- Comentarios de diagnóstico
              v-tooltip(v-if="hasEvaluationComments" top color="#C3D1FF")
                template(v-slot:activator="{ on, attrs }")
                  v-btn.tech-comment-button(
                    icon large v-bind="attrs" v-on="on" color="#3658D3"
                    @click="openCommentModal = !openCommentModal"
                  )
                    v-icon mdi-message-reply-text
                span.d-flex.align-center.tech-comment-tooltip
                  v-icon.ml-n1.mr-3(small) mdi-information-outline
                  span
                    strong Comentarios del técnico
                    br
                    | hechos en el diagnostico
              v-btn(v-else icon large disabled)
                v-icon mdi-message-reply-text

              //- Info
              v-tooltip(bottom color="purple lighten-3")
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon large v-bind="attrs" v-on="on")
                    v-icon mdi-information-outline
                span Aquí puedes asignar/reasignar un técnico para diágnostico y/o reparación.
          div
            OTEvaluationTechForm(:OTId="OT.id" :techId="OT.evaluation_tech" :state="OT.state" :available="OT.state === 'Ingresado' || OT.state === 'En diagnóstico' || OT.state ==='Borrador'" @showAlert="successAction")
          div.mt-3
            OTServiceTechForm(v-if="checkEstimatesState()" :services="services" :ot="OT" @save="successAction" :available="OT.state === 'Entregado'")
  SideModal(v-model="openCustomerForm" max-width="700")
    v-container
      h2.mb-5 Editar Datos del Cliente
      CustomerForm(ref="CustomerForm" :customerSelected="customerData" @save="handlerCustomerSave")
  SideModal(v-model="openVehicleForm" max-width="700")
    v-container
      h2.mb-5 Editar Datos del Vehículo
      VehicleForm(ref="VehicleForm" :vehicleSelected="OT.vehicle_data" @save="handlerVehicleSave" :patent="vehicleData.patent")
  CommentModal(
    v-model="openCommentModal"
    :OT="OT"
  )
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { cellFilter } from '@/utils/filters.js'
import CustomerForm from '../../customer/CustomerForm.vue'
import VehicleForm from '../../vehicle/VehicleForm.vue'
import SideModal from '../../shared/SideModal.vue'
import OTEvaluationTechForm from '../subcomponent/OTEvaluationTechForm.vue'
import OTServiceTechForm from '../subcomponent/OTServiceTechForm.vue'
import CommentModal from './subcomponents/CommentModal.vue'

export default {
  components: {
    CustomerForm,
    VehicleForm,
    SideModal,
    OTServiceTechForm,
    OTEvaluationTechForm,
    CommentModal,
  },
  filters: { cellFilter },
  data() {
    return {
      openCustomerForm: false,
      openVehicleForm: false,
      openCommentModal: false,
    }
  },
  props: {
    vehicle_milage: String,
    haveMilage: {
      type: Boolean,
      default: true,
    },
    services: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('ot', ['OT']),
    ...mapGetters('user', ['technicians']),
    customerData() {
      return this.OT ? this.OT.customer_data : {}
    },
    vehicleData() {
      // console.log(this.OT.vehicle_data)
      return this.OT ? this.OT.vehicle_data : {}
    },
    technicianData() {
      return this.OT ? this.OT.estimate : []
    },
    hasEvaluationComments() {
      return this.OT.estimate.some(
        (e) => e.evaluation && e.evaluation.evaluation_comment != null
      )
    },
  },
  methods: {
    ...mapActions('app', ['setTitle', 'setSubtitle']),
    getTechById(id) {
      return this.technicians.find((el) => el.id === id)
    },
    getAvatarText(id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    handlerCustomerSave(data) {
      this.openCustomerForm = !this.openCustomerForm
      this.$emit('showAlert', {
        type: 'success',
        message: 'Los datos del Cliente han sido editados',
        show: true,
        color: 'green',
      })
    },
    handlerVehicleSave(data) {
      this.openVehicleForm = !this.openVehicleForm
      // console.log('data del evento', data)
      // console.log('this.vehicleData', this.vehicleData)
      this.$emit('showAlert', {
        type: 'success',
        message: 'Los datos del Vehículo han sido editados',
        show: true,
        color: 'green',
      })
    },
    openModalVehicle () {
      this.openVehicleForm = !this.openVehicleForm
      console.log('this.SelectedVehicle', this.vehicleData)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('cl-ES', {
        dateStyle: 'short',
        timeStyle: 'short',
        hour12: true,
      }).format(new Date(date))
    },
    checkEstimatesState() {
      // return this.OT.estimate.some(
      //   (e) => e.state === 'Presupuesto aprobado' || e.state === 'En reparación'
      // )
      return true;
    },
    successAction(data) {
      this.$emit('showAlert', data)
    },
  },
  destroyed () {
    this.setSubtitle('')
  },
  watch: {
    OT() {
      this.setTitle(`OT ${this.OT.id}`)
      this.setSubtitle(
        `Fecha de creación: ${this.formatDate(this.OT.created_at)}`
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.v-card {
  color: #3658d3;
  padding-bottom: 20px;
}
h2 {
  color: #3658d3;
}
.v-card__title,
.v-card__text {
  @media (min-width: 1025px) and (max-width: 1600px) {
    font-size: 0.9rem;
  }
  @media (max-width: 1024px) {
    font-size: 0.8rem;
    margin-left: 7px;
  }
}
.graphics {
  width: 150px;
  height: 150px;
  margin-left: 16px;

  @media (min-width: 1025px) and (max-width: 1600px) {
    width: 100px !important;
    height: 100px !important;
    margin-left: 10px !important;
  }
  @media (max-width: 1024px) {
    width: 80px !important;
    height: 80px !important;
    margin-left: 5px !important;
  }
}
.client-img {
  background-image: url('../../../assets/img/client.png');
  background-size: cover;
  @media (width >= 320px) and (width <= 375px) {
    display: none;
  }
}
.vehicle-img {
  background-image: url('../../../assets/img/vehicle.png');
  background-size: cover;
  @media (width >= 320px) and (width <= 375px) {
    display: none;
  }
}
.workers-img {
  background-image: url('../../../assets/img/workers.png');
  background-size: cover;
  @media (width >= 320px) and (width <= 375px) {
    display: none;
  }
}
.tech-comment-tooltip {
  color: #3658d3;
}
.tech-comment-button:not(:hover)::before {
  opacity: 0.15;
}
.tech-comment-button:hover::before {
  opacity: 0.3;
}
.external-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;

  > .v-card {
    flex-grow: 1;
  }

  @media (min-width: 1025px) and (max-width: 1600px) {
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.card-wrapper {
  height: 100%;
  padding-top: 15px;

  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 30px;

  @media (min-width: 1263px) and (max-width: 1600px) {
    gap: 10px;
    padding-top: 10px;
  }

  @media (max-width: 1263px) {
    gap: 5px;
    padding-top: 5px;
    padding-left: 15px;
    flex-direction: column;
  }
}
.card-details {
  @media (max-width: 1024px) {
    width: 100%;
  }
  & > .v-card__title {
    @media (min-width: 1025px) and (max-width: 1600px) {
      margin-left: 5px;
      padding: 10px;
    }

    @media (width >= 1024px) {
      padding: 0 15px 0 0;
      margin-left: 0;
      padding: 5px;
    }

    @media (width >= 320px) and (width <= 375px) {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
