<template lang="pug">
v-container
  div
    br
    v-data-table(:headers="headers" class="elevation-0" hide-default-footer :items="templates" :loading="loading" loading-text="Buscando coincidencias..." no-data-text="No se encontraron coincidencias")
      template(v-slot:item.updated_at="{ item }")
        p {{ formatDate(item.updated_at) }}
      template(v-slot:item.created_by="{ item }")
        p {{ item.created_by.first_name }} {{ item.created_by.last_name }}
      template(v-slot:item.detail="{ item }")
        v-btn(icon large @click="openModalTemplate(item)")
          v-icon mdi-eye-outline
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  ModalTemplate(ref="ModalTemplate" :template="templateSelected" @use="useTemplate" :modalTitle="'Plantilla ' + templateSelected.name" buttonTitle="Usar Plantilla")
  OTForm(ref="OTForm" :formData="form")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ModalTemplate from './ModalTemplate.vue'
import OTForm from '../sale/OTForm.vue'
export default {
  props: {
    searchParam: {
      type: String,
      default: ''
    }
  },
  components: {
    ModalTemplate,
    OTForm
  },
  data () {
    return {
      loading: false,
      page: 1,
      rowCount: 0,
      form: null,
      templateSelected: {},
      headers: [
        // { text: 'ID', value: 'id' },
        { text: 'Nombre Plantilla', value: 'name' },
        { text: 'Fecha de creación', value: 'updated_at' },
        { text: 'Creado por', value: 'created_by' },
        { text: 'Acciones', value: 'detail' }
      ],
      templates: [],
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters('service', ['services']),
    ...mapGetters('inventory', ['pieces']),
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  methods: {
    ...mapActions('ot', ['getTemplates']),
    ...mapActions('service', ['listServices']),
    ...mapActions('inventory', ['listPieces']),
    async fetchData (search = '') {
      this.loading = true
      this.searchTerm = search !== '' ? search : ''
      await this.getTemplates({ params: { page: this.page, query: search } }).then(
        (response) => {
          this.templates = response.data.results
          this.rowCount = response.data.count
          this.loading = false
        })
      await this.listServices()
      await this.listPieces()
    },
    openModalTemplate (item) {
      this.templateSelected = item
      this.templateSelected.services = item.template_service.map(ts => {
        return {
          ...this.services.filter(s => s.id === ts.service)[0],
          service_quantity: ts.quantity,
          saleItems: ts.template_pieces.map(tp => {
            return {
              piece: this.pieces.filter(p => p.id === tp.piece)[0],
              quantity: tp.quantity,
              total_price: this.pieces.filter(p => p.id === tp.piece)[0].price * tp.quantity
            }
          })
        }
      })
      this.$refs.ModalTemplate.open()
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    useTemplate () {
      this.form = this.templateSelected
      this.$refs.OTForm.open()
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    page (newVal) {
      if (newVal !== 1) {
        this.fetchData(this.searchTerm)
      }
    },
    searchParam (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1
      }
      this.fetchData(newVal)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-data-table::v-deep th {
  font-size: 14px !important;
  font-weight: 400;
  background-color: #E4EAFF;
}
.v-icon:hover {
color: #0000FF;
}

</style>
