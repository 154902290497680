import { Mask } from 'maska'

const rutMask = new Mask({
  mask: '1.11#-V',
  tokens: {
    1: { pattern: /[0-9]/, repeated: true },
    V: { pattern: /[0-9Kk]/ }
  },
  reversed: true
})

const cellMask = new Mask({
  mask: '# #### ####'
})

export const rutFilter = (value) => value ? rutMask.masked(value) : ''
export const cellFilter = (value) => cellMask.masked(value)
