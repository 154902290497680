import axios from '../../plugins/axios'

export const listSales = ({ commit }, { params } = {}) => {
  return axios.get('inventory/sale/', { params })
    .then(res => {
      commit('SET_SALES', res.data.results)
      return res
    })
    .catch(err => err.response)
}

export const getSale = ({ commit }, { saleId, params } = {}) => {
  return axios.get(`inventory/sale/${saleId}/`, { params })
    .then(res => {
      commit('SET_SALE', res.data)
      return res
    })
    .catch(err => err.response)
}

export const createSale = (_, { data } = {}) => {
  return axios.post('inventory/sale/', data)
    .then(res => res)
    .catch(err => err.response)
}
