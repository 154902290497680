<template lang="pug">
div
    WorkCardDiagnostic(:data="evaluations" v-if="evaluations.length > 0")
    h4.mensaje(v-else) No tienes diagnósticos asociados por ahora.
</template>
<script>
import { mapGetters } from 'vuex'
import WorkCardDiagnostic from './WorkCardDiagnostic.vue'

export default {
  components: {
    WorkCardDiagnostic
  },
  computed: {
    ...mapGetters('ot', ['evaluations'])
  }
  
}
</script>
<style lang="scss" scoped>
.mensaje {
  text-align: center;
  margin-top: 20px;
  color: #A3A2A1;
}
</style>
