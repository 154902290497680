<template lang="pug">
SideModal(:value="value" @input="$emit('input', $event)" max-width="80%")
  h2.primary--text Nuevos repuestos
  .mb-4 Completa los datos para añadir stock de los repuestos

  v-form(ref="formNewPieces" lazy-validation @submit.prevent="submit")
    .new-piece-text
      span Ingresa nuevo repuesto
      span Aquí puedes ingresar los detalles del nuevo repuesto
    NewPieceFormItem(
      v-for="(item, index) in pieces"
      :key="`${index}-${item.new_piece_name}`"
      :piece="item"
      :suppliers="suppliers"
    )
    .flex-row.justify-end.gap-4
      v-btn.action-btn(@click="$emit('input', false)" color="primary" outlined) Cancelar
      v-btn.action-btn(@click="submit" color="primary") Crear repuesto{{ pieces.length > 1 ? 's' : '' }}
</template>

<script>
import { mapActions } from 'vuex'
import NewPieceFormItem from './NewPieceFormItem.vue'
import SideModal from '@/components/shared/SideModal.vue'

export default {
  components: {
    NewPieceFormItem,
    SideModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    itemsToCreate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pieces: [],
      suppliers: [],
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.init()
      }
    },
  },
  methods: {
    ...mapActions('inventory', ['listSuppliers', 'createSupplier']),
    fetchData() {
      this.listSuppliers().then((response) => {
        this.suppliers = response.data
      })
    },
    init() {
      this.pieces = this.itemsToCreate.map((item) => {
        return {
          supplier_id: null,
          supplier_piece_code: '',
          code: '',
          name: item.new_piece_name,
          purchase_price: 0,
          sale_price: item.unit_price,
          stock: 0,
          serviceIndex: item.serviceIndex,
          pieceIndex: item.pieceIndex,
        }
      })
    },

    async submit() {
      if (!this.$refs.formNewPieces.validate()) {
        return
      }
      const data = this.pieces.map((piece) => {
        return {
          ...piece,
          purchase_price: parseInt(piece.purchase_price),
          sale_price: parseInt(piece.sale_price),
          stock: parseInt(piece.stock),
          serviceIndex: undefined,
          pieceIndex: undefined,
        }
      })
      const res = await this.$store.dispatch('inventory/createNewPieces', {
        pieces: data,
      })
      if (res.status < 200 || res.status >= 300) {
        this.$toast.error('Error al crear los repuestos')
      }
      const createdPieces = res.data.map((piece, index) => {
        const originalPiece = this.pieces.find((p) => p.code === piece.code)
        return {
          ...piece,
          serviceIndex: originalPiece.serviceIndex,
          pieceIndex: originalPiece.pieceIndex,
        }
      })
      this.$emit('created', createdPieces)
      this.$emit('input', false)
      this.$toast.success('Repuestos creados correctamente')
    },
  },
  created() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.action-btn {
  width: 281px;
  height: 56px !important;
}
.new-piece-text {
  display: flex;
  flex-direction: column;
  color: #4c7aec;

  > span:first-child {
    font-weight: bold;
  }
}
</style>
