<template lang="pug">
SideModal(v-model="isOpen" max-width="700")
  div(v-if="isOpen")
    h2.primary--text {{ modalTitle }}
    div.mt-3(style="background-color: #F0F3FF;")
      v-col(cols=12)
          OTServiceDisplay(:services="form.services")
          .total_price
              label Total
              strong  {{totalPrice | currency}}
          .mb-4(v-if="showErrors")
            .red--text(v-for="error in errors") {{error}}
          v-btn(block color="primary" x-large @click="useTemplate") {{ buttonTitle }}
</template>
<script>
import SideModal from '../shared/SideModal.vue'
import OTServiceDisplay from './OTServiceDisplay.vue'
export default {
  components: {
    SideModal,
    OTServiceDisplay
  },
  data () {
    return {
      isOpen: false,
      selectVehicleStatus: 'UNSELECTED',
      showErrors: false,
      errors: [],
      success: false,
      searchCutomer: null,
      form: {
        services: [],
        vehicle: null,
        saleItems: [],
        customer: null,
        clientComments: null,
        requires_evaluation: true
      }
    }
  },
  props: {
    template: Object,
    modalTitle: String,
    buttonTitle: String
  },
  methods: {
    open () {
      this.isOpen = true
    },
    useTemplate () {
      this.isOpen = false
      this.$emit('use')
    }
  },
  computed: {
    totalPrice () {
      let total = 0
      for (let i = 0; i < this.form.services.length; i++) {
        if (this.form.services[i].saleItems) {
          total += this.form.services[i].saleItems.reduce((acum, el) => acum + el.total_price, 0)
        }
      }
      if (this.form.services.length > 0 && this.form.services[0] && this.form.services[0].service_quantity && this.form.services[0].id) {
        total += this.form.services.filter(el => el.service !== null).reduce((acum, el) => acum + (el.price * Number.parseInt(el.service_quantity)), 0)
      }
      return total
    }
  },
  watch: {
    template (newValue) {
      this.form.services = newValue.services
      this.form.saleItems = newValue.saleItems
    }
  }
}
</script>
<style lang="scss" scoped>
.total_price {
  font-size: 18px;
  text-align: right;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
