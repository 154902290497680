<template lang="pug">
div(v-if="ot")
  v-card(min-height="100%" flat)
    v-card-text(style="height: 100%;")
      div.payment-form(style="height: 100%;")
        div(v-if="ot" style="height: 100%;")
          .title Detalle para {{ot.customer_name}}
          div(v-for="(estimate, index) in ot.estimate.filter(e => e.is_final_payment_enabled)" :key="index")
            v-row
              v-col(cols="8")
                p.dateCreated Fecha de Ingreso: {{ formatDate(ot.created_at) }}
              v-divider
              v-expansion-panels(accordion)
                v-expansion-panel
                  v-expansion-panel-header.ml-5.title Presupuesto n° {{ estimate.id }}
                  v-expansion-panel-content
                    v-row
                      v-col(cols="6")
                        v-card.diagnostic-comments(tile elevation="0")
                            v-card-text Diagnóstico: {{ estimate.client_comments }}
                    v-row
                      v-col(cols="12")
                        strong.ml-4.mb-2 Servicios
                        v-data-table(dense :headers="tableBudget.headers" hide-default-footer :items="estimate.estimate_services")
                          template(v-slot:item.name="{ item }")
                            p.mt-2.mb-1 {{item.service_data.name}}
                          template(v-slot:item.quantity="{ item }")
                            p.mt-2.mb-1 x{{ item.service_quantity }}
                          template(v-slot:item.price="{ item }")
                            p.mt-2.mb-1 {{ item.service_data.price | currency }}
                      v-row
                        v-col(cols="12")
                          div.d-flex.justify-end.align-end.mr-5.subtotal-budget
                            strong Subtotal: {{ sumServicesPrices(estimate.estimate_services) | currency }}
                    v-row
                      v-col(cols="12")
                        strong.ml-4.mb-2 Repuestos
                        v-data-table(dense :headers="tableSaleItems.headers" hide-default-footer :items="estimate.sale_items_data")
                          template(v-slot:item.model="{ item }")
                            p {{ item.piece.name }} - {{ item.piece.brand_name }}
                          template(v-slot:item.quantity="{ item }")
                            p x{{item.quantity }}
                          template(v-slot:item.price="{ item }")
                            p {{ item.piece.price | currency }}
                      v-row
                        v-col(cols="12")
                          div.d-flex.justify-end.align-end.mr-5.subtotal-items
                            strong.mb-3 Subtotal: {{ sumItemsPrices(estimate.sale_items_data) | currency }}
                    div.d-flex.justify-end.align-end.mt-5
                      strong(style="font-size: 16px;") Total Presupuesto {{ estimate.id }}: #[strong {{ sumBudgetsPrices(estimate.estimate_services, estimate.sale_items_data) | currency }}]
              v-divider
            div.d-flex.justify-end(style="margin-top: 5%;")
              div(style="text-align: right;")
                span(v-for="item in ot.estimate.filter(e => e.state === 'Listo')" :key="item.id")
                  p Total Presupuesto {{ item.id }}: #[strong {{ sumBudgetsPrices(item.estimate_services, item.sale_items_data) | currency }}]
                span(v-for="(item, index) in budgets" :key="index")
                  p(v-if="item.state === 'Pagado'") Abonado el ({{ formatDate(item.transaction_date) }}) : #[strong {{ item.payment_total | currency }}]
                p.title(style="font-size: 20px;") Saldo pendiente: #[strong {{ pending_payment | currency }}]
  v-btn.mt-4(block color="primary" x-large @click="onSubmit(true)" :loading="isLoading") Pagar
</template>

<script>
import { mapActions } from 'vuex'
import VehicleAndCustomerDetailBox from '../vehicle/VehicleAndCustomerDetailBox.vue'
import CustomerAndVehicleForm from '../vehicle/CustomerAndVehicleForm.vue'

export default {
  components: {
    VehicleAndCustomerDetailBox,
    CustomerAndVehicleForm
  },
  data () {
    return {
      ot: null,
      budgets: [],
      pending_payment: 0,
      form: {
        estimates: []
      },
      tableBudget: {
        headers: [
          { text: 'Servicios a realizar', value: 'name', width: '150px' },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center' },
          { text: 'Precio', value: 'price', width: '100px' }
        ]
      },
      tableSaleItems: {
        headers: [
          { text: 'Modelo', value: 'model', width: '150px' },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center' },
          { text: 'Precio Unitario', value: 'price', width: '100px' }
        ]
      },
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('ot', ['getOTByToken', 'approvedPayments', 'getOT']),
    ...mapActions('payment', ['pay']),
    async onSubmit () {
      this.isLoading = true
      const data = {
        id: this.form.estimates.map(el => el.id),
        deposit: this.pending_payment,
        description: 'Orden de trabajo',
        token: this.$route.params.token
      }
      await this.pay({ data })
      this.isLoading = false
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    sumServicesPrices (services) {
      const initialValue = 0
      const total = services.map(s => {
        return s.service_data.price * s.service_quantity
      })
      return total.reduce((acc, curr) => { return acc + curr }, initialValue)
    },
    sumItemsPrices (items) {
      const initialValue = 0
      return items.reduce((acc, curr) => { return acc + curr.total_price }, initialValue)
    },
    sumBudgetsPrices (services, items) {
      return this.sumServicesPrices(services) + this.sumItemsPrices(items)
    }
  },
  async created () {
    const token = this.$route.params.token
    this.ot = null
    const response = await this.getOTByToken({ params: { token } })
    if (response.status >= 200 && response.status < 300) {
      this.ot = response.data
      this.form.estimates = this.ot.estimate.filter(e => e.is_final_payment_enabled)
      this.approvedPayments({ otID: this.ot.id }).then(
        (response) => {
          if (response.status === 200) {
            this.budgets = response.data === '' ? [] : response.data
          }
        }
      )
      this.getOT({ OTId: this.ot.id }).then(
        (response) => {
          if (response.status === 200) {
            this.pending_payment = response.data.pending_payment
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.dateCreated {
  color: #464544;
  margin-top: 30px !important;
  margin-left: 30px !important;
  font-weight: 600;
}
.title {
  color: #4C7AEC;
  font-size: 22px !important;
  margin-left: 30px !important;
}
.subtotal-budget, .subtotal-items  {
  height: 100%;
}
.diagnostic-comments {
  width: 100%;
  margin: 5px;
  padding: 5px;
  background-color: #EBF0F9;
  font-size: 16px;
  color: #81807E;
}
</style>
