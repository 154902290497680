<template lang="pug">
div
    WorkCardDiagnostic(:data="diagnosed" v-if="diagnosed.length > 0" :is-diagnosed="true")
    h4.mensaje(v-else) No tienes trabajos diagnosticados por ahora.
</template>
<script>
import { mapGetters } from 'vuex'
import WorkCardDiagnostic from './WorkCardDiagnostic.vue'
export default {
  components: {
    WorkCardDiagnostic
  },
  computed: {
    ...mapGetters('ot', ['diagnosed'])
  }
}
</script>
<style lang="scss" scoped>
.mensaje {
  text-align: center;
  margin-top: 20px;
  color: #A3A2A1;
}
</style>
