<template lang="pug">
v-container
  alert(ref="alert")
  div.totalDebt
    p.mr-5.pt-5.subtitle(style="color: #fff") Debes cobrar un total de
    h1.ml-8(style="color: #fff") {{ totalToReceive | currency }}
  v-col.mt-3.d-flex(cols="6")
    v-autocomplete.mr-3(outlined color="primary" dense label="Convenios" :items="agreements" item-text="name" item-value="id" hide-details prepend-inner-icon="mdi-office-building" v-model="agreementSelected" :disabled="clientSelected !== null" @change="fetchData(agreementSelected, 'agreement')" clearable @click:clear="fetchData()" no-data-text="No existe ese convenio")
    v-autocomplete(outlined color="primary" dense label="Clientes" :items="clientsWithAccountReceivable" item-text="full_name" item-value="id" hide-details prepend-inner-icon="mdi-account" v-model="clientSelected" :disabled="agreementSelected !== null" @change="fetchData(clientSelected, 'customer')" clearable @click:clear="fetchData()" no-data-text="No existe ese cliente")
  v-data-table(
    :headers="headers" ds
    class="elevation-0"
    :items="pendings"
    hide-default-footer
    no-data-text="No hay cuentas por cobrar"
    :loading="table.loading"
    loading-text="Cargando Movimientos..."
    )
    template(v-slot:item.work_order="{ item }")
      p(v-if="item.sale_data.customer") N° venta{{item.sale_data.id}}
      p(v-else) N° {{ item.work_order }} - ({{ item.estimates_to_pay[0] }})
    template(v-slot:item.client="{ item }")
      p(v-if="item.sale_data.customer") {{item.sale_data.customer_name}}
      .d-flex(v-else)
        p {{ item.client }}
        p(v-if="item.agreement") &nbsp({{ item.agreement.name }})
    template(v-slot:item.created_at="{ item }")
      p {{ formatDate(item.created_at) }}
    template(v-slot:item.description="{ item }")
      p(v-if="item.payment_gateway === 'Cheque'") {{ item.description }} <br/> {{ item.check_number && `Cheque ${item.check_qty} - N° ${item.check_number}` }}
      a.mr-2(v-if="item.sale_data.id" @click="$refs.saleDetail.open(item.sale_data.id)") {{ item.sale_data.id && `${item.description} N° ${item.sale_data.id}` }}
    template(v-slot:item.payment_total="{ item }")
      p {{ item.payment_total | currency }}
    template(v-slot:item.actions="{ item }")
      v-btn(style="background-color: #E1EAFF; color: #407BFF" elevation="0" @click="handleOpenConfirmPayment(item)")
        v-icon.mr-2(style="color: #407BFF" dense outline) mdi-cash-100
        span Cobrar
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  SaleDetail(ref="saleDetail")
  v-dialog(v-model="confirmPayment" transition="dialog-top-transition" width="450" content-class="elevation-0" style="border-radius: 16px;")
    v-card(style="padding: 50px 20px 20px 20px;")
      div.payment-img
      v-card-title.justify-center.mb-3(style="color: #4C7AEC;") ¿Deseas confirmar pago?
      v-card-subtitle(style="text-align: center;" v-if="selectedPendingReceivable !== null") Nuestro cliente está haciendo un pago por {{ selectedPendingReceivable.payment_total | currency }} correspondiente al Presupuesto N° {{ selectedPendingReceivable.estimates_to_pay[0] }} de la OT {{ selectedPendingReceivable.work_order }}
      v-card-actions
          v-col
            v-btn.mb-3(color="#4C7AEC" dark block large @click="handleReceivePaymentAgreement") Confirmar
            v-btn(block elevation="0" color="#4C7AEC" large outlined @click="confirmPayment = false") Cancelar
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../shared/alert.vue'
import SaleDetail from '../sale/SaleDetail.vue'
export default {
  components: {
    alert,
    SaleDetail,
  },
  data () {
    return {
      table: {
        loading: false
      },
      page: 1,
      pageCount: 0,
      headers: [
        { text: 'OT(ppto) o Venta', value: 'work_order' },
        { text: 'Cliente(Convenio Empresa)', value: 'client' },
        { text: 'Fecha de Ingreso', value: 'created_at' },
        { text: 'Descripción', value: 'description' },
        { text: 'Monto', value: 'payment_total' },
        { text: '', value: 'actions' }
      ],
      pendings: [],
      confirmPayment: null,
      selectedPendingReceivable: null,
      totalToReceive: 0,
      agreements: [],
      agreementSelected: null,
      clientsWithAccountReceivable: [],
      clientSelected: null
    }
  },
  methods: {
    ...mapActions('inventory', ['listAccountReceivable', 'receivePaymentAgreement', 'listAgreements', 'listClientsWithAccountReceivable']),
    fetchData (searchID = null, searchParam = null) {
      this.table.loading = true
      let param = null
      if (searchParam !== null) {
        param = searchParam === 'agreement' ? { agreement: searchID } : { customer: searchID }
      }
      this.listAccountReceivable({ params: { ...param, page: this.page }}).then(
        response => {
          if (response.status === 200) {
            this.pendings = response.data.results
            this.totalToReceive = this.pendings.reduce((acum, el) => acum + el.payment_total, 0)
            this.pageCount = Math.ceil(response.data.count / 10)
          } else {
            this.pendings = []
            this.pageCount = 0
          }
        }
      )
      this.listAgreements().then(
        response => {
          if (response.status === 200) {
            this.agreements = response.data
          }
        }
      )
      this.listClientsWithAccountReceivable().then(
        response => {
          if (response.status === 200) {
            this.clientsWithAccountReceivable = response.data
          }
        }
      )
      this.table.loading = false
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    handleOpenConfirmPayment (item) {
      this.confirmPayment = !this.confirmPayment
      this.selectedPendingReceivable = item
    },
    handleReceivePaymentAgreement () {
      this.receivePaymentAgreement({ data: this.selectedPendingReceivable, id: this.selectedPendingReceivable.id }).then(
        response => {
          if (response.status === 200) {
            this.confirmPayment = false
            this.fetchData()
            this.$refs.alert.handleShowAlert('success', 'Pago recibido con éxito', true, 'green')
          }
        }
      )
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.totalDebt {
  height: 100px;
  background-image: url('./../../assets/img/total-cobrar.png');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}
.subtitle {
  font-size: 16px;
  margin-left: 32px;
}
.v-application p {
  margin-bottom: 0;
}
.payment-img {
  height: 160px;
  background-image: url('./../../assets/img/payment.png');
  background-size: contain;
  background-position: center;
}
</style>
