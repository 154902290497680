<template lang="pug">
div
  ResumeSolicitudeSupplier
</template>
<script>
import ResumeSolicitudeSupplier from '../components/stock/ResumeSolicitudeSupplier.vue'
export default {
  components: {
    ResumeSolicitudeSupplier
  }
}
</script>
