<template lang="pug">
.flex-col.gap-1
  v-text-field.search-field(
    v-model="search"
    placeholder="Buscar"
    clearable
    outlined dense hide-details
  )
  v-data-table.ot-table(
    :headers="headers"
    :items="filteredResults"
    :loading="loading"
    :page.sync="page"
    :server-items-length="totalItems"
    loading-text="Cargando datos..."
    hide-default-footer
    no-data-text="No se encontraron cuentas por cobrar"
    :footer-props="{ \
      disableItemsPerPage: true, \
      disablePagination: loading, \
      itemsPerPageText: 'Ítems por página', \
    }"
  )
    template(#item.work_order="{ value }")
      span N° {{ value }}
    template(#item.payment_gateway="{ item, value }")
      span {{ value }}
      span(v-if="item.description")  ({{ item.description }})
    template(#item.payment_total="{ value }")
      span {{ value | currency }}
    template(#item.created_at="{ value }")
      span {{ value | formattedDate }}
    template(#item._actions="{ item }")
      v-btn.btn-detail.primary--text(
        v-if="item.sale"
        text
        @click="$refs.saleDetail.open(item.sale_data.id)"
      ) Saber más

  SaleDetail(ref="saleDetail")
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'vuex'
import { useCustomerListStore } from '@/stores/views/useCustomerListStore'
import SaleDetail from '@/components/sale/SaleDetail.vue'

export default {
  components: { SaleDetail },

  data() {
    return {
      results: [],
      loading: false,
      search: '',
      page: 1,
      totalItems: 0,
    }
  },

  computed: {
    ...mapState(useCustomerListStore, ['selectedCustomer']),

    headers() {
      return [
        { text: 'OT', value: 'work_order' },
        { text: 'Tipo', value: 'payment_gateway' },
        { text: 'ID', value: 'id' },
        { text: 'Monto', value: 'payment_total' },
        { text: 'Fecha emisión', value: 'created_at' },
        { text: 'Detalle', value: '_actions' },
      ]
    },

    filteredResults() {
      if (!this.search) return this.results
      return this.results.filter((item) => {
        const search = this.search.toLowerCase()
        return (
          item.work_order.toLowerCase().includes(search) ||
          item.payment_gateway.toLowerCase().includes(search) ||
          item.id.toString().includes(search) ||
          item.payment_total.toString().includes(search) ||
          item.created_at.toLowerCase().includes(search)
        )
      })
    },

    queryParams() {
      return {
        page: this.page,
        customer: this.selectedCustomer?.id,
      }
    },
  },

  watch: {
    queryParams: {
      handler(val) {
        if (!val == null) return
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions('inventory', ['listAccountReceivable']),

    async fetch() {
      this.loading = true
      const res = await this.listAccountReceivable({ params: this.queryParams })
      if (res.status < 200 || res.status >= 300) {
        this.loading = false
        return this.$toast.error('Error al cargar lista de vehículos')
      }

      this.results = res.data.results
      this.totalItems = res.data.count
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-detail {
  padding: 0 !important;
  height: auto !important;
  text-decoration: underline;
}

.seearch-field {
  max-width: 260px;
  &::v-deep .v-input__slot {
    min-height: 48px !important;
  }
}
</style>
