import axios from '../../plugins/axios'

export const listCustomers = ({ commit }, { params } = {}) => {
  return axios.get('order/customer/', { params })
    .then(res => {
      commit('SET_CUSTOMERS', res.data)
      return res
    })
    .catch(err => err.response)
}

export const getCustomer = ({ commit }, { customerId, params } = {}) => {
  return axios.get(`order/customer/${customerId}/`, { params })
    .then(res => {
      commit('SET_CUSTOMER', res.data)
      return res
    })
    .catch(err => err.response)
}

export const createCustomer = (_, { data } = {}) => {
  return axios.post('order/customer/', data)
    .then(res => res)
    .catch(err => err.response)
}

export const editCustomer = (_, { customerID, data } = {}) => {
  return axios.put(`order/customer/${customerID}/`, data)
  .catch(err => err.response)
}

export const createCustomerAndVehicle = (_, { data } = {}) => {
  return axios.post('order/create_customer_and_vehicle/', data)
    .then(res => res)
    .catch(err => err.response)
}

export const exportCustomersXls = (_) => {
  return axios.post(`/order/customer/export_customers/`, {}, { responseType: 'blob' })
    .catch(err => err.response)
}
