import axios from '../../plugins/axios'

export const createEstimate = (_, { data } = {}) => {
  return axios.post('inventory/estimate/', data)
    .catch(err => err.response)
}

export const listEstimates = (_, { params } = {}) => {
  return axios.get('inventory/estimate/', { params })
    .catch(err => err.response)
}

export const getEstimate = (_, { estimateId } = {}) => {
  return axios.get(`inventory/estimate/${estimateId}`)
    .catch(err => err.response)
}

export const setEstimateEvaluationTech = (_, { estimateId, data } = {}) => {
  return axios.put(`inventory/estimate/${estimateId}/set_estimate_evaluation_tech/`, data)
    .catch(err => err.response)
}

export const sendEstimate = (_, { estimateId, data } = {}) => {
  return axios.put(`inventory/estimate/${estimateId}/send_estimate/`, data)
    .catch(err => err.response)
}

export const setEstimateEvaluation = (_, { estimateId, data } = {}) => {
  return axios.put(`inventory/estimate/${estimateId}/set_estimate_evaluation/`, data)
    .catch(err => err.response)
}

export const updateEstimate = (_, { estimateId, data } = {}) => {
  return axios.put(`inventory/estimate/${estimateId}/`, data)
    .catch(err => err.response)
}

export const restoreOriginalEstimate = (_, { estimateId, data } = {}) => {
  return axios.put(`/inventory/estimate/${estimateId}/restore_estimate/`, data)
    .catch(err => err.response)
}
