import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const SaleStore = {
  namespaced: true,
  state: {
    OTs: [],
    OT: null,
    budgets: [],
    evaluations: [],
    jobs: [],
    diagnosed: [],
    payments: [],
    transitions: [],
    repaired: [],
    templates: [],
    evaluationsCount: 0,
    jobsCount: 0,
    diagnosedCount: 0,
    repairedCount: 0,
  },
  getters,
  mutations,
  actions
}

export default SaleStore
