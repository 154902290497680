<template lang="pug">
v-form(ref="form")
  v-autocomplete(
    v-model="selectedElement"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="name"
    hide-no-data
    hide-selected
    return-object
    outlined
    clearable
    dense
    class="elevation-0"
    placeholder="Buscar Insumos"
    prepend-inner-icon="mdi-magnify"
    @click:clear="piecesResult = []; selectedElement = null"
    @change="handleSelect"
    :disabled="disabled"
    )
  v-data-table(
    v-if="!isLoading"
    dense
    :headers="tableAddicionalItems.headers"
    :items="filteredPieces"
    hide-default-footer
    mobile-breakpoint="0"
    class="elevation-0"
    no-data-text="No hay insumos"
    )
    template(v-slot:item.name="{ item }")
      span {{ item.name }}
    template(v-slot:item.brand="{ item }")
      span {{ item.brand_name }}
    template(v-slot:item.quantity="{ item }")
      PlusMinusField(:min="1" :value="item.quantity" :pieceID="item.piece" @input="handleQuantity" :selected="item.selected" :disabled="disabled" :rules="getItemValidationRules(item)")
    template(v-slot:item.delete="{ item }")
      v-btn(icon @click="deleteItem(item)" v-show="item.quantity > 0" :disabled="disabled")
        v-icon mdi-trash-can-outline
  div(:class="{'d-flex': desktopStyle, 'justify-end': desktopStyle}")
    v-btn.mt-3(color="primary" :block="!desktopStyle" :outlined="desktopStyle" :disabled="newItems.length === 0 && !selectedElement && disabled" @click="handleSaveChanges") Guardar Cambios
</template>
<script>
import { mapActions } from 'vuex'
import PlusMinusField from '../../shared/PlusMinusField.vue'
export default {
  components: {
    PlusMinusField,
  },
  props: {
    ot: Object,
    disabled: Boolean,
    desktopStyle: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedElement: null,
      tableAddicionalItems: {
        headers: [
          { text: 'Insumos', value: 'name' },
          { text: 'Marca', value: 'brand', align: !this.desktopStyle ? ' d-none' : 'left'},
          {
            text: 'Cantidad',
            value: 'quantity',
            align: 'center',
            sortable: false
          },
          { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
        ],
      },
      pieces: [],
      piecesResult: [],
      newItems: [],
      isLoading: false,
      search: null,
    }
  },
  methods: {
    ...mapActions('inventory', ['listPieces', 'deletePieces', 'searchPiece']),
    ...mapActions('ot', ['addItems', 'updateItems']),

    getItemValidationRules(item) {
      return [
        (v) => (v && (v <= item.stock || item.stock == null)) || `Sin stock (Stock: ${item.stock})`
      ]
    },

    reset() {
      this.selectedElement = null
      this.piecesResult = []
      this.pieces = []
      this.newItems = []
      this.isLoading = false
      this.search = null
    },
    handleSearch(event) {
      if (event) {
        this.searchPiece({ param: this.selectedElement }).then((response) => {
          this.pieces = response.data
        })
      } else if (event === null || event === '') {
        this.getAddicionalItems()
      }
    },
    async handleSaveChanges() {
      if (!this.$refs.form.validate()) return
      this.isLoading = true
      if (this.newItems.length > 0) {
        this.newItems.forEach(async (d) => {
          if (d.modified) {
            await this.updateItems({ id: d.id, data: d })
          }
        })
        const createData = this.newItems.filter((d) => !Object.hasOwn(d, 'id'))
        if (createData.length > 0) {
          await this.addItems({ id: this.ot.id, data: { pieces: createData } })
        }
      }
      setTimeout(() => {
        this.reset()
        this.$emit('showAlert', {
          type: 'success',
          message: 'Se han modificado los insumos con éxito ',
          show: true,
          color: 'green',
        })
      }, 1500)
    },
    getAddicionalItems() {
      this.listPieces().then((response) => {
        this.pieces = response.data.map((p) => {
          const piece = this.ot.pieces?.filter((q) => q.piece === p.id) || []
          return {
            id: p.id,
            name: p.name,
            quantity: piece.length > 0 ? piece[0].quantity : 1,
          }
        })
      })
    },
    handleQuantity({ id, value }) {
      const itemIndex = this.newItems.map((i) => i.piece).indexOf(id)
      if (itemIndex > -1) {
        this.newItems[itemIndex].quantity = value
        this.newItems[itemIndex].modified = true
      } else {
        this.ot.pieces.forEach((p) => {
          if (p.piece.id === id) {
            this.newItems.push({
              id: p.id,
              piece: p.piece.id,
              name: p.piece.name,
              brands: p.piece.brand,
              quantity: value,
              work_order: this.ot.id,
              modified: true,
            })
          }
        })
      }
    },
    deleteItem(item) {
      if (item.isNew) {
        this.newItems = this.newItems.filter((i) => i.piece !== item.piece)
        return
      }
      this.deletePieces({ id: item.id }).then((response) => {
        this.reset()
        this.$emit('showAlert', {
          type: 'success',
          message: 'Se han eliminado insumos con éxito ',
          show: true,
          color: 'green',
        })
      })
    },
    handleSelect() {
      if (this.selectedElement === null) return
      this.newItems.push(this.selectedElement)
    },
  },
  computed: {
    filteredPieces() {
      const pieces = this.ot.pieces?.map((p) => {
        return {
          id: p.id, // ID de la pieza en la OT
          piece: p.piece.id, // ID de la pieza
          name: p.piece.name,
          brand: p.piece.brand_name,
          quantity: p.quantity,
          work_order: this.ot.id,
          isNew: false,
        }
      }) || []
      if (this.newItems.length > 0) {
        this.newItems.forEach((p) => {
          const piece = pieces.filter((q) => q.piece === p.piece)
          if (piece.length === 0) {
            pieces.unshift({
              piece: p.piece, // ID de la pieza
              name: p.name,
              brand: p.brand,
              quantity: p.quantity,
              work_order: this.ot.id,
              isNew: true,
            })
          }
        })
      }
      return pieces
    },
    items() {
      return this.piecesResult.map((p) => {
        // Filtro los que se encuentran en la OT
        if (this.ot.pieces.filter((q) => q.piece.id === p.id).length > 0) return
        return {
          piece: p.id, // ID de la pieza
          name: p.name,
          brand: p.brand_name,
          quantity: 1,
        }
      })
    },
  },
  watch: {
    search(val) {
      if (this.items.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.searchPiece({ param: val })
        .then((response) => {
          this.piecesResult = response.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
  created() {
    this.newItems = []
    this.getAddicionalItems()
  },
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #E4EAFF !important;
  height: 70px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>