<template lang="pug">
SideModal(v-model="isOpen" max-width="850")
  div(v-if="ot")
    h2.title Completa el presupuesto
    div Si es necesario, realiza los cambios del nuevo presupuesto.
    VehicleAndCustomerDetailBox.my-4(:vehicle="ot.vehicle_data" :customer="ot.customer_data" :state="estimate.state" :vehicleMilage="ot.vehicle_milage")
    v-form(ref="form" @submit.prevent="onSubmit(true)" :disabled="disabled || loading")
      //- Datos de diagnóstico
      v-row(dense)
        v-col(cols=12)
          v-textarea.comment(v-model="form.evaluation.evaluation_comment" outlined background-color="#FFF4E1" color="#EA5C0B" label="Resultado Diagnóstico:" auto-grow rows="4" row-height="25")
        v-col(cols=12)
          div(style="overflow-x: auto; display: flex; position: relative;")
            v-card.mr-2(v-for="(photo, index) in images" :key="index" height="173px" width="173px")
              v-img.white--text.align-end(style="margin: 0 4px 0 0 !important;" height="173px" width="173px" :src="photo.preview" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)")
                v-card-text {{photo.comment}}

      //- Lista de servicios
      OTServiceInput(v-model="form.services" :vehicle="form.vehicle" @totalPrice="serviceTotalPrice = $event" @totalItemsPrice="itemsTotalPrice = $event" :disabled="disabled")

      .mb-2
        .mb-2.primary--text.ml-3.mr-3 Porcentaje de abono requerido
        v-text-field.ml-3.mr-3(v-model="form.deposit" outlined suffix="%" type="number" min=0 max=100 @click="handleChange")
        //- v-checkbox.mt-0(v-model="form.freezes_work_order" label="¿Congela la OT?")
        v-divider
        v-row
          v-col(cols="12")
            v-expansion-panels.ml-3(accordion flat)
              v-expansion-panel
                v-expansion-panel-header.primary--text(style="font-weight: 600; font-size: 16px;") Recomendaciones {{ recommendations.length > 0 ? `(${recommendations.length})` : '' }}
                v-expansion-panel-content(id="recommendations")
                  v-card-subtitle(v-if="recommendations.length === 0").py-0.mt-1.pl-1 No tienes recomendaciones por ahora.
                  v-card(v-for="r in recommendations" elevation="0" outlined)
                    v-row
                      v-col(cols="9")
                        v-card-subtitle(v-if="recommendations").py-0.mt-3 Creado: {{ formatDate(r.date) }} - {{formatDateHour(r.date) }} hrs.
                        p.ml-4 {{ r.text }}
                      v-col(cols="3")
                        v-avatar.mt-4(color="#FFD699" size=32 dark) {{getAvatarText(r.added_by)}}
            //- FavoriteTemplate.ml-3(:form="form")
        v-row.mb-4(dense justify="end")
          v-col
            .total_price
              label Abono:
            .total_price
              label Total:
          v-col(cols="auto")
              strong.d-block  {{ deposit | currency }}
              strong.d-block  {{ totalPrice | currency }}
        .mb-4(v-if="showErrors")
          .red--text(v-for="error in errors") {{error}}
      v-row(v-if="!disabled")
        v-col(cols=12 sm=6)
          v-btn(block color="primary" x-large outlined @click="onSubmit(false)" :loading="loading") Guardar borrador
        v-col(cols=12 sm=6)
          v-btn(block color="primary" x-large type="submit" :loading="loading") Enviar cotización
      v-btn(v-else block color="primary" x-large @click="isOpen = false") Cerrar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VehicleAndCustomerDetailBox from '../vehicle/VehicleAndCustomerDetailBox.vue'
import SideModal from '../shared/SideModal.vue'
import SaleItemInput from '../sale/subcomponents/SaleItemInput.vue'
import OTServiceInput from '../sale/subcomponents/OTServiceInput.vue'
import FavoriteTemplate from '../sale/subcomponents/FavoriteTemplate.vue'

export default {
  components: {
    VehicleAndCustomerDetailBox,
    SideModal,
    SaleItemInput,
    OTServiceInput,
    FavoriteTemplate
  },
  data () {
    return {
      ot: null,
      estimate: null,
      form: {
        services: [],
        evaluation: { evaluation_comment: null },
        freezes_work_order: true,
        deposit: 0,
      },
      serviceTotalPrice: 0,
      itemsTotalPrice: 0,
      isOpen: false,
      showErrors: false,
      errors: [],
      recommendations: [],
      loading: false,
      piecesSummed: []
    }
  },
  methods: {
    ...mapActions('ot', ['getOT', 'setOTBudget']),
    ...mapActions('customer', ['listCustomers', 'getCustomer']),
    ...mapActions('vehicle', ['listVehicles']),
    ...mapActions('estimate', ['getEstimate', 'sendEstimate', 'updateEstimate']),
    ...mapActions('service', ['listServicesInWorkOrder']),
    ...mapActions('inventory', ['getRecommendations']),
    searchCustomer () {
      this.listCustomers({ parmas: { search: this.searchCustomer } })
    },
    onSearchVehicle () {
      this.listCustomers({ parmas: { search: this.searchVehicle } })
    },
    async onChangeVehicle (vehicle) {
      this.form.vehicle = vehicle
      this.selectVehicleStatus = 'SELECTED'
      if (this.form.vehicle.customer) {
        const response = await this.getCustomer({ customerId: this.form.vehicle.customer })
        this.form.customer = response.data
      }
    },
    async open (estimateId) {
      this.ot = null
      const estimateResponse = await this.getEstimate({ estimateId })
      const otResponse = await this.getOT({ OTId: estimateResponse.data.work_order })
      if (otResponse.status === 200) {
        this.ot = otResponse.data
        this.estimate = estimateResponse.data
        this.form = { ...estimateResponse.data }
        this.form.services = estimateResponse.data.estimate_services.map((es) => {
          return {
            ...es,
            saleItems: es.sale_items_data
          }
        })
        this.form.deposit = this.form.deposit * 100
      }
      this.isOpen = true

      await this.getRecommendations({ id: this.ot.id }).then(
        (response) => {
          this.recommendations = response.data
        }
      )
    },
    handleChange(value) {
      this.form.deposit = ''
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    formatDateHour (date) {
      return new Intl.DateTimeFormat('cl-ES', { timeStyle: 'short' }).format(new Date(date))
    },
    getAvatarText (tech) {
      return tech.first_name.charAt(0) + tech.last_name.charAt(0)
    },
    async onSubmit (nextState = false) {
      this.showErrors = true
      this.doValidation()
      if (!this.$refs.form.validate() || this.errors.length) {
        return
      }

      this.loading = true
      const data = this.getFormatedData()
      data.next_state = nextState

      let response
      if (nextState) {
        response = await this.sendEstimate({ estimateId: this.estimate.id, data })
      } else {
        response = await this.updateEstimate({ estimateId: this.estimate.id, data })
      }

      if (response.status >= 200 && response.status < 300) {
        this.$emit('save', { type: 'success', message: 'Su presupuesto ha sido completado exitosamente.', show: true, color: 'green' })
        this.isOpen = false
      }

      this.loading = false
    },
    getFormatedData () {
      const data = { ...this.form }
      data.estimate_services = this.form.services.map(el => {
        return {
          service: el.service.id,
          service_quantity: Number.parseInt(el.service.service_quantity),
          sale_items: el.saleItems.map(el => {
            return {
              piece: el.piece?.id || el.piece,
              quantity: el.quantity,
              unit_price: el.unit_price,
              new_piece_name: el.new_piece_name,
            }
          })
        }
      })
      data.deposit /= 100.0
      data.evaluation = { evaluation_comment: this.form.evaluation.evaluation_comment }
      return data
    },
    // Validaciones
    unselectedSaleItemValidation () {
      this.form.services.forEach((s) => {
        if (s.saleItems.filter(el => el.piece).filter(el => el.quantity > el.piece.stock).length) {
          if (!this.errors.includes('Algunos items no tienen un producto asociado')) {
            this.errors.push('Algunos items no tienen un producto asociado')
          }
        }
      })
    },
    unselectedServiceValidation () {
      if (this.form.estimate_services.filter(el => !el).length) {
        this.errors.push('Algunos servicios no estan selecionados')
      }
    },
    saleItemStockValidation () {
      this.form.services.forEach((s) => {
        if (s.saleItems.filter(el => el.piece).filter(el => el.quantity > el.piece.stock).length) {
          if (!this.errors.includes('Algunos items no tienen stock')) {
            this.errors.push('Algunos items no tienen stock')
          }
        }
      })
    },
    saleItemMinValidation () {
      this.form.services.forEach((s) => {
        if (s.saleItems.filter(el => el.piece).filter(el => el.quantity > el.piece.stock).length) {
          if (!this.errors.includes('Debe haber al menos un producto o servicio')) {
            this.errors.push('Debe haber al menos un producto o servicio')
          }
        }
      })
    },
    doValidation () {
      this.errors = []
      this.unselectedSaleItemValidation()
      this.saleItemStockValidation()
      this.saleItemMinValidation()
      this.unselectedServiceValidation()
    },
  },
  computed: {
    ...mapGetters('inventory', ['pieces']),
    ...mapGetters('customer', ['customers']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('user', ['technicians']),
    totalPrice () {
      return this.serviceTotalPrice + this.itemsTotalPrice
    },
    deposit () {
      return (this.totalPrice * this.form.deposit) / 100
    },
    images () {
      if (this.ot && this.estimate.evaluation) {
        return this.estimate.evaluation.images.map(el => ({ preview: el.image_file, img: el.image_file, comment: el.image_description }))
      }
      return []
    },
    disabled () {
      return this.estimate && this.estimate.state !== 'Diagnosticado'
    }
  },
  watch: {
    isOpen (val) {
      if (val) {
        this.listVehicles()
      }
    },
    form: {
      deep: true,
      handler (value) {
        this.doValidation()
        if(this.form.deposit !== value?.deposit && value?.deposit > 0 && value?.deposit < 100 ) {
          this.form.deposit = value.deposit * 1
        }
        else if(value?.deposit > 100) {
          this.form.deposit = ''
        }
        else {
          this.form.deposit = value.deposit * 1
        }
      }
    },
    // 'form.deposit': {
    //   hanlder(value) {
    //     console.log(value)
    //     if(value !== this.form.deposit && value > 0 < 100) {
    //       this.form.deposit = value
    //     }
    //   },
    //   deep: true
    // }
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 400;
  font-size: 24px;
  color: #4C7AEC;
}
.sale-form {
  background-color: white;
}
.total_price {
  font-size: 18px;
  text-align: right;
}
.technician-comment {
  padding: 12px;
  min-height: 190px;
  background: #FFF4E1;
  border: 1px solid #E4E3E2;
  border-radius: 4px;
  color: #EA5C0B;
}

.comment::v-deep textarea{
  color: #EA5C0B !important;
}

.v-expansion-panel-header {
  padding: 0px;
}
</style>

<style lang="css">
#recommendations > .v-expansion-panel-content__wrap {
  padding: 0px 0px 0px !important;
}
</style>
