<template lang="pug">
v-container(fluid)
  v-card.pa-2(color="#C3D1FF" flat rounded)
    v-row 
      v-col(cols="1") 
        v-icon.pt-1(color="ultramarine") mdi-calendar-month
      v-col.pa-0.pl-1(cols="11") 
        v-card-subtitle.px-0.pb-0.ultramarine--text Esta orden de trabajo proviene desde la sección de "Agenda".
        v-card-subtitle.pa-0.pt-2.ultramarine--text Cita: 
          strong {{ new Date(appointment?.start).toLocaleDateString("es-CL", { year: "numeric", month: "long", day: "numeric", weekday: 'long' }) }} - Hora: {{ new Date(appointment?.start).toLocaleTimeString('es-VE', { hour: 'numeric', minute: 'numeric', hour12: true }) }}
        v-card-subtitle.pa-0.ultramarine--text Servicio: 
          strong {{ appointment?.service.name }}
        v-card-subtitle.pa-0.pb-4.ultramarine--text Duración: 
          strong {{ appointment?.service.duration }} Hora(s)
</template>

<script>
export default {
  props: {
    appointment: {
      type: Object,
      default: null,
    }
  }
}
</script>