<template lang="pug">
  v-row(style="height: 80vh;" justify="center" align-content="center")
    v-col.text-center(cols=11)
      v-icon(color="primary" size=104) mdi-check-circle
      .success-text ¡Tu
        strong(v-if="selectedOT === null && !isQuotation")  OT N°{{ otID }}
        strong(v-if="isQuotation")  Cotización
        strong(v-if="selectedOT !== null")  Nuevo Presupuesto
        span(v-if="selectedOT !== null")  fue creado con éxito!
        span(v-else)  fue creada con éxito!
      .mb-11(v-if="!isQuotation") Puedes revisar el detalle
        router-link.ml-2(:to="{ name: 'Detalle OT', params: {otID: this.otID }}") Aquí
      .mb-11(v-else)
      v-btn(v-if="isQuotation" color="primary" x-large block @click="goToListQuotations()") ¡Entendido!
      v-btn(v-else color="primary" x-large block @click="goToListOts()") ¡Entendido!
</template>
<script>
export default {
  methods: {
    goToListOts () {
      this.$route.name === 'Detalle OT'
      ? this.$emit('closeModal')
      : this.$emit('back', 'success')
      if (this.$route.name === 'Presupuestos') this.$router.push({ name: 'OT' })
    },
    goToListQuotations () {
      this.$emit('back', 'success')
      this.$emit('quotationCreated', this.otID)
      if (this.$route.name === 'Cotizacion') this.$router.push({ name: 'Cotizacion' })
    }
  },
  props: {
    otID: {
      type: Number
    },
    selectedOT: {
      type: Object,
      default: null
    },
    isQuotation: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.success-text {
  font-size: 24px;
  margin-top: 42px;
}
</style>
