import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const VehicleStore = {
  namespaced: true,
  state: {
    vehicles: [],
    vehicle: null,
    models: [],
    carEstimate: []
  },
  getters,
  mutations,
  actions
}

export default VehicleStore
