const mutations = {
  'SET_CUSTOMERS' (state, value) {
    state.customers = value
  },
  'SET_CUSTOMER' (state, value) {
    state.customer = value
  }
}

export default mutations
