import axios from '../../plugins/axios';

export const updateFocus = ({ commit }, newFocus) => {
  commit('SET_FOCUS', newFocus);
};

export const listServices = async ({ commit }) => {
  return axios.get('/schedule/service/')
    .then(res => {
      const data = res.data.results;
      
      commit('SET_SERVICES', data)
      return res
    })
    .catch(err => err.response)
};

export const listAppointments = async ({ commit }, { params } = {}) => {
  return await axios.get('/schedule/calendar/', { params })
    .then(res => {
      const data = res.data;
      const dataFiltered = data.filter((event) => event.state !== 'CANCELED')
      
        const events = dataFiltered.map((event) => ({
          id: event.id,
          name: event.name,
          start: event.start.replace('T', ' ').slice(0, 16),
          end: event.end.replace('T', ' ').slice(0, 16),
          color: event.color,
          customer: event.customer,
          service: event.service,
          vehicle: event.vehicle,
          state: event.state,
          work_order: event.work_order,
          url_approve_reschedule: event.url_approve_reschedule,
          url_cancel: event.url_cancel,
          url_reschedule: event.url_reschedule
          
        }))

        commit('LIST_APPOINTMENTS', events)
        
      })
      .catch(err => err.response)
};

export const listDisabledSchedule  = async ({ commit }, { params } = {}) => {
  return await axios.get('/schedule/disabled-calendar/', { params })
  .then(res => {
    const data = res.data;
      const events = data.map((event) => ({
        start: event.start_at.replace('T', ' ').slice(0, 16),
        end: event.end_at.replace('T', ' ').slice(0, 16),
        color: '#DDD',
      }))
      commit('LIST_DISABLED', events)
  })
  .catch(err => err.response)
};

export const listTodayAppointments = async ({ commit }, today = {}) => {
  return await axios.get(`/schedule/calendar/?start_at__gte=${today}+00%3A00&start_at__lte=${today}+23%3A59&start_at__range=`,)
    .then(res => {
      const data = res.data;
       
        const events = data.map((event) => ({
          id: event.id,
          name: event.name,
          start: event.start.replace('T', ' ').slice(0, 16),
          end: event.end.replace('T', ' ').slice(0, 16),
          service: event.service,
          customer: event.customer,
          vehicle: event.vehicle,
          color: event.color,
          ...event
        }
        
        ))

      commit('SET_TODAY_APPOINTMENTS', events)
        
    })
    .catch(err => err.response)
};


export const listCustomers = ({ commit }) => {
  return axios.get('order/customer/')
    .then(res => {
      commit('SET_CUSTOMERS', res.data)
      return res
    })
    .catch(err => err.response)
};

export const createAppointment = async (_, data = {}) => {
  return await axios.post('schedule/appointment/', data)
    .then(res => {
      if(res?.status === 201) {
        console.log(res.status, 'Agendado con éxito!')
        return res.status;
      }
      else {
        console.log('Status: ', res?.status, 'response: ', res.data)
        const { status, data }  = res
        return { 
          status, 
          data,
          dataText: Object.values(data)[0][0],
        };
      }
    })
    .catch (err => {
      console.log(err) 
    })
}

export const getAppointment = (_, id = {}) => {
  return axios.get(`schedule/appointment/${id}/`,)
    .then(res => {
      return res.data
    })
    .catch(err => err.response)  
};

export const deleteAppointment = (_, id = {}) => {
  return axios.delete(`/schedule/appointment/${id}/`)    
    .then(res => {
      if(res?.status === 204) {
        console.log(res.status, 'Eliminado con éxito!')
        return true;
      }
      else {
        console.log('No se pudo eliminar, intente nuevamente')
        return false;
      }
    })
    .catch(err => err.response)
};

export const updateCurrentAppointment = ({ commit }, newAppointment) => {
  commit('SET_APPOINTMENT', newAppointment);
};


export const rescheduleAppointment = async (_, data = {}) => {
  return await axios.post(`/schedule/reschedule_by_admin/${data.id}/`, data.newDate)
    .then(res => {
      if(res?.status === 200) {
        console.log(res.status, 'Servicio reagendado con éxito!')
        return res.status;
      }
      else {
        console.log('Status: ', res?.status, 'response: ', res.data)
        const { status, data }  = res
        return { 
          status, 
          data,
          dataText: Object.values(data)[0][0],
        };
      }
    })
    .catch (err => {
      console.log(err) 
    })
}

export const getTimeBlocksByService = async (_, { serviceId, date}) => {
  return await axios.get(`schedule/service/${serviceId}/time_blocks/${date}/`)
    .then(response => ({ data: response.data, response }))
    .catch(error => ({ error }))
}