<template lang="pug">
div
  v-tabs(v-model="tab" fixed-tabs)
    v-btn.mt-2(elevation="0" color="primary" @click="handleBack" icon style="margin-left: 10px;")
      v-icon mdi-arrow-left
    v-tab.style Plantillas
    v-tab.style(:disabled="this.$route.params.vehicleID === 'quotations'") Modelo
    v-tab.style(:disabled="this.$route.params.vehicleID === 'quotations'") Patente
    v-tab.style Cotización
    div.d-flex.justify-end(style="width: 100%")
      v-text-field(style="margin-right: 10px;" flat hide-details label="Buscar" prepend-inner-icon="mdi-magnify" background-color="#E4EAFF" @change="search" clearable solo @click:clear="clearable")
  v-tabs-items(v-model="tab")
    v-tab-item
      ListTemplates(:searchParam="searchParam")
    v-tab-item(:disabled="this.$route.params.vehicleID === 'quotations'")
      BudgetsByModel(:searchParam="searchParam")
    v-tab-item(:disabled="this.$route.params.vehicleID === 'quotations'")
      BudgetsByPatent(:searchParam="searchParam")
    v-tab-item
      Quotation(:searchParam="searchParam")
</template>
<script>
import ListTemplates from '../components/templates/ListTemplates.vue'
import BudgetsByPatent from '../components/templates/BudgetsByPatent.vue'
import BudgetsByModel from '../components/templates/BudgetsByModel.vue'
import Quotation from '../components/templates/Quotation.vue'

export default {
  components: {
    ListTemplates,
    BudgetsByPatent,
    BudgetsByModel,
    Quotation
  },
  data () {
    return {
      tab: null,
      searchParam: null
    }
  },
  methods: {
    search (searchValue) {
      this.searchParam = searchValue
    },
    clearable () {
      this.searchParam = ''
    },
    handleBack () {
      localStorage.setItem('back', true)
      // console.log('regresando de budgets')
      this.$router.back()
    }

  },
  created () {
    if (this.$route.params.vehicleID === 'templates') {
      this.tab = 0
    } else if (this.$route.params.vehicleID === 'quotations') {
      this.tab = 3
    }
  }
}
</script>
<style lang="scss" scoped>
.style {
  color: #1A1796;
}
.arrow {
  width: 50px !important;
}
</style>
