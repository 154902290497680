import axios from 'axios'
import settings from '../../settings'
import router from '../router'

const instance = axios.create({
  baseURL: `${settings.API_URL}/api/`,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})

instance.interceptors.response.use(
  response => response,
  error => {
    // solución parche, buscar una mejor forma de identificar una sesión expirada o inexistente
    if (error.response.status === 403) {
      localStorage.removeItem('user')
      router.push({ name: 'Login' })
    }
    return error.response;
  }
)

export default instance
