import axios from '../../plugins/axios'

export const pay = (_, { data }) => {
  return axios.post('inventory/payment', data)
    .then(res => {
      location.href = `${res.data.url}?token_ws=${res.data.token}`
      return res
    })
    .catch(err => err.response)
}

export const getPaymentResult = (_, { paymentId }) => {
  return axios.get(`inventory/work_order_deposit/${paymentId}/`)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const getConfirmPayment = (_, { paymentId }) => {
  return axios.get(`inventory/confirm_final_transaction/${paymentId}/`)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}
