<template lang="pug">
  div
    ListSale
</template>

<script>
import ListSale from '../components/sale/ListSale.vue'
export default {
  components: {
    ListSale
  }
}
</script>
