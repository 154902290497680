<template lang="pug">
SideModal(v-model="isOpen" max-width="680")
  v-container
    v-form(ref="formModalExpenses" lazy-validation v-model="formIsValid")
      div(style="margin-bottom: 20px; margin-top: -20px")
        h2.color {{ modalExpenseTitle }}
        p.mt-2.ml-0 {{ formatDate(today)}} hrs.

      div(v-if="isExpense" style="background-color: #f0f3ff; margin-bottom: 20px; border-radius: 15px;")
        p.color.subtitle ¿Asociado a una OT?
        v-row
          v-col(cols="2")
            v-radio-group.ml-5(column v-model="asociateOT")
              v-radio(label="Sí" :value="true")
              v-radio(label="No" :value="false")

          v-col(cols="10")
            v-autocomplete.otNumber(
              v-model="expense.work_order"
              label="¿A qué numero de OT corresponde?"
              no-data-text="No se encontraron OTs"
              :search-input.sync="search"
              :items="ots"
              :loading="loadingOts"
              item-text="work_order_display"
              item-value="id"
              solo dense flat clearable
              :disabled="!asociateOT"
            )

      div(style="background-color: #f0f3ff; margin-bottom: 20px; border-radius: 15px;" v-if="isExpense")
        p.color.subtitle Proveedor
        v-autocomplete.mx-5.mt-3(
          v-model="expense.expense_supplier"
          :items="expensesSuppliers"
          no-data-text="No se encontraron proveedores"
          item-text="name"
          item-value="id"
          placeholder="Buscar proveedor"
          :rules="[v => !!v || 'El proveedor es obligatorio']"
          mandatory solo flat
          :disabled="prepaidExpenseSelected"
          @change="handleSupplierChange"
        )
        div(v-if="prepaid_expenses.length > 0")
          p.color.subtitle Gastos Anticipados
          v-autocomplete.mx-5(
            v-model="selectedExpense"
            :items="prepaid_expenses"
            item-text="transaction_date - payment_total"
            solo
            flat
            return-object
            clearable
            @click:clear="resetExpense(true)"
            @change="handlePrepaidExpenseChange"
          )
            template(v-slot:selection="{attrs, item}")
              span(v-bind="attrs") {{ item.transaction_date | moment('DD/MM/YYYY') }} - {{ item.payment_total | currency }}
            template(v-slot:item="{ item }")
              span {{ item.transaction_date | moment('DD/MM/YYYY') }} - {{ item.payment_total | currency }}

      div(style="background-color: #f0f3ff; margin-bottom: 20px; border-radius: 15px;" v-if="isExpense")
        v-row.pb-0
          v-col.ml-4(cols="5")
            v-checkbox.mt-2(v-model="expense.prepaid_expense" label="Gasto Anticipado" color="primary" hide-details :disabled="prepaidExpenseSelected" @change="expense.payment_gateway = ''")
        v-row.pb-0(v-if="!expense.prepaid_expense || expense.id")
          v-col(cols="5")
            p.color.subtitle.pb-1 N° de Factura
          v-col(cols="7" style=" padding-bottom: 0;")
            v-text-field(
              v-model="expense.invoice_number"
              label="Ingrese N° de factura"
              type="string"
              style="width: 80%; margin-top: 12px; padding-bottom: 0; margin-bottom: 0"
              solo dense flat clearable
              :rules="[v => !!v || 'El N° de factura es obligatorio']"
            )
        v-row.pt-0.mt-0(style="align-items: center" v-if="!expense.prepaid_expense || expense.id")
          v-col(cols="5" style="padding-top: 0")
            p.color.subtitle.pb-2 Fecha de emisión
          v-col(cols="7" style="padding-top: 0")
            div.d-inline-flex(style="width: 100%;")
              v-menu(ref="menu" v-model="menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
                template(v-slot:activator="{ on, attrs }")
                  v-text-field(
                    v-model="invoice_date.split('-').reverse().join('/')"
                    prepend-inner-icon="mdi-calendar"
                    solo dense flat
                    v-bind="attrs"
                    v-on="on"
                    style="max-width: 80%; margin-top: 12px;"
                    placeholder=" Ingrese una fecha"
                    readonly clearable
                    :rules="[v => !!v || 'La fecha de emisión es obligatoria']"
                    @click:clear="invoice_date = ''"
                  )
                v-date-picker(v-model="invoice_date" no-title locale="es-CL" :allowed-dates="maxDate")
      div(style="background-color: #f0f3ff; margin-bottom: 10px; border-radius: 15px;" v-if="isExpense")
        p.color.subtitle Tipo de pago
        v-radio-group.ml-5(row v-model="expense.payment_gateway" column mandatory :rules="[v => !!v || 'Tipo de pago es obligatorio']" @change="resetPaymentFields")
          v-radio(label="Efectivo" value="Efectivo" )
          v-radio(label="Tarjeta" value="Débito" )
          v-radio(label="Transferencia" value="Transferencia" )
          v-radio(label="A Crédito" value="A Crédito" )
          v-radio(label="Cheque" value="Cheque" :disabled="expense.prepaid_expense || prepaidExpenseSelected")

        div(v-if="expense.payment_gateway === 'A Crédito'")
          p.color.mr-5.pl-5 Fecha de pago
          v-menu(ref="menu2" v-model="menu2" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
            template(v-slot:activator="{ on, attrs }")
              v-text-field.ml-5.mt-3(
                v-model="expense.credit_term.split('-').reverse().join('/')"
                prepend-inner-icon="mdi-calendar"
                solo dense flat
                v-bind="attrs"
                v-on="on"
                style="max-width: 40%; margin-top: 12px;"
                placeholder=" Ingrese una fecha"
                readonly clearable outlined
                :rules="[v => !!v || 'La fecha de pago es obligatoria']"
              )
            v-date-picker(v-model="expense.credit_term" no-title locale="es-CL")

        div(v-if="expense.payment_gateway === 'Cheque'" style="display: flex; flex-direction: column")
          div.float-end.pb-3.pr-2
            v-btn.float-end(color="primary" dense @click="newCheck" :disabled="disabledNewCheck"  ) + Nuevo cheque
          div(v-if="expense.payment_gateway === 'Cheque'")
            v-data-table.pb-8(:headers="headers" :items="allChecks" density="compact" item-key="" hide-default-footer)
              template(v-slot:item.check_number="{ item }")
                v-text-field.mt-2(
                  outlined dense
                  v-model="item.check_number"
                  placeholder="N° Cheque"
                  :rules="[v => v * 1 !== 0 || 'Debe ser mayor a 0', rules.required, rules.isNumber]"
                  :disabled="item.disabled"
                )
              template(v-slot:item.check_date="{ item }")

                v-menu(ref="menu" v-model="item.menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field.mt-2(
                      v-model="item.check_date.split('-').reverse().join('/')"
                      outlined dense
                      v-bind="attrs"
                      v-on="on"
                      placeholder="Fecha"
                      readonly
                      :rules="[rules.required]"
                      :disabled="item.disabled"
                    )
                  v-date-picker(v-model="item.check_date" no-title locale="es-CL")

              template(v-slot:item.check_amount="{ item }")
                v-text-field.mt-2(
                  outlined dense
                  v-model="item.check_amount"
                  prefix="$"
                  :rules="[rules.required, rules.greaterThanZero]"
                  :disabled="item.disabled"
                  v-maska:[moneyMaskFormat]
                )
              template(v-slot:item.actions="{ item }")
                div(style="display: flex; margin-top: -20px !important")
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(icon @click="clearCheck(item)" v-bind="attrs" v-on="on")
                        v-icon mdi-broom
                    span Limpiar
                  v-tooltip(bottom v-if="item.allowDelete")
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(icon v-bind="attrs" v-on="on" @click="deleteCheck(item)")
                        v-icon mdi-trash-can
                    span Eliminar

      div(v-if="isExpense" style="background-color: #f0f3ff; margin-bottom: 20px; border-radius: 15px;")
        p.color.subtitle Tipo de gasto
          v-select.mr-5.mt-3(
            v-model="expense.expense_group"
            :items='expense_groups'
            item-text="name"
            item-value="name"
            :rules="[v => !!v || 'El tipo de gasto es obligatorio']"
            mandatory solo flat
          )

      div(style="background-color: #f0f3ff; margin-bottom: 10px; border-radius: 15px;")
        p.color.subtitle Descripción
        v-textarea.mx-5(
          v-model="expense.description"
          label="Escribe algo..."
          clear-icon="mdi-close-circle"
          rows="3"
          :rules="[v => !!v || 'La descripción es obligatoria', v => v == null || v.length <= 100 || 'Máximo 100 caracteres']"
          solo clearable counter flat
        )
      div.d-flex.justify-end(style="background-color: #fff; margin-bottom: 5px; border-radius: 15px; width: 100%")
        div
          p.color.subtitle Monto total de egreso
          v-text-field.mx-5(
            v-model="expense.payment_total"
            label="Ingrese monto"
            :readonly="expense.payment_gateway === 'Cheque' || prepaidExpenseSelected"
            :clearable="expense.payment_gateway !== 'Cheque'"
            style="width: 200px" prefix="$"
            :rules="[v => !!v || 'El monto es obligatorio', rules.notGreaterThanAvailableCash ]"
            v-maska:[moneyMaskFormat]
            :disabled="action !== 'create'"
          )
      v-btn(color="primary" block style="font-weight: 400; padding: 25px 0" @click="handleCreateExpense" :disabled="disabledSave") {{ prepaidExpenseSelected ? 'Editar egreso' : 'Crear egreso' }}

</template>
<script>
import { mapActions } from 'vuex'
import SideModal from '../shared/SideModal.vue'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../utils/mask'
export default {
  directives: { maska: vMaska },
  components: {
    SideModal,
  },
  props: {
    isExpense: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      action: 'create',
      otResults: [],
      search: null,
      loadingOts: false,
      isLoading: false,
      prepaidExpenseSelected: false,
      prepaid_expenses: [],
      expensesSuppliers: [],
      formIsValid: true,
      checkbox: true,
      isOpen: false,
      column: null,
      selectedExpense: {},
      expense: {
        payment_total: 0,
        payment_gateway: '',
        description: '',
        balance: 0,
        aproved_by: null,
        work_order: '',
        movement_type: this.isExpense ? 'Gasto' : 'Retiro de efectivo',
        credit_term: '',
        state: 'Pagado',
        stock_movement: [],
        invoice_number: '',
        invoice_date: '',
        expense_group: '',
        expense_supplier: '',
        prepaid_expense: false,
      },
      today: new Date(),
      asociateOT: false,
      asociateSupplier: false,
      expense_groups: [],
      availableCash: 0,
      menu: false,
      invoice_date: null,
      date: null,
      menu2: false,
      headers: [
        { text: 'Número de cheque *', value: 'check_number', class: 'blue lighten-5', sortable: false },
        { text: 'Fecha *', value: 'check_date', class: 'blue lighten-5', sortable: false, width: '140px' },
        { text: 'Monto *', value: 'check_amount', class: 'blue lighten-5', filterable: false, sortable: false },
        { text: 'Acciones', value: 'actions', class: 'blue lighten-5', filterable: false, }
      ],
      allChecks: [
        {
          check_number: '',
          check_date: '',
          check_amount: 0,
          disabled: false,
          allowDelete: false
        }
      ],
      rules: {
        required: value => !!value || 'Requerido',
        greaterThanZero: value => +value.split('.').join('') > 0 || 'Debe ser mayor a cero',
        isNumber: value => Number.isInteger(+value) || 'Debe ser un número',
        formatValue: value => (this.formatValue()),
        uniqueCode: v => {
          const codes = this.localPieces.map(p => p.code)
          return codes.filter(c => c === v).length === 1 || 'Código ya existe'
        },
        notGreaterThanAvailableCash: v => {
          if(this.expense.payment_gateway === 'Efectivo' || this.expense.movement_type === 'Retiro de efectivo') {
            return +v.toString().split('.').join('') <= this.availableCash || 'El monto no puede ser mayor al efectivo disponible'
          } else {
            return true
          }
        }
      },
      moneyMaskFormat,
    }
  },
  computed: {
    disabledSave() {
      return Object.values(this.state).includes(false)
    },
    state() {
      if (this.isExpense) {
        return {
          payment_total:
            +this.expense.payment_total?.toString().split('.').join('') > 0 &&
            this.expense.payment_total !== '' &&
            this.expense.payment_total !== ' ',
          paymentGateway: this.expense.payment_gateway !== '',
          expense_group: this.expense.expense_group !== '',
          credit_term:
            this.expense.payment_gateway === 'A Crédito'
              ? this.expense.credit_term !== ''
              : true,
          invoice_date: this.prepaidExpenseSelected ? (this.invoice_date !== '' && this.invoice_date !== null) : true,
          invoice_number: this.prepaidExpenseSelected ? (this.expense.invoice_number !== '' && this.expense.invoice_number !== null) : true,
        }
      }
      return {
        payment_total:
          +this.expense.payment_total?.toString().split('.').join('') > 0 &&
          this.expense.payment_total !== '' &&
          this.expense.payment_total !== ' ',
      }
    },
    disabledNewCheck () {
      const lastCheck = this.allChecks.at(-1)
      return ['', 0,].map(v => Object.values(lastCheck).includes(v)).includes(true)
    },
    modalExpenseTitle () {
      let title = this.isExpense ? 'Nuevo Gasto' : 'Nuevo Retiro de Efectivo'
      if (this.expense.id || this.prepaidExpenseSelected) {
        title = 'Completar Gasto'
      }
      return title
    },
    ots () {
      return this.otResults.map(ot => ot)
    }
  },
  methods: {
    ...mapActions('inventory', ['createExpense', 'updateExpense', 'listExpenseGroups', 'getCurrentBalance', 'getSupplierExpenses', 'getSupplierPrepaidExpenses']),
    ...mapActions('ot', ['searchOTsByID']),
    open(isExpense, selectedExpenseToComplete = null) {
      this.isOpen = true
      this.resetExpense(isExpense)
      if (selectedExpenseToComplete) {
        this.expense = { ...selectedExpenseToComplete }
        this.prepaidExpenseSelected = true
        this.action = 'workingWithPrepaidExpense'
      }
    },
    resetExpense(isExpense) {
      this.expense = {
        payment_total: 0,
        description: '',
        balance: 0,
        aproved_by: null,
        work_order: '',
        movement_type: isExpense ? 'Gasto' : 'Retiro de efectivo',
        payment_gateway: isExpense ? '' : 'Efectivo',
        credit_term: '',
        state: 'Pagado',
        stock_movement: [],
        expense_group: isExpense ? '' : 'Retiro de efectivo',
        expense_supplier: '',
        invoice_number: '',
        invoice_date: '',
        check_number: null,
        check_date: '',
        check_amount: null,
        check_qty: null,
        prepaid_expense: false,
      }
      this.otResults = []
      this.selectedExpense = {}
      this.prepaidExpenseSelected = false
      this.prepaid_expenses = []
      this.date = null
      this.invoice_date = ''
      this.asociateOT = false
      this.$nextTick(() => {
        this.$refs.formModalExpenses.resetValidation()
      })
    },
    listExpensesSuppliers() {
      this.getSupplierExpenses().then((response) => {
        if (response.status === 200) {
          this.expensesSuppliers = response.data
        } else {
          this.expensesSuppliers = []
        }
      })
    },
    getExpenseGroups() {
      this.listExpenseGroups().then((response) => {
        if (response.status === 200) {
          this.expense_groups = response.data
        } else {
          this.expense_groups = []
        }
      })
    },
    handleCreateExpense () {
      this.expense.payment_gateway === 'Cheque'
      ? this.createMultiExpense()
      : this.createSingleExpense()
    },
    async handleSupplierChange(id) {
      await this.getSupplierPrepaidExpenses({id}).then((response) => {
        if (response.status === 200) {
          this.prepaid_expenses = response.data
        }
      })
    },
    handlePrepaidExpenseChange(item) {
      if (item) {
        const selectedSupplier = this.expense.expense_supplier
        this.prepaidExpenseSelected = true
        this.expense = item
        this.expense.expense_supplier = selectedSupplier
        this.expense.payment_gateway = ''
      }
    },
    createMultiExpense () {
      if (!this.$refs.formModalExpenses.validate()) return
      this.isLoading = false

      this.expense.movement_type = this.isExpense
        ? 'Gasto'
        : 'Retiro de efectivo'
      this.expense.state = 'Pendiente'
      for (const check of this.allChecks) {
        this.expense.check_number = check.check_number
        this.expense.check_date = check.check_date
        this.expense.credit_term = check.check_date
        this.expense.check_amount = +check.check_amount.split('.').join('')
        this.expense.check_qty = `${Number(this.allChecks.indexOf(check)) + 1}/${this.allChecks.length}`
        this.expense.payment_total = +check.check_amount.split('.').join('')
        this.expense.expense_group = Object.hasOwn(this.expense.expense_group, 'name') ? this.expense.expense_group.name : this.expense.expense_group

        const action = this.prepaidExpenseSelected ? this.updateExpense : this.createExpense

        action({ data: {...this.expense, invoice_date: this.invoice_date || null } }).then((response) => {
          if (response.status === 201 || response.status === 200) {
            if(Number(this.allChecks.indexOf(check)) + 1 === this.allChecks.length) {
              this.isLoading = false
              this.isOpen = false
              this.$emit('refreshData', {
                type: 'success',
                message: 'Egreso creado con éxito',
                show: true,
                color: 'green',
              })
              this.$nextTick(() => {
                this.$refs.formModalExpenses.reset()
              })
            }
          }
        })
      }
    },
    createSingleExpense () {
      if (!this.$refs.formModalExpenses.validate()) return
      this.isLoading = true

      this.expense.movement_type = this.isExpense
        ? 'Gasto'
        : 'Retiro de efectivo'
      if (!this.isExpense) {
        delete this.expense.credit_term
        this.expense.check_date = null
      } else if (this.expense.payment_gateway === 'A Crédito') {
          this.expense.state = 'Pendiente'
      }
      this.expense = {
        ...this.expense,
        check_date: this.expense.check_date || null,
        credit_term: this.expense.credit_term || null,
        payment_total: +this.expense.payment_total.split('.').join(''),
        prepaid_expense: !this.prepaidExpenseSelected,
      }
      console.log(this.expense.expense_group)
      this.expense.expense_group = Object.hasOwn(this.expense.expense_group, 'name') ? this.expense.expense_group.name : this.expense.expense_group
      console.log(this.expense.expense_group)

      const action = this.prepaidExpenseSelected ? this.updateExpense : this.createExpense

      action({ data: {...this.expense, invoice_date: this.invoice_date || null } }).then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.isLoading = false
          this.isOpen = false
          this.$emit('refreshData', {
            type: 'success',
            message: 'Egreso creado con éxito',
            show: true,
            color: 'green',
          })
          this.$nextTick(() => {
            this.$refs.formModalExpenses.reset()
          })
        }
      })
      this.isLoading = false
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('cl-ES', {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(new Date(date))
    },
    newCheck () {
      // this.allChecks.at(-1).disabled = true
      this.allChecks.push({
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: true
        })
    },
    deleteCheck (item) {
      this.allChecks = this.allChecks.filter((c) => c !== item)
      this.allChecks.at(-1).disabled = false
    },
    clearCheck (item) {
      this.allChecks = this.allChecks.map((c) => c !== item? c : {
        ...c,
        check_number: '',
        check_date: '',
        check_amount: '',
        disabled: false,
      })
    },
    resetPaymentFields () {
      this.allChecks = [
        {
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: false
        }
      ]
      this.expense.credit_term = ''
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today
    },
    maxDate(val) {
      return new Date(val) <= new Date()
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.getExpenseGroups()
        this.listExpensesSuppliers()
        const availableCash = this.getCurrentBalance()
        availableCash.then(res => {
          this.availableCash = res.data.cash_balance
          // console.log(this.availableCash, 'availableCash')
        })
      }
    },
    allChecks: {
      handler(value) {
        if (this.expense.payment_gateway !== 'Cheque') return
        const lastCheck = this.allChecks.at(-1)
        if(lastCheck.check_amount[0] === '0') {
          lastCheck.check_amount = Number(lastCheck.check_amount) * 1
        }
        this.expense.payment_total = lastCheck.check_amount
        if(this.allChecks.length > 1) {

          const paymentTotal = this.allChecks.reduce((acc, c) => acc + +(c.check_amount.split('.').join('')), 0)
          this.expense.payment_total = paymentTotal
        }
      },
      deep: true
    },
    'expense.payment_total': {
      handler(value) {
        if(this.expense.payment_total && value[0] === '0') {
          this.expense.payment_total = Number(value) * 1
        }
      },
      deep: true
    },
    search (val) {
      if (val === '' || val === null) {
        this.otResults = []
      } else {
        if (this.ots.length > 0) return

        if (this.loadingOts) return

        this.loadingOts = true
        this.searchOTsByID({ params: { query: val } }).then(
          response => {
            this.otResults = response.data.map(ot => {
              return {
                id: String(ot.id),
                work_order_display: ot.work_order_display,
              }
            })
          }
        )
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.loadingOts = false))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.color {
  color: #4c7aec;
}
.subtitle {
  padding-top: 20px;
  padding-left: 20px;
}
.otNumber {
  width: 80%;
  margin-top: 12px;
}
</style>
