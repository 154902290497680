<template lang="pug">
v-dialog(v-model="isOpenRejectBudget" max-width="600" ref="RejectBudget")
  v-card.pa-4
    v-card-title.d-flex.justify-space-between
      h4.primary--text Rechazo de Presupuesto
      v-btn.close-btn(fab color="white" small depressed @click="resetModal()")
        v-icon mdi-close
    v-card-text
      v-data-table(
        v-model="selectedBudgets"
        :headers="headers"
        :items="budgets"
        hide-default-footer
        show-select
        :single-select="false"
      )
        template(v-slot:item.id="{ item }")
          span {{ `Presupuesto N° ${item.id}` }}
        template(v-slot:item.total_price="{ item }")
          span {{ item.total_price | currency }}
      v-textarea.mt-2(
        v-model="rejectReason"
        label="Motivo del Rechazo*"
        outlined clearable clear-icon="mdi-close-circle"
        counter rows="4" row-heigth="25"
        :rules="[v => !!v || 'Este campo es requerido', v => v.length <= maxReasonLength || `Máximo ${maxReasonLength} caracteres`]"
        required)
    v-card-actions.d-flex.justify-end
      v-btn(color="primary" outlined @click="resetModal") Cancelar
      v-btn(color="primary" @click="rejectBudget()" :disabled="selectedBudgets.length === 0 || rejectReason === '' || rejectReason.length > maxReasonLength") Enviar
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      isOpenRejectBudget: false,
      rejectReason: '',
      budgets: [],
      headers: [
        { text: 'Presupuestos', value: 'id' },
        { text: 'Total', value: 'total_price' },
      ],
      selectedBudgets: [],
      maxReasonLength: 200,
    }
  },
  methods: {
    ...mapActions('inventory', ['manualRejection']),
    async rejectBudget() {
      const estimatesList = this.selectedBudgets.map(budget => budget.id);
      const data = {
        estimate_list: estimatesList,
        rejection_reason: this.rejectReason,
      }
      const response = await this.manualRejection(data)
      if (response.status === 200) {
        this.$emit('showAlert', { type: 'success', message: 'Presupuesto Rechazado', show: true, color: 'green' })
        this.resetModal()
      } else {
        this.$emit('showAlert', { type: 'error', message: 'Error al rechazar presupuesto', show: true, color: 'red' })
      }
    },
    resetModal() {
      this.isOpenRejectBudget = false
      this.selectedBudgets = []
      this.rejectReason = ''
    },
    open(budgets) {
      this.budgets = budgets.filter(budget => budget.state === 'Presupuestado');
      this.isOpenRejectBudget = true;
    },
  },
}
</script>
