<template lang="pug">
div(style="margin-top: -30px;")
  v-row
    v-col(cols="12")
      v-tabs(v-model="tab").hide-print
        v-tab Caja Chica
        v-tab Movimientos del día
        v-tab Cuentas por Pagar
        v-tab Cuentas por Cobrar
        v-tab Efectivo por Depositar
        v-tab Cartola
        v-tab Gastos Anticipados
      v-tabs-items(v-model="tab")
        v-tab-item
          PettyCash(:tab="tab")
        v-tab-item
          DailyMovements(:tab="tab")
        v-tab-item
          PendingPayment(:tab="tab")
        v-tab-item
          PendingReceivable(:tab="tab")
        v-tab-item
          PendingCashDeposit(:tab="tab")
        v-tab-item
          Printout(:tab="tab")
        v-tab-item
          PrepaidExpenses(:tab="tab")
</template>
<script>
import { mapActions } from 'vuex'
import PettyCash from '../components/finance/PettyCash.vue'
import DailyMovements from '../components/finance/DailyMovements.vue'
import Printout from '../components/finance/Printout.vue'
import PendingPayment from '../components/finance/PendingPayment.vue'
import PendingReceivable from '../components/finance/PendingReceivable.vue'
import PendingCashDeposit from '../components/finance/PendingCashDeposit.vue'
import PrepaidExpenses from '../components/finance/PrepaidExpenses.vue'

export default {
  data () {
    return {
      tab: null,
    }
  },
  components: {
    PettyCash,
    DailyMovements,
    Printout,
    PendingPayment,
    PendingReceivable,
    PendingCashDeposit,
    PrepaidExpenses
  },
  methods: {
    ...mapActions('app', ['setTitle', 'setSubtitle'])
  },
  created () {
    this.setTitle('Finanzas')
  }
}
</script>
<style lang="scss" scoped>
</style>
