import { render, staticRenderFns } from "./SupplierSolicitudeDetail.vue?vue&type=template&id=02512e60&scoped=true&lang=pug"
import script from "./SupplierSolicitudeDetail.vue?vue&type=script&lang=js"
export * from "./SupplierSolicitudeDetail.vue?vue&type=script&lang=js"
import style0 from "./SupplierSolicitudeDetail.vue?vue&type=style&index=0&id=02512e60&prod&lang=scss&scoped=true"
import style1 from "./SupplierSolicitudeDetail.vue?vue&type=style&index=1&id=02512e60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02512e60",
  null
  
)

export default component.exports