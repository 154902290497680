<template lang="pug">
SideModal(v-model="isOpen" max-width="700")
  div(v-if="isOpen")
    h2.primary--text ID # {{ quotationSelected.id }} - {{ quotationSelected.name }}
    p.grey--text.my-0 Fecha de creación: {{ formatDate(quotationSelected.created_at) }}
    p.grey--text.my-0 Duración Estimada: {{ quotationSelected.estimated_duration }}
    p.grey--text Vigente hasta: {{ formatDate(quotationSelected.vigencia) }}
    div.mt-3
      v-col(cols=12)
          OTServiceDisplay(:services="form.services")
          .footerModalQuotation
            v-textarea(v-model="form.client_comments" outlined label="Comentarios del cliente")
            .subtotal_price
              label Subtotal
              strong  {{totalPrice | currency}}
            .subtotal_price(v-if="form.discount")
              label Descuento
              strong  - {{form.discount | currency}}
            .subtotal_price
              label Abono
              strong  {{form.deposit * 100}}%
            .total_price
              label Total
              strong  {{total | currency}}
            .mb-4(v-if="showErrors")
              .red--text(v-for="error in errors") {{error}}
            v-btn.mb-5.mt-5(block color="primary" x-large dense @click="useQuotation")
              v-icon mdi-car-wrench
              span.ml-2 Pasar a orden de trabajo
              v-tooltip(bottom color="purple lighten-3")
                template(v-slot:activator="{ on, attrs }")
                  v-icon.ml-2(x-small v-bind="attrs" v-on="on") mdi-information-outline
                span Puedes pasar esta cotización a Orden de Trabajo.
            v-btn(block color="primary" x-large outlined dense @click="handleDownloadPdf(quotationSelected.id)")
              span.ml-2 Descargar PDF
</template>
<script>
import SideModal from '../shared/SideModal.vue'
import OTServiceDisplay from './OTServiceDisplay.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    SideModal,
    OTServiceDisplay
  },
  data () {
    return {
      isOpen: false,
      selectVehicleStatus: 'UNSELECTED',
      showErrors: false,
      errors: [],
      success: false,
      searchCutomer: null,
      form: {}
    }
  },
  props: {
    quotationSelected: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  methods: {
    ...mapActions('inventory', ['downloadPdf']),
    open () {
      this.isOpen = true
      this.form = this.quotationSelected
    },
    useQuotation () {
      this.isOpen = false
      this.$emit('use')
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    handleDownloadPdf (id) {
      this.downloadPdf({ id }).then(
        response => {
          const blob = new Blob([response.data], {
            type: {
              type: response.headers['content-type']
            }
          })
          const blobUrl = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobUrl
          tempLink.setAttribute('download', `Presupuesto - ${id}.pdf`)
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
        }
      )
    }
  },
  computed: {
    total () {
      return Object.prototype.hasOwnProperty.call(this.form, 'total_price') ? this.form.total_price : this.totalPrice
    },
    totalPrice () {
      let total = 0
      for (let i = 0; i < this.form.services.length; i++) {
        if (this.form.services[i].saleItems) {
          total += this.form.services[i].saleItems.reduce((acum, el) => acum + el.total_price, 0)
        }
      }
      if (this.form.services.length > 0 && this.form.services[0].service && this.form.services[0].service.service_quantity && this.form.services[0].service.id) {
        total += this.form.services.filter(el => el.service !== null).reduce((acum, el) => acum + (el.service.price * Number.parseInt(el.service.service_quantity)), 0)
      }
      return total
    }
  },
  watch: {
    quotationSelected (newValue) {
      console.log(newValue)
      this.form = newValue
      this.form.services = this.form.services.map((s) => {

        return { 
          ...s,
          saleItems: s.saleItems.map((item) => {
            return {
              
              ...item,
              unit_price: item.price,
              total_price: item.price * item.quantity
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.subtotal_price {
  text-align: right;
}
.total_price {
  font-size: 18px;
  text-align: right;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.footerModalQuotation {
  background-color: #F0F3FF;
  border-radius: 10px;
  padding: 20px;
}
</style>
