<template lang="pug">
#payment-result-view
    v-container(v-if="!loading" fluid style="height: 100%")
      v-row(style="height: 100%")
        v-col.d-none.d-md-block(cols=6 style="height: 100%")
          v-card(height="100%" color="#B7E6FF" flat)
            v-row(style="height: 100%" align-content="center" no-gutters)
              v-col
                v-img.car-2(v-if="paymentResult && paymentResult.state === 'Pagado'" src="../assets/img/car-2.svg" style="margin: 0 !important; overflow: unset;")
                v-img(v-else src="../assets/img/car-3.svg" style="margin: 0 !important;")
        v-col(style="min-height: 100%").d-flex.flex-column
          v-card.result.mb-5(flat)
            v-row(style="height: 100%" align-content="center" no-gutters)
              v-col.result-content.mb-16(cols=12)
                v-icon.mb-5(size=75 color="#2F58A3") {{texts.icon}}
                div {{texts.text1}}
                div(style="font-weight: 700; font-size: 32px;") {{texts.text2}}
              v-col.result-info(v-if="paymentResult && paymentResult.state === 'Pagado'" cols=12)
                div Fecha de pago
                .result-info-data {{paymentResult.created_at | moment('DD/MM/YYYY')}}
                div ID
                .result-info-data {{paymentResult.id}}
                div Monto
                .result-info-data {{paymentResult.payment_total | currency}}
              v-col.result-info(v-else cols=12)
                div No se ha realizado el pago
          v-btn(block color="primary" :href="customerHomeUrl").mb-1 Volver al inicio
</template>

<script>
import { mapActions } from 'vuex'
import settings from '../../settings'

export default {
  data () {
    return {
      succesData: {
        icon: 'mdi-check-circle',
        text1: '¡Felicidades! Has llegado a la meta',
        text2: '¡El pago está hecho!'
      },
      errorData: {
        icon: 'mdi-close-circle',
        text1: '¡Upps!',
        text2: '¡Algo salió mal!'
      },
      paymentResult: {
        transaction_date: new Date(),
        id: 11,
        payment_total: 3000
      },
      loading: true
    }
  },
  methods: {
    ...mapActions('payment', ['getPaymentResult'])
  },
  computed: {
    texts () {
      if (this.paymentResult && this.paymentResult.state === 'Pagado') {
        return this.succesData
      }
      return this.errorData
    },
    customerHomeUrl() {
      return `${settings.APP_CLIENTES_URL}/ot`
    },
  },
  async created () {
    const response = await this.getPaymentResult({ paymentId: this.$route.params.paymentId })
    if (response.status >= 200 && response.status < 300) {
      this.paymentResult = response.data
    }
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
#payment-result-view {
  height: 100%;
  background-color: #F7F5F4;
}
.result-content {
  color: #2F58A3;
  text-align: center;
}
.result-info {
  text-align: center;
  font-size: 16px;
  color: #464544;
  &-data {
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 600;
  }
}
.result {
  height: 100%;
}
.car-2::v-deep .v-image__image{
  left: -10px;
}
</style>
