<template lang="pug">
.flex-col.gap-1
  v-text-field.search-field(
    v-model="search"
    placeholder="Buscar vehículo"
    clearable
    outlined dense hide-details
  )
  v-data-table.ot-table(
    :headers="headers"
    :items="filteredResults"
    :loading="loading"
    loading-text="Cargando vehículos..."
    hide-default-footer
    no-data-text="No se encontraron vehículos"
  )
    template(#item._actions="{ item }")
      v-btn.btn-detail.primary--text(
        text
        @click="gotoVehicle(item.id)"
      ) Saber más
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'vuex'
import { useCustomerListStore } from '@/stores/views/useCustomerListStore'

export default {
  data() {
    return {
      results: [],
      loading: false,
      search: '',
    }
  },

  computed: {
    ...mapState(useCustomerListStore, ['selectedCustomer']),

    headers() {
      return [
        { text: 'Marca', value: 'brand_name' },
        { text: 'Modelo', value: 'model_display' },
        { text: 'Patente', value: 'patent' },
        { text: 'Detalle', value: '_actions' },
      ]
    },

    filteredResults() {
      if (!this.search) return this.results
      return this.results.filter((item) => {
        const search = this.search.toLowerCase()
        return (
          item.brand_name.toLowerCase().includes(search) ||
          item.model_display.toLowerCase().includes(search) ||
          item.patent.toLowerCase().includes(search)
        )
      })
    },
  },

  watch: {
    'selectedCustomer.id': {
      handler(val) {
        if (!val == null) return
        this.fetch(val)
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('vehicle', ['customerVehicles']),

    async fetch(id) {
      this.loading = true
      const res = await this.customerVehicles({ id })
      if (res.status < 200 || res.status >= 300) {
        this.loading = false
        return this.$toast.error('Error al cargar lista de vehículos')
      }

      this.results = res.data
      this.loading = false
    },

    gotoVehicle(id) {
      this.$router.push({ name: 'Vehiculos', query: { id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-detail {
  padding: 0 !important;
  height: auto !important;
  text-decoration: underline;
}

.seearch-field {
  max-width: 260px;
  &::v-deep .v-input__slot {
    min-height: 48px !important;
  }
}
</style>
