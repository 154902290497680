import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import sale from './sale'
import customer from './customer'
import payment from './payment'
import inventory from './inventory'
import vehicle from './vehicle'
import service from './service'
import ot from './ot'
import estimate from './estimate'
import user from './user'
import app from './app'
import OTListFilterStore from './otListFilter'
import schedule from './schedule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    sale,
    customer,
    inventory,
    vehicle,
    service,
    ot,
    user,
    estimate,
    payment,
    app,
    OTListFilterStore,
    schedule,
  }
})
