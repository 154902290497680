<template lang="pug">
v-app

  v-main(v-if="$route.meta.public")
    router-view
  v-main(app v-else)
    NavMenuMobile.show-on-mobile.hide-print(
      :routes="filteredRoutes"
      @logout="onLogout"
    )
    .main-padding
      v-card.m-0.p-0.mi-card
        NavMenu.hide-on-mobile.hide-print(
          :routes="filteredRoutes"
          @logout="onLogout"
        )
        .app-content-wrapper
          v-container.main-container(fluid)
            v-row.header-row.hide-print(v-if="!$route.meta.noHeader" justify="space-between" align="center" :class="backgroundClass")
              v-col(cols="12").pb-0
                v-card.header-card(elevation="0" :class="backgroundClass === 'carBackgroundImage' ? 'transparentBackground' : ''")
                  .header
                    .header-title {{ title }}
                    .header-subtitle(v-if="subTitles.length === 0") {{ subTitle }}
                    .header-subtitle(v-else v-for="(sub, index) in subTitles" :key="index") {{sub}}
            v-row.content-row.printable
              v-col.pr-0
                router-view
</template>

<script>
import checkGroups from './utils/checkGroups'
import { mapActions, mapGetters } from 'vuex'
import { mapActions as mapActionsPinia} from 'pinia'
import NavMenuMobile from './components/nav/NavMenuMobile.vue'
import NavMenu from './components/nav/NavMenu.vue'
import initializeFirebase from './plugins/firebase'
import isAuthenticated from './utils/isAuthenticated'
import { useNotificationStore } from './stores/api/useNotificationStore'

export default {
    name: "App",
    components: { NavMenuMobile, NavMenu },
    data: () => ({
        routes: [
            {
                text: "Orden de Trabajo",
                url: "/ot",
                icon: "mdi-car-wrench",
                small: false,
                groups: ["Recepcionista", "Bodega", "Supervisor"]
            },
            {
                text: "Agenda",
                url: "/schedule",
                icon: "mdi-calendar-month",
                small: false,
                groups: ["Recepcionista"]
            },
            {
                text: "Correos",
                url: "/mail",
                icon: "mdi-email-outline",
                small: false,
                groups: ["Recepcionista"]
            },
            {
                text: "Bodega",
                icon: "mdi-wrench",
                small: true,
                children: [
                    {
                        text: "Inventario",
                        url: "/piezas",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Movimiento de Inventario",
                        url: "/movimiento-stock",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Ingreso de Mercadería",
                        url: "/stock",
                        groups: ["Recepcionista", "Supervisor"]
                    },
                    {
                        text: "Solicitud de Ingreso de Mercadería",
                        url: "/SolicitudeStock",
                        groups: ["Recepcionista", "Bodega"]
                    },
                    {
                        text: "Marcas",
                        url: "/marcas",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Tipo de Gasto",
                        url: "/tipo-gasto",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Convenios",
                        url: "/convenios",
                        groups: ["Recepcionista"]
                    }
                ]
            },
            {
                text: "Cotización",
                url: "/budgets/quotations",
                icon: "mdi-file",
                small: false,
                groups: ["Recepcionista"]
            },
            {
                text: "Clientes",
                icon: "mdi-account-multiple",
                small: false,
                children: [
                    {
                        text: "Plantillas",
                        url: "/plantillas",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Listado de Clientes",
                        url: "/clientes",
                        groups: ["Recepcionista"]
                    }
                ]
            },
            {
                text: "Vehículos",
                icon: "mdi-car",
                small: false,
                children: [
                    {
                        text: "Vehículos",
                        url: "/vehiculos",
                        icon: "mdi-star",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Modelo Vehículo",
                        url: "/modelo-vehiculos",
                        groups: ["Recepcionista"]
                    }
                ]
            },
            {
                text: "Ventas",
                icon: "mdi-cash-multiple",
                small: false,
                children: [
                    {
                        text: "Finanzas",
                        url: "/finance",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Proveedores",
                        url: "/suppliers",
                        groups: ["Recepcionista"]
                    },
                    {
                        text: "Listado de Ventas",
                        url: "/ventas",
                        groups: ["Recepcionista"]
                    }
                ]
            },
            {
                text: "Trabajo en Curso",
                url: "/works",
                icon: "mdi-star",
                small: false,
                groups: ["Técnico"]
            },
        ]
    }),
    computed: {
        ...mapGetters("user", ["technicians"]),
        ...mapGetters("app", ["title", "subTitle", "backgroundClass", "subTitles"]),
        ...mapGetters("auth", ["user"]),
        filteredRoutes() {
            const localRoutes = this.routes.map(route => {
                if (this.checkChildren(route)) {
                    const filteredChildren = route.children.filter(child => checkGroups(child.groups, this.user));
                    if (filteredChildren.length > 0) {
                        return {
                            text: route.text,
                            icon: route.icon,
                            small: route.small,
                            children: filteredChildren
                        };
                    }
                    return {};
                }
                else {
                    if (checkGroups(route.groups, this.user))
                        return { ...route };
                    return {};
                }
            }).filter(r => (r && Object.keys(r).length > 0));
            return localRoutes;
        }
    },
    methods: {
        ...mapActions("app", ["setTitle", "setSubtitle", "setBackgroundClass", "setSubtitles"]),
        ...mapActions("auth", ["logout"]),
        ...mapActionsPinia(useNotificationStore, ['registerNotificationsDevice']),
        onLogout() {
            this.logout()
                .then(() => this.$router.push("/login"));
        },
        // getAvatarText() {
        //     return this.user ? this.user.first_name.charAt(0) + this.user.last_name.charAt(0) : "";
        // },
        checkChildren(route) {
            return Object.prototype.hasOwnProperty.call(route, "children");
        }
    },
    watch: {
        $route: {
          handler(to, from) {
            // Watch para rutas con titulo fijo (no dinámico)
            if (Object.prototype.hasOwnProperty.call(to.meta, "title")) {
                this.setTitle(to.meta.title);
                this.setBackgroundClass("");
                this.setSubtitles([]);
            }
            if (Object.prototype.hasOwnProperty.call(to.meta, "subtitle")) {
                this.setSubtitle(to.meta.subtitle);
                this.setBackgroundClass("");
                this.setSubtitles([]);
            } else {
                this.setSubtitle('');
                this.setSubtitles([]);
            }
            if (to.name === "Detalle Reparaciones") {
                this.setTitle(to.meta.title);
                this.setBackgroundClass("carBackgroundImage");
            }
          },
          immediate: true,
        }
    },
    async created() {
      if (!isAuthenticated()) return
      const token = await initializeFirebase()
      if (token) {
        this.registerNotificationsDevice({ device_token: token })
      }
    },
}
</script>

<style lang="scss" scoped>
.transparentBackground {
  background: transparent !important;
  color: #fff ;
}
.carBackgroundImage {
  background-image: url("./assets/img/auto_detalles.svg");
  height: 210px;
  margin: 0 !important;
  color: #fff;
}
.header-title {
  font-size: 28px;
}
.header-subtitle {
  font-size: 18px;
}
</style>

<style lang="scss" scope>
html {
  overflow-y: auto !important;
}

.app-content-wrapper {
  height: 100%;
  overflow-y: auto;
}

.v-image{
  margin: 48px 0 !important;
}
.v-card.border{
  height:100%;
}
.test{
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 0 !important;
  background: #4C7AEC;
  @media (max-width: 770px) {
    padding: 0 !important;
    border-radius: 0 !important;
    background: #F8F8F8;
  }
}
.v-card.v-sheet.theme--light.mi-card{
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-radius: 30px !important;
    height: 100%;
    /* background: #4C7AEC; */

    & aside{
      border-top-left-radius: 30px !important;
      border-bottom-left-radius: 30px !important;
      border-top-right-radius: 0 !important;
    }
    @media (max-width: 770px) {
      padding: 0 !important;
      border-radius: 0 !important;
      background: #F8F8F8;
      & aside{
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
}
.container.container--fluid.main-container{
  padding: 16px 16px;
  background: #F9FAFE;
  border-radius: 30px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 770px) {
    padding: 0;
    border-radius: 0;
  }
}
.main-padding {
  height: 100vh;
  padding: 20px;
  @media (max-width: 770px) {
    padding: 0;
  }
}
.hide-on-mobile {
  @media (max-width: 770px) {
    display: none !important;
  }
}
.show-on-mobile {
  display: none !important;
  @media (max-width: 770px) {
    display: block !important;
  }
}
.center{
  justify-content: flex-end;
  align-items: center;
}
.v-main {
  @media (min-width: 770px) {
    padding: 0 !important;
  }
}

.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin: 0 !important;
}

.header-row {
  flex-grow: 0;
}

.content-row {
  flex-grow: 1;
}

.header-card {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}
</style>
