<template lang="pug">
div(class="minusplusnumber")
  div(id="field_container")
    //- input(type="number" v-model="newValue" disabled)
    v-text-field.centered-input.pt-0(
      ref="field" 
      type="number" 
      v-model="newValue" 
      readonly 
      :rules="rules"
      hide-details
      :disabled="disabled"
      )
      template(v-slot:prepend-inner)
        v-btn(class="mpbtn minus" @click="mpminus()" icon :disabled="disabled")
          v-icon mdi-arrow-down-drop-circle-outline
      template(v-slot:append)
        v-btn(class="mpbtn plus" @click="mpplus()" icon :disabled="disabled")
          v-icon mdi-arrow-up-drop-circle-outline
</template>

<script>
export default {
  props: {
    value: {
      default: 1,
      type: Number
    },
    min: {
      default: 1,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    pieceID: {
      type: Number
    },
    selected: {
      default: true,
      type: Boolean
    },
    rules: {
      default: () => [],
      type: Array
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      newValue: 1
    }
  },

  methods: {
    getNotificationClass (notification) {
      return `alert alert-${notification.type}`
    },
    mpplus: function () {
      if (this.max === undefined || (this.newValue < this.max)) {
        this.newValue = this.newValue + 1
        this.$emit('input', { value: this.newValue, id: this.pieceID })
      }
    },
    mpminus: function () {
      if (this.newValue > this.min) {
        this.newValue = this.newValue - 1
        this.$emit('input', { value: this.newValue, id: this.pieceID })
      }
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.newValue = newVal
      }
    }
  },
  created: function () {
    this.newValue = this.value
    this.$nextTick(() => {
      this.$refs.field.form.validate()
    })
  },
}
</script>
<style lang="scss" scoped>
  .minusplusnumber {
      border:1px solid transparent;
      border-radius:5px;
      background-color: transparent;
      margin:0 5px 0 5px;
      display:inline-block;
      user-select: none;
  }
  .minusplusnumber div {
      display:inline-block;
  }
  .minusplusnumber #field_container input {
      width:50px;
      text-align:center;
      font-size:15px;
      padding:5px;
      border:none;
  }
  .minusplusnumber .mpbtn {
      padding:5px 5px 5px 5px;
      cursor:pointer;
      border-radius:5px;
  }
  .minusplusnumber .mpbtn:hover {
      background-color:#E8EAF6;
  }
  .minusplusnumber .mpbtn:active {
      background-color:#E8EAF6;
  }
</style>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
