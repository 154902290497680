<template lang="pug">
v-dialog(v-model="deliveryIsOpen" width="300" content-class="elevation-0")
  v-card
    div.delivery-img
    v-card-title.title ¡Felicidades!
    v-card-subtitle.subtitle Se han terminado todos los trabajos relacionados a la OT.
    v-card-actions
      v-btn.btn(@click="finish()") Finalizar
</template>
<script>
export default {
  data () {
    return {
      deliveryIsOpen: false
    }
  },
  methods: {
    open () {
      this.deliveryIsOpen = true
    },
    finish () {
      this.deliveryIsOpen = false
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  color: #1A1796;
  font-weight: 800 !important;
  justify-content: center;
  padding-bottom: 30px !important;
  padding-top: 5px !important
}
.subtitle {
  color: #1A1796 !important;
  justify-content: center !important;
  text-align: center;
}
.btn {
  background-color: #407BFF !important;
  color: #fff;
  margin: 20px auto;
}
.delivery-img {
  width: 300px;
  height: 200px;
  background-image: url('../../assets/img/delivery-img.png');
  background-size: auto;
  background-position: center;
}
</style>
