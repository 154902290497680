<template lang="pug">
span
  .d-flex.flex-column.serviceCard(v-for="service in services" :key="service.id")
    h3.mb-3(style="color: #7498f1;") Servicio realizado
    v-row(dense)
      v-col
        v-text-field(v-model="service.name" outlined :disabled="true")
      v-col(cols=2)
        v-text-field(v-model="service.service_quantity" outlined type="number" min=1 :disabled="true")
      v-col(cols=3)
        v-text-field(:value="(service.price * service.service_quantity)" outlined type="number" prefix="$" disabled)
    SaleItemDisplay(v-if="service.saleItems.length > 0" :saleItems="service.saleItems")
</template>

<script>
import SaleItemDisplay from './SaleItemDisplay.vue'
export default {
  components: {
    SaleItemDisplay
  },
  props: {
    services: Array
  }
}
</script>
<style lang="scss" scoped>
.serviceCard {
  background-color: #F0F3FF;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 20px;
}
</style>
