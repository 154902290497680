<template lang="pug">
v-dialog(
  :value="value"
  @input="$emit('input', $event)"
  max-width="400px" eager
)
  v-card
    v-card-title.mb-4
      h2 Asignar técnico
    v-card-text(v-if="OT")
      div
        OTEvaluationTechForm(:OTId="OT.id" :techId="OT.evaluation_tech" :state="OT.state" :available="OT.state === 'Ingresado' || OT.state === 'En diagnóstico' || OT.state ==='Borrador'" @showAlert="successAction")
      div.mt-3
        OTServiceTechForm(v-if="checkEstimatesState" :services="services" :ot="OT" @save="successAction" :available="OT.state === 'Entregado'")
</template>

<script>
import { mapGetters } from 'vuex';
import OTEvaluationTechForm from '../subcomponent/OTEvaluationTechForm.vue'
import OTServiceTechForm from '../subcomponent/OTServiceTechForm.vue'

export default {
  components: {
    OTServiceTechForm,
    OTEvaluationTechForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('ot', ['OT']),

    checkEstimatesState() {
      return this.OT?.estimate.some(
        (e) => e.state === 'Presupuesto aprobado' || e.state === 'En reparación'
      )
    },
  },
  methods: {
    successAction(data) {
      this.$emit('showAlert', data)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
