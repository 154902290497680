<template lang="pug">
SideModal(v-model="isOpen" max-width="600")
  v-container
    v-form(ref="formModalCash" lazy-validation v-model="formIsValid")
      div(style="margin-bottom: 20px; margin-top: -20px")
        h2.color {{ 'Nuevo Ingreso de Efectivo' }}
        p.mt-2.ml-0 {{ formatDate(today)}} hrs.
      div(style="background-color: #f0f3ff; margin-bottom: 10px; border-radius: 15px;")
        p.color.subtitle Descripción
        v-textarea.mx-5(
          v-model="expense.description"
          label="Escribe algo..."
          clear-icon="mdi-close-circle"
          rows="3"
          :rules="[v => !!v || 'La descripción es obligatoria', v => v == null || v.length <= 100 || 'Máximo 100 caracteres']"
          solo clearable counter flat
        )
      div.d-flex.justify-end(style="background-color: #fff; margin-bottom: 5px; border-radius: 15px; width: 100%")
        div
          p.color.subtitle Monto total de ingreso
          v-text-field.mx-5(
            v-model="expense.payment_total" 
            label="Ingrese monto" 
            :readonly="expense.payment_gateway === 'Cheque'" 
            :clearable="expense.payment_gateway !== 'Cheque'" 
            style="width: 200px" 
            prefix="$" 
            :rules="[v => !!v || 'El monto es obligatorio']"
            v-maska:[moneyMaskFormat]
          )
      v-btn(color="primary" block style="font-weight: 400; padding: 25px 0" @click="handleCashDeposit" :disabled="disabledSave") Crear ingreso

      v-dialog(v-model="dialog" transition="dialog-top-transition" width="450" content-class="elevation-0" style="border-radius: 16px;")
        v-card(style="padding: 50px 20px 20px 20px;")
          div.money-img
          v-card-title(
            style="color: #4C7AEC; text-align: center; font-size: 20px;"
          ) {{ "¿Seguro quieres crear un ingreso de" }}<br> {{ "efectivo por " }} ${{ expense.payment_total }}?
          v-card-text Al confirmar esta opción no puedes modificar el monto.
          v-card-actions
            v-row
              v-col(cols="6")
                v-btn(@click="dialog = false" block elevation="0" color="#4C7AEC" outlined) Cancelar
              v-col(cols="6")
                v-btn.mr-5(color="#4C7AEC" dark @click="createCashDeposit()" block) Confirmar

</template>
<script>
import { mapActions } from 'vuex'
import SideModal from '../shared/SideModal.vue'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../utils/mask'

export default {
  directives: { maska: vMaska },
  components: {
    SideModal,
  },
  props: {
    isExpense: {
      type: Boolean,
    },
  },
  data() {
    return {
      formIsValid: true,
      checkbox: true,
      isOpen: false,
      column: null,
      expense: {
        payment_total: 0,
        payment_gateway: '',
        description: '',
        balance: 0,
        aproved_by: null,
        work_order: '',
        movement_type: 'Ingreso de efectivo',
        credit_term: '',
        state: 'Pagado',
        stock_movement: [],
        invoice_number: '',
        invoice_date: '',
        expense_group: '',
      },
      today: new Date(),
      asociateOT: false,
      asociateSupplier: false,
      expense_groups: [],
      // availableCash: null,

      date: null,
      rules: {
        required: value => !!value || 'Requerido',
        greaterThanZero: value => value > 0 || 'Debe ser mayor a cero',
        isNumber: value => Number.isInteger(+value) || 'Debe ser un número',
      },
      dialog: false,
      moneyMaskFormat
    }
  },
  computed: {
    disabledSave() {
      return Object.values(this.state).includes(false)
    },
    state() {
      return {
        payment_total:
          this.expense.payment_total !== '' &&
          this.expense.payment_total !== ' ',
      }
    },
  },
  methods: {
    ...mapActions('inventory', ['createExpense']),
    open(availableCash) {
      this.isOpen = true
      this.expense = {
        payment_total: 0,
        payment_gateway: 'Efectivo',
        description: '',
        balance: 0,
        aproved_by: null,
        work_order: '',
        movement_type: 'Ingreso de efectivo',
        credit_term: null,
        state: 'Pagado',
        stock_movement: [],
        invoice_number: null,
        invoice_date: null,
        check_number: null,
        check_date: null,
        check_amount: null,
        check_qty: null,
      }
      this.date = null
      this.invoice_date = ''
      
      this.$nextTick(() => {
        this.$refs.formModalCash.resetValidation()
      })
    },
    handleCashDeposit () {
      if (!this.$refs.formModalCash.validate()) return
      this.dialog = true
    },
    createCashDeposit () {
      // if (!this.$refs.formModalCash.validate()) return

      this.expense.movement_type = 'Ingreso de efectivo'
      delete this.expense.credit_term
      this.expense.payment_total = +this.expense.payment_total.split('.').join('')
      
      const bankWithdrawal = { 
        ...this.expense,
        movement_type: 'Retiro bancario',
        description: `Retiro bancario por concepto de: ${this.expense.description}`
      }
      
      this.createExpense({ data: { ...this.expense } }).then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.createExpense({ data: { ...bankWithdrawal } }).then((response) => {
          })
          this.isOpen = false
          this.$emit('refreshData', {
            type: 'success',
            message: 'Ingreso de efectivo creado con éxito',
            show: true,
            color: 'green',
          })
          this.$nextTick(() => {
            this.$refs.formModalCash.reset()
          })
        }
      })
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('cl-ES', {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(new Date(date))
    },
  },
  watch: {
    'expense.payment_total': {
      handler(value) {
        // console.log(this.expense.payment_total, 'payment_total')
        // console.log(this.state, 'this estate')
        if(this.expense.payment_total && value[0] === '0') {
          this.expense.payment_total = Number(value) * 1
        }
      },
      deep: true    
    }
  }
}
</script>
<style lang="scss" scoped>
.color {
  color: #4c7aec;
}
.subtitle {
  padding-top: 20px;
  padding-left: 20px;
}
.otNumber {
  width: 80%;
  margin-top: 12px;
}
</style>
