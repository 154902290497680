<template lang="pug">
  v-dialog(v-model="isOpen" max-height=800 max-width=800)
    v-card(v-if="form.preview" style="height:100%; position: relative;" height="100%" width="100%")
      v-img.align-end(:src="form.preview" height="100%" width="100%" style="margin: 0 !important;")
        v-card-text
          v-form(@submit.prevent="onSubmit")
            v-textarea.white.mb-2(v-model="form.comment" outlined hide-details rows=2)
            v-btn(color="primary" block type="submit") Subir foto
        v-btn.close-btn(icon @click="isOpen = false")
          v-icon mdi-close
</template>

<script>
export default {
  data () {
    return {
      isOpen: false,
      form: {
        img: null,
        comment: null,
        preview: null
      }
    }
  },
  methods: {
    open (item) {
      this.form = {
        img: item.img,
        comment: item.comment,
        preview: item.comment
      }
      const form = this.form
      var reader = new FileReader()
      reader.onloadend = function () {
        form.preview = reader.result
      }

      if (item.img) {
        reader.readAsDataURL(item.img)
        this.isOpen = true
      }
    },
    onSubmit () {
      this.$emit('save', { ...this.form })
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
