<template lang="pug">
  div
    v-menu(offset-y :close-on-content-click="false" @input="onOpen")
      template(v-slot:activator="{ on, attrs }")
        a(v-bind="attrs" v-on="on") Presupuestos ({{count}})
      v-card(width=277)
        div(v-for="estimate in estimates" :key="estimate.id")
          OTListEstimateSummaryItem(:estimate="estimate" @update="fetchData")
          v-divider
        v-btn(text block :disabled="OT.state === 'Entregado'" @click="$refs.OTForm.open(OT)") AÑADIR NUEVO PRESUPUESTO
    OTForm(ref="OTForm" @save="handleSave" :selectedOT="OT")
</template>

<script>
import OTListEstimateSummaryItem from './OTListEstimateSummaryItem'
import OTForm from '../../sale/OTForm.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    count: {
      type: Number
    },
    OT: {
      type: Object,
      required: true
    }
  },
  components: {
    OTListEstimateSummaryItem,
    OTForm
  },
  data () {
    return {
      estimates: []
    }
  },
  methods: {
    ...mapActions('estimate', ['listEstimates']),
    async fetchData () {
      const response = await this.listEstimates({ params: { work_order: this.OT.id } })
      if (response.status === 200) {
        this.estimates = response.data.results
      }
    },
    onOpen (isOpen) {
      if (isOpen) {
        this.fetchData()
      }
    },
    handleSave () {
      this.$emit('save')
    }
  }
}
</script>
