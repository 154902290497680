import { defineStore } from 'pinia'
import axios from '@/plugins/axios'

export const useRecommendedPieceStore = defineStore('recommendedPiece', {
  state: () => ({}),
  getters: {},
  actions: {
    async getRecommendedPieces(params) {
      return axios
        .get('/inventory/get_recommended_pieces', { params })
        .then((res) => res)
        .catch((err) => err.response)
    },
  },
})
