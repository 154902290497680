<template lang="pug">
ListOT
</template>

<script>
import ListOT from '../components/OT/ListOT.vue'

export default {
  components: {
    ListOT
  }
}
</script>
