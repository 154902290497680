<template lang="pug">
v-container
  alert(ref="alert")
  v-card
    div.d-flex
      v-expansion-panels(accordion)
        v-expansion-panel
          v-expansion-panel-header.pl-5
            v-card-title.budgetTitle.pl-1.py-0.pt-1 Insumos Adicionales
          v-expansion-panel-content
            div.pa-4
              AdditionalItems(v-if="selectedOt !== null" :ot="selectedOt" @showAlert="showAlert" :desktopStyle="true")
</template>
<script>
import { mapGetters } from 'vuex'
import AdditionalItems from '../subcomponent/AdditionalItems.vue'
import alert from '@/components/shared/alert.vue'
export default {
  components: {
    AdditionalItems,
    alert
  },
  data () {
    return {
      ItemsHeaders: [
        'Insumo',
        'Marca',
        'Cantidad',
      ],
      dialog: false
    }
  },
  props: {
    selectedOt: Object
  },
  computed: {
    ...mapGetters('inventory', ['pieces'])
  },
  methods: {
    getData (data, piece) {
      if (this.pieces.length > 0) {
        return this.pieces.find(p => p.id === piece)[data]
      }
      return ''
    },
    openModalAditionalItems () {
      this.dialog = true
    },
    showAlert(data) {
      this.$refs.alert.handleShowAlert(data.type, data.message, data.show, data.color)
      this.$emit('refresh')
      this.dialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.budgetTitle {
  color: #3658D3;
}
</style>
