<template lang="pug">
div
  v-autocomplete(:items="items" placeholder="Buscar proveedor" label="Nombre proveedor" item-text="name" outlined hide-details return-object clearable v-model="supplier" @click:clear="selectedSupplierClear" @change="show=false")
    template(#append-item)
      v-btn(color="primary" block @click="newSupplier()" elevation="0" min-height="48" outlined
      ) Agregar nuevo proveedor
  v-row.mt-2.ml-2(v-if="show")
    v-col(cols="4")
      p.mb-0.color.title-new-supplier Proveedor
      p.mb-0.color Elije tu proveedor o crea uno nuevo
    v-col.d-flex.align-center
      v-text-field(outlined placeholder="Nombre del proveedor" label="Nombre de Proveedor" color="primary" width="30%" hide-details v-model="supplierName" dense)
      v-btn.ml-3(color="primary" elevation="0" @click="handleSave") Crear
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      supplier: null,
      isSelected: false,
      show: false,
      items: [],
      supplierName: ''
    }
  },
  methods: {
    ...mapActions('inventory', ['listSuppliers', 'createSupplier']),
    fetchData () {
      this.listSuppliers().then(
        response => {
          this.items = response.data
        }
      )
    },
    selectedSupplierClear () { 
      this.supplier = null
      this.isSelected = false
    },
    newSupplier () {
      this.show = !this.show
      this.supplier = null
      this.isSelected = false
    },
    handleSave () {
      this.createSupplier({ data: { name: this.supplierName } }).then(
        response => {
          if (response.status === 201) {
            this.fetchData()
            this.reset(response.data)
            this.$emit('selectedSupplier', { supplier: response.data })
          }
        }
      )
    },
    handleContinue () {
      this.$emit('selectedSupplier', { supplier: this.supplier })
    },
    reset (data = null) {
      this.show = false
      this.supplier = data
      this.isSelected = data === null
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    supplier (val) {
      if (val !== null) {
        this.isSelected = true
        this.$emit('supplierChanged', val)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.background {
  background-color: #fff;
  height: 300px;
}
.title-new-supplier {
  font-size: 16px;
  font-weight: 500;
}
.color {
  color: #4C7AEC;
}
</style>
