<template lang="pug">
SideModal(v-model="isOpen" max-width="600" )
  alert(style="text-align: end !important; position: absolute !important; z-index: 999999 !important;" ref="alert")
  v-container(ref="form")
    div(style="margin-bottom: 20px; margin-top: -20px")
      h2.color {{ 'Agenda servicios' }}
        
      div(style="margin-top: 30px; height: fit-content")
        label.primary--text Servicio
        v-select(
          ref="serviceSelected"
          v-model="serviceSelected"
          style=" height: fit-content; margin: 0; padding: 0" 
          placeholder="Selecciona tu servicio" 
          outlined
          return-object
          :item-text="({ name, duration }) => name + ' - ' + duration + ' hora(s)'"
          :disabled="isSelectDisabled"
          :items="services"
          @change="fetchTimeBlocks()"
      
        )

    //- div(style="background: #fff; margin-bottom: 1.5rem")
    //-   v-row.mt-1
    //-     v-icon.ml-2.mr-1 mdi-calendar-month
    //-     div(style="border-radius: 0.4rem"  class="time-date")
    //-       v-row.pl-3.pr-6.py-4(style="font-weight: 500") 
    //-         v-container.py-1(grid-list-md style=" width: fit-content; padding: 0" )
    //-           <v-layout row wrap style=" width: fit-content; padding: 0">
    //-             <v-flex xs12 lg12 style="display:flex; flex-direction: row; padding-right: 1rem;">
    //-                 v-menu(
    //-                   ref="menu1"
    //-                   v-model="menu1"
    //-                   :close-on-content-click="false"
    //-                   :nudge-right="40"
    //-                   transition="scale-transition"
    //-                   offset-y
    //-                   max-width="290px"
    //-                   min-width="290px"                  
    //-                 )
    //-                   <template v-slot:activator="{ on }" style="padding: 0; width: fit-content" >
    //-                     div(
    //-                       v-model="dateFormatted"
    //-                       @blur="date = parseDate(date)"
    //-                       v-on="on"
    //-                       class="dateAppointment"
    //-                       style="width: fit-content; margin: 0; padding: 0; display: flex; flex-direction: row;"
    //-                     ) 
    //-                       h3.ml-1.mr-2 {{ dateFormatted }}
    //-                   </template>

    //-                   <v-date-picker v-model="focus" @click:date="viewDay" no-title :min="today" :allowed-dates="allowedDates" locale="cl-Es" @input="menu1 = false"></v-date-picker>
    //-                 </v-menu>

    //-                   <input ref="startTime" style="display: flex; flex-direction: row;  cursor: text;" type="time" :min="workingHours.start" :max="workingHours.end" name="startTime" required v-model="currentTime">
    //-                   <span class="validity"></span>
    //-             </v-flex>
    //-           </v-layout>
      <span class="hint">
        <p>Horario: <br/> - Lunes - Viernes 8:00am - 6:00pm <br/> - Sábado 9:00am - 2:00pm
        </p>
      </span>            
    v-row(cols=12)
      v-col.py-0
        v-menu(
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        )
          <template v-slot:activator="{ on }">
            label.primary--text Fecha
              v-text-field(
                outlined
                append-icon="mdi-calendar" 
                v-model="dateFormatted"
                v-on="on"
                :rules="rules.date"
              )
          </template>

          v-date-picker(
            v-model="focus" 
            no-title
            :min="today" 
            :allowed-dates="allowedDates" 
            locale="cl-Es" 
            @input="menu1 = false, fetchTimeBlocks()"
            @click:date="viewDay"
          )
      v-col.py-0.mb-4(cols="12")
        label.primary--text Horario
          div.black--text
            div(v-if="!dateFormatted") Selecciona una fecha para ver los horarios disponibles
            div(v-else-if="!serviceSelected") Selecciona un servicio para ver los horarios disponibles
            div(v-else-if="timeBlocks.length === 0 && !loadingBlocks") No hay horarios disponibles
          v-chip-group(v-model="form.time" column :rules="rules.time" active-class="actived-hour")
            v-chip(
              v-for="(block, index) in timeBlocks"
              outlined
              large
              label
              filter="true"
              filter-icon="mdi-checkbox-marked-circle"
              :key="index" 
              color="primary"
              :value="block.hour"
            ) {{ block.hour }}
          div.error--text(v-if="validated && !form.time") Este campo es requerido

    v-row.mb-4(cols=12 dense)
      div.mb-1.mr-2(v-if="customerData.id" style="display: flex; width: 100%; justify-content: end;")
        span.ml-2(style="color: #3658d3; font-weight: 500; cursor: pointer;" @click="handleCustomerEdit") Datos del cliente
          v-icon.ml-2() mdi-square-edit-outline

      v-col(cols=12)
        AutoField(
          v-if="!openCustomerForm"
          ref="customerName"
          v-model="newCustomer"
          label="Nombre del cliente"
          :fetch="listCustomers",
          :value="newCustomer"
          :disabled="isCustomerDisabled"
          
          
          :options=`{            
            label:"Nombre del cliente",
            disabled:isCustomerDisabled,
            itemText:(item) => item?.name + ' '+ item?.last_name,
            returnObject: true,
            filled: true,
            height: '70px',
            dense: false,
            clearable: false,
          }`
        )
          template(#append-item)
            div.mr-2.ml-2(@click="handleResetCustomerForm") 
              v-btn(outlined height=56 block color="primary" @click="openCustomerForm = !openCustomerForm") Nuevo cliente

      v-col(cols=10 v-if="newCustomer?.id")
        v-autocomplete(
          ref="vehicle"
          label="Vehículo"
          v-model="vehicles3"
          outlined
          :disabled="isVehicleDisabled"
          item-text="model_display"
          return-object
          :items="vehicles"
          
        )
        
      v-col(cols=1 v-if="newCustomer?.id")
        v-btn(class="mr-0" 
          height=56 
          block color="primary" 
          @click="openCustomerForm2 = !openCustomerForm2" 
          :disabled="isVehicleDisabled"
        ) +
          v-icon(dark) mdi-car
          
      v-col(cols=12 v-if="newCustomer?.id")
        v-text-field(
          ref="patent" 
          v-model="patentSelected" 
          label="Patente" 
          outlined
          readonly 
          :disabled="isVehicleDisabled"
        )

  v-btn(
    color="primary" 
    block
    :disabled="false"
    :loading="tryingSave" 
    style="font-weight: 400; padding: 25px 0" 
    @click="handlerAppointmentSave"
  ) Guardar
  v-card(color="#4ade80" flat v-if="saveSuccess") 
    h5.ma-1.pa-2(style="color: #FFFFFF; text-align: center;") Agendado con éxito
  v-card(color="#fc8181" flat v-if="saveError") 
    h5.ma-1.pa-2(style="color: #FFFFFF; text-align: center;") No se pudo guardar, intenta de nuevo
  v-card(color="#fc8181" flat v-if="formError" v-model="errorMessage") 
    h5.ma-1.pa-2(style="color: #FFFFFF; text-align: center;") {{ errorMessage }}
      
  
  SideModal(v-model="openCustomerForm" max-width="600")
    v-container
      h2.mb-5 {{ customerData.id? 'Datos del cliente' : 'Crear nuevo cliente' }}
      div
        CustomerForm(ref="customerForm" :customerSelected="customerData" @save="handlerCustomerSave")
        alert(style="text-align: end !important; position: absolute !important;" ref="alert")
  
  SideModal(v-model="openCustomerForm2" max-width="600")
    v-container
      h2.mb-5 Nuevo vehículo
      CustomerAndVehicleForm2(ref="customerForm2" :customerSelected="newCustomer" @save="handlerVehicleSave")
  
</template>

<script>
import SideModal from '../shared/SideModal.vue';
import Alert from '../shared/alert.vue'
import { mapGetters, mapActions } from 'vuex';
import CustomerFormModal from '../customer/CustomerFormModal.vue';
import CustomerForm from '../customer/CustomerForm.vue';
import AutoField from '../shared/AutoField.vue';
import CustomerAndVehicleForm2 from '../vehicle/CustomerAndVehicleForm2.vue';

export default {
  components: {
    SideModal,
    CustomerFormModal,
    CustomerForm,
    AutoField,
    CustomerAndVehicleForm2,
    Alert,
  },
  props: {
    getTodayAppointments: {
      
    },
  },
  data () {
    return {
      isOpen: false,
      form: {
        vehicle: null,
        service: null,
        date: null,
        time: null,
      },
      rules: [v => v.length <= 100 || 'Máximo 100 caracteres'],
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10),
      dateFormatted: this.formatDate2(),
      menu1: false,     
      customersList: [],

      newEvent: { 
        // service_id
        service: '',  
        // 2023-4-29T14:24
        start_at: '',
        // customer_id
        customer: '',
        // patent: '', 
      },

      openCustomerForm: false,
      openCustomerForm2: false,

      customerData: {
        name:'',
        last_name: '',
        rut: '',
        address: '',
        email: '',
        contact: '',
      },

      newCustomer: {},
      currentTime: this.getCurrentTime(),
      focus: this.focusDay,
      vehicle: {
        patent: null,
        model: {},
      },

      vehicle2: this.vehicles,
    

      rescheduling: false,
      vehicles3: null,
      patentSelected: null,
      serviceSelected: null,
      isVehicleDisabled: false,
      isSelectDisabled: false,
      isCustomerDisabled: false,

    
      saveSuccess: false,
      saveError: false,
      formError: false,
      errorMessage: '',

      workingHours: {
        start: '08:00',
        end: '18:00',
      },

      tryingSave: false,

      timeBlocks: [],
      loadingBlocks: false,
      loadingSubmit: false,
      validated: false,

    };
  },
  computed: {
    ...mapGetters("schedule", ['focusDay', 'services', 'customers', 'currentAppointment']),
    ...mapGetters('vehicle', ['models', 'vehicles']),
    computedDateFormatted (date) {
      return this.formatDate(date)
    },

  },
  created () {
    this.listServices();
    this.getWorkingHours();
    this.listModels();
  },

  methods: {
    ...mapActions(
      'schedule', 
      [
        'updateFocus',
        'listServices', 
        'createAppointment', 
        'listAppointments', 
        'rescheduleAppointment', 
        'updateCurrentAppointment',
        'getTimeBlocksByService'
      ]
    ),
    ...mapActions('customer', ['listCustomers']),
    ...mapActions('vehicle', ['listModels', 'customerVehicles']),
    async open () {
      if(this.currentAppointment?.id) {
        
        this.newCustomer = this.currentAppointment.customer;
        this.rescheduling = true;
        this.vehicles3 = this.currentAppointment.vehicle;
        this.patentSelected = this.currentAppointment.vehicle.patent;
        this.serviceSelected = this.currentAppointment.service;

        this.isVehicleDisabled = true;
        this.isSelectDisabled = true;
        this.isCustomerDisabled = true;
            
        this.updateFocus(this.currentAppointment.start.split(' ')[0])
        this.focus = await this.focusDay
        await this.fetchTimeBlocks();
        this.form.time = this.currentAppointment.start.slice(11, 16);
        this.addBlock({ hour: this.form.time })
      }
      else {
        this.handleResetAppointment()
        this.validateDate(this.focusDay)
      }
      this.isOpen = true
    },

    allowedDates: 
      val => new Date(val).getDay() !== 6
    ,

    validateDate (focusDay) {
      if(focusDay === '') focusDay = this.today;
      let selectedDateToMS = new Date(focusDay).getTime();
      const selectedDayName = this.dateFormatted.split(',')[0];
      const currentDateToMS = new Date(this.today).getTime();
      const todayDayName = this.formatDate2().split(',')[0];  
      const oneDayToMS = 86400000;

      const currentToNextDay = () => {
        const nextDay = Number(selectedDateToMS) + oneDayToMS
        const nextDayFormated = new Date(nextDay).toISOString().slice(0, 10)
          
        this.updateFocus(nextDayFormated)
      }

      if(selectedDateToMS <= currentDateToMS) {
        if(todayDayName === 'Domingo') {
          selectedDateToMS = currentDateToMS
          currentToNextDay();
        } else {
        this.updateFocus(this.today)
        }
      }

      if(selectedDateToMS > currentDateToMS && selectedDayName === 'Domingo') {
          currentToNextDay();
      }
    },

    getWorkingHours () {
      const selectedDay = this.dateFormatted.split(',')[0]
      if(selectedDay === 'Sábado') {
          this.workingHours.start = '09:00';
          this.workingHours.end = '14:00';
      } else {    
        this.workingHours.start = '08:00';
        this.workingHours.end = '18:00';
      }

    },
    
    formatDate (date) {
      if (!date) return null
      const [year, month, day,] = date?.split('-')
      const formDate = `${month}/${day}/${year}/`
      const today = new Date(formDate);
      
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return today.toLocaleDateString('cl-Es', options).slice(0, 1).toUpperCase() + today.toLocaleDateString('cl-Es', options).slice(1)
    },

    formatDate2 () {      
      const today = new Date();
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return today.toLocaleDateString('cl-Es', options).slice(0, 1).toUpperCase() + today.toLocaleDateString('cl-Es', options).slice(1)
      },
    parseDate (date) {

      this.updateFocus(date)
      return date;
    },

    viewDay (date) {
      this.updateFocus(date)
    },

    async handlerVehicleSave (newVehicle) {
      await this.customerVehicles({ id: this.newCustomer.id })
      this.vehicles3 = newVehicle
      this.openCustomerForm2 = false;
    },

    handlerCustomerSave (customer) {
      this.newCustomer = customer
      this.openCustomerForm = false;
    },

    async handlerAppointmentSave () {
      this.formError = false;
      this.saveSuccess = false;

      const serviceSelected = this.services.filter((el)=> el?.name === this.$refs.serviceSelected.selectedItems[0]?.name)      
      // const serviceSelectedDuration = Number(serviceSelected[0]?.duration) * 60;

      // const startTime = this.$refs.startTime.value;
      const startTime = this.form.time;
      const selectedDate = this.focusDay !== ''? this.focusDay : this.today

      // const currentTimeToMinute = Number(startTime.split(':')[0]) * 60 + Number(startTime.split(':')[1])
      // const minHourToMinute = Number(this.workingHours.start.split(':')[0]) * 60;
      // const maxHourToMinute = Number(this.workingHours.end.split(':')[0]) * 60;

      if(serviceSelected[0] === undefined) {
        // this.errorMessage = 'Por favor, selecciona un servicio'
        // this.formError = true;

        this.$refs.alert.handleShowAlert('error', 'Por favor, selecciona un servicio', true, 'red')

        // setTimeout(() => {
        //   this.formError = false;
        // }, 4000);
        return
      }
      if(!startTime) {
        // this.errorMessage = 'Por favor, selecciona un horario'
        // this.formError = true;

        this.$refs.alert.handleShowAlert('error', 'Por favor, selecciona un horario', true, 'red')

        // setTimeout(() => {
        //   this.formError = false;
        // }, 4000);
        return    
      }
      // else if(currentTimeToMinute < minHourToMinute || currentTimeToMinute > maxHourToMinute) {
      //   this.errorMessage = 'Por favor, selecciona un horario válido'
      //   this.formError = true;

      //   setTimeout(() => {
      //     this.formError = false;
      //   }, 4000);
      //   return     
      // }
      // else if((currentTimeToMinute + serviceSelectedDuration) > maxHourToMinute) {
      //   this.errorMessage = 'La duración del servicio seleccionado excede el horario de trabajo. Por favor, selecciona un horario menor para este servicio'
      //   this.formError = true;

      //   setTimeout(() => {
      //     this.formError = false;
      //   }, 8000);
      //   return
      // }      
      if(this.$refs.customerName.value === null ||  this.$refs.customerName?.value.id === undefined) { 
        // this.errorMessage = 'Por favor, selecciona un cliente'
        // this.formError = true;

        this.$refs.alert.handleShowAlert('error', 'Por favor, selecciona un cliente', true, 'red')
        // setTimeout(() => {
        //   this.formError = false;
        // }, 4000);
        return
      }
      else if(this.$refs.patent.value === null || this.$refs.patent.value.replace(/\s+/, '').length !== 6) {
        // this.errorMessage = 'Por favor, ingresa una patente válida. (6 caracteres)'
        // this.formError = true;

        this.$refs.alert.handleShowAlert('error', 'Por favor, selecciona un vehículo', true, 'red')

        // setTimeout(() => { 
        //   this.formError = false;
        // }, 4000);
        return
      }
      
      this.tryingSave = true;
      let response;

      if(this.currentAppointment?.id) {
        const data = {
          id: this.currentAppointment.id,
          newDate: {
            start_at: `${selectedDate}T${startTime}`,
          }
        };

        response = await this.rescheduleAppointment(data);

      } 
      else {  
      
        const newEvent = {
          service: serviceSelected[0].id,
          // 2023-4-29T14:24
          start_at: `${selectedDate}T${startTime}`,
          customer: this.$refs.customerName.value.id,
          vehicle: this.vehicles3?.id

        }
        
              
        response = await this.createAppointment(newEvent)
      }
      setTimeout(() => {
        this.tryingSave = false;
        if([200, 201].includes(response)) {
          // this.saveSuccess=true;

          this.$refs.alert.handleShowAlert('success', 'Agendado con éxito', true, 'green')

          this.updateCurrentAppointment(null)
          this.handleResetAppointment();
          this.listAppointments();
          // setTimeout(() => {
          //   this.saveSuccess = false;
          // }, 4000);
        }
        else if (response.data.includes('<!DOCTYPE html>')) {
          const errorText = response.data.split('"exception_value">')[1].split(':')[0]
          // this.errorMessage = errorText
          
          this.$refs.alert.handleShowAlert('error', errorText, true, 'red')

          // this.formError = true
          // setTimeout(() => {
          //   this.formError = false
          // }, 5000);
        }
        else {
          this.$refs.alert.handleShowAlert('error', response.dataText, true, 'red')
          // this.errorMessage = response.dataText
          // this.formError = true;
          // setTimeout(() => {
          //   this.formError = false;
          // }, 5000);
        }

      }, 1000);  
    },
    handleCustomerEdit () {
      this.openCustomerForm = !this.openCustomerForm
    },

    handleResetAppointment() {
      this.currentTime = this.getCurrentTime();
      this.newCustomer = null;
      this.$refs.serviceSelected && this.$refs.serviceSelected.reset();
      this.$refs.patent && this.$refs.patent.reset();
      

      this.isVehicleDisabled = false;
      this.isSelectDisabled = false;
      this.isCustomerDisabled = false;

      this.vehicles3 = null
      this.serviceSelected = null;
      this.customerData = {
        name:'',
        last_name: '',
        rut: '',
        address: '',
        email: '',
        contact: '',
      }
      this.timeBlocks = []

      setTimeout(() => {
        
        if(this.rescheduling) {
          this.isOpen = false;
          this.rescheduling = false;
        }
      }, 1000)

    },

    handleResetCustomerForm() {
      this.$refs.customerForm.$refs.form.reset();
      
      this.customerData = {
        name:'',
        last_name: '',
        rut: '',
        address: '',
        email: '',
        contact: '',
      }
    },

    getCurrentTime() {
      const today = new Date();
      return `${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`
    },

    addBlock (block) {
      const found = this.timeBlocks.find(b => b.hour === block.hour)
      if (!found) {
        // Agrega el bloque seleccionado en la lista de bloques, en el orden correcto
        const timeToInt = time => Number(time.split(':').join(''))
        const index = this.timeBlocks.findIndex(b => timeToInt(b.hour) > timeToInt(block.hour))
        if (index === -1) {
          this.timeBlocks.push(block)
        } else {
          this.timeBlocks.splice(index, 0, block)
        }
        this.timeBlocks = [...this.timeBlocks]
      }
    },

    async fetchTimeBlocks () {
      this.form.time = null
      if (!this.serviceSelected || !this.focusDay) return
      this.loadingBlocks = true;
      const service = this.serviceSelected
      const date = this.focus || this.focusDay
      const params = { serviceId: service.id, date }
      const { data } = await this.getTimeBlocksByService(params);
      this.timeBlocks = data;
      this.loadingBlocks = false;
    },
    
  },

  watch: {
    focusDay (val) {
        this.dateFormatted = this.formatDate(val)
    },

 
    newCustomer: {
      handler (val) {
        if(val?.id) {
          const res = this.newCustomer
          this.customerData = res
          this.customerVehicles({ id: val?.id })       
        }
        if (val === '' || val === null) {
          this.customerData = {
            name:'',
            last_name: '',
            rut: '',
            address: '',
            email: '',
            contact: '',
          }
        }
      },
      deep: true
    },

    isOpen: {
      handler (newVal) {
        if(newVal === false) {
          this.handleResetAppointment();
          this.updateCurrentAppointment(null);
        }
      },
      deep: true
    },

    openCustomerForm2: {
      handler (val) {
        if(!val) {
          this.$refs.customerForm2.handleResetVehicleForm()
        }
      }
    },
    openCustomerForm: {
      handler (val) {
        const res = { ...this.newCustomer }
        this.customerData = res
        if (this.newCustomer === null) {
          this.customerData = {
            name:'',
            last_name: '',
            rut: '',
            address: '',
            email: '',
            contact: '',
          }
        }
      },
      deep: true
    },

    vehicles3: {
      handler (newVal) {
        if(newVal) {
          this.patentSelected = newVal.patent
        }
        else {
          this.patentSelected = null
        }
      },
      deep: true
    },
    vehicles: {
      handler (newVal) {
        if(newVal) {
          this.vehicles3 = this.vehicles[0]
        }
      },
      deep: true
    }

    

  },


}
</script>
<style lang="scss" scoped>

.time-date {
  color: #3c4043E0;
  
  
  h3 {
    font-weight: 500;
    font-size: 1.05rem;
  }

}
.time-date:hover {
  background: #70757a14;
  border-radius: 0.4rem;
  cursor: pointer;
}

.time-date h3:hover {
  text-decoration: underline;
  background: none;
}

input:invalid + span::after {
  position: absolute;
  color: red;
  content: "✖";
  padding-left: 5px;
}

input:valid + span::after {
  position: absolute;
  color: green;
  content: "✓";
  padding-left: 5px;
}

input[type = 'time']:focus::-webkit-calendar-picker-indicator {
    display: block;
    padding: 0 0.2;
    margin: 0;
    cursor: pointer;

}
 input::-webkit-calendar-picker-indicator {
    padding: 0 0.2;
    margin: 0;
    display: none;
}


.hint {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #3c404394;
  margin-left: 1.7rem;

  p {
    padding-top: 0.5rem;
  }

}
.color {
  color: #4C7AEC;
}
.subtitle {
  padding-top: 20px;
  padding-left: 20px;
}
.otNumber {
  width: 80%;
  margin-top: 12px;
}
.actived-hour {
  background: #4C7AEC;
  font-weight: 500;
  border: 2px solid #4C7AEC;
  cursor: pointer;
}
</style>
