<template lang="pug">
.main-wrapper
  SelectSupplierNewPiece.mr-2(
    @supplierChanged="piece.supplier_id = $event.id"
    ref="selectSupplierNewPiece"
    :simpleComponent="true"
  )
  v-text-field.mr-2(v-model="piece.supplier_piece_code" outlined label="Código proveedor" :rules="[v => !!v]")
  v-text-field.mr-2(v-model="piece.code" outlined label="Código pieza" :rules="[v => !!v]")
  v-text-field.mr-2(v-model="piece.name" outlined label="Nombre pieza" :rules="[v => !!v]")
  v-text-field.mr-2(v-model="piece.purchase_price" type="number" outlined label="Precio compra" :rules="[v => !!v]")
  v-text-field.mr-2(v-model="piece.sale_price" type="number" outlined label="Precio venta" :rules="[v => !!v]")
  v-text-field.mr-2(v-model="piece.stock" type="number" outlined label="Stock a ingresar" :rules="[v => !!v]")
</template>

<script>
import SelectSupplierNewPiece from '@/components/stock/SelectSupplierNewPiece.vue'

export default {
  components: {
    SelectSupplierNewPiece,
  },
  props: {
    piece: {
      type: Object,
      default: () => ({}),
    },
    suppliers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      supplier: null,
      isSelected: false,
    }
  },
  methods: {
    selectedSupplierClear () {
      this.supplier = null
      this.isSelected = false
    },
  },
}
</script>

<style lang="scss" scoped>
.main-wrapper {
  background-color: #f9fafe;
  padding: 16px 0 0 16px;
  display: flex;

}
</style>
