<template lang="pug">
  div
    WorkCardRepair(:data="jobs" v-if="jobs.length > 0")
    h4.mensaje(v-else) No tienes trabajos asignados por ahora.
</template>
<script>
import { mapGetters } from 'vuex'
import WorkCardRepair from './WorkCardRepair.vue'
export default {
  components: {
    WorkCardRepair
  },
  computed: {
    ...mapGetters('ot', ['jobs'])
  }
}
</script>
<style lang="scss" scoped>
.mensaje {
  text-align: center;
  margin-top: 20px;
  color: #A3A2A1;
}
</style>
