<template lang="pug">
SideModal(v-model="isOpen")
  alert(ref="alert")
  SaleFormSuccess(v-if="success" @back="reset()")
  div(v-else)
    h2 Ventas
    div Selecciona o crea un cliente
    v-row.mb-2(dense)
      v-col(sm=8)
      v-autocomplete(
          v-model="form.customer"
          :items="customers"
          outlined
          placeholder="Busca al cliente por rut"
          item-text="rut"
          return-object
          hide-details
          @change="form.customer=$event, selectCustomerStatus='SELECTED'"
        )
          template(v-slot:item="{ item }")
            span {{ item.contact }} - {{ item.name }} {{ item.last_name }}
      v-col(sm=4)
        v-btn(outlined height=56 block color="primary" @click="selectCustomerStatus='ON_CREATE', form.customer=null") Nuevo cliente
    //- Datos del cliente
    .mb-2
      .mb-2(v-if="selectCustomerStatus!=='UNSELECTED'") Datos del cliente
      SaleCustomerDetail(v-if="selectCustomerStatus==='SELECTED'" :customer="form.customer" @showAlert="onChangeCustomer")
      CustomerForm(v-else-if="selectCustomerStatus==='ON_CREATE'" @save="form.customer=$event, selectCustomerStatus='SELECTED'")
    //- Lita de items
    .mb-2
    v-form(ref="form" @submit.prevent="onSubmit")
      .mb-2 Items
      SaleItemInput(v-model="form.saleItems")
      .mb-4
        .total_price
          label Total
          strong  {{totalPrice | currency}}
      .mb-4(v-if="showErrors")
        .red--text(v-for="error in errors") {{error}}
      div(style="background-color: #f0f3ff; margin-bottom: 10px; border-radius: 15px;padding:10px")
          p.color.subtitle Tipo de pago
          v-radio-group.ml-5(row v-model="expense.payment_gateway" mandatory)
            v-radio(label="Efectivo" value="Efectivo")
            v-radio(label="Tarjeta" value="Débito")
            v-radio(label="Transferencia" value="Transferencia")
            v-radio(label="A crédito" value="A Crédito")
          //- div(v-if="expense.payment_gateway === 'A Crédito'")
          //-   p.color.mr-5.pl-5 ¿A cuánto plazo es el crédito?
          //-   v-text-field.ml-5.mt-3(solo label="Ingrese número de días" type="number" min="1" dense flat style="width: 50%;" v-model="expense.credit_term")

          div(v-if="expense.payment_gateway === 'A Crédito'")
            p.color.mr-5.pl-5 Fecha de pago
            v-menu(ref="menu" v-model="menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field.ml-5.mt-3(
                  v-model="expense.credit_term.split('-').reverse().join('/')" 
                  prepend-inner-icon="mdi-calendar" 
                  solo dense flat 
                  v-bind="attrs" 
                  v-on="on"
                  style="max-width: 40%; margin-top: 12px;"
                  placeholder=" Ingrese una fecha"
                  readonly clearable outlined
                  :rules="[v => !!v || 'La fecha de pago es obligatoria']"
                )
              v-date-picker(v-model="expense.credit_term" no-title locale="es-CL")

      v-btn(block color="primary" x-large type="submit" :disabled="!expense.payment_gateway") + Crear Venta
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerForm from '../customer/CustomerForm.vue'
import SideModal from '../shared/SideModal.vue'
import SaleCustomerDetail from './subcomponents/SaleCustomerDetail.vue'
import SaleItemInput from './subcomponents/SaleItemInput.vue'
import SaleFormSuccess from './subcomponents/SaleFormSuccess.vue'
import alert from '@/components/shared/alert.vue'

export default {
  components: {
    CustomerForm,
    SideModal,
    SaleCustomerDetail,
    SaleItemInput,
    SaleFormSuccess,
    alert,
  },
  data () {
    return {
      selectCustomerStatus: 'UNSELECTED', // Estados: UNSELECTED, ON_CREATE, SELECTED
      form: {
        customer: null,
        saleItems: []
      },
      isOpen: false,
      searchCutomer: null,
      showErrors: false,
      errors: [],
      success: false,
      expense: {
        payment_gateway: null,
        credit_term: '',
        total_price: 0,
        description: 'Venta de producto',
        movement_type: 'Pago',
        aproved_by: null,
        state: 'Pagado',
        sale: null
      },
      menu: false
    }
  },
  methods: {
    ...mapActions('sale', ['getSale', 'createSale']),
    ...mapActions('customer', ['listCustomers', 'getCustomer']),
    ...mapActions('inventory', ['listPieces']),
    ...mapActions('inventory', ['createExpense']),
    searchCustomer () {
      this.listCustomers({ parmas: { search: this.searchCustomer } })
    },
    async onChangeCustomer () {
      const response = await this.getCustomer({ customerId: this.form.customer.id })
      this.form.customer = response.data
      this.$refs.alert.handleShowAlert('success', 'Los datos del Cliente han sido editados', true, 'green')
    },
    async open () {
      this.reset()
      this.isOpen = true
    },
    async onSubmit () {
      this.showErrors = true
      if (!this.$refs.form.validate() || this.errors.length) {
        return
      }

      if (this.expense.credit_term === '') {
        delete this.expense.credit_term
      }
      
      const data = this.getFormatedData()

      const response = await this.createSale({ data })

      if (response.status >= 200 && response.status < 300) {
        this.expense.payment_total = response.data.total_price
        this.expense.sale = response.data.id
        await this.createExpense({ data: this.expense })
        this.$emit('save', response.data)
        this.success = true
      }
    },
    reset () {
      this.form.customer = null
      this.form.saleItems = [{ piece: null, quantity: 1, total_price: 0 }]
      this.selectCustomerStatus = 'UNSELECTED'
      this.showErrors = false
      this.errors = []
      this.success = false
      this.searchCutomer = null
      this.expense.credit_term =''
      this.expense.payment_gateway = null
    },
    getFormatedData () {
      return {
        customer: this.form.customer.id,
        sale_items: this.form.saleItems.map(el => {
          return {
            piece: el.piece.id,
            quantity: el.quantity
          }
        })
      }
    },
    // Validaciones
    cutomerNotNullValidation () {
      if (!this.form.customer) {
        this.errors.push('Debe selecionar un cliente')
      }
    },
    unselectedSaleItemValidation () {
      if (this.form.saleItems.filter(el => !el.piece).length) {
        this.errors.push('Algunos items no tienen un producto asociado')
      }
    },
    saleItemStockValidation () {
      if (this.form.saleItems.filter(el => el.piece).filter(el => el.quantity > el.piece.stock).length) {
        this.errors.push('Algunos items no tienen stock')
      }
    },
    saleItemMinValidation () {
      if (!this.form.saleItems.length) {
        this.errors.push('Debe haber al menos un producto')
      }
    }
  },
  computed: {
    ...mapGetters('sale', ['sale']),
    ...mapGetters('inventory', ['pieces']),
    ...mapGetters('customer', ['customers']),
    totalPrice () {
      return this.form.saleItems.reduce((acum, el) => acum + el.total_price, 0)
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.errors = []
        this.cutomerNotNullValidation()
        this.unselectedSaleItemValidation()
        this.saleItemStockValidation()
        this.saleItemMinValidation()
      }
    }
  },
  created () {
    this.listPieces()
    this.listCustomers()
  }
}
</script>

<style lang="scss" scoped>
.sale-form {
  background-color: white;
}
.total_price {
  font-size: 18px;
  text-align: right;
  margin-top: 1rem;
}
</style>
