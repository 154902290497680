
export const setTitle = ({ commit }, newTitle) => {
  commit('SET_TITLE', newTitle)
}

export const setSubtitle = ({ commit }, newSubTitle) => {
  commit('SET_SUBTITLE', newSubTitle)
}

export const setSubtitles = ({ commit }, newSubTitles) => {
  commit('SET_SUBTITLES', newSubTitles)
}

export const setBackgroundClass = ({ commit }, newBackgroundClass) => {
  commit('SET_BACKGROUND_CLASS', newBackgroundClass)
}
