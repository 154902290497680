<template lang="pug">
div
  ResumeSupplier
</template>
<script>
import ResumeSupplier from '../components/stock/ResumeSupplier.vue'
export default {
  components: {
    ResumeSupplier
  }
}
</script>
