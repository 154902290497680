<template lang="pug">
.sale-Cutomer-detail
  v-row
    v-col(cols="10")
      strong {{customer.name}} {{customer.last_name}}
      label  {{customer.rut | rutFilter}}
      div {{customer.address}}
      div (+56) {{customer.contact | cellFilter}}
      div {{customer.email}}
    v-col.d-flex.justify-end(cols="2")
      v-btn(icon @click="editCustomerData" color="primary")
        v-icon mdi-pencil
  SideModal(v-model="openCustomerForm" max-width="700")
    v-container
      h2.mb-5 Editar Datos del Cliente
      CustomerForm(ref="CustomerForm" :customerSelected="customer" @save="handlerCustomerSave")
</template>

<script>
import { rutFilter, cellFilter } from '@/utils/filters.js'
import CustomerForm from '@/components/customer/CustomerForm.vue'
import SideModal from '@/components/shared/SideModal.vue'

export default {
  components: {
    CustomerForm,
    SideModal
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openCustomerForm: false,
    }
  },
  filters: { rutFilter, cellFilter },
  methods: {
    handlerCustomerSave(data) {
      this.openCustomerForm = !this.openCustomerForm
      this.$emit('showAlert')
    },
    editCustomerData() {
      this.openCustomerForm = !this.openCustomerForm
    }
  }
  
}
</script>

<style lang="scss" scoped>
.sale-Cutomer-detail {
  border: 1px solid #E4E3E2;
  padding: 16px;
}
</style>
