import { defineStore } from 'pinia'
import axios from '@/plugins/axios'

export const useNotificationStore = defineStore('notification', {
  state: () => ({}),
  getters: {},
  actions: {
    async registerNotificationsDevice(data) {
      return axios
        .post('/notification/push_notification_device/register/', data)
        .then((res) => res)
        .catch((err) => err.response)
    },
  },
})
