import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const ScheduleStore = {
  namespaced: true,
  state: {
    currentDay: '',
    focusDay: '',
    services: [],
    appointments: [],
    todayAppointments: [],
    disabled: [],
    currentAppointment: null,
  },
  getters,
  mutations,
  actions,
}

export default ScheduleStore
