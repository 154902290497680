<template lang="pug">
v-container
  v-card
    div
      v-expansion-panels(accordion)
        v-expansion-panel
          v-expansion-panel-header.pl-5
            v-card-title.budgetTitle.pl-1.py-0.pt-1 Repuestos
          v-expansion-panel-content
            v-data-table(
              v-for="budget in estimateData" :key="budget.id"
              :headers="headers"
              hide-default-footer
              :items="getSaleItems(budget.estimate_services)"
              no-data-text="No hay repuestos"
            )
              template(v-slot:item.service_name="{ item }")
                p.my-2 {{ item.service_name }}
              template(v-slot:item.piece="{ item }")
                p.my-2 {{ item.piece ? item.piece.name : item.new_piece_name }}
              template(v-slot:item.brand_name="{ item }")
                p.my-2 {{ item.piece ? item.piece.brand_name : '-' }}
              template(v-slot:item.quantity="{ item }")
                p.my-2 {{ item.quantity }}
              template(v-slot:item.price="{ item }")
                p.my-2 {{ (item.piece ? item.piece.price : item.price || item.unit_price ) | currency }}
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { text: 'Servicio', value: 'service_name', sortable: false, width: '20%', align: 'center' },
        { text: 'Repuesto', value: 'piece', sortable: false, width: '20%', align: 'center'  },
        { text: 'Marca', value: 'brand_name', sortable: false, width: '20%', align: 'center'  },
        { text: 'Cantidad', value: 'quantity', sortable: false, width: '20%', align: 'center'  },
        { text: 'Precio', value: 'price', sortable: false, width: '20%', align: 'center'  }
      ]
    }
  },
  computed: {
    ...mapGetters('ot', ['OT']),
    ...mapGetters('user', ['technicians']),
    estimateData () {
      return this.OT ? this.OT.estimate : []
    },
    techniciansData () {
      return this.OT ? this.OT.estimate.technicians : []
    }
  },
  methods: {
    getTechById (id) {
      console.log(id)
      return this.technicians.find(el => el.id === id)
    },
    getAvatarText (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    getSaleItems (estimateServices) {
      const saleItems = []
      estimateServices.forEach((es) => {
        es.sale_items_data.forEach((s) => {
          saleItems.push({
            service_name: es.service_data.name,
            ...s
          })
        })
      })
      return saleItems
    }
  }
}
</script>
<style lang="scss" scoped>
.budgetTitle {
  color: #3658D3;
}
</style>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #E4EAFF !important;
  height: 70px;
  font-size: 24px !important;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
