const mutations = {
  'SET_SERVICES' (state, value) {
    state.services = value
  },
  'SET_SERVICES_IN_WORK_ORDER' (state, value) {
    state.servicesInWorkOrder = value
  },
  'SET_SERVICE' (state, value) {
    state.service = value
  }
}

export default mutations
