import { render, staticRenderFns } from "./BudgetsByModel.vue?vue&type=template&id=6f333607&scoped=true&lang=pug"
import script from "./BudgetsByModel.vue?vue&type=script&lang=js"
export * from "./BudgetsByModel.vue?vue&type=script&lang=js"
import style0 from "./BudgetsByModel.vue?vue&type=style&index=0&id=6f333607&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f333607",
  null
  
)

export default component.exports