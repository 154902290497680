export const OT = state => state.OT
export const OTs = state => state.OTs
export const budgets = state => state.budgets
export const evaluations = state => state.evaluations
export const jobs = state => state.jobs
export const diagnosed = state => state.diagnosed
export const payments = state => state.payments
export const transitions = state => state.transitions
export const repaired = state => state.repaired
export const templates = state => state.templates
export const evaluationsCount = state => state.evaluationsCount
export const jobsCount = state => state.jobsCount
export const diagnosedCount = state => state.diagnosedCount
export const repairedCount = state => state.repairedCount
