<template lang="pug">
.flex-row.justify-space-between.align-center
  v-text-field.search-field(
    v-model="filters.search"
    placeholder="Patente, modelo o nombre/teléfono de cliente"
    clearable
    outlined dense hide-details
  )

  .buttons.flex-row.gap-2
    v-btn(color="primary" @click="showNewVehicleModal = true")
      v-icon.mr-1 mdi mdi-plus
      span Vehículo
    //- v-btn(color="primary" text @click="exportXls")
    //-   v-icon.mr-1 mdi mdi-microsoft-excel
    //-   span Excel

  SideModal(v-model="showNewVehicleModal" max-width="600")
    v-container
      h2.mb-5 Crear nuevo vehículo
      VehicleForm(
        :vehicleSelected="emptyVehicle"
        :dataVehicle="emptyVehicle"
        noFeedback
        @save="onVehicleCreated"
        @error="onErrorSaving"
      )
</template>

<script>
import { mapActions, mapWritableState } from 'pinia'
import { useVehicleListStore } from '@/stores/views/useVehicleListStore'
import { debounce } from 'lodash'
import SideModal from '@/components/shared/SideModal.vue'
import VehicleForm from '@/components/vehicle/VehicleForm.vue'

export default {
  components: { SideModal, VehicleForm },

  data() {
    return {
      showNewVehicleModal: false,
      emptyVehicle: {},
    }
  },

  computed: {
    ...mapWritableState(useVehicleListStore, ['filters', 'tableOptions']),
  },

  watch: {
    'filters.search'(search) {
      this.onSearchChange(search)
    },
  },

  methods: {
    ...mapActions(useVehicleListStore, ['getVehicles', 'getVehiclesExcel']),

    async exportXls() {
      const success = await this.getVehiclesExcel()
      if (!success) {
        this.$toast.error('Error al exportar')
      }
    },

    onVehicleCreated() {
      this.emptyVehicle = {
        model: null,
        fuel_type: 2,
        id: null,
        customer: null,
        patent: null,
        engine_number: null,
        chassis_number: null
      }
      this.showNewVehicleModal = false
      this.getVehicles()
      this.$toast.success('Vehículo creado con éxito')
    },

    onErrorSaving(data) {
      this.$toast.error(`Error al guardar (${data?.error || 'Error desconocido'})`)
    },
  },

  created() {
    this.onSearchChange = debounce(async function (search) {
      this.tableOptions.page = 1
      await this.getVehicles()
    }, 500)
  },
}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
  &::v-deep .v-input__slot {
    min-height: 48px !important;
  }
}

.buttons {
  .v-btn {
    width: 110px;
    height: 48px;
  }
}
</style>
