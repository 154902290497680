<template lang="pug">
div
  SupplierPayment
</template>

<script>
import SupplierPayment from '../components/stock/SupplierPayment.vue'

export default {
  components: {
    SupplierPayment
  }
}
</script>
