<template lang="pug">
div(v-if="services").d-flex.flex-column
  v-row.justify-end.mb-2
    v-btn.mb-3.mr-9(v-if="!disabled" color="primary" @click="myServicesPush()" outlined) + Agregar Trabajo
  div.service-card(v-for="(item, index) in myServices" :key="`s ${index}`")
    v-btn.close-service-button(fab color="white" x-small depressed @click="removeService(index)")
      v-icon mdi-close-thick
    p.mt-1.primary--text(style="font-weight: 600;") Servicio
    v-row(dense)
      v-col
        v-autocomplete(
          v-if="item.service.id == null || editableService"
          v-model="item.service"
          :search-input.sync="item.search"
          :items="servicesWithQuantity"
          return-object
          item-value="id"
          item-text="name"
          :rules="[v => (!!v && !!v.id) || 'Debe seleccionar un servicio']"
          no-data-text="No hay servicios"
          outlined
          label="Servicio"
          @change="loadDefaultPieces(item)"
          :menu-props="{ offsetOverflow: false}"
        )
          template(#append-item)
            div.mr-2.ml-2
              v-btn(
                color="primary" block
                @click="convertToNewService(item, index)"
                :disabled="false"
              ) Agregar como servicio nuevo
        p.red--text(v-if="showError") Debe seleccionar al menos un servicio
        v-textarea.large-service(
          v-if="item.service.id != null && !editableService"
          v-model="item.service.name"
          outlined disabled
          :rows="(item.service.name ? item.service.name.length : 0) < 55 ? 1 : 2"
          no-resize)
      v-col(cols="2")
        v-text-field(v-model="item.service.price" outlined v-maska:[moneyMaskFormat] prefix="$" label="Precio" disabled)
      v-col(cols="1")
        v-text-field(v-model="item.service.service_quantity" outlined type="number" label="Cantidad" min=1  :rules="[v => (v && v > 0) || 'La cantidad mínima es 1']" )
      v-col(cols="2")
        v-text-field(:value="getTotal(item.service)" outlined v-maska:[moneyMaskFormat] label="Total" prefix="$" disabled)
      v-col(v-if="!disabled" cols="auto")
        div(style="width: 32px;")
    SaleItemInput.mb-0(
      v-model="item.saleItems"
      :vehicle="vehicle"
      :disabled="item.service.id === null"
      :isQuotation="isQuotation"
    )
  NewServiceModal(ref="newServiceModal" @serviceCreated="handleServiceCreated")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapActions as mapActionsPinia } from 'pinia'
import SaleItemInput from '../subcomponents/SaleItemInput.vue'
import NewServiceModal from './NewServiceModal.vue';
import { useRecommendedPieceStore } from '../../../stores/api/inventory/useRecommendedPieceStore';
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../../utils/mask'
export default {
  components: {
    SaleItemInput,
    NewServiceModal
  },
  props: {
    vehicle: {
      type: Object
    },
    value: {
      type: Array[Object]
    },
    disabled: Boolean,
    showError: Boolean,
    isQuotation: {
      type: Boolean,
      default: false
    },
    editableService: {
      type: Boolean,
      default: false
    }
  },
  directives: { maska: vMaska },
  data () {
    return {
      myServices: this.value.map(el => {
        return {
          service: el,
          saleItems: el.saleItems,
          source_estimate_service: el.source_estimate_service,
          id: el.id,
        }
      }),
      moneyMaskFormat
    }
  },
  methods: {
    ...mapActions('service', ['listServices']),
    ...mapActionsPinia(useRecommendedPieceStore, ['getRecommendedPieces']),
    checkHasProperty (item, property) {
      if (typeof item === 'object' && item !== null) {
        return Object.prototype.hasOwnProperty.call(item, property)
      }
      return false
    },
    myServicesPush () {
      this.myServices.push({
        service: {
          id: null,
          name: '',
          price: 0,
          commission: 0.0,
          estimate: [],
          service_quantity: 1,
          saleItems: [],
        },
      })
    },
    formatMyServices () {
      if (this.myServices.length > 0 && this.myServices[0].service && Object.prototype.hasOwnProperty.call(this.myServices[0].service, 'service_data')) {
        this.myServices = this.myServices.map((elem) => {
          return {
            id: elem.service.id,
            source_estimate_service: elem.service.source_estimate_service,
            service: {
              ...elem.service.service_data,
              service_quantity: elem.service.service_quantity,
            },
          }
        })
      }
    },
    convertToNewService (item) {
      this.$refs.newServiceModal.open(item.search)
    },
    handleServiceCreated (service) {
      this.myServices.push({ service: {
        ...service,
        service_quantity: 1

      }, saleItems: []})
      this.myServices = this.myServices.filter(el => el.service.id !== null)
    },

    /** Trae los repuestos recomendados para el servicio seleccionado */
    async loadDefaultPieces(item) {
      if (this.editableService && item.saleItems.length > 0) {
        return
      }
      const params = {
        service: item.service.id,
        vehicle_model: this.vehicle.model,
      }
      const res = await this.getRecommendedPieces(params)
      if (res.status < 200 || res.status >= 300) {
        return this.$toast.error('Error al cargar repuestos recomendados')
      }

      item.saleItems = res.data.map((o) => {
        return {
          piece: o.piece,
          price: o.piece?.price,
          quantity: 0,
          unit_price: o.piece?.price,
          total_price: 0,
        }
      })
    },
    removeService (index) {
      this.$emit('removeService', this.myServices[index])
      this.myServices.splice(index, 1)
    },
    getTotal(service) {
      if (service.price) {
        let price = service.price
        if (typeof service.price === 'string') price = parseInt(service.price.split('.').join(''))
        return price * service.service_quantity
      }
      return 0
    }
  },
  computed: {
    ...mapGetters('service', ['services']),
    totalPrice () {
      let total = 0
      this.myServices.filter(el => el.service).forEach(elem => {
        if (elem.service?.id == null) return
        const price = Number(elem.service.price.replace(/\./g, ''))
        total = total + price * elem.service.service_quantity
      })
      return total
    },
    totalItemsPrice () {
      let total = 0
      this.myServices.filter(el => el.service).forEach(elem => {
        if (!elem.saleItems) return
        total =
            total +
            elem.saleItems
              .reduce((acum, curr) => {
                return acum + curr.total_price
              }, 0)
      })
      return total
    },
    servicesWithQuantity () {
      return this.services.map((service) => {
        return {
          ...service,
          service_quantity: 1
        }
      })
    }
  },
  watch: {
    myServices: {
      deep: true,
      handler () {
        this.formatMyServices()
        this.$emit('input', this.myServices)
        this.$emit('change', this.myServices)
        this.$emit('change', this.myServices)
        this.$emit('totalPrice', this.totalPrice)
        this.$emit('totalItemsPrice', this.totalItemsPrice)
        console.log('totalPrice', this.totalPrice)
      }
    },
    value: {
      deep: true,
      handler (newValue) {
        if (newValue !== this.myServices) {
          this.myServices = newValue.map(el => {
            return {
              service: el,
              saleItems: el.saleItems
            }
          })
        }
      }
    }
  },
  created () {
    this.myServices = this.value.map(el => {
      return {
        service: el,
        saleItems: el.saleItems,
      }
    })
    this.listServices()
    this.$emit('totalPrice', this.totalPrice)
  }
}
</script>
<style lang="scss" scoped>
.disable_text {
  color: rgba(0, 0, 0, 0.12);
}

.service-card {
  background-color: #E4EAFF;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}

.close-service-button {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(20%, -20%);
}
</style>
<style>
.large-service textarea {
  display: flex !important;
  align-self: center !important;
  padding: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  margin: 0px !important;
}
</style>
