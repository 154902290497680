<template lang="pug">
v-pagination(
  v-model="tableOptions.page"
  :length="pageCount"
  :loading="loading"
)
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import { useCustomerListStore } from '@/stores/views/useCustomerListStore';

export default {
  computed: {
    ...mapState(useCustomerListStore, ['totalItems', 'loading']),
    ...mapWritableState(useCustomerListStore, ['tableOptions']),

    pageCount() {
      return Math.ceil(this.totalItems / this.tableOptions.itemsPerPage)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
