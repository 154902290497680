<template lang="pug">
v-data-table(
  :headers="headers"
  :options.sync="tableOptions"
  :items="results"
  :server-items-length="totalItems"
  :loading="loading"
  hide-default-footer
  loading-text="Cargando datos..."
)
  template(#item.customer__name="{ item }")
    span {{ item.customer_full_name }}
  template(#item.model__brand__name="{ item }")
    span {{ item.brand_name }}
  template(#item.model__name="{ item }")
    span {{ item.model_name }}
  template(#item.customer__contact="{ item }")
    span {{ item.customer_contact }}
  template(#item._actions="{ item }")
    v-btn.action-btn(text @click="selectItem(item)")
      v-icon.mr-1 mdi mdi-eye
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { mapActions as mapActionsVuex } from 'vuex'
import { useVehicleListStore } from '../../../stores/views/useVehicleListStore'

export default {
  computed: {
    ...mapState(useVehicleListStore, ['results', 'totalItems', 'loading']),
    ...mapWritableState(useVehicleListStore, [
      'tableOptions',
      'showVehicleDetailModal',
      'selectedVehicle',
    ]),

    headers() {
      return [
        { text: 'Última OT', value: 'last_ot', sortable: false },
        { text: 'Patente', value: 'patent' },
        { text: 'Dueño', value: 'customer__name' },
        { text: 'Marca', value: 'model__brand__name' },
        { text: 'Modelo', value: 'model__name' },
        { text: 'Color', value: 'color' },
        { text: 'Teléfono', value: 'customer__contact' },
        { text: 'Detalle', value: '_actions', sortable: false },
      ]
    },
  },

  watch: {
    tableOptions: {
      handler() {
        this.getVehicles()
      },
      deep: true,
    },

    '$route.query.id': {
      async handler(vehicleId) {
        if (vehicleId == null) return
        const res = await this.getVehicle({ vehicleId })
        if (res < 200 || res >= 300) {
          return this.$toast.error('Error al cargar vehículo')
        }
        this.selectedVehicle = res.data
        this.showVehicleDetailModal = true
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(useVehicleListStore, ['getVehicles']),
    ...mapActionsVuex('vehicle', ['getVehicle']),

    selectItem(item) {
      this.selectedVehicle = item
      this.showVehicleDetailModal = true
    },
  },

  async created() {
    await this.getVehicles()
  },
}
</script>

<style lang="scss" scoped>
.action-btn {
  color: #e2e2e2;
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
