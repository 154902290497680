<template lang="pug">
v-container(v-if="!table.loading")
  br
  v-row(style="flex-wrap: nowrap;")
    div.balanceBox.mr-2()
      v-row
        v-col(cols="6")
          div(style="margin: 20px 0 0 20px;")
            p.mb-0 Saldo de cierre
            h2 {{ closingBalance | currency }}
        v-col(cols="6")
          div(style="margin: 30px 0 0 0px;")
            v-row
              v-icon.mb-2.mr-2(style="color: #fff;") mdi-calendar-range
              p(v-if="lastClose !== null" style="font-size: 14px;") {{ formatDateDay(lastClose) }}
              p(v-else) -
          div(style="margin: 20px 0 0 0px;")
            v-row
              v-icon.mb-4.mr-2(style="color: #fff;") mdi-clock
              p(v-if="lastClose !== null" style="font-size: 14px;") Cierre a las {{ formatDateHour(lastClose) }} hrs.
              p(v-else) -
    div.movementBox()
      h4.color(style="margin: 10px 0 0 30px;") Movimientos del día en Efectivo
      div
        v-row
          v-col.ml-3.mt-1(cols="5")
            p.ml-10.color Ingresos
            div
              v-row.ml-1
                v-img.my-0.mr-1(src="./../../assets/img/plus.png"  max-height="30" max-width="30")
                h3.color {{ incomes | currency }}
          v-col.ml-3.mt-1(cols="5")
            p.ml-10.color Egresos
            div
              v-row.ml-1
                v-img.my-0.mr-1(src="./../../assets/img/minus.png"  max-height="30" max-width="30")
                h3.color {{ outcomes | currency }}

    div.ml-5()
      v-row
        v-col(cols="6")
          v-row.mt-2()
            v-btn.ml-3.mb-1(color="primary" dark outlined  @click="openModalCash(false)" style="font-weight: 400;")
              v-icon.mr-2() mdi-cash-check
              span Ingreso de efectivo
          v-row()
            v-btn.ml-3.mt-1.pr-7(color="primary" dark @click="openModalExpenses(false)" style="font-weight: 400;")
              v-icon.mr-2() mdi-cash-remove
              span Retiro de efectivo
        v-col(cols="6")
          v-row.mt-2.ml-3()
            v-btn.ml-1(color="primary" dark @click="openModalExpenses(true)" style="font-weight: 400;")
              v-icon.mr-2 mdi-cash-register
              span Nuevo Gasto
  br
  v-data-table(
    :headers="headers"
    class="elevation-0"
    :items="movements"
    hide-default-footer
    :loading="table.loading"
    loading-text="Cargando Movimientos..."
    no-data-text="No hay movimientos"
    )
    template(v-slot:item.created_at="{ item }")
      p {{formatDateHour(item.created_at)}} hrs.
    template(v-slot:item.movement_type="{ item }")
      v-chip(label :color="getColor(item.movement_type)" :text-color="getColorText(item.movement_type)" dark) {{ item.movement_type }} - {{ item.payment_gateway }}
    template(v-slot:item.OT="{ item }")
      p(v-if="item.work_order !== null") N° {{ item.work_order }}
      p(v-else) -
    template(v-slot:item.supplier="{ item }")
      span.d-flex.align-center(v-if="item.supplier_name !== null && item.supplier_name !== '-'")
        p.mb-0.mr-1 {{ item.supplier_name }}
        small(v-if="item.expense_group") ({{ item.expense_group.name }})
      span.d-flex.align-center(v-else-if="item.expense_supplier_name !== null && item.expense_supplier_name !== '-'")
        p.mb-0.mr-1 {{ item.expense_supplier_name }}
        small(v-if="item.expense_group") ({{ item.expense_group.name }})
      p(v-else) Sin proveedor
    template(v-slot:item.payment_total="{ item }")
      p {{ item.payment_total | currency }}
    template(v-slot:item.current_balance="{ item }")
      p {{ currentBalance | currency }}
    template(v-slot:item.description="{ item }")
      a.mr-2(v-if="item.sale_data.id" @click="$refs.saleDetail.open(item.sale_data.id)") {{ item.sale_data.id && `${item.description} N° ${item.sale_data.id}` }}
      p(v-else) {{ item.description }}
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  ModalExpenses(ref="ModalExpenses" :isExpense="isExpense" @refreshData="successAction")
  ModalCash(ref="ModalCash" :isExpense="isExpense" @refreshData="successAction")
  v-dialog(v-model="dialog" transition="dialog-top-transition" width="450" content-class="elevation-0" style="border-radius: 16px;")
    v-card(style="padding: 50px 20px 20px 20px;")
      div.money-img
      v-card-title(style="color: #4C7AEC; text-align: center; font-size: 20px;") ¿Seguro quieres cerrar caja chica con {{ currentBalance | currency }}?
      v-card-text Al confirmar esta opción no puedes modificar el monto.
      v-card-actions
        v-row
          v-col(cols="6")
            v-btn(@click="dialog = false" block elevation="0" color="#4C7AEC" outlined) Cancelar
          v-col(cols="6")
            v-btn.mr-5(color="#4C7AEC" dark @click="confirmDailyClose()" block) Confirmar
  alert(ref="alert")
  SaleDetail(ref="saleDetail")
</template>
<script>
import { mapActions } from 'vuex'
import ModalExpenses from './ModalExpenses.vue'
import ModalCash from './ModalCash.vue'
import alert from '../shared/alert.vue'
import SaleDetail from '../sale/SaleDetail.vue'
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    }
  },
  components: {
    ModalExpenses,
    ModalCash,
    alert,
    SaleDetail,
  },
  data () {
    return {
      isExpense: false,
      page: 1,
      pageCount: 0,
      table: {
        loading: false
      },
      headers: [
        { text: 'Hora', value: 'created_at' },
        { text: 'Tipo de Operación', value: 'movement_type', align: 'left' },
        { text: 'OT asociada', value: 'OT' },
        { text: 'Descripción', value: 'description' },
        { text: 'Proveedor', value: 'supplier' },
        { text: 'Monto', value: 'payment_total' }
      ],
      movements: [],
      currentBalance: 0,
      closingBalance: 0,
      incomes: 0,
      outcomes: 0,
      today: new Date(),
      lastClose: null,
      dialog: false
    }
  },
  methods: {
    ...mapActions('inventory', ['listPettyCash', 'closePettyCash', 'closePettyCashInfo']),
    async fetchData () {
      this.table.loading = true
      await this.closePettyCashInfo().then(
        response => {
          if (response.data.results.length > 0) {
            const data = response.data.results
            const lastClosing = data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
            this.lastClose = new Date(lastClosing.date)
            this.closingBalance = lastClosing.closing_balance
          }
        }
      )
      await this.listPettyCash({ param: { page: this.page }}).then(
        (response) => {
          if (response.status === 200) {
            this.movements = response.data.results
            this.currentBalance = response.data.current_balance
            const initialValue = 0
            this.incomes = this.movements.reduce((acc, curr) => { return acc + (curr.movement_type === 'Pago' || curr.movement_type === 'Ingreso de efectivo' ? curr.payment_total : 0) }, initialValue)
            this.outcomes = this.movements.reduce((acc, curr) => { return acc + (curr.movement_type !== 'Pago' && curr.movement_type !== 'Ingreso de efectivo' ? curr.payment_total : 0) }, initialValue)
            this.pageCount = Math.ceil(response.data.count / 10)
          } else {
            this.movements = []
            this.pageCount = 0
          }
        }
      )
      this.table.loading = false
    },
    getColor (type) {
      return type === 'Pago' || type === 'Ingreso de efectivo' ? '#DBFFD6' : '#FFD6EC'
    },
    getColorText (type) {
      return type === 'Pago' || type === 'Ingreso de efectivo' ? '#2DA71A' : '#A71A5E'
    },
    openModalExpenses (item) {
      this.isExpense = item
      this.$refs.ModalExpenses.open(item)
    },
    openModalCash (item) {
      this.isExpense = item
      const availableCash = Number(this.incomes) - Number(this.outcomes)
      this.$refs.ModalCash.open(availableCash)
    },
    async confirmDailyClose () {
      await this.closePettyCash().then(
        () => {
          this.dialog = false
          this.fetchData()
          this.$refs.alert.handleShowAlert('success', 'Caja cerrada con éxito', true, 'green')
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date))
    },
    formatDateDay (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'long' }).format(new Date(date))
    },
    formatDateHour (date) {
      return new Intl.DateTimeFormat('cl-ES', { timeStyle: 'short' }).format(new Date(date))
    },
    successAction (data) {
      this.fetchData()
      this.$refs.alert.handleShowAlert(data.type, data.message, data.show, data.color)
    }
  },
  watch: {
    page () {
      this.fetchData()
    },
    tab (newVal) {
      if (newVal === 0) {
        this.fetchData()
      }
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.balanceBox {
  background-image: url(./../../assets/img/balance.png);
  height: 100px;
  width: 390px;
  border-radius: 10px;
  color: #fff;
}
.movementBox {
  background-image: url(./../../assets/img/movements.png);
  height: 100px;
  width: 390px;
  border-radius: 10px;
}
.color {
  color: #462DFA;
}
.money-img {
  height: 160px;
  background-image: url('./../../assets/img/money.png');
  background-size: contain;
  background-position: center;
}
</style>
