<template lang="pug">
v-container(fluid)
  v-row.mt-4
    v-col.pl-0(cols="12")
      v-data-table(
        :loading="table.loading"
        :headers="table.headers"
        :items="mailCampaigns?.results"       
        :total-visible="10"
        :options.sync="table.options"
        hide-default-footer
      )
        template(v-slot:item.details="{ item }")
          v-btn(icon @click="() => openCampaignDetails(item.id, dates)" large)
            v-icon(style="color: #8E8E8E") mdi-eye-outline
    
  v-pagination.pt-4(v-model="table.options.page" :length="pageCount" circle color="primary" elevation=0)
  ModalCampaignDetails(ref="ModalCampaignDetails" :selectedCampaignId="selectedCampaignId" :selectedRange="dates")
</template>

<script>
import ModalCampaignDetails from './ModalCampaignDetails.vue';
import axios from '../../plugins/axios';
export default {
  props: {
    selectedDays: {
      type: Number,
      default: 30
    }, 
    dates: {
      // type: ArrayObject,
      default: null,
    }
  },
  components: {
    ModalCampaignDetails,
  },
  data: () => ({
    table: {
      loading: false,
      headers: [
        { text: 'Nombre de la campaña', value: 'name',  fieldName: 'name' },
        { text: 'N° de envíos en los últimos 30 días', value: 'total_delivered',  fieldName: 'total_sent' },
        { text: '% de apertura', value: 'percentage_opened',  fieldName: 'percentage_opened' },
        { text: '% de clicks', value: 'percentage_clicked',  fieldName: 'percentage_clicked' },
        { text: 'Detalle', value: 'details',  fieldName: 'details', sortable: false, },
      ],
      options: {
        page: 1,
        itemsPerPage: 10
      },
    },
    rowCount: 33,

    campaigns: {
      count: 11,
      next: null, 
      previous: null,
      results: [
        {
          id: 1,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 1,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 2,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 3,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 4,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 5,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 6,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 7,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 8,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 9,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 10,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 11,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 11,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 1,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 1,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 2,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 3,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 4,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 5,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 6,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 7,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 8,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 9,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 10,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 11,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 11,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 1,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 1,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 2,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 3,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 4,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 5,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 6,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 7,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 8,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 9,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 10,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 18,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
        {
          id: 11,
          name: 'Campaña 1',
          subject: 'Hola mundo',
          mode: 'TIME_FROM_LAST_VISIT',
          time_unit: 'DAYS',
          time_value: 1,
          specific_date: null,
          vehicle_model: null, 
          total_sent: 11,
          total_delivered: 3,
          total_opened: 1,
          total_clicked: 0,
          percentage_delivered: '18%',
          percentage_opened: '5%',
          percentage_clicked: '0%'    
        },
      ],
      totals: {
        sent: 18,
        delivered: 3,
        opened: 1,
        clicked: 0
      },
      percentages: {
        delivered: 16.66666666,
        opened: 5.55555555,
        clicked: 0
      },
    },
    mailCampaigns: null,
    count: 0,
    selectedCampaignId: null,
}),
  computed: {
    pageCount () {
      return Math.ceil(this.count / 10)
    }
  },  

  methods: {
    openCampaignDetails (id, dates) {
      // console.log('openCampaignDetails)', id)
      this.selectedCampaignId = id
      // this.$emit('selectCampaign', id)
      this.$refs.ModalCampaignDetails.open(id, dates)
    },

    async listMailCampaigns () {
      if(this.dates) {
        const params = { start_at__gte: `${this.dates[0]} 00:00:00`, start_at__lte: `${this.dates[1]} 00:00:00` }
        const response = await axios.get('mail/mail_campaign/', { params })
        // console.log('data', response.data)
        this.mailCampaigns = response.data
        // console.log('data ok')
        this.count = response.data.count
        this.$emit('mailCampaigns', response.data)
      }
      
    },
    
  },
  watch: {
    selectedDays: {
      handler (value) {
        this.table.headers[1].text = `N° de envíos en los últimos ${value} días`
      },
      deep: true,
    },

    dates: {
      handler (value) {
        if(this.dates.length === 2) this.listMailCampaigns()
      },
      deep: true,
    }
  }
}
</script>