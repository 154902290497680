<template lang="pug">
.flex-col
  .header.primary--text Datos personales
  .info-box
    .flex-col
      span.font-weight-bold {{ selectedCustomer.full_name }}
      span {{ selectedCustomer.rut }}
      span {{ selectedCustomer.address }}
      span {{ selectedCustomer.contact }}
      span {{ selectedCustomer.email }}
    v-btn.btn-edit.primary--text(text @click="showEditCustomerModal = true") Editar

  SideModal(v-model="showEditCustomerModal" max-width="600")
    v-container
      h2.mb-5 Editar cliente
      CustomerForm(
        :customerSelected="{ ...selectedCustomer }"
        noFeedback
        @save="onCustomerSaved"
        @error="onErrorSaving"
      )
</template>

<script>
import { mapActions, mapWritableState } from 'pinia';
import { useCustomerListStore } from '@/stores/views/useCustomerListStore';
import SideModal from '@/components/shared/SideModal.vue';
import CustomerForm from '@/components/customer/CustomerForm.vue';

export default {
  components: { SideModal, CustomerForm },

  data() {
    return {
      showEditCustomerModal: false,
    }
  },

  computed: {
    ...mapWritableState(useCustomerListStore, [
      'showCustomerDetailModal',
      'selectedCustomer',
    ]),
  },

  methods: {
    ...mapActions(useCustomerListStore, ['getCustomers']),

    onCustomerSaved(customer) {
      this.selectedCustomer = customer
      this.showEditCustomerModal = false
      this.getCustomers()
      this.$toast.success('Cliente actualizado correctamente')
    },

    onErrorSaving(data) {
      this.$toast.error(`Error al guardar (${data?.error || 'Error desconocido'})`)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 16px;
  font-weight: 700;
}

.info-box {
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: start;
  border: 1px solid #E4E3E2;
  font-size: 16px;
  line-height: 1.3;
}

.btn-edit {
  padding: 0 !important;
  height: auto !important;
  text-decoration: underline;
}
</style>
