export const WORK_ORDER_FILTERS = [
  {
    name: 'Ingresado',
    states: ['Ingresado'],
    color: '#FBF0B8',
    text: "#7D7501"
  },
  {
    name: 'En diagnóstico',
    states: ['En diagnóstico'],
    color: '#FEEBED',
    text: "#FF30D2"
  },
  {
    name: 'Diagnosticado',
    states: ['Diagnosticado'],
    color: '#FEE4E1',
    text: "#FF410A"
  },
  {
    name: 'Aprobados',
    states: ['Presupuesto aprobado'],
    color: '#E0DCFE',
    text: "#462DFA"
  },
  {
    name: 'En reparación',
    states: ['En reparación'],
    color: '#DFFEFC',
    text: "#0080FF"
  },
  {
    name: 'Listo',
    states: ['Listo'],
    color: '#DAFFB0',
    text: "#2DA71A"
  },
]

export const WORK_ORDER_MORE_FILTERS = [
  {
    name: 'Por enviar',
    states: ['Por enviar'],
    color: '#FBF0B8',
    text: "#7D7501"
  },
  {
    name: 'Presupuestado',
    states: ['Presupuestado'],
    color: '#E6EBF4',
    text: "#335DB7"
  },
  {
    name: 'Reparado',
    states: ['Reparado'],
    color: '#C1FCE6',
    text: "#146E1A"
  },
  {
    name: 'Entregado',
    states: ['Entregado'],
    color: '#F7F7F7',
    text: "#464544"
  },
  {
    name: 'Cotización',
    states: ['Cotización'],
    color: '#FEBFF0',
    text: '#701A5D'
  },
]
