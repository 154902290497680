<template lang="pug">
v-container(fluid)
  alert(ref="alert")
  .d-flex.justify-end.mb-4
    v-btn(color="primary" @click="openModalSupplier") Agregar Proveedor +
  v-data-table(
    :headers="table.headers" 
    class="elevation-0" 
    hide-default-footer 
    :items="table.suppliers" 
    :loading="table.loading" 
    loading-text="Buscando proveedores..." 
    no-data-text="No se encontraron proveedores"
    )
    template(v-slot:item.rut="{ item }")
      p.mt-3 {{ item.rut | rutFilter }}
    template(v-slot:item.actions="{ item }")
      v-btn.mr-4(icon @click="handleEditSupplierData(item)")
        v-icon mdi-pencil
      v-btn(icon @click="handleDeleteSupplier(item)")
        v-icon mdi-delete
  SideModal(v-model="isOpen" max-width="600")
    div(v-if="isOpen")
      h2.primary--text {{ supplier.name ? 'Editar' : 'Agregar' }} Proveedor
      v-row.mt-4
        v-col(cols="12")
          v-text-field(label="Nombre" v-model="supplier.name" outlined required)
        v-col(cols="12")
          v-text-field(
            label="Rut" 
            v-model="supplier.rut"
            :error-messages="errors.rut"
            v-maska="maskaRut"
            data-maska="1.11#-V"
            data-maska-tokens="1:[0-9]:repeated|V:[0-9Kk]"
            data-maska-reversed 
            outlined required)
        v-col(cols="12")
          v-select(
            label="Tipo de documento" 
            v-model="supplier.document_type" 
            :items="['Boleta', 'Factura']" 
            required outlined)
        v-col.d-flex.justify-end(cols="12")
          v-btn(color="primary" @click="submit" :disabled="disabled") Guardar
  v-dialog(v-model="dialog" max-width="500" persistent)
    v-card.pa-4
      v-card-title.justify-center
        h5.secondary--text ¿Estás seguro de eliminar este proveedor?
      v-card-text.d-flex.justify-center
        h2.primary--text {{ supplier.name }}
      v-card-actions.justify-space-between
        v-btn(color="primary" @click="dialog = false" outlined) Cancelar
        v-btn(color="primary" @click="deleteSupplier(supplier.id)") Eliminar
</template>
<script>
import SideModal from '../../shared/SideModal.vue'
import { rutFilter } from '@/utils/filters.js'
import { vMaska } from 'maska'
import { mapActions } from 'vuex'
import alert from '../../shared/alert.vue'
export default {
  directives: {
    maska: vMaska
  },
  components: {
    SideModal,
    alert
  },
  filters: {
    rutFilter
  },
  data () {
    return {
      dialog: false,
      isOpen: false,
      table: {
        loading: false,
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Rut', value: 'rut' },
          { text: 'Tipo de documento', value: 'document_type' },
          { text: 'Acciones', value: 'actions' }
        ],
        suppliers: []
      },
      supplier: {
        name: '',
        rut: '',
        document_type: '',
        id: null
      },
      errors: {
        rut: []
      },
      maskaRut: {
        unmasked: null,
      },
    }
  },
  methods: {
    ...mapActions('inventory', ['getSupplierExpenses', 'deleteSupplierExpense', 'createSupplierExpense', 'updateSupplierExpense']),
    async submit() {
      this.errors = {
        rut: []
      }
      const actions = this.supplier.id ? this.updateSupplierExpense : this.createSupplierExpense
      const data = { ...this.supplier }
      data.rut = data.rut.replace(/[^0-9Kk]/g, '')
      const response = await actions({ data })
      if (response.status === 201 || response.status === 200) {
        this.isOpen = false
        this.fetchData() 
        this.$refs.alert.handleShowAlert('success', 'Proveedor guardado correctamente', true, 'green')
      } else {
        this.errors.rut = ['El rut ya existe o no es válido. Intente nuevamente.']
      }
    },
    openModalSupplier () {
      this.isOpen = true
      this.supplier = {
        name: '',
        rut: '',
        document_type: '',
        id: null
      }
    },
    async fetchData () {
      this.loading = true
      await this.getSupplierExpenses().then(
        response => {
          this.table.suppliers = response.data
        }
      )
      this.loading = false
    },
    handleEditSupplierData (item) {
      this.openModalSupplier()
      this.supplier = { ...item }
    },
    handleDeleteSupplier (item) {
      this.dialog = true
      this.supplier = { ...item }
    },
    deleteSupplier (id) {
      this.deleteSupplierExpense({id}).then(() => {
        this.dialog = false
        this.fetchData()
        this.$refs.alert.handleShowAlert('success', 'Proveedor eliminado correctamente', true, 'green')
      })
    }
  },
  computed: {
    disabled () {
      return !this.supplier.name || !this.supplier.rut || !this.supplier.document_type
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
