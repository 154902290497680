<template lang="pug">
v-container
  alert(ref="alert")
  div.prepaidExpenses
    p.mr-5.pt-5.subtitle(style="color: #fff") Gastos anticipados
    h1.ml-8(style="color: #fff")
  br
  v-data-table(
    :headers="table.headers"
    class="elevation-0"
    :items="prepaidExpenses"
    hide-default-footer
    no-data-text="No hay gastos anticipados"
    :loading="table.loading"
    loading-text="Cargando Gastos anticipados..."
    )
    template(v-slot:item.created_at="{ item }")
      p {{ formatDate(item.created_at) }} hrs.
    template(v-slot:item.expense_type="{ item }")
      p {{ item.expense_group.name }}
    template(v-slot:item.expense_supplier_name="{ item }")
      p {{ item.expense_supplier_name }}
    template(v-slot:item.description="{ item }")
      p {{ item.description }}
    template(v-slot:item.amount="{ item }")
      p {{ item.payment_total | currency }}
    template(v-slot:item.actions="{ item }")
      v-btn(style="background-color: #E1EAFF; color: #407BFF" elevation="0" @click="handleCompletePrepaidExpense(item)")
        v-icon.mr-2(style="color: #407BFF" dense) mdi-invoice-text-edit-outline
        span Completar
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  ModalExpenses(ref="modalExpenses" @refreshData="successAction" :isExpense="true")
</template>
<script>
// import alert from '@/shared/alert.vue'
import { mapActions } from 'vuex'
import alert from '../shared/alert.vue'
import ModalExpenses from './ModalExpenses.vue'
export default {
  props: {
    tab: {
      type: Number,
      default: 5
    }
  },
  components: {
    alert,
    ModalExpenses,
  },
  data () {
    return {
      table: {
        loading: false,
        headers: [
          { text: 'Fecha', value: 'created_at' },
          { text: 'Tipo de gasto', value: 'expense_type' },
          { text: 'Proveedor', value: 'expense_supplier_name' },
          { text: 'Descripción', value: 'description' },
          { text: 'Monto', value: 'amount' },
          { text: 'Acciones', value: 'actions', sortable: false }
        ]
      },
      prepaidExpenses: [],
      page: 1,
      pageCount: 0
    }
  },
  methods: {
    ...mapActions('inventory', ['listPrepaidExpenses']),
    async fetchData () {
      this.table.loading = true
      await this.listPrepaidExpenses().then(
        (response) => {
          this.prepaidExpenses = response.data.results
          this.pageCount = Math.ceil(response.data.count / 10)
        }
      )
      this.table.loading = false
    },
    handleCompletePrepaidExpense (item) {
      this.$refs.modalExpenses.open(true, item)
    },
    completePrepaidExpense (data) {
      this.$refs.alert.handleShowAlert('success', 'Gasto anticipado completado con éxito', true, 'green')
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date))
    },
    successAction (data) {
      this.fetchData()
      this.$refs.alert.handleShowAlert(data.type, data.message, data.show, data.color)
    },
  },
  watch: {
    page () {
      this.fetchData()
    },
    tab (newVal) {
      if (newVal === 5) {
        this.fetchData()
      }
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
