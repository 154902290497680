<template lang="pug">
v-container
  v-card
    div
      v-expansion-panels(accordion)
        v-expansion-panel
          v-expansion-panel-header.pl-5
            v-card-title.budgetTitle.pl-1.py-0.pt-1 Recomendaciones
          v-expansion-panel-content
            v-data-table(
              :headers="headers"
              hide-default-footer
              :items="recommendations"
              no-data-text="No hay recomendaciones"
            )
              template(v-slot:item.date="{ item }")
                p.mt-2.mb-1 {{ formatDate(item.date) }} - {{ formatDateHour(item.date) }} hrs.
              template(v-slot:item.text="{ item }")
                p.mt-2.mb-1.description {{ item.text }}
              template(v-slot:item.technical="{ item }")
                v-avatar(color="#FFD699" style="margin-right: 200px;" size=32 dark) {{getAvatarText(item.added_by)}}

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      recommendations: [],
      headers: [
        { text: 'Fecha y Hora', value: 'date', sortable: false, align: 'center' },
        { text: 'Descripción', value: 'text', sortable: false, align: 'center' },
        { text: 'Técnico', value: 'technical', sortable: false, align: 'center' }
      ]
    }
  },
  methods: {
    ...mapActions('inventory', ['getRecommendations']),
    fetchData () {
      this.getRecommendations({ id: this.$route.params.otID }).then(
        (response) => {
          this.recommendations = response.data
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    formatDateHour (date) {
      return new Intl.DateTimeFormat('cl-ES', { timeStyle: 'short' }).format(new Date(date))
    },
    getAvatarText (tech) {
      return tech.first_name.charAt(0) + tech.last_name.charAt(0)
    }
  },
  computed: {
    ...mapGetters('user', ['technicians'])
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.budgetTitle {
  color: #3658D3;
}
</style>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #E4EAFF !important;
  height: 70px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.description {
  word-break: break-all !important;
}
</style>
