<template lang="pug">
  .sale-vehicle-detail
    v-row(dense)
      v-col(cols=7)
        strong.model {{vehicle.model_display}}
        div Patente: {{vehicle.patent}}
        div Número de motor: {{vehicle.engine_number}}
        div Número de chasis: {{vehicle.chassis_number}}
        div Color: {{ vehicle.color }}
        div Combustible: {{vehicle.fuel_type_display}}
      v-col(cols=5)
        v-img(src="../../assets/img/car-1.svg" max-height=75 max-width=165 style="margin: 15px 0 0 0 !important; mar")
    slot
</template>

<script>
export default {
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sale-vehicle-detail {
  background-color: #F6F5FD;
  padding: 16px;
  color: #A3A2A1;
}
.model {
  color: #1A1796;
}
</style>
