import { render, staticRenderFns } from "./OTDiagnosticReceptionistForm.vue?vue&type=template&id=003de84e&scoped=true&lang=pug"
import script from "./OTDiagnosticReceptionistForm.vue?vue&type=script&lang=js"
export * from "./OTDiagnosticReceptionistForm.vue?vue&type=script&lang=js"
import style0 from "./OTDiagnosticReceptionistForm.vue?vue&type=style&index=0&id=003de84e&prod&lang=scss&scoped=true"
import style1 from "./OTDiagnosticReceptionistForm.vue?vue&type=style&index=1&id=003de84e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003de84e",
  null
  
)

export default component.exports