import { DEPOSIT_TYPES } from '@/constants/deposit'


/**
  * Calcula el monto total de los depositos dependiendo de su tipo
  * @param {Array} deposits - Lista de depositos
  * @returns {Number} - Monto total de los depositos
  */
export const calculateDepositAmount = deposits => {
  return deposits.reduce((acc, deposit) => {
    if ([DEPOSIT_TYPES.PAYMENT, DEPOSIT_TYPES.CASH_DEPOSIT, DEPOSIT_TYPES.BANK_DEPOSIT].includes(deposit.movement_type)) {
      // Ingesos
      return acc + deposit.payment_total
    } else if ([DEPOSIT_TYPES.CASH_WITHDRAWAL, DEPOSIT_TYPES.EXPENSE, DEPOSIT_TYPES.BANK_WITHDRAWAL].includes(deposit.movement_type)) {
      // Egresos
      return acc - deposit.payment_total
    }
    console.warn(`No se reconoce el tipo de deposito: ${deposit.movement_type}, se ignorará.`)
    return acc
  }, 0)
}