<template lang="pug">
.contents(v-if="filters.length > 0")
  .filter-chips
    v-chip.chip(
      v-for="filter in filters" :key="filter.name"
      large close :color="filter.color" :text-color="filter.text"
      @click:close="removeFilter(filter)"
    ) {{ filter.name }}

  v-btn(v-if="filters.length >= 2" color="#4C7AEC" @click="clearFilters")
    .white--text Limpiar filtros
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { WORK_ORDER_FILTERS, WORK_ORDER_MORE_FILTERS } from './workOrderFilters'
const WORK_ORDER_ALL_FILTERS = [...WORK_ORDER_FILTERS, ...WORK_ORDER_MORE_FILTERS]

export default {
  computed: {
    ...mapFields('OTListFilterStore', ['selectedFilters']),
    filters() {
      return this.selectedFilters.map((filter) =>
        WORK_ORDER_ALL_FILTERS.find((f) => f.name === filter.name)
      )
    },
  },
  methods: {
    removeFilter(filter) {
      const index = this.selectedFilters.indexOf(filter)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
      }
    },
    clearFilters() {
      this.selectedFilters = []
    }
  },
}
</script>

<style lang="scss" scoped>
.contents {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.filter-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px;
}

.chip {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  height: 40px;
  border-radius: 50px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;

  &::v-deep .v-chip__content {
    margin-left: 6px;
    margin-right: 3px;
    width: 100%;
    justify-content: space-between;
    .v-chip__close {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
