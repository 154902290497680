<template lang="pug">
div
  SupplierSolicitudeDetail
</template>

<script>
import SupplierSolicitudeDetail from '../components/stock/SupplierSolicitudeDetail.vue'

export default {
  components: {
    SupplierSolicitudeDetail
  }
}
</script>
