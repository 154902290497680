import { defineStore } from 'pinia'
import store from '@/store/index'

export const useVehicleListStore = defineStore('vehicleList', {
  state: () => ({
    filters: {
      search: null,
    },
    tableOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ['id'],
      sortDesc: [true],
    },
    loading: false,
    results: [],
    totalItems: 0,
    showVehicleDetailModal: false,
    selectedVehicle: null,
  }),

  getters: {
    queryParams: (state) => {
      const { filters: f, tableOptions: o } = state
      const ordering = o.sortBy
        .map((field, i) => (o.sortDesc[i] ? '-' : '') + field)
        .join(',')
      return {
        paginated: true,
        page: o.page,
        query: f.search,
        ordering,
      }
    },
  },

  actions: {
    async getVehicles() {
      this.loading = true
      const res = await store.dispatch('vehicle/listVehicles', {
        params: this.queryParams,
      })

      if (res.status !== 200) return

      this.results = res.data.results
      this.totalItems = res.data.count
      this.loading = false
    },
  },
})
