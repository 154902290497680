<template lang="pug">
  div
    div(style="display:flex")
      v-btn.mt-2(elevation="0" color="primary" @click="$router.back()" icon style="margin-left: 10px;")
        v-icon mdi-arrow-left
      span.ml-1.mt-4(style=" display: flex; text-align: left;") Volver
    
    iframe(:src="$route.meta.djangoUrl + param" width="100%" height="700"  :title="$route.name" frameBorder="0")
</template>
<script>
export default {
  computed: {
    param () {
      const relatedPayment = JSON.parse(localStorage.getItem('related_payment'))
      return relatedPayment !== null ? `?related_payment=${relatedPayment}` : ''
    }
  },
  // created () {
  //   console.log('creado')
  //   console.log(this.$route.meta.djangoUrl, 'Router django')
  // }
}
</script>
