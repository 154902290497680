<template lang="pug">
.flex-col
  .header.primary--text Datos vehículo
  .info-box
    .flex-col
      span.font-weight-bold {{ selectedVehicle.patent }}
      span {{ selectedVehicle.model_display }}
      span N° motor: {{ selectedVehicle.engine_number }}
      span N° chasis: {{ selectedVehicle.chassis_number }}
      //- span Kilometraje:
    v-btn.btn-edit.primary--text(text @click="showEditVehicleModal = true") Editar

  SideModal(v-model="showEditVehicleModal" max-width="600")
    v-container
      h2.mb-5 Editar vehículo
      VehicleForm(
        :vehicleSelected="selectedVehicleForEdit"
        :dataVehicle="selectedVehicleForEdit "
        noFeedback
        :patent="selectedVehicle.patent"
        @save="onVehicleSaved"
        @error="onErrorSaving"
      )
</template>

<script>
import { mapActions, mapWritableState } from 'pinia';
import { useVehicleListStore } from '@/stores/views/useVehicleListStore';
import SideModal from '@/components/shared/SideModal.vue';
import VehicleForm from '@/components/vehicle/VehicleForm.vue';

export default {
  components: { SideModal, VehicleForm },

  data() {
    return {
      showEditVehicleModal: false,
    }
  },

  computed: {
    ...mapWritableState(useVehicleListStore, [
      'showVehicleDetailModal',
      'selectedVehicle',
    ]),

    selectedVehicleForEdit() {
      return { ...this.selectedVehicle,
        patente: this.selectedVehicle.patent,
        modelo: this.selectedVehicle.model,
      }
    },
  },

  methods: {
    ...mapActions(useVehicleListStore, ['getVehicles']),

    onVehicleSaved(vehicle) {
      this.selectedVehicle = vehicle
      this.showEditVehicleModal = false
      this.getVehicles()
      this.$toast.success('Vehículo actualizado correctamente')
    },

    onErrorSaving(data) {
      this.$toast.error(`Error al guardar (${data?.error || 'Error desconocido'})`)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 16px;
  font-weight: 700;
}

.info-box {
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: start;
  border: 1px solid #E4E3E2;
  font-size: 16px;
  line-height: 1.3;
}

.btn-edit {
  padding: 0 !important;
  height: auto !important;
  text-decoration: underline;
}
</style>
