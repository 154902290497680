const mutations = {
  'SET_VEHICLES' (state, value) {
    state.vehicles = value
  },
  'SET_VEHICLE' (state, value) {
    state.vehicle = value
  },
  'SET_MODELS' (state, value) {
    state.models = value
  },
  'SET_CAR_ESTIMATES' (state, value) {
    state.car_estimates = value
  },
  'ADD_VEHICLE_MODEL' (state, value) {
    state.models.push(value)
  },
}

export default mutations
