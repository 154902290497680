<template lang="pug">
SideModal(v-model="isOpen" max-width="700")
  v-container(fluid)
    h1.title Entrega de Vehículo
    br
    VehicleAndCustomerDetailBox(:vehicle="ot.vehicle_data" :customer="ot.customer_data")
    br
    v-form(ref="form" v-model="formValid" lazy-validation).d-flex.flex-column
      v-text-field.mb-3.pl-3.pt-5.reference-input(
        v-model="receipt_id"
        label="Referencia/#Boleta:" style="width: 30%"
        :rules="rulesBoleta"
      )
      v-textarea.px-3(
        v-model="comment"
        label="Comentario de Entrega:"
        outlined clearable clear-icon="mdi-close-circle"
        counter rows="3" row-heigth="20"
      )
    v-btn(block x-large depressed color="primary" @click="handleDelivery()" :loading="loading") Entregar Vehículo
    DeliveryModal(ref="deliveryMsg" @success="$emit('success')")
</template>
<script>
import { mapActions } from 'vuex'
import SideModal from '../shared/SideModal.vue'
import VehicleAndCustomerDetailBox from '../vehicle/VehicleAndCustomerDetailBox.vue'
import DeliveryModal from '../shared/DeliveryModal.vue'
import { required } from '@/utils/validators'

export default {
  props: {
    ot: Object
  },
  components: {
    SideModal,
    VehicleAndCustomerDetailBox,
    DeliveryModal
  },
  data () {
    return {
      isOpen: false,
      receipt_id: '',
      comment: '',
      loading: false,
      formValid: false,
    }
  },
  computed: {
    rulesBoleta: () => [required]
  },
  methods: {
    ...mapActions('ot', ['returnCar']),
    open () {
      this.isOpen = true
    },
    handleDelivery () {
      if (!this.$refs.form.validate()) return
      this.loading = true
      const data = {
        wo_id: this.ot.id,
        receipt_id: this.receipt_id,
        delivery_comment: this.comment,
      }
      this.returnCar({ data }).then(
        (response) => {
          this.loading = false
          if (response.status === 200) {
            this.isOpen = false
            if(this.$router.currentRoute.path !== '/ot') this.$router.back()
            this.$refs.deliveryMsg.deliveryIsOpen = true
          } else {
            this.$emit('showAlert', { type: 'danger', message: 'Ha ocurrido un error al entregar el vehículo', show: true, color: 'red' })
          }
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  color: #4C7AEC;
}
</style>
