<template lang="pug">
div()
    v-card(min-height="100%" flat)
      v-card-text(style="height: 100%;")
        div.payment-form(style="height: 100%;")
          div(v-if="ot" style="height: 100%;")
            .title Detalle para {{ot.customer_name}}
            VehicleAndCustomerDetailBox.my-4(:vehicle="ot.vehicle_data" :customer="ot.customer_data" style="border: 0;")
            div(v-for="estimate in estimates" v-if="estimate")
              v-checkbox.n-estimate(v-model="form.estimates" :label="`Presupuesto n°${estimate.id}`" :value="estimate")
              .estimate-content
                v-row.mb-2
                  v-col(cols=12 sm=6)
                    v-card(color="#396ac61a" flat min-height="103px")
                      v-card-text
                        | {{estimate.evaluation ?  estimate.evaluation.evaluation_comment: ''}}
                  v-col(cols=12 sm=6)
                    div(style="overflow-x: auto; display: flex; position: relative;" v-if="estimate.evaluation")
                      v-card( v-for="(photo, index) in estimate.evaluation.images" height="103px" width="103px")
                            v-img.white--text.align-end(style="margin: 0 4px 0 0 !important;" height="103px" width="103px" :src="photo.image_file" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)")
                              v-card-text {{photo.image_description}}
                div(v-if="estimate.estimate_services.length")
                  strong.black--text.mb-2.d-block Servicios
                  v-row(dense)
                    v-col.black--text Servicio a realizar
                    v-col.black--text Cantidad
                    v-col.black--text(cols="auto") Precio
                  v-row(v-for="service in estimate.estimate_services" dense)
                    v-col {{service.service_data.name}}
                    v-col x{{service.service_quantity}}
                    v-col(cols="auto") {{service.service_data.price | currency}}
                .text-right.black--text.mt-2
                  div Subtotal:
                    strong  {{getServiceSubtotalFromEstimate(estimate) | currency}}
                div(v-if="estimate.sale_items.length")
                  strong.black--text.mb-2.d-block Repuestos
                  v-row(dense)
                    v-col.black--text Repuesto
                    v-col.black--text Cantidad
                    v-col.black--text(cols="auto") Precio
                  v-row(v-for="item in estimate.sale_items_data" dense)
                    v-col {{item.piece.name}}
                    v-col x{{item.quantity}}
                    v-col(cols="auto") {{item.total_price | currency}}
                .text-right.black--text.mt-2
                  div Subtotal:
                    strong  {{getItemsSubtotalFromEstimate(estimate) | currency}}
                .text-right.black--text.mt-4
                  div Total presupuesto n°{{estimate.id}}:
                    strong  {{estimate.total_price | currency}}
                .text-right.black--text.mt-2
                  div Abono requerido ({{estimate.deposit * 100}}%):
                    strong  {{getDepositFromEstimate(estimate) | currency}}
              v-divider.my-4

            .estimate-content
              .text-right.black--text.mt-4.total-price
                div Total:
                  strong  {{ totalPrice | currency }}
              .text-right.black--text.mt-2.mb-4.total-price
                div Abono requerido:
                  strong  {{ deposit | currency }}
              v-spacer
    v-btn.mt-4(
      block color="primary" x-large
      @click="onSubmit(true)"
      :disabled="form.estimates.length === 0"
      v-if="estimates.length > 0 && estimates[0].agreement === null"
      :loading="isLoading"
    ) Pagar {{ deposit | currency}}
</template>

<script>
import { mapActions } from 'vuex'
import VehicleAndCustomerDetailBox from '../vehicle/VehicleAndCustomerDetailBox.vue'
import CustomerAndVehicleForm from '../vehicle/CustomerAndVehicleForm.vue'

export default {
  components: {
    VehicleAndCustomerDetailBox,
    CustomerAndVehicleForm
  },
  data () {
    return {
      ot: null,
      form: {
        estimates: []
      },
      estimates: [],
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('ot', ['getOTByToken']),
    ...mapActions('payment', ['pay']),
    getServiceSubtotalFromEstimate (estimate) {
      return estimate.estimate_services.reduce((acum, el) => acum + el.total_price, 0)
    },
    getDepositFromEstimate (estimate) {
      return Math.round(estimate.total_price * estimate.deposit)
    },
    getItemsSubtotalFromEstimate (estimate) {
      return estimate.sale_items_data.reduce((acum, el) => acum + el.total_price, 0)
    },
    async onChangeVehicle (vehicle) {
      this.form.vehicle = vehicle
      this.selectVehicleStatus = 'SELECTED'
      if (this.form.vehicle.customer) {
        const response = await this.getCustomer({ customerId: this.form.vehicle.customer })
        this.form.customer = response.data
      }
    },
    async onSubmit () {
      this.isLoading = true
      const data = {
        id: this.form.estimates.map(el => el.id),
        deposit: this.deposit,
        description: 'Orden de trabajo (abono)',
        token: this.$route.params.token
      }
      await this.pay({ data })
      this.isLoading = false
    }
  },
  computed: {
    totalPrice () {
      return this.form.estimates.reduce((acum, el) => acum + el.total_price, 0)
    },
    // estimates () {
    //   return this.ot.estimate
    // },
    deposit () {
      return this.form.estimates.reduce((acum, el) => acum + (Math.round(el.total_price * el.deposit)), 0)
    }
  },
  async created () {
    const token = this.$route.params.token
    this.ot = null
    const response = await this.getOTByToken({ params: { token } })
    if (response.status === 200) {
      this.ot = response.data
      const noPago = ['Ingresado', 'Por enviar', 'En diagnóstico', 'Diagnosticado', 'Presupuesto aprobado', 'Cotización', 'Entregado']
      this.estimates = this.ot.estimate.filter(el => !noPago.includes(el.state))
      // this.estimates = this.ot.estimate.filter(el => el.state === 'Presupuestado')
      this.form.estimates = this.estimates
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-form {
  font-size: 16px;
}
.title {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 28px;
  color: #000000;
}
.n-estimate::v-deep label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #4C7AEC;
}
.estimate-content {
  margin-left: 28px;
  margin-right: 40px;
}
.sale-form {
  background-color: white;
}
.total_price {
  font-size: 18px;
  text-align: right;
  margin-top: 1rem;
}
.technician-comment {
  padding: 12px;
  min-height: 190px;
  background: #FFF4E1;
  border: 1px solid #E4E3E2;
  border-radius: 4px;
  color: #EA5C0B;
}
.total-price {
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #464544;
}
</style>
