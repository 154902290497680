<template lang="pug">
v-container(fluid)
  v-data-table(
    :headers="table.headers" 
    class="elevation-0" 
    hide-default-footer 
    :items="table.suppliers" 
    :loading="table.loading" 
    loading-text="Buscando proveedores..." 
    no-data-text="No se encontraron proveedores"
    )
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      table: {
        headers: [
          { text: 'Nombre', value: 'name' },
        ],
        suppliers: [],
        loading: false
      }
    }
  },
  methods: {
    ...mapActions('inventory', ['listSuppliers']),
    async fetchData() {
      this.table.loading = true
      const response = await  this.listSuppliers()
      if (response.status === 200) {
        this.table.suppliers = response.data
      }
      this.table.loading = false
    }
  },
  created() {
    this.fetchData()
  }
}
</script>