<template lang="pug">
v-expansion-panels
  v-expansion-panel

    v-expansion-panel-header.pa-3
      .flex-row.align-center.gap-2
        .icon-circle
          v-icon.white--text mdi-clock-time-three-outline
        span.font-weight-bold Historial de OTs

    v-expansion-panel-content
      v-data-table.ot-table(
        :headers="headers"
        :page.sync="page"
        :items="orderList"
        :server-items-length="totalItems"
        :loading="loading"
        loading-text="Cargando historial..."
        no-data-text="No se encontraron OTs"
        :footer-props="{ \
          disableItemsPerPage: true, \
          disablePagination: loading, \
          itemsPerPageText: 'Ítems por página', \
        }"
      )
        template(#item.state="{ item }")
          v-chip.state-chip(
            label
            :text-color="getStatusTextColor(item.state)"
            :color="getStatusColor(item.state)"
          ) {{ item.state }}

        template(#item._actions="{ item }")
          v-btn.btn-edit.primary--text(
            text
            @click="$router.push({ name: 'Detalle OT', params: {otID: item.id }})"
          ) Ver detalle
</template>

<script>
// import { mapState } from 'pinia'
import { mapActions } from 'vuex'
// import { useCustomerListStore } from '../../../../stores/views/useCustomerListStore'
import otStatusColors from '@/shared/otStatusColors'

export default {
  props: {
    params: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      orderList: [],
      page: 1,
      totalItems: 0,
      loading: false,
    }
  },

  computed: {
    // ...mapState(useCustomerListStore, ['selectedCustomer']),

    headers() {
      return [
        { text: 'Número', value: 'id' },
        { text: 'Estado', value: 'state', width: '1%', align: 'center' },
        { text: 'Detalle', value: '_actions', align: 'end' },
      ]
    },

    queryParams() {
      return {
        page: this.page,
        ...this.params,
      }
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.fetch()
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions('ot', ['listOT']),

    async fetch() {
      this.loading = true
      const res = await this.listOT({ params: this.queryParams })
      if (res.status < 200 || res.status >= 300) {
        this.loading = false
        return this.$toast.error('Error al cargar historial de OTs')
      }

      this.orderList = res.data.results
      this.totalItems = res.data.count
      this.loading = false
    },

    getStatusColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].color
        : otStatusColors.default.color
    },

    getStatusTextColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].textColor
        : otStatusColors.default.textColor
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-circle {
  border-radius: 50%;
  background-color: #4c7aec;
  padding: 6px;
  color: white;
}

.ot-table::v-deep td,
.ot-table::v-deep th {
  color: #4c7aec !important;
}

.btn-edit {
  padding: 0 !important;
  height: auto !important;
  text-decoration: underline;
}

.state-chip {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
