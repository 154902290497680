<template lang="pug">
.sale-vehicle-detail
    v-row(dense)
      v-col
        strong.header {{customer.name}} {{customer.last_name}}
        div  {{customer.rut}}
        div {{customer.address}}
        div (+56) {{customer.contact | cellFilter}}
        div {{customer.email}}
      v-col
        strong.header {{vehicle.model_display}}
        div Patente: {{vehicle.patent}}
        div Número de motor: {{vehicle.engine_number}}
        div Número de chasis: {{vehicle.chassis_number}}
        div Color: {{ vehicle.color }}
        div Combustible: {{vehicle.fuel_type_display}}
        div(v-if="vehicleMilage") {{ vehicleMilage}} Kms
        div(v-else) Kilometraje no registrado
      v-col(v-if="state")
        div.header Status
        strong.header {{state}}
</template>

<script>
import { cellFilter } from '@/utils/filters.js'
export default {
  filters: { cellFilter },
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    vehicleMilage: Number,
    state: String
  }
}
</script>

<style lang="scss" scoped>
.sale-vehicle-detail {
  background-color: #fff;
  border: 1px solid #E4E3E2;
  padding: 16px;
  color: #A3A2A1;
}
.header {
  color: #464544;
  display: block;
}
</style>
