<template lang="pug">
v-container(v-if="!loading" fluid)
  v-form(ref="formSupplierPayment" lazy-validation v-model="formIsValid")
    br
    v-row
      v-col(cols="5")
        v-row
          v-icon(style="color: #000;" dark @click="goBack") mdi-arrow-left
          h3.color.mb-5.ml-2.mt-3 Resumen de tu mercadería a pagar
        v-row.mt-5
          p.mr-5.ml-10.mt-1.subtitles Nombre del proveedor
          p {{supplier.name}}
        v-row.mt-5
          p.mr-5.ml-10.mt-1.subtitles N° Factura (Obligatorio)
          v-text-field(dense v-model="supplierPayment.invoice_number" style="max-width: 200px;" :rules="[rules.invoiceRequired]")
        v-row.mt-5
          p.mr-5.ml-10.mt-1.subtitles Monto total a pagar
          p.ml-6 {{ getTotalAmount | currency }}
        v-row.mt-5(v-if="supplierPayment.payment_info.payment_gateway === 'Cheque'")
          p.mr-5.ml-10.mt-1.subtitles Monto total en cheques
          p.ml-0 {{ supplierPayment.payment_info.payment_total | currency }}
      v-col(cols="7")
        h3.color Elige tu forma de pago
        v-radio-group(v-model="supplierPayment.payment_info.payment_gateway" row @change="resetPaymentFields")
          v-radio(label="Efectivo" value="Efectivo")
          v-radio(label="Tarjeta" value="Débito")
          v-radio(label="Transferencia" value="Transferencia")
          v-radio(label="A crédito" value="A Crédito")
          v-radio(label="Cheque" value="Cheque")
        div(v-if="supplierPayment.payment_info.payment_gateway === 'A Crédito'")
          p.subtitles Fecha de Pago
            v-menu(ref="menu" v-model="menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field(
                  v-model="supplierPayment.payment_info.credit_term && supplierPayment.payment_info.credit_term.split('-').reverse().join('/')"
                  prepend-inner-icon="mdi-calendar"
                  solo dense flat
                  v-bind="attrs"
                  v-on="on"
                  style="max-width: 35%; margin-top: 12px;"
                  placeholder=" Ingrese una fecha"
                  readonly clearable outlined
                  :rules="[v => !!v || 'La fecha de pago es obligatoria']"
                )
              v-date-picker(v-model="supplierPayment.payment_info.credit_term" no-title locale="es-CL")

        div(v-if="supplierPayment.payment_info.payment_gateway === 'Cheque'" style="display: flex; flex-direction: column")
          div.float-end.pb-3
            v-btn.float-end(color="primary" dense @click="newCheck" :disabled="disabledNewCheck") + Nuevo cheque
          div(v-if="supplierPayment.payment_info.payment_gateway === 'Cheque'")
            v-data-table.pb-8(:headers="headers" :items="allChecks" density="compact" hide-default-footer)
              template(v-slot:item.check_number="{ item }")
                v-text-field.mt-2(
                  outlined dense
                  v-model="item.check_number"
                  placeholder="N° Cheque"
                  :rules="[v => v * 1 !== 0 || 'Debe ser mayor a 0', rules.required, rules.isNumber]"
                  :disabled="item.disabled")
              template(v-slot:item.check_date="{ item }")

                v-menu(ref="menu" v-model="item.menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field.mt-2(
                      v-model="item.check_date.split('-').reverse().join('/')"
                      outlined dense
                      v-bind="attrs"
                      v-on="on"
                      placeholder="Fecha"
                      readonly
                      :rules="[rules.required]"
                      :disabled="item.disabled"
                    )
                  v-date-picker(v-model="item.check_date" no-title locale="es-CL")

              template(v-slot:item.check_amount="{ item }")
                v-text-field.mt-2(
                  outlined dense
                  v-model="item.check_amount"
                  prefix="$"
                  :rules="[rules.required, rules.greaterThanZero, rules.lowerOrEquialsToTotal, rules.notGreaterThanTotal]"
                  :disabled="item.disabled"
                  v-maska:[moneyMaskFormat]
                )
              template(v-slot:item.actions="{ item }")
                div(style="display: flex; margin-top: -20px !important")
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(icon @click="clearCheck(item)" v-bind="attrs" v-on="on")
                        v-icon mdi-broom
                    span Limpiar
                  v-tooltip(bottom v-if="item.allowDelete")
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(icon v-bind="attrs" v-on="on" @click="deleteCheck(item)")
                        v-icon mdi-trash-can
                    span Eliminar

    v-row(cols="1").float-end.mb-2
      v-btn(color="primary" @click="handleSave" :disabled="disableSave") Pagar
</template>
<script>
import { mapActions } from 'vuex'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../utils/mask'
import { mapGetters } from 'vuex/dist/vuex.common.js'
export default {
  directives: { maska: vMaska },
  data () {
    return {
      supplierPayment: {
        supplier_id: null,
        sm_list: [],
        payment_info: {
          payment_gateway: '',
          payment_total: 0,
          credit_term: null,
          check_number: null,
          check_date: null,
          check_amount: null,
          check_qty: null
        },
        invoice_number: ''
      },
      formIsValid: true,
      rules: {
        required: value => !!value || 'Requerido',
        isNumber: value => Number.isInteger(+value) || 'Debe ser un número',
        greaterThanZero: value => +value.toString().split('.').join('') > 0 || 'Debe ser mayor a cero',
        invoiceRequired: value => value !== '' || 'N° de factura obligatorio',
        lowerOrEquialsToTotal: value => +value.toString().split('.').join('') <= this.getTotalAmount || 'Debe ser menor o igual al monto total',
        notGreaterThanTotal: value => this.getTotalAmount - this.supplierPayment.payment_info.payment_total - +value.toString().split('.').join('') >= (- +value.toString().split('.').join('')) || 'El monto total en cheques supera el monto total a Pagar'
      },
      confirmPayment: false,
      disableSaveBtn: false,
      receiptDate: '',
      menu: false,
      headers: [
        { text: 'Número de cheque *', value: 'check_number', class: 'blue lighten-5', sortable: false },
        { text: 'Fecha *', value: 'check_date', class: 'blue lighten-5', sortable: false, width: '140px' },
        { text: 'Monto *', value: 'check_amount', class: 'blue lighten-5', filterable: false, sortable: false },
        { text: 'Acciones', value: 'actions', class: 'blue lighten-5', filterable: false, }
      ],
      allChecks: [
        {
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: false
        }
      ],
      moneyMaskFormat,
      supplier: null,
      loading: false,
      pieces: [],
    }
  },
  computed: {
    ...mapGetters('inventory', ['solicitude']),
    disableSave () {
      if (this.disableSaveBtn) return true
      if (this.supplierPayment.payment_info.payment_gateway === 'A Crédito') return Object.values(this.state).includes(false)
      if (this.supplierPayment.payment_info.payment_gateway === 'Cheque') return Object.values(this.state).includes(false)
      return this.state.paymentGateway === false || this.state.invoiceNumber === false
    },
    state () {
      return {
        paymentGateway: this.supplierPayment.payment_info.payment_gateway !== '',
        check_number: this.supplierPayment.payment_info.payment_gateway === 'Cheque' ? !!this.supplierPayment.payment_info.check_number : true,
        check_date: this.supplierPayment.payment_info.payment_gateway === 'Cheque' ? !!this.supplierPayment.payment_info.check_date : true,
        invoiceNumber: this.supplierPayment.invoice_number !== '',
        credit_term: this.supplierPayment.payment_info.payment_gateway === 'A Crédito' ? !!this.supplierPayment.payment_info.credit_term : true,
        totalAmount: this.supplierPayment.payment_info.payment_gateway === 'Cheque' ? this.supplierPayment.payment_info.payment_total === this.getTotalAmount : true

      }
    },
    disabledNewCheck () {
      const lastCheck = this.allChecks.at(-1)
      const checksCompleted = (this.supplierPayment.payment_info.payment_total - this.getTotalAmount) >= 0
      return checksCompleted || ['', 0,].map(v => Object.values(lastCheck).includes(v)).includes(true)
    },
    getTotalAmount () {
      const totalAmount = this.pieces.reduce((acc, p) => acc + (p.payed_price * p.amount), 0)
      return totalAmount
    },
  },
  methods: {
    ...mapActions('inventory', ['updateAproveMerchandiseEntryRequest', 'getMerchandiseEntryRequest']),
    async fetchData () {
      this.loading = true
      this.getMerchandiseEntryRequest({ id: +this.$route.params.stockID }).then(
        response => {
          console.log(response)
          this.supplier = {
            id: response.data.pending_stock_movements[0].piece.supplier,
            name: response.data.pending_stock_movements[0].piece.supplier_name
          }
          this.supplierPayment.invoice_number = response.data.invoice_number
          this.pieces = response.data.pending_stock_movements.map((p) => {
            const storeSolicitude = this.solicitude.sm_list.find((s) => s.piece_id === p.piece.piece)
            return {
              code: p.piece.piece_code,
              piece_id: p.piece.piece,
              supplier_id: p.piece.supplier,
              supplier_code: p.piece.supplier_piece_code,
              category_code: p.piece.category_code,
              name: p.piece.piece_name,
              brand_name: p.piece.brand,
              supplier_name: p.piece.supplier_name,
              payed_price: storeSolicitude.payed_price || p.payed_price,
              sale_price: p.sale_price,
              amount: storeSolicitude.amount || p.amount,
            }
          })
          this.loading = false
        }
      )
    },
    goBack () {
      this.$router.go(-1)
    },
    handleSave () {
      if (!this.$refs.formSupplierPayment.validate()) return

      this.supplierPayment.supplier_id = this.supplier.id
      this.supplierPayment.sm_list = this.pieces
      this.supplierPayment.sm_list = this.supplierPayment.sm_list.filter(piece => piece.amount > 0)
      if (this.supplierPayment.payment_info.payment_gateway === 'Cheque') {
        const remainingChecks = this.allChecks.slice(1)
        this.supplierPayment.payment_info.check_number = this.allChecks[0].check_number
        this.supplierPayment.payment_info.check_date = this.allChecks[0].check_date
        this.supplierPayment.payment_info.credit_term = this.allChecks[0].check_date
        this.supplierPayment.payment_info.check_amount = +(this.allChecks[0].check_amount.toString().split('.').join(''))
        this.supplierPayment.payment_info.check_qty = `1/${this.allChecks.length}`
        this.supplierPayment.payment_info.remaining_checks = remainingChecks.map((c) => {
          return {
            check_number: c.check_number,
            check_date: c.check_date,
            check_amount: +(c.check_amount.toString().split('.').join(''))
          }
        })
      }

      this.disableSaveBtn = true;
      setTimeout(() => {
        this.disableSaveBtn = false;
      }, 5000);

      const id = this.$route.params.stockID
      this.updateAproveMerchandiseEntryRequest({ id, data: this.supplierPayment }).then(
        response => {
          if (response.status === 200 || response.status === 201) {
            this.supplierPayment = {
              supplier_id: null,
              sm_list: [],
              payment_info: {
                payment_gateway: '',
                credit_term: null,
                check_number: null,
                check_date: null,
                check_amount: null,
                check_qty: null
              },
              invoice_number: ''
            }
            this.$toast.success('Pago realizado con éxito')
            this.$router.push({ name: 'Ingreso de Mercadería' })
          }
        }
      )
    },
    newCheck () {
      // this.allChecks.at(-1).disabled = true
      this.allChecks.push({
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: true
        })
    },
    deleteCheck (item) {
      this.allChecks = this.allChecks.filter((c) => c !== item)
      this.allChecks.at(-1).disabled = false
    },
    clearCheck (item) {
      this.allChecks = this.allChecks.map((c) => c !== item? c : {
        ...c,
        check_number: '',
        check_date: '',
        check_amount: '',
        disabled: false,
      })
    },
    resetPaymentFields () {
      this.supplierPayment.payment_info.credit_term = null
      this.allChecks = [
        {
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: false
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    allChecks: {
      handler(value) {
        const lastCheck = this.allChecks.at(-1)
        if(lastCheck.check_amount[0] === '0') {
          lastCheck.check_amount = Number(lastCheck.check_amount) * 1
        }
        this.supplierPayment.payment_info.check_amount = +lastCheck.check_amount.toString().split('.').join('')
        this.supplierPayment.payment_info.payment_total = this.supplierPayment.payment_info.check_amount

        if(this.allChecks.length > 1) {
          const paymentTotal = this.allChecks.reduce((acc, c) => acc + +(c.check_amount.toString().split('.').join('')), 0)
          this.supplierPayment.payment_info.payment_total = paymentTotal
        }

        this.supplierPayment.payment_info.check_number = lastCheck.check_number
        this.supplierPayment.payment_info.check_date = lastCheck.check_date
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.color {
  color: #4C7AEC;
}
.subtitles {
  font-size: 14px;
}
.payment-img {
  height: 160px;
  background-image: url('../../assets/img/interaction.png');
  background-size: contain;
  background-position: center;
}
</style>
