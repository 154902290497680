import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const SaleStore = {
  namespaced: true,
  state: {
    sales: [],
    sale: null
  },
  getters,
  mutations,
  actions
}

export default SaleStore
