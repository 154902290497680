<template lang="pug">
v-navigation-drawer.float-left(
  permanent expand-on-hover floating dark color="#4C7AEC"
  mini-variant-width="85px" width="225px"
  :mini-variant.sync="mini"
)
  v-list(:class="{ 'mb-3': mini }")
    //- Logo
    v-list-item.pa-0
      v-list-item-content
        img.logo-mini(v-if="mini" src="@/assets/logo/isologo-white-garage.png").m-auto
        img.logo-full(v-else src="@/assets/logo/logo-white-garage.png").m-auto

    //- Avatar
    v-list-item.pa-0(v-if="!mini")
      v-list-item-content
        .d-flex.justify-center
          v-avatar(color="#E4E3E2" size="46").black--text {{ userInitials }}

  //- v-divider

  v-list(nav dense style="max-width: 225px")
    v-list-item-group(active-class="nav-item-active")
      v-list-item.nav-item(
        v-for="(route, index) in routes" :key="index" :to="route.url"
        :class="{ 'nav-item-submenu': checkChildren(route), 'nav-item-expanded': !mini }"
      )

        //- Item individual
        v-list-item-icon(v-if="!checkChildren(route)").d-flex.justify-center.mx-0
          v-icon(v-text="route.icon")
        v-list-item-content(v-if="!checkChildren(route)").ml-2
          .nav-item-text {{ route.text }}

        //- Item submenú
        v-list-group.nav-submenu(v-else no-action :value="false")
          template(v-slot:activator)
            v-list-item-icon.d-flex.justify-center.mx-0
              v-icon(v-text="route.icon")
            v-list-item-content.ml-2
              .nav-item-text {{ route.text }}
          v-list-item(v-for="child in route.children" :key="child.text" :to="child.url").nav-item-child
            v-list-item-content
              .nav-item-text {{ child.text }}

  //- Cerrar sesión
  template(#append)
    v-list(nav dense style="max-width: 225px").mb-8
      v-list-item-group(active-class="nav-item-active")
        v-list-item.nav-item(:class="{ 'nav-item-expanded': !mini }" @click="$emit('logout')")
          v-list-item-icon.d-flex.justify-center.mx-0
            v-icon mdi-logout
          v-list-item-content.ml-2
            .nav-item-text Cerrar Sesión
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mini: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    userInitials() {
      return this.user
        ? this.user.first_name.charAt(0) + this.user.last_name.charAt(0)
        : ''
    },
  },
  methods: {
    checkChildren(route) {
      return Object.prototype.hasOwnProperty.call(route, 'children')
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-mini,
.logo-full {
  height: 64px;
  padding: 0;
  object-fit: contain;
}

.logo-full {
  width: auto;
}

.nav-submenu {
  width: 100%;

  &::v-deep > .v-list-item {
    margin: 0 !important;
  }
}

.nav-item {
  padding: 6px 8px;
}

.nav-item-submenu {
  &.nav-item-expanded {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  & > .v-list-item {
    width: 100%;
  }
}

.nav-item-active {
  &::before {
    opacity: 1;
    background-color: rgba(34, 64, 119, 0.25);
    border-radius: 0;
    margin-left: -8px;
    margin-right: -8px;
  }

  // &.nav-item-submenu::before {
  //   opacity: 0;
  // }
}

.nav-item-expanded {
  padding-left: 10px !important;
}

.nav-item-child {
  padding-left: 40px !important;
}

.nav-item-text {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

/* ===== Scrollbar CSS ===== */
::v-deep .v-navigation-drawer__content {
  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: #345fcb #3d6eeb;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #3d6eeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #345fcb;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }
}
</style>
