<template lang="pug">
v-container(fluid)
  v-tabs(v-model="tab" fixed-tabs)
    v-btn.mt-2(elevation="0" color="primary" @click="handleBack" icon style="margin-left: 10px;")
      v-icon mdi-arrow-left
    v-tab.style Proveedores de Gastos
    v-tab.style Proveedores de Mercadería
  v-tabs-items(v-model="tab")
    v-tab-item
      SuppliersExpenses
    v-tab-item
      SuppliersMerchandise
</template>
<script>
import SuppliersExpenses from '../components/sale/suppliers/SuppliersExpenses.vue'
import SuppliersMerchandise from '../components/sale/suppliers/SuppliersMerchandise.vue'
export default {
  components: {
    SuppliersExpenses,
    SuppliersMerchandise
  },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    handleBack () {
      localStorage.setItem('back', true)
      this.$router.back()
    }
  },
}
</script>
<style lang="scss" scoped>
.style {
  color: #1A1796;
}
</style>