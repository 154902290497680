<template lang="pug">
  .estimate-service-list
      ul
        li(v-for="service in estimate.estimate_services" :key="service.service_data.id") {{ service.service_data.service_code || '' }} {{service.service_data.name || 'No hay trabajos sugeridos'}} (x{{service.service_quantity || ''}})
</template>

<script>
export default {
  props: {
    estimate: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-service-list {
  background-color: #F6F5FD;
  padding: 16px;
  color: #1A1796;
}
</style>
