<template lang="pug">
v-snackbar(:timeout="timeout" :type="type" v-model="showAlert" :color="color")
  p.alert {{ message }}
</template>
<script>
export default {
  data () {
    return {
      timeout: 3000,
      alert: true,
      type: 'success',
      message: '',
      showAlert: false,
      color: ''
    }
  },
  methods: {
    handleShowAlert (type, message, showAlert, color) {
      this.type = type
      this.message = message
      this.showAlert = showAlert
      this.color = color
    }
  }
}
</script>
<style lang="scss" scoped>
.alert {
  text-align: center;
  margin-bottom: 0;
  white-space: pre-wrap;
}
</style>
