const mutations = {
  'SET_PIECES'(state, value) {
    state.pieces = value
  },
  'SET_EXPENSE_GROUPS'(state, value) {
    state.expenseGroups = value
  },
  'SET_SOLICITUDE'(state, value) {
    state.solicitude = value
  }
}

export default mutations
