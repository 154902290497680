<script>
import otStatusColors from '../../shared/otStatusColors'

export default {
  methods: {
    getStatusColor (state) {
      return otStatusColors[state] ? otStatusColors[state].color : otStatusColors.default.color
    }
  }
}
</script>
