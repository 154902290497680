<template lang="pug">
v-autocomplete(
  :value="value"
  @input="$emit('input', $event)"
  :items="items"
  :search-input.sync="search"
  :loading="loading"
  :clearable="options.clearable == null ? true : options.clearable"
  no-data-text="No se encontraron datos"
  @focus="search = search"
  v-bind="options"
)
  slot(v-for="name in $slots" :name="name" :slot="name")

  template(v-for="(_, name1) in slotsWithProps" v-slot:[name1]="slotProps")
    slot(:name="name1" v-bind="slotProps")
  template(v-for="(_, name2) in slotsWithoutProps" v-slot:[name2])
    slot(:name="name2")

</template>

<script>
import { debounce } from 'lodash'

const SLOTS_WITH_PROPS = ['counter', 'item', 'message', 'selection']

export default {
  props: {
    value: {
      type: null,
      default: null,
    },
    fetch: {
      type: Function,
      required: true,
    },
    extraParams: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      search: null,
      loading: false,
      items: [],
      slotsWithProps: {},
      slotsWithoutProps: {},
    }
  },

  computed: {
    params() {
      return {
        search: this.search,
        ...this.extraParams,
      }
    },
  },

  watch: {
    search: {
      handler(val) {
        this.onChange(val)
      },
    },

    value: {
      handler(val, oldVal) {
        if (oldVal == null && val != null) {
          this.search = String(val.id)
        }
      },
    },

    '$scopedSlots': {
      handler(val) {
        Object.keys(this.$scopedSlots).forEach((name) => {
          if (SLOTS_WITH_PROPS.includes(name)) {
            this.slotsWithProps[name] = this.$scopedSlots[name]
          } else {
            this.slotsWithoutProps[name] = this.$scopedSlots[name]
          }
        })
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.items = this.value
      } else {
        this.items = [this.value]
      }
    }

    this.onChange = debounce(async function (search) {
      this.loading = true
      if(this.value && typeof(this.value) === 'object' && search === null ) {
        try {
          this.items = (await this.fetch({ params: this.params })).data
          if (this.value && !this.items.find((item) => item.id === this.value.id)) {
            this.items = [this.value, ...this.items]
            
          }
        } catch (e) {
          this.$emit('error', e)
        } finally {
          this.loading = false
          if(this.search === this.value.full_name) {
            this.items = this.items.filter(i => i.full_name.toLowerCase().includes(search.toLowerCase()))
          }
        }
      } 
      else if(search?.replace(/\s+/, '').length > 1) {
        try {
          this.items = (await this.fetch({ params: this.params })).data.filter(i => i.full_name.toLowerCase().includes(search.toLowerCase()))
          if (this.value && !this.items.find((item) => item.id === this.value.id)) {
            this.items = [this.value, ...this.items]
          }
        } catch (e) {
          this.$emit('error', e)
        } finally {
          this.loading = false
        }
      }
      else if(search?.replace(/\s+/, '').length === 1 || search === null || search === undefined || this.value === null ) {
        this.items = [];
      }
      this.loading = false;
    }, 200)
  },

  methods: {
    onChange() {},
  },
}
</script>

<style lang="scss" scoped></style>
