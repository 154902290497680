<template lang="pug">
SideModal(v-model="isOpen" max-width="800")
  v-container(fluid v-if="selectedOt")
    alert(ref="alert")
    v-btn.close-btn(fab color="white" small depressed @click="resetModal()")
      v-icon mdi-close-thick
    v-card
      v-card-title.title Pago de Saldos Pendientes.
      v-spacer
      v-card-subtitle Pago Final de OT {{ selectedOt.id }}
      v-spacer
      v-data-table.mt-2.ml-2(
          dense
          :headers="tableBudget.headers"
          hide-default-footer
          :items="selectedOt.services"
          :single-expand="false"
          :expanded.sync="expandedServices"
          show-expand
          item-key="id"
        )
          template(v-slot:item.name="{ headers, item }")
            p.mt-2.mb-1 {{ item.service_data.service_code || '' }} {{item.service_data.name}}
          template(v-slot:item.quantity="{ item }")
            p.mt-2.mb-1 x{{ item.quantity }}
          template(v-slot:item.price="{ item }")
            p.mt-2.mb-1 {{ item.unit_price || item.service_data.price | currency }}
          template(v-slot:expanded-item="{ headers, item }")
            td.py-2(:colspan="headers.length")
              v-data-table.elevation-0(
                dense
                :headers="tableSaleItems.headers"
                hide-default-footer
                :items="item.sale_items_data"
                no-data-text="No existen repuestos"
                )
                template(v-slot:item.model="{ item }")
                  p.itemStyle(v-if="!item.piece") {{ item.new_piece_name }}
                  p.itemStyle(v-else-if="!item.piece.brand_name") {{ item.piece.code || ''}} {{ item.piece.name }}
                  p.itemStyle(v-else) {{ item.piece.code || ''}} {{ item.piece.name }} - {{ item.piece.brand_name }}
                template(v-slot:item.quantity="{ item }")
                  p.itemStyle x{{item.quantity }}
                template(v-slot:item.price="{ item }")
                  p.itemStyle {{ item.unit_price || item.piece.price | currency }}

      div.d-flex.flex-column.justify-end.align-end.pb-2.pr-2.mt-3
        strong.subtotal-color Total Repuestos: {{ sumItemsPrices(otServices) | currency }}
        strong.subtotal-color Total Servicios: {{ sumServicesPrices(otServices) | currency }}
    br
    p.pl-3.title.mb-0 Método de Pago:
    v-radio-group.pl-3.payment-methods(v-model="paymentMethod" row @change="resetPaymentFields")
      v-radio(label="Transbank" value="Transbank")
      v-radio(label="Efectivo" value="Efectivo")
      v-radio(label="A Crédito" value="A Crédito")
      v-radio(label="Transferencia" value="Transferencia")
      v-radio(label="Débito" value="Débito")
      v-radio(label="Cheque" value="Cheque")


    div(v-if="paymentMethod === 'A Crédito'")
      p.color.mr-5.pl-5 Fecha de pago
      v-menu(ref="menu2" v-model="menu2" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
        template(v-slot:activator="{ on, attrs }")
          v-text-field.ml-5.mt-3(
            ref="credit_term"
            v-model="credit_term.split('-').reverse().join('/')"
            prepend-inner-icon="mdi-calendar"
            solo dense flat
            v-bind="attrs"
            v-on="on"
            style="max-width: 40%; margin-top: 12px;"
            placeholder=" Ingrese una fecha"
            readonly clearable outlined
            :rules="[v => !!v || 'La fecha de pago es obligatoria']"
          )
        v-date-picker(v-model="credit_term" no-title locale="es-CL")

    div(v-if="paymentMethod === 'Cheque'" style="display: flex; flex-direction: column")
      div.float-end.pb-3.pr-2
        v-btn.float-end(color="primary" dense @click="newCheck" :disabled="disabledNewCheck"  ) + Nuevo cheque
      div(v-if="paymentMethod === 'Cheque'")
        v-data-table.pb-8(:headers="tableCheck.headers" :items="allChecks" density="compact" item-key="" hide-default-footer)
          template(v-slot:item.check_number="{ item }")
            v-text-field.mt-2(
              ref="check_number"
              outlined dense
              v-model="item.check_number"
              placeholder="N° Cheque"
              :rules="[v => v * 1 !== 0 || 'Debe ser mayor a 0', rules.required, rules.isNumber]"
              :disabled="item.disabled"
            )
          template(v-slot:item.check_date="{ item }")

            v-menu(ref="menu" v-model="item.menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field.mt-2(
                  ref="check_date"
                  v-model="item.check_date.split('-').reverse().join('/')"
                  outlined dense
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Fecha"
                  readonly
                  :rules="[v => !!v || 'La fecha es obligatoria']"
                  :disabled="item.disabled"
                )
              v-date-picker(v-model="item.check_date" no-title locale="es-CL")

          template(v-slot:item.check_amount="{ item }")
            v-text-field.mt-2(
              ref="check_amount"
              outlined dense
              v-model="item.check_amount"
              prefix="$"
              :rules="[rules.required, rules.greaterThanZero]"
              :disabled="item.disabled"
              v-maska:[moneyMaskFormat]
            )
          template(v-slot:item.actions="{ item }")
            div(style="display: flex; margin-top: -20px !important")
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon @click="clearCheck(item)" v-bind="attrs" v-on="on")
                    v-icon mdi-broom
                span Limpiar
              v-tooltip(bottom v-if="item.allowDelete")
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on" @click="deleteCheck(item)")
                    v-icon mdi-trash-can
                span Eliminar

    v-row
      v-col.d-flex(cols="3")
        v-text-field.pl-3.pt-5.reference-input(label="Referencia/ID:" v-model="payment_id")
      v-col.d-flex.justify-end.mt-5(cols="9")
        div(style="text-align: right; margin-top: 50px;")
          p Total Trabajos: #[strong {{ sumBudgetsPrices(selectedOt.services) | currency }}]
          span(v-for="(item, index) in budgets" :key="index +'-x'")
            p(v-if="item.state === 'Pagado' || item.state === 'Pendiente'") Abonado el ({{ formatDate(item.transaction_date) }}) : #[strong {{ item.payment_total | currency }}]
          p.title(style="font-size: 20px;" :class="conditionalHighlight()") Saldo pendiente: #[strong {{ getPendingPayment | currency }}]

          v-row
            v-col(cols="6")
              a(@click="() => paymentMethod !== 'Cheque'? showInputPay=!showInputPay : ''") {{ paymentMethod === 'Cheque' ? 'Monto total en cheques' : 'Otro Monto' }}
            v-col(cols="6" v-if="showInputPay")
              v-text-field.text-input.text-black.mt-0(
                v-model="payOtherAmount"
                ref="otherAmountField"
                label="Ingrese Monto"
                prefix="$"
                hide-details="auto"
                :readonly="paymentMethod === 'Cheque'"
                :rules="[rules.required, rules.notGreaterThanPendingPayment]"
                v-maska:[moneyMaskFormat]
              )
          v-btn.mt-5(
            color="primary"
            :disabled="paymentMethod === 'Cheque'? disabledNewCheck : disabledActions"
            :loading="loading"
            @click="handlePay()"
            ) Pagar
</template>
<script>
import { mapActions } from 'vuex'
import SideModal from '../shared/SideModal.vue'
import alert from '../shared/alert.vue'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../utils/mask'
export default {
  directives: { maska: vMaska },
  props: {
    budgets: Array,
    otID: Number
  },
  components: {
    SideModal,
    alert
  },
  data () {
    return {
      selectedOt: {},
      payment_id: null,
      payOtherAmount: '',
      paymentMethod: null,
      payment_total: '',
      credit_term: '',
      allChecks: [
        {
          check_number: '',
          check_date: '',
          check_amount: 0,
          disabled: false,
          allowDelete: false
        }
      ],
      menu2: false,
      showInputPay: false,
      budgetsSelected: [],
      isOpen: false,
      loading: false,
      expanded: [],
      expandedServices: [],
      table: {
        headers: [
          { text: 'Presupuestos', value: 'budget_select' },
          { text: '', value: 'data-table-expand' }
        ]
      },
      tableBudget: {
        headers: [
          { text: 'Servicios a realizar', value: 'name', width: '150px'},
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center'},
          { text: 'Precio', value: 'price', width: '100px'},
          { text: '', value: 'data-table-expand' },
        ]
      },
      tableSaleItems: {
        headers: [
          { text: 'Repuesto', value: 'model', width: '150px', class: 'itemStyle' },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center', class: 'itemStyle' },
          { text: 'Precio Unitario', value: 'price', width: '100px', class: 'itemStyle' }
        ]
      },
      tableCheck: {
        headers: [
          { text: 'Número de cheque *', value: 'check_number', class: 'blue lighten-5', sortable: false },
          { text: 'Fecha *', value: 'check_date', class: 'blue lighten-5', sortable: false, width: '140px' },
          { text: 'Monto *', value: 'check_amount', class: 'blue lighten-5', filterable: false, sortable: false },
          { text: 'Acciones', value: 'actions', class: 'blue lighten-5', filterable: false, }
        ],
      },
      rules: {
        required: value => !!value || 'Requerido',
        greaterThanZero: value => +value.split('.').join('') > 0 || 'Debe ser mayor a cero',
        isNumber: value => Number.isInteger(+value) || 'Debe ser un número',
        notGreaterThanPendingPayment: v => {
          return +v.toString().split('.').join('') <= this.getPendingPayment || 'El monto no puede ser mayor al saldo pendiente'
        }
      },
      moneyMaskFormat,
    }
  },
  computed: {
    otServices () {
      return this.selectedOt?.services || []
    },
    disabledActions () {
      const amountEmpty = this.payOtherAmount === '' || parseInt(this.payOtherAmount) <= 0
      const amountInvalid = parseInt(this.payOtherAmount) > this.getPendingPayment
      return (this.paymentMethod == null)
        || this.showInputPay && (amountEmpty || amountInvalid)
        || this.paymentMethod === 'A Crédito' && !(this.credit_term.length > 8)
        || this.paymentMethod === 'A Crédito' && !this.$refs.credit_term?.valid
    },
    totalBudgetToPay () {
      let total = 0
      this.budgetsSelected.forEach(budget => {
        total = total + budget.budgetPayment
      })
      return total
    },
    disabledNewCheck () {
      const disableEmptyChecks = this.allChecks.map(check => {
        return ['', 0,].map(v => Object.values(check).includes(v)).includes(true)
      })
      return this.paymentMethod === 'Cheque' && !this.$refs.otherAmountField?.valid || disableEmptyChecks.includes(true)
    },
    getPendingPayment() {
      const newPendingPayment = this.selectedOt.pending_payment
      return newPendingPayment
    }
  },
  methods: {
    ...mapActions('ot', ['getOT', 'manualPayment']),

    async open () {
      this.selectedOt = {}
      this.payOtherAmount = null
      this.budgetsSelected = []
      await this.getOT({ OTId: this.otID }).then(
        (response) => {
          this.selectedOt = response.data
          this.selectedOt.estimate = this.selectedOt.estimate.filter((e) => e.state === 'Presupuesto aprobado' || e.state === 'En reparación' || e.state === 'Reparado' || e.state === 'Listo')
          this.selectedOt.estimate.forEach(e => this.selectBudget(e))
          this.isOpen = true
        }
      )
    },
    sumServicesPrices (services) {
      if (!services) return 0
      const total = services.map(s => {
        return s.quantity * (s.unit_price || s.service_data.price)
      })
      return total.reduce((acc, curr) => { return acc + curr }, 0)
    },
    sumItemsPrices (items) {
      if (!items) return 0
      let total = 0
      items.forEach((s) => {
        total = total  + s.sale_items.reduce((acc, curr) => acc + curr.total_price, 0)
      })
      return total
    },
    sumBudgetsPrices (services) {
      return this.sumServicesPrices(services) + this.sumItemsPrices(services)
    },
    selectBudget (item) {
      if (!this.budgetsSelected.includes(item)) {
        this.budgetsSelected.push(item)
        item.checked = true
        item.style = 'color: #000; font-weight: bold'
      } else {
        const index = this.budgetsSelected.indexOf(item)
        this.budgetsSelected.splice(index, 1)
        item.checked = false
        item.style = ''
      }
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    handlePay () {
      const data = {
        deposit_order: {
          payment_total: this.payOtherAmount !== '' ? +this.payOtherAmount.toString().split('.').join('') : this.selectedOt.pending_payment,
          payment_gateway: this.paymentMethod,
          payment_id: this.payment_id,
          estimates_to_pay: this.budgetsSelected.map((budget) => budget.id)
        }
      }
      if (this.paymentMethod === 'A Crédito') {
        data.deposit_order.credit_term = this.credit_term
      } else if (this.paymentMethod === 'Cheque') {
        this.loading = true

        for (const check of this.allChecks) {
          data.deposit_order.check_number = check.check_number
          data.deposit_order.check_date = check.check_date
          data.deposit_order.check_amount = +check.check_amount.split('.').join('')
          data.deposit_order.check_qty = `${Number(this.allChecks.indexOf(check)) + 1}/${this.allChecks.length}`
          data.deposit_order.payment_total = +check.check_amount.split('.').join('')

          this.manualPayment({ data }).then(
            (response) => {
              this.loading = false
              if (response.status === 200) {
                if(Number(this.allChecks.indexOf(check)) + 1 === this.allChecks.length) {
                  this.isOpen = false
                  this.resetModal()
                  this.$emit('showAlert', { type: 'success', message: 'Saldo Pendiente PAGADO con éxito', show: true, color: 'green' })
                }
              } else {
                this.$emit('showAlert', { type: 'danger', message: 'Ha ocurrido un error al realizar el pago', show: true, color: 'red' })
              }
            }
          )
        }
        return
      }
      this.loading = true
      this.manualPayment({ data }).then(
        (response) => {
          this.loading = false
          if (response.status === 200) {
            this.isOpen = false
            this.resetModal()
            this.$emit('showAlert', { type: 'success', message: 'Saldo Pendiente PAGADO con éxito', show: true, color: 'green' })
          } else {
            this.$emit('showAlert', { type: 'danger', message: 'Ha ocurrido un error al realizar el pago', show: true, color: 'red' })
          }
        }
      )
    },
    newCheck () {
      this.allChecks.push({
        check_number: '',
        check_date: '',
        check_amount: '',
        disabled: false,
        allowDelete: true
      })
    },
    deleteCheck (item) {
      this.allChecks = this.allChecks.filter((c) => c !== item)
      this.allChecks.at(-1).disabled = false
    },
    clearCheck (item) {
      this.allChecks = this.allChecks.map((c) => c !== item? c : {
        ...c,
        check_number: '',
        check_date: '',
        check_amount: '',
        disabled: false,
      })
    },
    resetModal () {
      this.payment_id = null
      this.payOtherAmount = null
      this.paymentMethod = null
      this.showInputPay = false
      this.budgetsSelected = []
      this.isOpen = false
      this.resetPaymentFields()
    },
    resetPaymentFields () {
      this.allChecks = [
        {
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: false
        }
      ]
      this.credit_term = ''
      // this.payOtherAmount = ''
      // this.showInputPay = false
    },
    conditionalHighlight () {
      return this.payOtherAmount ? 'conditionalHighlight' : ''
    }
  },
  watch: {
    allChecks: {
      handler(value) {
        const lastCheck = this.allChecks.at(-1)
        if(lastCheck.check_amount[0] === '0') {
          lastCheck.check_amount = Number(lastCheck.check_amount) * 1
        }
        this.payment_total = lastCheck.check_amount
        if(this.allChecks.length > 1) {
          const paymentTotal = this.allChecks.reduce((acc, c) => acc + +(c.check_amount.toString().split('.').join(''), 0))
          this.payment_total = paymentTotal
        }
      },
      deep: true
    },
    payment_total: {
      handler(value) {
        if(value[0] === '0') {
          this.payment_total = Number(value) * 1
        }
        if(this.paymentMethod === 'Cheque') {
          this.payOtherAmount = this.payment_total
        }
      },
      deep: true
    },
    paymentMethod: {
      handler(value) {
        if(value === 'Cheque') {
          this.showInputPay = true
        }
      },
      deep: true
    },
    payOtherAmount: {
      handler(value) {
        if(this.showInputPay) {
          if(value.toString().split('.').join('') === 0) {
            this.payOtherAmount = ''
          } else if(value[0] === '0') {
            this.payOtherAmount = Number(value) * 1
          }
        }
      },
      deep: true
    },
    showInputPay: {
      handler(value) {
        if(!value) {
          this.payOtherAmount = ''
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  color: #4C7AEC;
}
.close-btn {
  position: fixed;
  top: 12px;
  right: 12px;
}
.percentage-input {
  width: 60px;
}
.reference-input {
  width: 100px;
}
.text-grey {
  color: #A3A2A1;
}
.detail {
  margin-left: auto;
  width: 50%;
  .text-black {
    color: #181818;
    font-weight: bold;
  }
}
.text-input {
  max-width: 120px !important;
  margin: 0;
  padding: 0;
}
.conditionalHighlight {
  color: #A3A2A1 !important;
  text-decoration-line: line-through !important;
}
.payment-methods::v-deep div[role="radiogroup"] {
  columns: 3;
  display: block;
  row-gap: 50px;

  &>div {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
.subtotal-color {
  color: #969696;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
  font-weight: 500;
}
.v-data-table::v-deep td {
  font-size: 14px !important;
}
.v-data-table::v-deep .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-style: italic;
  padding: 0px;
  padding-left: 20px;
}
</style>
<style>
.itemStyle {
  color: #969696 !important;
}
</style>
