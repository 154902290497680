<template lang="pug">
v-row
  v-col.pr-0.pb-0
    v-menu(
      v-model="menu"
      :close-on-content-click="false"
      :nudge-left="80"
      transition="scale-transition"
      offset-y
      min-width="auto"
    )
      <template v-slot:activator="{ on, attrs }">
        v-text-field(
          v-model="dateRangeText"
          single-line
          outlined
          dense
          hide-details="auto"
          readonly
          v-bind="attrs"
          v-on="on"
          style="width: max-content;"
        )
      </template>
      v-date-picker(
        v-model="dates" 
        range 
        @input="dates.length === 2? menu = false : menu = true"
        locale="cl-Es"
        :selected-items-text="getSelectedDays"
      )
</template>

<script>
  export default {
    props: {
      selectedRange: {
        default: null,
      }
    },
    data: () => ({
      menu: false,
      modal: false,
      dates: [],
      currentDate: new Date(),
    }),

    computed: {
      dateRangeText () {
        const startDate = this.dates[0]?.split('-').reverse().join('-') || ''
        const endDate = this.dates[1]?.split('-').reverse().join('-') || ''
        if(this.dates.length < 2) return 'Selecciona un rango'
        return `${startDate} al ${endDate}`
      },
      getSelectedDays () {
        const date1 = new Date(this.dates[0]).getTime();
        const date2 = new Date(this.dates[1]).getTime();        
        const diff = date1 - date2;
        const selectedDays = Math.abs(diff/(1000*60*60*24))+1
        if(selectedDays) {
          this.$emit('selectedDays', selectedDays)
        }
        return `${selectedDays} días seleccionados`
      },
    },

    methods: {
      defaultDateRange() {
        // console.log('default', this.selectedRange)
        if(!this.selectedRange) {
          // últimos 30 días hasta la fecha actual
          this.currentDate.setDate(this.currentDate.getDate() - 29 )
          const start = this.currentDate.toISOString().slice(0, 10)
          // fecha actual
          const end = new Date().toISOString().slice(0, 10)

          this.dates.push(start)
          this.dates.push(end)
        } else {
          this.dates = this.selectedRange
        }
      }
    },    

    mounted () {
      this.defaultDateRange();
    },

    watch: {
      dates: {
        handler (value) {
          if(this.dates.length === 2) {
            const date1 = new Date(this.dates[0]).getTime();
            const date2 = new Date(this.dates[1]).getTime();
            if(date1 > date2) this.dates = this.dates.reverse()
            this.$emit('startEndDates', this.dates)
          }
        },
        deep: true
      },

      selectedRange: {
        handler (value) {
          if(value) {
            // console.log('rango selecionadoooo')
            // console.log('rangooooo', value)
            this.dates = value
          }
        },
        deep: true
      }
    }
  }
</script>
<style>
.v-picker__title__btn, .v-date-picker-title__date, .v-picker__title__btn--active {
  font-size: 1.4rem;
}
</style>