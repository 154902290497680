<template lang="pug">
v-col(class="fill-height")
  v-card-title(
    class="headline font-weight-medium pt-1 pl-0 mb-2"
    style="color: #4C7AEC; font-size: 1.8rem !important;"
  ) Correos
  v-container.pa-0(fluid)
    v-row.pt-3(cols="12")
      v-col.pa-0.pr-2(cols="2")
        v-card(:img="require('../assets/mail/cardMain.svg')"  style="background-size: fit-content;" rounded="xl" dark flat)
          v-card-title.text-no-wrap(class="text-h7 white--text") Correos enviados
          v-card-subtitle(class="text-h4 white--text font-weight-medium") {{ mailCampaignsData?.totals.delivered || 0 }}
      v-col.pa-0.px-2
        v-card( color="#89b4dc" rounded="xl" dark flat)
          v-card-title.text-no-wrap(class="text-h7 white--text") Abiertos
          v-card-subtitle(class="text-h4 white--text font-weight-medium") {{ `${mailCampaignsData?.percentages.opened || 0}%` }}
      v-col.pa-0.px-2
        v-card( color="#89b4dc" rounded="xl" dark flat)
          v-card-title.text-no-wrap(class="text-h7 white--text") Clickeados
          v-card-subtitle(class="text-h4 white--text font-weight-medium") {{ `${mailCampaignsData?.percentages.clicked || 0}%` }}
      v-col.pr-0.pb-0(cols="7" style="text-align: -webkit-right;")
        v-col.pb-0(style="width: fit-content; height: 100%")    
          v-card-title.pa-0.primary--text.text-h7 Reporte por fechas
          v-row.ma-0.pa-0
            DateRangePicker.mr-0.pa-0(style="justify-content: right" @selectedDays="changeSelectedDays" @startEndDates="setStartEndDates")
            v-btn.ml-1(style="min-width: 42px; height: 39px" color="primary" @click="downloadReport" :loading="tryingDownload")
              v-icon(style="width: 1px") mdi-tray-arrow-down
    CampaignList.pt-2.px-0(:selectedDays="selectedDays" :dates="dates" @mailCampaigns="mailCampaigns")
    
    
</template>

<script>
import DateRangePicker from '../components/mail/DateRangePicker.vue';
import CampaignList from '../components/mail/CampaignList.vue';
import axios from '../plugins/axios'
  export default {
    components: {
      DateRangePicker,
      CampaignList,
    },
    data: () => ({
      selectedDays: null,
      campaigns: {
        count: 1,
        next: null, 
        previous: null,
        results: [
          {
            id: 1,
            name: 'Campaña 1',
            subject: 'Hola mundo',
            mode: 'TIME_FROM_LAST_VISIT',
            time_unit: 'DAYS',
            time_value: 1,
            specific_date: null,
            vehicle_model: null, 
            total_sent: 18,
            total_delivered: 3,
            total_opened: 1,
            total_clicked: 0,
            // convertir estos valores a string con el símbolo del porcentaje
            percentage_delivered: '18%',
            percentage_opened: '5%',
            percentage_clicked: '0%'    
          },
        ],
        totals: {
          sent: 18,
          delivered: 3,
          opened: 1,
          clicked: 0
        },
        percentages: {
          // convertir a números enteros y agregar el símbolo del porcentaje
          delivered: 16.66666666,
          opened: '5%',
          clicked: '0%'
        },
      },
      tryingDownload: false,
      dates: null,
      mailCampaignsData: null,

    }),

    methods: {
      changeSelectedDays (days) {
        this.selectedDays = days
      },

      async downloadReport () {
        this.tryingDownload = true;

        if(this.dates.length === 2) {
          console.log('descargando')
          const params = { start_at__gte: `${this.dates[0]} 00:00:00`, start_at__lte: `${this.dates[1]} 00:00:00`}
          const response = await axios.get('/mail/mail_campaign/export_xls/', { params })
          if(response) {
            this.generateFileAndDownload(response.data, response.headers, `Reporte de Campañas de correo.xlsx`)
            console.log('Descargado con éxito!')
            
          }
        }
        setTimeout(() => {
          this.tryingDownload = false;
        }, 200);
      },

      setStartEndDates (dates) {
        // console.log('recibiendo dates', dates)
        this.dates = dates
      },

      selectCampaign (id) {
        this.selectedCampaignId = id
      },

      mailCampaigns (data) {
        // console.log(data)
        this.mailCampaignsData = data
      },

      generateFileAndDownload (data, headers, nameFile) {
        const blob = new Blob([data], {
          type: {
            type: headers['content-type']
          }
        })
        const blobUrl = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobUrl
        tempLink.setAttribute('download', nameFile)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      },
    },

}

</script>
