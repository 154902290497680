const mutations = {
  'SET_TITLE' (state, value) {
    state.title = value
  },
  'SET_SUBTITLE' (state, value) {
    state.subTitle = value
  },
  'SET_SUBTITLES' (state, value) {
    state.subTitles = value
  },
  'SET_BACKGROUND_CLASS' (state, value) {
    state.backgroundClass = value
  }
}

export default mutations
