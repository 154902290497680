import { getField, updateField } from 'vuex-map-fields'

const OTListFilterStore = {
  namespaced: true,
  state: {
    selectedFilters: [],
  },
  getters: { getField },
  mutations: { updateField },
  actions: {},
}

export default OTListFilterStore
