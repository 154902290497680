module.exports = {
  API_URL: process.env.VUE_APP_API_URL || 'http://localhost:8000',
  PATENTES_API_URL: process.env.VUE_APP_PATENTES_API_URL || 'http://localhost:3000',
  PATENTES_API_KEY: process.env.VUE_APP_PATENTES_API_KEY || '',
  APP_CLIENTES_URL: process.env.VUE_APP_APP_CLIENTES_URL || 'http://localhost:8081',
  webSocketsUrl: process.env.VUE_APP_WEBSOCKETS_URL || 'ws://localhost:3000',
  firebasePublicVapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
  firebaseConfig: {
    apiKey: process.env.VUE_APP_FCM_API_KEY,
    authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FCM_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FCM_APP_ID,
  },
}
