<template lang="pug">
v-container.py-0(v-if="OT" fluid)
  div
    v-card(min-height="100%" flat)
      v-card-title {{ OT.customer_name }}
      v-row
        v-col(cols="4")
          div.client-img.graphics
        v-col(cols="8")
          v-card-text.py-0.mt-3.subtitle Datos del Cliente
          v-card-text.py-0 {{ OT.customer_data.full_name }}
          v-card-text.py-0 {{ OT.customer_data.address }}
          v-card-text.py-0 (+56) {{ OT.customer_data.contact  | cellFilter }}
          v-card-text.py-0 {{ OT.customer_data.email }}
      v-row
        v-col(cols="4")
          div.vehicle-img.graphics
        v-col(cols="8")
          v-card-text.py-0.mt-4.subtitle Datos del Vehículo
          v-card-text.py-0 {{ OT.vehicle_data.patent }}
          v-card-text.py-0 {{ OT.vehicle_data.model_display }}
          v-card-text.py-0 {{ OT.vehicle_data.engine_number }}

</template>
<script>
import { mapGetters } from 'vuex'
import { cellFilter } from '@/utils/filters.js'
export default {
  filters: { cellFilter },
  computed: {
    ...mapGetters('ot', ['OT'])
  }
}
</script>
<style lang="scss" scoped>
.client-img {
  background-image: url('../../assets/img/client.png');
  background-size: cover;
}
.vehicle-img {
  background-image: url('../../assets/img/vehicle.png');
  background-size: cover;
}
.graphics {
  width: 100px;
  height: 100px;
  margin-left: 16px;
}
.v-card__text {
  color: #4C7AEC;
}
.subtitle {
  font-weight: 600;
}
</style>
