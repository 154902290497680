const mutations = {
  SET_FOCUS(state, value) {
    state.focusDay = value
  },

  SET_SERVICES(state, value) {
    state.services = value
  },

  LIST_APPOINTMENTS(state, value) {
    state.appointments = value
  },

  SET_CUSTOMERS(state, value) {
    state.customers = value
  },
  'SET_TODAY_APPOINTMENTS'(state, value) {
    state.todayAppointments = value
  },
  LIST_DISABLED(state, value) {
    state.disabled = value
  },
  'SET_APPOINTMENT' (state, value) {
    state.currentAppointment = value
  },
}

export default mutations
