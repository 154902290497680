<template lang="pug">
div.mt-5
  v-card.pt-1.mb-5.pb-5.diagnosticCard(v-for= "(d, index) in evaluations" :key="index")
    v-row
      v-col(cols="9")
        v-card-text.py-1(style="font-size: 12px;") {{ isDiagnosed ? 'Diagnosticado' : 'Asignado' }}: {{ formatDate(d.estimate_updated_at) }}
        v-card-title.pb-5.pt-0(style="color: #462DFA") {{ isDiagnosed ? 'Diagnosticado' : 'Diagnóstico' }}
        v-card-subtitle.py-1(style="color: #7B90DA; font-size: 16px;") {{d.vehicle_brand}} - {{d.vehicle_model}} - {{d.vehicle_patent}}
        v-card-text.pb-0(style="color: #7B90DA; font-size: 16px;")
          | OT N°: {{ d.work_order }}
          | - Presupuesto N°: {{ d.estimate }}
      v-col.d-flex.align-center(cols="3")
        v-btn.chevronButton(
          depressed
          width="40px"
          height="40px"
          color="#462DFA"
          @click="$router.push({ name: 'Detalle Diagnóstico', params: {otID: d.work_order, diagnosticoID: d.estimate }})"
          :disabled="isDiagnosed"
        )
          v-icon(style="color: #fff;" dense) mdi-chevron-right
  v-pagination.mt-4.mb-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    isDiagnosed: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      page: 1,
      rowCount: 0,
      evaluations: []
    }
  },
  methods: {
    ...mapActions('ot', ['listEvaluation', 'listDiagnosed']),
    fetchData () {
      if (this.isDiagnosed) {
        this.listDiagnosed({ params: { page: this.page } }).then(
          response => {
            this.rowCount = response.data.count
            this.evaluations = response.data.results
          }
        )
      }
      else {
        this.listEvaluation({ params: { page: this.page } }).then(
          response => {
            this.rowCount = response.data.count
            this.evaluations = response.data.results
          }
        )
      }
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    page () {
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card__title {
  font-weight: 600 !important;
}
  a {
    color: #A3A2A1 !important;
  }
.diagnosticCard {
  border-left: 16px solid #462DFA;
}
.chevronButton {
  min-width: 40px !important;
  margin-left: 5px;
  margin-top: 12px;
}
</style>
