import Vue from 'vue'
import moment from 'moment'

Vue.filter('currency', (money) => {
  if (money === null || money === undefined) {
    return ''
  }
  return money.toLocaleString('es-CL', {
    style: 'currency',
    currency: 'CLP'
  })
})

Vue.filter('formattedDate', (value) => {
  console.log('formattedDate', value);
  if (value && value !== '') {
    return moment(value).format('DD/MM/YYYY')
  }
  return ''
})
