<template lang="pug">
  .sale-vehicle-detail
    strong {{vehicle?.model_display}}
    div Patente: {{vehicle?.patent}}
    div Color: {{ vehicle?.color }}
    div Número de motor: {{vehicle?.engine_number}}
    div Número de chasis: {{vehicle?.chassis_number}}
    div Combustible: {{vehicle?.fuel_type_display}}
</template>

<script>
export default {
  props: {
    vehicle: {
      // type: Object,
      // required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sale-vehicle-detail {
  border: 1px solid #E4E3E2;
  padding: 16px;
}
</style>
