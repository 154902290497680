import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login.vue'
import SaleView from '../views/SaleView'
import OTView from '../views/OTView'
import PaymentView from '../views/PaymentView'
import FinalPaymentView from '../views/FinalPaymentView'
import PaymentResultView from '../views/PaymentResultView'
import FinalPaymentResultView from '../views/FinalPaymentResultView'
import isAuthenticated from '../utils/isAuthenticated'
import DjangoLayout from '../layouts/DjangoLayout'
import WorkInProgressView from '../views/WorkInProgressView'
import DiagnosticDetailView from '../views/DiagnosticDetailView'
import RepairDetailView from '../views/RepairDetailView'
import OTDetailView from '../views/OTDetailView'
import settings from '../../settings'
import checkGroups from '../utils/checkGroups'
import checkGroupsWithoutSU from '../utils/checkGroupsWithoutSU'
import FinanceView from '../views/FinanceView'
import SuppliersView from '../views/SuppliersView'
import BudgetsView from '../views/BudgetsView'
import StockView from '../views/StockView'
import SolicitudeStockView from '../views/SolicitudeStockView'
import DetailSolicitudeStockView from '../views/DetailSolicitudeStockView.vue'
import NewSolicitudeStockView from '../views/NewSolicitudeStockView'
import OTAdjustView from '../views/OTAdjustView'
import ScheduleView from '../views/ScheduleView.vue'
import MailView from '../views/MailView.vue'
import CustomerListView from '../views/CustomerListView.vue'
import VehicleListView from '../views/VehicleListView.vue'
import PaymentStockSolicitudeView from '../views/PaymentStockSolicitudeView'

Vue.use(VueRouter)

/**
 * Asigna la ruta por defecto según el grupo del usuario
 * @returns {Object} Ruta para redireccionar
 */
const getDefaultRoute = () => {
  if (checkGroupsWithoutSU(['Técnico'])) return { name: 'Trabajo en Curso' }
  return { name: 'OT' }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: (to) => getDefaultRoute(),
  },
  {
    path: '/ventas',
    name: 'Ventas',
    component: SaleView,
    meta: {
      groups: ['Recepcionista'],
      title: 'Ventas',
    },
  },
  {
    path: '/ot',
    name: 'OT',
    component: OTView,
    meta: {
      groups: ['Recepcionista', 'Bodega', 'Supervisor'],
      title: 'OT',
      noHeader: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/movimiento-stock',
    name: 'MovimientoStock',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/stockmovement/`,
      groups: ['Recepcionista', 'Técnico'],
      title: 'Movimientos de Stock',
    },
  },
  {
    path: '/piezas',
    name: 'Piezas',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/piece/`,
      groups: ['Recepcionista', 'Técnico'],
      title: 'Repuestos',
    },
  },
  {
    path: '/marcas',
    name: 'Marcas',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/vehicle/brand/`,
      groups: ['Recepcionista', 'Técnico'],
      title: 'Marcas',
    },
  },
  {
    path: '/convenios',
    name: 'Convenios',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/agreement/`,
      groups: ['Recepcionista'],
      title: 'Convenios',
    },
  },
  {
    path: '/modelo-vehiculos',
    name: 'ModeloVehiculos',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/vehicle/vehiclemodel/`,
      groups: ['Recepcionista', 'Técnico'],
      title: 'Modelo de Vehículos',
    },
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: CustomerListView,
    meta: {
      groups: ['Recepcionista'],
      title: 'Clientes',
    },
  },
  {
    path: '/vehiculos',
    name: 'Vehiculos',
    component: VehicleListView,
    meta: {
      groups: ['Recepcionista'],
      title: 'Vehículos',
    },
  },
  {
    path: '/plantillas',
    name: 'Plantillas',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/estimatetemplate/`,
      groups: ['Recepcionista'],
      title: 'Plantillas',
      subtitle: '',
    },
  },
  {
    path: '/tipo-gasto',
    name: 'Tipo de Gasto',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/expensegroup/`,
      groups: ['Recepcionista'],
      title: 'Tipo de Gasto',
    },
  },
  {
    path: '/pago-ot/:token',
    name: 'Pago',
    component: PaymentView,
    meta: {
      public: true,
    },
  },
  {
    path: '/pago-ot-final/:token',
    name: 'Pago Final',
    component: FinalPaymentView,
    meta: {
      public: true,
    },
  },
  {
    path: '/resultado-pago-final/:paymentId',
    name: 'Resultado Pago Final',
    component: FinalPaymentResultView,
    meta: {
      public: true,
    },
  },
  {
    path: '/resultado-pago/:paymentId',
    name: 'Resultado Pago',
    component: PaymentResultView,
    meta: {
      public: true,
    },
  },
  {
    path: '/works',
    name: 'Trabajo en Curso',
    component: WorkInProgressView,
    meta: {
      groups: ['Técnico'],
      title: 'Trabajos en Curso',
    },
  },
  {
    path: '/ot/:otID/diagnostico/:diagnosticoID',
    name: 'Detalle Diagnóstico',
    component: DiagnosticDetailView,
    meta: {
      groups: ['Técnico'],
      title: 'Diagnóstico',
    },
  },
  {
    path: '/ot/:otID/trabajos-abiertos/:estimateID',
    name: 'Detalle Reparaciones',
    component: RepairDetailView,
    meta: {
      groups: ['Técnico'],
    },
  },
  {
    path: '/ot/:otID',
    name: 'Detalle OT',
    component: OTDetailView,
    meta: {
      groups: ['Recepcionista', 'Supervisor'],
    },
  },
  {
    path: '/budgets/:vehicleID',
    name: 'Presupuestos',
    component: BudgetsView,
    meta: {
      groups: ['Recepcionista', 'Supervisor'],
      title: 'Cotización',
      subtitle: 'Aquí podrás encontrar los presupuestos del vehículo',
    },
  },
  {
    path: '/finance',
    name: 'Finanzas',
    component: FinanceView,
    meta: {
      groups: ['Recepcionista'],
      title: 'Finanzas',
      subtitle: '',
    },
  },
  {
    path: '/suppliers',
    name: 'Proveedores',
    component: SuppliersView,
    meta: {
      groups: ['Recepcionista'],
      title: 'Proveedores ',
    },
  },
  {
    path: '/stock',
    name: 'Ingreso de Mercadería',
    component: StockView,
    meta: {
      groups: ['Recepcionista', 'Supervisor'],
      title: 'Ingresos de Mercadería',
    },
  },
  {
    path: '/stock/detail/:stockID',
    name: 'Ver detalle de Ingreso de Mercadería',
    component: DetailSolicitudeStockView,
    meta: {
      groups: ['Recepcionista', 'Supervisor', 'Bodega'],
      title: 'Ingresos de Mercadería',
    },
  },
  {
    path: '/stock/approve/detail/:stockID',
    name: 'VerDetalleAprobarSolicitudMercaderia',
    component: DetailSolicitudeStockView,
    meta: {
      groups: ['Recepcionista', 'Supervisor'],
      title: 'Ingresos de Mercadería',
    },
  },
  {
    path: '/stock/:stockID/payment',
    name: 'Pagar Solicitud de Mercadería',
    component: PaymentStockSolicitudeView,
    meta: {
      groups: ['Recepcionista', 'Supervisor'],
      title: 'Ingresos de Mercadería',
    },
  },
  {
    path: '/stock/edit/:stockID',
    name: 'Editar Solicitud de Mercadería',
    component: DetailSolicitudeStockView,
    meta: {
      groups: ['Recepcionista', 'Bodega'],
      title: 'Solicitud de Ingresos de Mercadería',
    },
  },
  {
    path: '/solicitudeStock',
    name: 'Mis Solicitudes de Mercadería',
    component: SolicitudeStockView,
    meta: {
      groups: ['Recepcionista', 'Bodega'],
      title: 'Mis Solicitudes de Ingreso de Mercadería',
    },
  },
  {
    path: '/solicitudeStock/new',
    name: 'Nueva Solicitud de Mercadería',
    component: NewSolicitudeStockView,
    meta: {
      groups: ['Recepcionista', 'Bodega'],
      title: 'Nueva Solicitud de Ingreso de Mercadería',
    },
  },
  {
    path: '/budgets/quotations',
    name: 'Cotizacion',
    component: BudgetsView,
    meta: {
      groups: ['Recepcionista'],
      title: 'Cotizaciones',
    },
  },
  {
    path: '/ot/:otID/adjustment',
    name: 'Ajuste de Presupuesto',
    component: OTAdjustView,
    meta: {
      groups: ['Recepcionista', 'Supervisor'],
      title: 'Ajuste de Presupuesto',
    },
  },
  {
    path: '/schedule',
    name: 'Agenda',
    component: ScheduleView,
    meta: {
      groups: ['Recepcionista'],
      // title: 'Agenda tus servicios aquí',
      noHeader: true,
    },
  },
  {
    path: '/mail',
    name: 'Correos',
    component: MailView,
    meta: {
      groups: ['Recepcionista'],
      // title: 'Agenda tus servicios aquí',
      noHeader: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' && isAuthenticated()) {
    console.log('[router] is authenticated, redirect from login')
    return next({ name: 'Home' })
  }
  if (to.name === 'Login') {
    console.log('[router] is not authenticated, proceed to login')
    return next()
  }
  if (!isAuthenticated() && !to.meta.public) {
    console.log("[router] is not authenticated and view isn't public, redirect to login")
    return next({ name: 'Login' })
  }
  if (!to.meta.groups) {
    console.log('[router] no groups required, proceed')
    return next()
  }
  if (!checkGroups(to.meta.groups)) {
    console.log('[router] user does not have required groups, redirect to home');
    return next({ name: 'Home' })
  }
  next()
})

export default router
