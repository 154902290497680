import { render, staticRenderFns } from "./PaymentResultView.vue?vue&type=template&id=9ac22574&scoped=true&lang=pug"
import script from "./PaymentResultView.vue?vue&type=script&lang=js"
export * from "./PaymentResultView.vue?vue&type=script&lang=js"
import style0 from "./PaymentResultView.vue?vue&type=style&index=0&id=9ac22574&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ac22574",
  null
  
)

export default component.exports