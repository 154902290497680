<template lang="pug">
div
  SupplierSolicitudeCreationDetail
</template>

<script>
import SupplierSolicitudeCreationDetail from '../components/stock/SupplierSolicitudeCreationDetail.vue'

export default {
  components: {
    SupplierSolicitudeCreationDetail
  }
}
</script>
