<template lang="pug">
v-container(fluid)
  v-row
    v-col
      v-data-table(:headers="headers" :items="stockMovement" class="elevation-0" hide-default-footer no-data-text="No hay datos para mostrar")
        template(v-slot:item.date="{ item }")
          p {{ formatDate(item.date) }} hrs.
        template(v-slot:item.requested_by="{ item }")
          p {{ item.requested_by.first_name }} {{ item.requested_by.last_name }}
        template(v-slot:item.state="{ item }")
          v-chip(:color="item.state === 'Pendiente' ? 'gray' : 'primary'") {{ item.state }}
        template(v-slot:item.detail="{ item }")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon large v-bind="attrs" v-on="on" @click="toRelatedPayment(item)" color="primary" :disabled="item.state !== 'Pendiente'")
                v-icon mdi-eye-outline
            span Ver detalles
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { text: 'Fecha de Ingreso', value: 'date', filterable: false, sortable: false },
        { text: 'Usuario', value: 'requested_by', filterable: false, sortable: false },
        { text: 'Proveedor', value: 'supplier', filterable: false, sortable: false },
        { text: 'Estado', value: 'state',filterable: false, sortable: false },
        { text: 'Detalle', value: 'detail', filterable: false, sortable: false }
      ],
      stockMovement: [],
      page: 1,
      rowCount: 0,
      loading: false
    }
  },
  methods: {
    ...mapActions('inventory', ['getMerchandiseEntriesRequests']),
    fetchData () {
      this.loading = true
      this.getMerchandiseEntriesRequests({ params: { page: this.page } }).then(
        response => {
          this.rowCount = response.data.count
          this.stockMovement = response.data.results
          this.loading = false
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(date))
    },
    toRelatedPayment (item) {
      this.$router.push({ name: 'VerDetalleAprobarSolicitudMercaderia', params: { stockID: item.id, isApprove: 'approve' } })
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    page () {
      this.fetchData()
    }
  }
}
</script>
