<template lang="pug">
v-dialog(v-model="model" max-width="529")
  v-card(color="#F8F9FD")

    v-card-title
      h2 Comentarios diagnóstico
      v-icon.ml-auto(@click="$emit('input', false)") mdi-close

    v-card-text.pt-2
      .estimate-card(v-for="eva in evaluations" :key="eva.id")
        h3 {{ eva.estimateName }}

        .d-flex.justify-space-between
          .d-flex.align-center
            v-avatar.mr-2(color="#FFD699" size=32 dark) {{ eva.avatarText }}
            span {{ eva.technicianName }}
          .d-flex.align-center
            v-icon.mr-2(color="#407BFF") mdi-calendar
            span.created-at {{ eva.createdAt | formatDate }}

        .d-flex.flex-column
          h3.grey--text.mb-1 Comentario
          .comment {{ eva.comment }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    OT: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['technicians']),
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    evaluations () {
      return this.OT.estimate
        .filter(e => e.evaluation && e.evaluation.evaluation_comment != null)
        .map(e => {
          const tech = this.getTechById(e.evaluation.assigned_to)
          return {
            id: e.id,
            estimateName: e.name || `Presupuesto ${e.id}`,
            createdAt: e.evaluation.created_at,
            technicianName: tech ? this.getTechName(tech) : '',
            avatarText: tech ? this.getAvatarText(tech) : '',
            comment: e.evaluation.evaluation_comment
          }
        })
    }
  },
  methods: {
    getAvatarText (tech) {
      return tech.first_name.charAt(0) + tech.last_name.charAt(0)
    },
    getTechName (tech) {
      return (tech.first_name + ' ' + tech.last_name).trim()
    },
    getTechById (id) {
      if (!id) return
      return this.technicians.find(el => el.id === id)
    }
  },
  filters: {
    formatDate: (date) => new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: #4C7AEC;
  font-weight: 600;
  font-size: 23px;
}
h3 {
  color: #407BFF;
  font-weight: 500;
  font-size: 18px;
}
.v-card__title, .v-card__text {
  @media (min-width: 1025px) and (max-width: 1600px) {
    font-size: 0.9rem;
  }
  @media  (max-width: 1024px) {
    font-size: 0.8rem;
    margin-left: 7px;
  }
}
.v-card {
  height: 476px;
  border-radius: 10px;
}
.v-card__text {
  flex-grow: 1;
  overflow-y: auto;
}
.estimate-card {
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.created-at {
  font-weight: 400;
  font-size: 16px;
  color: #ccc;
}
.comment {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
</style>
