<template lang="pug">
  #OTListEstimateSummaryItem(:style="{ borderColor: getStatusColor(estimate.state) }")
    .date {{estimate.created_at | moment('DD/MM/YYYY') }}
    div {{ estimate.name !== null && estimate.name !== '' ? `N° ${ estimate.id } - ${estimate.name}` : `Presupuesto N° ${ estimate.id }` }}
      v-tooltip(bottom v-if="estimate.agreement !== null")
        template(v-slot:activator="{ on }")
          v-icon.ml-1(style="color: #D78237;" v-on="on") mdi-alpha-c-circle
        span {{ estimate.agreement.name }}
      span.price  {{estimate.total_price * estimate.deposit | currency}} ({{estimate.deposit * 100}}%)
    .d-flex
      v-chip.mr-2(label small :text-color="getStatusTextColor(estimate.state)" :color="getStatusColor(estimate.state)") {{estimate.state}}
</template>

<script>
import otStatusColors from '../../../shared/otStatusColors'

export default {
  props: {
    estimate: {
      type: Object,
      required: true
    }
  },
  methods: {
    getStatusColor (state) {
      return otStatusColors[state] ? otStatusColors[state].color : otStatusColors.default.color
    },
    getStatusTextColor (state) {
      return otStatusColors[state] ? otStatusColors[state].textColor : otStatusColors.default.textColor
    }
  }
}
</script>

<style lang="scss" scoped>
#OTListEstimateSummaryItem {
  border-style: solid;
  border: 0;
  border-left: 10px solid;
  padding: 9px;
  .date {
    font-size: 9px;
  }
  .price {
    color: #A3A2A1;
  }
}
</style>
