<template lang="pug">
#payment-view
    v-container(fluid style="height: 100%")
      v-row(style="min-height: 100%")
        v-col.d-none.d-md-block(cols=4 style="min-height: 100%")
          v-card(height="100%" color="#B7E6FF" flat)
            v-row(style="height: 100%" align-content="center" no-gutters)
              v-col
                v-img(src="../assets/img/icono-vista-pago.svg" style="margin: 0 !important;")
        v-col
          PaymentForm
</template>

<script>
import PaymentForm from '../components/payment/PaymentForm.vue'

export default {
  components: {
    PaymentForm
  }
}
</script>

<style lang="scss" scoped>
#payment-view {
  height: 100%;
  background-color: #F7F5F4;
}
</style>
