export const sales = state => state.sales.map(el => {
  el.date = el.created_at.slice(0, 10)
  el.formated_created_at = el.created_at.slice(0, 10)
  el.formated_total_price = `$${el.total_price.toLocaleString(undefined)}`
  return el
})

export const sale = state => {
  const result = { ...state.sale }
  result.date = result.created_at.slice(0, 10)
  result.formated_created_at = result.created_at.slice(0, 10)
  result.formated_total_price = `$${result.total_price.toLocaleString(undefined)}`
  return result
}
