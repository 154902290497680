import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const InventoryStore = {
  namespaced: true,
  state: {
    pieces: [],
    expenseGroups: [],
    solicitude: {}
  },
  getters,
  mutations,
  actions
}

export default InventoryStore
