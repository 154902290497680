export const patentMaskFormat = {
  mask: 'AAAAAA',
  tokens: {
    A: { pattern: /[a-zA-Z0-9]/ },
  },
  postProcess: (value) => value.toUpperCase(),
}

export const rutMaskFormat = {
  mask: '1.11#-V',
  tokens: {
    1: { pattern: /[0-9]/, repeated: true },
    V: /[0-9Kk]/,
  },
  reversed: true,
  postProcess: (value) => value.toUpperCase(),
}

export const moneyMaskFormat = {
  mask: '9.###.###.###.###.###',
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
}
