<template lang="pug">
v-container
  div
    div.d-flex.align-center.justify-start
      p.ml-1.mt-2(style="color: #1A1796; font-size: 20px;") Presupuestos de #[strong "{{ vehicle.patent }}"]
    br
    v-data-table(:headers="headers" class="elevation-0" hide-default-footer :items="budgets" :loading="loading" loading-text="Buscando coincidencias..." no-data-text="No se encontraron coincidencias")
      template(v-slot:item.budgets="{ item }")
        p.mt-3 {{ item.id }}
      template(v-slot:item.customer="{ item }")
        p.mt-3 {{ vehicle.customer_full_name }}
      template(v-slot:item.date_created="{ item }")
        p.mt-3 {{ formatDate(item.created_at) }}
      template(v-slot:item.created_by="{ item }")
        p.mt-3 {{ item.seller.first_name }} {{ item.seller.last_name }}
      template(v-slot:item.detail="{ item }")
        v-btn(icon large @click="openModalTemplate(item)" v-if="item.estimate_services.length > 0 ")
          v-icon mdi-eye-outline
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  ModalTemplate(ref="ModalTemplate" :template="budget" :modalTitle="`Presupuesto asociado a patente  ${vehicle.patent}`" buttonTitle="Usar Prespuesto" @use="useTemplate")
  OTForm(ref="OTForm" :formData="form")
</template>
<script>
import { mapActions } from 'vuex'
import ModalTemplate from './ModalTemplate.vue'
import OTForm from '../sale/OTForm.vue'
export default {
  props: {
    searchParam: {
      type: String,
      default: ''
    }
  },
  components: {
    ModalTemplate,
    OTForm
  },
  data () {
    return {
      loading: false,
      page: 1,
      rowCount: 0,
      headers: [
        { text: 'ID Presupuesto', value: 'budgets', align: 'center', width: '150px' },
        { text: 'Cliente', value: 'customer', align: 'center' },
        { text: 'Fecha de creación', value: 'date_created', align: 'center' },
        { text: 'Creado por', value: 'created_by', align: 'center' },
        { text: '', value: 'detail', align: 'center' }
      ],
      budgets: [],
      customer: {},
      vehicle: {},
      budget: {},
      form: null,
      searchTerm: ''
    }
  },
  methods: {
    ...mapActions('customer', ['getCustomer']),
    ...mapActions('vehicle', ['getVehicle', 'carEstimates']),
    openModalTemplate (item) {
      this.budget = []
      this.budget = item
      this.form = null
      this.budget.services = item.estimate_services.map(ts => {
        return {
          ...ts.service_data,
          service_quantity: ts.service_quantity
        }
      })
      this.budget.saleItems = item.sale_items_data.map(tp => {
        return {
          piece: tp.piece,
          quantity: tp.quantity,
          total_price: tp.unit_price * tp.quantity
        }
      })
      this.$refs.ModalTemplate.open()
    },
    async fetchData (search = '') {
      this.loading = true
      this.searchTerm = search !== '' ? search : ''
      await this.getVehicle({ vehicleId: this.$route.params.vehicleID }).then(
        response => {
          this.vehicle = response.data
        }
      )
      await this.carEstimates({ params: { page: this.page, patente: this.vehicle.patent, query: search || this.searchParam } }).then(
        response => {
          this.budgets = response.data.results
          this.rowCount = response.data.count
          this.loading = false
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    useTemplate () {
      this.form = this.budget
      this.$refs.OTForm.open()
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  watch: {
    page (newVal) {
      if (newVal !== 1) {
        this.fetchData(this.searchTerm)
      }
    },
    searchParam (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1
      }
      this.fetchData(newVal)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-data-table::v-deep th {
  font-size: 14px !important;
  font-weight: 400;
  background-color: #E4EAFF;
}
.v-icon:hover {
color: #0000FF;
}
.search {
  width: 10rem;
  background-color: #EFF0FE;
  border: 1px solid #3658D3;
}

</style>
