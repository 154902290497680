<template lang="pug">
v-dialog(v-model="openModalExport" transition="dialog-top-transition" max-width="400")
  template(v-slot:default="dialog")
    v-card(tile style="height: 100%;")
      v-img(src="../../assets/img/report.png" height="65px" class="white--text" style="margin: 0 auto !important;")
        v-card-title Genera reporte
      v-card-text
        p.subtitle.mt-5 Tipo de reporte
        v-select(:items="type_report" label="Selecciona tipo de reporte" outlined v-model="selectedReport")
        p.subtitle Selecciona un rango de fecha
        v-menu(ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="picker" transition="scale-transition" offset-y min-width="auto")
          template(v-slot:activator="{ on, attrs }")
            v-text-field(v-model="pickerFormated" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined label="Selecciona aquí" clearable @click:clear="picker = []")
          v-date-picker(v-model="picker" range no-title scrollable color="primary" locale="es-CL" :max="today" @change="picker = picker.sort()")
              v-spacer
              v-btn(color="primary" @click="menu = false" outlined) Cerrar
              v-btn(color="primary" @click="handleSelectedMonth") Confirmar
        v-btn.my-3(@click="download" color="primary" block large :disabled="selectedReport === '' || picker.length < 2")
          span.mr-2 Descargar
          v-icon mdi-download
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      openModalExport: false,
      menu: false,
      picker: [],
      pickerFormated: '',
      selectedReport: '',
      type_report: ['Listado de OT', 'Listado de Presupuesto', 'Listado de Piezas']
    }
  },
  computed: {
    today() {
      return new Date().toISOString().substring(0, 10)
    }
  },
  methods: {
    ...mapActions('inventory', ['exportWorkOrderSheet', 'exportEstimatesSheet', 'exportPiecesSheet']),
    open () {
      this.openModalExport = !this.openModalExport
      this.selectedReport = ''
    },
    handleSelectedMonth () {
      this.$refs.menu.save(this.picker)
    },
    getDate () {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short' }).format(new Date())
    },
    formatDatePicker (date) {
      if (!date) return null
      const [year, month, day] = date[0].split('-')
      if (date.length < 2) return [`${day}/${month}/${year}`, '']
      const [year2, month2, day2] = date[1].split('-')
      return [`${day}/${month}/${year}`, `${day2}/${month2}/${year2}`]
    },
    generateFileAndDownload (data, headers, nameFile) {
      const blob = new Blob([data], {
        type: {
          type: headers['content-type']
        }
      })
      const blobUrl = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobUrl
      tempLink.setAttribute('download', nameFile)
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
    },
    download () {
      const fechaInicio = this.picker[0] < this.picker[1] ? this.picker[0] : this.picker[1]
      const fechaFinal = this.picker[0] > this.picker[1] ? this.picker[0] : this.picker[1]
      if (this.selectedReport === 'Listado de OT') {
        this.exportWorkOrderSheet({ data: { fecha_inicio: fechaInicio, fecha_final: fechaFinal } }).then(
          response => {
            this.generateFileAndDownload(response.data, response.headers, `Cartola OTs - ${this.getDate()}.xlsx`)
          }
        )
      } else if (this.selectedReport === 'Listado de Piezas') {
        this.exportPiecesSheet({ data: { fecha_inicio: fechaInicio, fecha_final: fechaFinal } }).then(
          response => {
            this.generateFileAndDownload(response.data, response.headers, `Cartola Piezas - ${this.getDate()}.xlsx`)
          }
        )
      } else {
        this.exportEstimatesSheet({ data: { fecha_inicio: fechaInicio, fecha_final: fechaFinal } }).then(
          response => {
            this.generateFileAndDownload(response.data, response.headers, `Cartola Presupuestos - ${this.getDate()}.xlsx`)
          }
        )
      }
    }
  },
  watch: {
    picker () {
      if (this.picker.length === 2) {
        this.pickerFormated = this.formatDatePicker(this.picker).join('~')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.subtitle {
  color: #7B90DA;
}
</style>
