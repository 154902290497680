<template lang="pug">
v-container
  v-card
    div
      v-expansion-panels(accordion)
        v-expansion-panel
          v-expansion-panel-header.pl-5
            v-card-title.budgetTitle.pl-1.py-0.pt-1 Egresos
          v-expansion-panel-content
            v-data-table(
              :headers="headers"
              hide-default-footer
              :items="expensesData"
              no-data-text="No hay egresos"
            )
              template(v-slot:item.created_at="{ item }")
                p.mt-2.mb-1 {{ formatDate(item.created_at) }} hrs.
              template(v-slot:item.aproved_by="{ item }")
                p.mt-2.mb-1(v-if="item.aproved_by") {{ item.aproved_by }}
                p.mt-2.mb-1(v-else) -
              template(v-slot:item.payment_gateway="{ item }")
                p.mt-2.mb-1 {{ item.payment_gateway }}
              template(v-slot:item.description="{ item }")
                p.mt-2.mb-1.description {{ item.description }}
              template(v-slot:item.payment_total="{ item }")
                p.mt-2.mb-1 {{ item.payment_total | currency }}
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { text: 'Fecha y Hora', value: 'created_at', sortable: false, align: 'center' },
        { text: 'Usuario', value: 'aproved_by', sortable: false, align: 'center' },
        { text: 'Tipo de Egreso', value: 'payment_gateway', sortable: false, align: 'center' },
        { text: 'Descripción', value: 'description', sortable: false, align: 'center' },
        { text: 'Monto', value: 'payment_total', sortable: false, align: 'center' }
      ],
      expensesData: []
    }
  },
  methods: {
    ...mapActions('ot', ['getExpensesByOT']),
    fetchData () {
      this.getExpensesByOT({ OTId: this.$route.params.otID }).then(
        response => {
          this.expensesData = response.data
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date))
    },
  },
  created () {
    this.fetchData()
  }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #E4EAFF !important;
  height: 70px;
  font-size: 24px !important;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.description {
  word-break: break-all !important;
}
</style
