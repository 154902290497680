<template lang="pug">
  OTDiagnosticTechnicianForm(ref="OTDiagnosticTechnicianForm")
</template>
<script>
import OTDiagnosticTechnicianForm from '@/components/OT/OTDiagnosticTechnicianForm.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    OTDiagnosticTechnicianForm
  },
  methods: {
    ...mapActions('estimate', ['listEstimates']),
    async fetchData () {
      this.listEstimates({ params: { work_order: this.$route.params.otID } }).then(
        response => {
          if (response.status === 200) {
            const estimate = response.data.results.filter(e => e.id === +this.$route.params.diagnosticoID)
            this.$refs.OTDiagnosticTechnicianForm.open(estimate[0].id)
          } else {
            this.$router.back()
          }
        }
      )
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>

</style>
