<template lang="pug">
SideModal(v-model="isOpen" max-width="800" )
  v-container 
    div(style="margin-bottom: 20px; margin-top: -10px")
      h2.primary--text {{ 'Nombre del correo' }}
    v-row(cols="12")
      v-col.px-2
        v-card.pa-5(color="#C3D1FF40" flat) 
          v-card-title.primary--text.text-h7.pa-0 Enviados
          v-card-subtitle.primary--text.text-h5.pa-0.pt-4 {{ campaignSelected?.totals.delivered || 0 }}
      v-col.px-2
        v-card.pa-5(color="#C3D1FF40" flat) 
          v-card-title.primary--text.text-h7.pa-0 Abiertos
          v-card-subtitle.primary--text.text-h5.pa-0.pt-4 {{ `${campaignSelected?.percentages.opened || 0}%` }}
      v-col.px-2
        v-card.pa-5(color="#C3D1FF40" flat) 
          v-card-title.primary--text.text-h7.pa-0 Clickeados
          v-card-subtitle.primary--text.text-h5.pa-0.pt-4 {{ `${campaignSelected?.percentages.clicked || 0}%` }}
      v-col.py-0.mt-1(cols="4")         
        v-card-title.pa-0.primary--text.text-h7 Reporte por fechas
      
        div.mx-0(style="width: fit-content")
          DateRangePicker.mr-0.pa-0.mb-1.pt-0(:selectedRange="dates" @selectedDays="changeSelectedDays" @startEndDates="setStartEndDates")
          v-btn.ma-0.pa-0(
            style="min-width: 42px; height: 35px" 
            color="primary" 
            @click="downloadReport" 
            block
            :loading="tryingDownload"
          )
            v-icon(style="width: 1px") mdi-tray-arrow-down
    
    v-row.pt-4
      v-col() 
        v-data-table(
          :loading="table.loading"
          :headers="table.headers"
          :items="campaignSelected?.results"
          :total-visible="10"
          :options.sync="table.options"
          hide-default-footer

        )
    
    v-pagination.pt-4(v-model="table.options.page" :length="count" circle color="primary" elevation=0)  

</template>

<script>
import SideModal from '../shared/SideModal.vue';
import DateRangePicker from './DateRangePicker.vue';
import axios from '../../plugins/axios';
  export default {
    props: {
      selectedRange: {
        default: null,
      }
    },
    components: {
      SideModal,
      DateRangePicker,
    },
    data: () => ({
      isOpen: false,
      table: {
        loading: false,
        headers: [
          { text: 'Nombre cliente', value: 'customer.full_name',  fieldName: ''},
          { text: 'Correo cliente', value: 'customer.email',  fieldName: ''},
          { text: 'Envío', value: 'delivered_at',  fieldName: ''},
          { text: 'Apertura', value: 'opened_at',  fieldName: ''},
          { text: 'Clickeado', value: 'clicked_at',  fieldName: ''},
        ],
        options: {
          page: 1,
          itemsPerPage: 10
        },
      },
      rowCount: 45,

      campaignSelected2: [
        { name: 'Juanito Perez1', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez16', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez31', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
        { name: 'Juanito Perez', email: "nombredelcorreo@gmail.com", sent: "13/05/23 - 13:23", opened: 'No', clicked: 'No' },
      ],
      selectedDays: null,
      dates: null,
      campaignSelected: null,
      tryingDownload: false,
      count: 0,
      idSelected: null,
    }),

    computed: {
      pageCount () {
        return Math.ceil(this.rowCount / 10)
      }
    },
    methods: {
      open (id, dates) {
        this.mailCampaignDetail(id, dates);
        this.isOpen = true;
        this.idSelected = id
        // console.log('abriendo modal', id, dates)
      },

      changeSelectedDays (days) {
        this.selectedDays = days
      },

      setStartEndDates (dates) {
        // console.log('recibiendo dates', dates)
        this.dates = dates
      },

      async mailCampaignDetail (id, dates) {
        // console.log(dates)
        const params = { start_at__gte: `${dates[0]} 00:00:00`, start_at__lte: `${dates[1]} 00:00:00`, id: id }
        const response = await axios.get(`mail/mail_campaign/${id}/emails/`, { params })
        // console.log('data-details', response.data)
        this.campaignSelected = response.data
        // console.log('data-details ok')
        this.count = response.data.count
         
      },

      resetModal () {
        this.table.options.page = 1
      },

      async downloadReport () {
        this.tryingDownload = true;
        
        if(this.dates.length === 2) {
          // console.log('descargando')
          const params = { start_at__gte: `${this.dates[0]} 00:00:00`, start_at__lte: `${this.dates[1]} 00:00:00`}
          const response = await axios.get(`/mail/mail_campaign/${this.idSelected}/emails/`, { params })
          if(response) {
            this.generateFileAndDownload(response.data, response.headers, `Reporte de Campaña de correo.xlsx`) 
            console.log('Descargado con éxito!')
          }
        }

        setTimeout(() => {
          this.tryingDownload = false;
        }, 200);
      },

      generateFileAndDownload (data, headers, nameFile) {
        const blob = new Blob([data], {
          type: {
            type: headers['content-type']
          }
        })
        const blobUrl = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobUrl
        tempLink.setAttribute('download', nameFile)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      },
    },
    watch: {
      isOpen: {
        handler (value) {
          if(!value) {
            this.resetModal();
          }
        },
        deep: true,
      },

      selectedRange: {
        handler (value) {
          if(value) {
            this.dates = value
          }
        },
        deep: true
      }
    },
  }
</script>
