import axios from '../../plugins/axios'

export const listTechnicians = ({ commit }, { params } = {}) => {
  return axios.get('techuser/', { params })
    .then(res => {
      commit('SET_TECHNICIANS', res.data)
      return res
    })
    .catch(err => err.response)
}
