<template lang="pug">
v-form(ref="form" @submit.prevent="onSubmit")
    alert(ref="alert" style="text-align: end !important; position: absolute !important;")
    .mb-2.primary--text Datos del cliente
    v-row(dense)
      v-col(cols=12 md=6)
        v-text-field(v-model="customer.name" label="Nombre" outlined :rules="[v => !!v || 'El nombre es requerido']")
      v-col(cols=12 md=6)
        v-text-field(v-model="customer.last_name" label="Apellido" outlined :rules="[v => !!v || 'El apellido es requerido']")
      v-col(cols=12 md=6)
        v-text-field(
          v-model="customer.contact"
          label="Celular"
          outlined
          :error-messages="errors.contact"
          type="tel"
          maxLength="11"
          v-maska="maskaContact" data-maska="# #### #### ####"
          prefix="(+56)"
          :rules="[rules.celularRequired, rules.celularLength]"
          @focus="$event.target.selectionStart = 7; $event.target.selectionEnd = -1"
        )
      v-col(cols=12 md=6)
        v-text-field(
          v-model="customer.rut"
          label="Rut"
          outlined
          :error-messages="errors.rut"
          v-maska="maskaRut"
          data-maska="1.11#-V"
          data-maska-tokens="1:[0-9]:repeated|V:[0-9Kk]"
          data-maska-reversed
        )
      v-col(cols=12 md=6)
        v-text-field(v-model="customer.email" label="Correo" outlined :rules="[v => !!v || 'El correo es requerido']")
      v-col(cols=12 md=6)
        v-text-field(v-model="customer.address" label="Dirección" outlined :rules="[v => !!v || 'La dirección es requerida']")

      //- v-col(cols=12 md=6)
      //-   v-autocomplete(
      //-     label="Cliente"
      //-     placeholder="Busca por Rut del cliente"
      //-     v-model="vehicle.customer"
      //-     outlined
      //-     item-text="rut"
      //-     return-object
      //-     :hide-no-data="hideData"
      //-     hide-selected
      //-     no-data-text="Cliente no encontrado"
      //-     :items="items"
      //-     :search-input.sync="search"
      //-     :loading="isLoading"
      //-     :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
      //-     :rules="[v => !!v || 'El cliente es requerido']"
      //-   )
      //-     template(#item="{ item }")
      //-       span {{ maskRutText(item.rut) }} - {{ item.name }}
      //-     template(#selection="{ item }")
      //-       span {{ maskRutText(item.rut) }} - {{ item.name }}
      //-     template(#append-item)
      //-       div.mr-2.ml-2
      //-         v-btn(color="primary" block @click="$emit('newClient')") Crear nuevo Cliente

      v-col(cols=12 md=12)
        .mb-2.primary--text Datos del vehículo
      v-col(cols=12 md=6)
        v-autocomplete(
          label="Modelo"
          v-model="vehicle.model"
          outlined
          item-text="model_display"
          return-object
          :items="models"
          :rules="[v => !!v || 'El modelo es requerido']")
      v-col(cols=12 md=6)
        v-text-field(
          v-model="vehicle.patent" label="Patente" outlined :rules="[v => !!v || 'La patente es requerida']"
          :error-messages="errors.patent"
          v-maska:[patentMaskFormat]
        )
      v-col(cols=12 md=6)
        v-text-field(v-model="vehicle.engine_number" label="Número de motor" outlined)
      v-col(cols=12 md=6)
        v-text-field(v-model="vehicle.chassis_number" label="Número de chasis" outlined)
      v-col(cols=12 md=6)
        v-text-field(v-model="vehicle.color" label="Color" outlined)
      v-col(cols=12 md=6)
        v-select(v-model="vehicle.fuel_type" label="Combustible" outlined :items="fuelTypeOptions")
    v-btn(block color="primary" x-large type="submit" :loading="isSaving") + Crear Cliente y Vehículo
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vMaska } from 'maska'
import { patentMaskFormat } from '@/utils/mask'
import alert from '@/components/shared/alert.vue'

export default {
  directives: { maska: vMaska },
  components: { alert },
  props: {
    patent: {
      type: String,
    },
    dataVehicle: {
      type: Object,
    },
    creatingNewCustomer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vehicle: {
        model: this.dataVehicle ? this.dataVehicle.modelo : null,
        fuel_type: 2,
        id: null,
        customer: null,
        patent: this.dataVehicle ? this.dataVehicle.patente : null,
        engine_number: this.dataVehicle ? this.dataVehicle.engine_number : null,
        color: this.dataVehicle ? this.dataVehicle.color : null,
        chassis_number: this.dataVehicle
          ? this.dataVehicle.chassis_number
          : null,
      },
      customer: {
        name: null,
        last_name: null,
        rut: '',
        contact: '9',
        email: null,
        address: null,
      },
      fuelTypeOptions: [
        {
          text: 'Bencina',
          value: 2,
        },
        {
          text: 'Diesel',
          value: 1,
        },
      ],
      errors: {
        rut: [],
        contact: [],
        patent: [],
      },
      maskaRut: { unmasked: null },
      maskaContact: { unmasked: null },
      isSaving: false,
      rules: {
        celularRequired: v => !!v || 'El celular es requerido',
        celularLength: v => v.replace(/ /g,'').length === 9 || 'El celular debe tener 9 caracteres'
      }
    }
  },
  methods: {
    ...mapActions('customer', ['listCustomers', 'createCustomer', 'createCustomerAndVehicle']),
    ...mapActions('vehicle', ['createVehicle', 'listModels']),
    async onSubmit() {
      this.errors = { rut: [], contact: [], patent: [] }
      if (!this.$refs.form.validate()) return

      this.isSaving = true

      const data = {
        customer: {
          ...this.customer,
          rut: this.maskaRut.unmasked,
          contact: this.maskaContact.unmasked,
        },
        vehicle: {
          ...this.vehicle,
          model: this.vehicle.model.id,
        }
      }

      const res = await this.createCustomerAndVehicle({ data })
      if (res.status !== 201) {
        this.errors = {
          rut: res.data.customer?.rut || [],
          contact: res.data.customer?.contact || [],
          patent: res.data.vehicle?.patent || [],
        }
        if (res.data.includes('<!DOCTYPE html>') && res.data.includes('DETAIL:')){
          const errorMessage = res.data.split('DETAIL:')[1].split('.')[0]
          this.$refs.alert.handleShowAlert('error', errorMessage, true, 'red')
          
        } else {
            this.$refs.alert.handleShowAlert('error', 'Error al guardar el cliente y vehículo', true, 'red')
          // this.$toast.error('Error al guardar el cliente y vehículo')
        }
        this.isSaving = false
        return
      }

      this.$emit('save', res.data.vehicle)
      this.$emit('input', res.data.vehicle)
      this.$emit('change', res.data.vehicle)
      this.isSaving = false
    },
  },
  computed: {
    ...mapGetters('customer', ['customers']),
    ...mapGetters('vehicle', ['models']),
    patentMaskFormat: () => patentMaskFormat,
  },
  created() {
    this.listCustomers()
    this.listModels()
    if (!this.dataVehicle) {
      this.vehicle.model = null
      this.vehicle.engine_number = null
      this.vehicle.chassis_number = null
      this.vehicle.color = null
      this.customer.name = null
      this.customer.rut = null
      this.maskaRut.unmasked = null
    }
    if (this.patent) {
      this.vehicle.patent = this.patent
    }
  },
  watch: {
    patent:{
      handler(val) {
        this.vehicle.patent = val
      },
      immediate: true,
    },
    dataVehicle(val) {
      this.vehicle.patent = this.dataVehicle.patente
      this.vehicle.model = this.dataVehicle.modelo
      this.vehicle.engine_number = this.dataVehicle.numero_motor
      this.vehicle.chassis_number = this.dataVehicle.numero_chasis
      this.vehicle.color = this.dataVehicle.color
    },
  },
}
</script>
