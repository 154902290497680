<template lang="pug">
SideModal(v-model="isOpen" max-width="1300")
  v-container(fluid v-if="jobs && otData")
    h2(style="color: #3658D3;") Aprobar Trabajos {{ otData.id ? `de OT N° ${otData.id}` : ''}}
    v-row
      v-col(cols="5")
        v-card.mt-5
          v-row
            v-col(cols="4")
              div.vehicle-img.graphics
            v-col(cols="8")
              div
                v-card-title Datos del Vehículo
                v-card-text.py-0 {{ otData.vehicle_data.patent }}
                v-card-text.py-0 {{ otData.vehicle_data.model_display }}
                v-card-text.py-0 N° motor: {{ otData.vehicle_data.engine_number }}
                v-card-text.py-0 N° chasis: {{ otData.vehicle_data.chassis_number }}
                v-card-text.py-0(v-if="otData.vehicle_milage") {{ otData.vehicle_milage}} Kms
                v-card-text.py-0(v-else style="color: #F44336 !important") Kilometraje no registrado
                  v-btn.mr-3(icon large)
                  v-icon(style="color: #462DFA" @click="openVehicleMilageModal()") mdi-square-edit-outline
      v-col(cols="7")
        v-card.mt-2
          v-card-title Diagnóstico
            .diagnostic-comments
              span(v-for="estimate in otData.estimate" :key="estimate.id")
                v-card-text.diagnostic-text(v-if="estimate.evaluation") {{ estimate.name !== null && estimate.name !== '' ? estimate.name : `Presupuesto N° ${ estimate.id }` }}: {{ estimate.evaluation.evaluation_comment === 'null' ? '-' : estimate.evaluation.evaluation_comment }}
    div(style="margin-top: 3%;")
      .d-flex.justify-end
        v-btn.my-3(outlined color="primary" @click="openAproveAllWorksModal()" :loading="loading" :disabled="otData.vehicle_milage === null || otData.vehicle_milage === '' || loading") Aprobar todos los Trabajos
      v-data-table(:headers="headers" hide-default-footer dense :items="localJobs")
        template(v-slot:item.related_estimate="{ item }")
          p {{ getEstimateName(item) || 'No asociado'}}
        template(v-slot:item.technician="{ item }")
          v-badge(bordered bottom color="primary" content="T" offset-x="12" offset-y="12")
            v-avatar.mr-1(color="#FFD699" size=32 dark) {{getAvatarTextById(item.assigned_to)}}
        template(v-slot:item.total_price="{ item }")
          p {{ item.total_price | currency }}
        template(v-slot:item.date_finished="{ item }")
          p {{ formatDate(item.date_finished) || 'Vuelve a Reparación'}}
        template(v-slot:item.state="{ item }")
          v-btn.mr-3(icon large v-show="!item.aproved_date && item.date_finished !== ''" :disabled="otData.vehicle_milage === null || otData.vehicle_milage === '' || loading" @click="openAproveModal(item)" :loading="loading")
            v-icon(style="color: #3658D3") mdi-check-circle-outline
          v-chip.mr-3.ma-2(color="success" outlined v-show="item.aproved_date") Aprobado
          v-btn.mr-3(icon large @click="openRejectionModal(item)" v-show="!item.aproved_date && item.date_finished !== ''" :disabled="otData.vehicle_milage === null || otData.vehicle_milage === '' || loading" :loading="loading")
            v-icon(style="color: #EA5C0B") mdi-close-circle-outline
          v-chip(class="ma-2" color="red" outlined v-show="item.date_finished === ''") Rechazado
    v-dialog(v-model="openJustification" transition="dialog-top-transition" max-width="500")
      v-card(tile style="height: 100%;")
        v-toolbar(color="#4C7AEC" dark) ¿Cuál es el motivo de rechazo?
        v-container
          v-card-subtitle.subtitleText Por favor ingresar un comentario para continuar
          v-textarea.px-3(outlined label="Comentarios:" clearable clear-icon="mdi-close-circle" counter rows="3" row-height="25" v-model="comment" )
        v-card-actions.justify-end
          v-btn.mr-5(color="#4C7AEC" dark @click="handleRejection()" :disabled="comment.length === 0") Enviar
          v-btn(@click="openJustification = false") Cerrar
    v-dialog(v-model="openConfirmation" transition="dialog-top-transition" max-width="500")
      v-card(tile style="height: 100%;")
        v-toolbar(color="#4C7AEC" dark) Confirmar acción
        v-container
          h4 ¿Está seguro que desea aprobar el trabajo seleccionado?
        v-card-actions.justify-end
          v-btn.mr-5(color="#4C7AEC" dark @click="handleAprovment()") Enviar
          v-btn(@click="openConfirmation = false") Cerrar
    v-dialog(v-model="openConfirmationAllWorks" transition="dialog-top-transition" max-width="500")
      v-card(tile style="height: 100%;")
        v-toolbar(color="#4C7AEC" dark) Confirmar acción
        v-container
          h4 ¿Está seguro que desea aprobar todos los trabajos?
        v-card-actions.justify-end
          v-btn.mr-5(color="#4C7AEC" dark @click="handleAprovmentAllWorks" :loading="loading") Enviar
          v-btn(@click="openConfirmationAllWorks = false" :disabled="loading") Cerrar
    v-dialog(v-model="openVehicleMilage" transition="dialog-top-transition" max-width="500")
      v-card(title style="height:100%;")
        v-toolbar(color="#4C7AEC" dark) Registrar kilometraje del vehículo
        v-container
          v-form
            v-text-field.mt-3(outlined dense type="number" style="width: 100%;" placeholder="Ingresa el kilometraje" v-model="vehicle_milage")
            .text-right
              v-btn.mt-3.mr-3(color="primary" outlined :disabled="vehicle_milage === null || vehicle_milage === '' || loading" @click="handleRegisterMilage" :loading="loading") Guardar
              v-btn.mt-3(@click="openVehicleMilage = false" :disabled="loading") Cerrar
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SideModal from '../shared/SideModal.vue'
export default {
  components: {
    SideModal
  },
  props: {
    otID: {
      type: Number
    }
  },
  data () {
    return {
      comment: '',
      workSelected: {},
      openConfirmation: false,
      openJustification: false,
      openConfirmationAllWorks: false,
      isOpen: false,
      headers: [
        { text: 'Presupuesto asociado', value: 'related_estimate' },
        { text: 'Trabajo', value: 'service_name' },
        { text: 'Técnico', value: 'technician', align: 'center' },
        { text: 'Monto', value: 'total_price' },
        { text: 'Fecha de término', value: 'date_finished' },
        { text: 'Estado', value: 'state' }
      ],
      vehicle_milage: null,
      openVehicleMilage: false,
      loading: false
    }
  },
  methods: {
    ...mapActions('ot', ['aproveJob', 'servicesForAproval', 'aproveJobs']),
    ...mapActions('inventory', ['getVehicleMilage', 'createVehicleMilage']),
    open () {
      this.isOpen = true
      // console.log(this.localJobs)
    },
    async changeWork (aproved) {
      console.log(this.workSelected.id)
      const data = {
        job_id: this.workSelected.id,
        aproved: aproved,
        next_state: true
      }
      if (!aproved) {
        console.log(this.workSelected.id)
        data.rejection_comment = this.comment
      }
      this.loading = true
      await this.aproveJob({ data }).then(
        (response) => {
          if (response.status === 200 && aproved) { // trabajo aprobado, busco trabajos para aprobar
            this.servicesForAproval({ otID: this.otID }).then(
              response => {
                if (response.status === 204) { // no quedan trabajos por aprobar/rechazar
                  this.isOpen = false
                  this.$emit('reloadData', { type: 'success', message: 'Todos los trabajos fueron aprobados exitosamente', show: true, color: 'green' })
                } // quedan trabajos por aprobar/rechazar
                this.$emit('reloadData', { type: 'success', message: 'Trabajo aprobado exitosamente', show: true, color: 'green' })
              }
            )
          } else if (response.status === 200 && !aproved) { // trabajo rechazado
            this.isOpen = false
            this.$emit('reloadData', { type: 'success', message: 'Trabajo rechazado exitosamente', show: true, color: 'green' })
          } else { // error al guardar
            this.isOpen = false
            const action = aproved ? ' aprobar' : ' rechazar'
            this.$emit('reloadData', { type: 'success', message: `Ha ocurrido un error al intentar ${action} el trabajo seleccionado`, show: true, color: 'danger' })
          }
        }
      )
      this.loading = false
    },
    async saveAsDraft (aproved) {
      const data = {
        job_id: this.workSelected.id,
        aproved: aproved,
        next_state: false
      }
      if (!aproved) {
        data.rejection_comment = this.comment
      }
      await this.aproveJob({ data }).then(
        async (response) => {
          if (response.status === 200) {
            await this.servicesForAproval({ otID: this.otID })
            this.$emit('reloadData')
          }
        }
      )
    },
    openVehicleMilageModal () {
      this.openVehicleMilage = !this.openVehicleMilage
    },
    async handleRegisterMilage () {
      this.loading = true
      const vehicleMilage = +this.vehicle_milage.split('.').join('')
      await this.createVehicleMilage({ id: this.otID, data: { vehicle_milage: vehicleMilage } }).then(
        async (response) => {
          if (response.status === 200) {
            this.openVehicleMilage = false
            this.$emit('reloadData', { type: 'success', message: 'Kilometraje guardado exitosamente', show: true, color: 'green' })
          } else {
            this.openVehicleMilage = false
            this.$emit('reloadData', { type: 'success', message: 'Ha ocurrido un error al intentar ingresar el kilometraje', show: true, color: 'danger' })
          }
        }
      )
      this.loading = false
    },
    handleShowAlert (data) {
      this.panel = -1
      window.scrollTo(1000, 0)
      this.fetchData()
      this.showAlert(data)
    },
    handleAprovment () {
      this.changeWork(true)
      this.openConfirmation = !this.openConfirmation
    },
    openAproveModal (item) {
      this.openConfirmation = !this.openConfirmation
      this.workSelected = item
    },
    async  handleAprovmentAllWorks () {
      const data = {
        jobs_id: this.jobs.map((j) => j.id),
        next_state: true
      }
      this.loading = true
      await this.aproveJobs({ data }).then(
        (response) => {
          if (response.status === 200) {
            this.isOpen = false
            this.$emit('reloadData', { type: 'success', message: 'Todos los trabajos fueron aprobados exitosamente', show: true, color: 'green' })
          } else { // error al guardar
            this.isOpen = false
            const action = ' aprobar'
            this.$emit('reloadData', { type: 'success', message: `Ha ocurrido un error al intentar ${action} los trabajos`, show: true, color: 'danger' })
          }
        }
      )
      this.loading = false
    },
    openAproveAllWorksModal () {
      this.openConfirmationAllWorks = !this.openConfirmationAllWorks
    },
    handleRejection () {
      this.changeWork(false)
      this.openJustification = !this.openJustification
    },
    openRejectionModal (item) {
      this.openJustification = !this.openJustification
      this.workSelected = item
    },
    handleConfirmation () {
      this.isOpen = false
      this.$emit('reloadData')
    },
    getAvatarTextById (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    getTechById (id) {
      if (id) {
        return this.technicians.find(el => el.id === id)
      }
      return null
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    getEstimateName (item) {
      if (this.otData !== null) {
        const name = this.otData.estimate.filter(e => e.id === item.related_estimate)[0]?.name
        return name !== null && name !== '' ? name : `N° ${item.related_estimate}`
      }
      return `N° ${item.related_estimate}`
    }
  },
  computed: {
    ...mapGetters('ot', ['OTs', 'jobs', 'OT']),
    ...mapGetters('user', ['technicians']),
    otData () {
      if (this.jobs.length > 0) {
        if (this.OTs.length > 0) {
          return this.OTs.filter(ot => ot.id === this.jobs[0].work_order)[0]
        }
        return this.OT
      }
      return null
    },
    localJobs () {
      const jobs = this.jobs
      return jobs.sort(({ aproved_date: a }, { aproved_date: b }) => (b === null) - (a === null) || b - a)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  color: #3658D3;
  height: 190px;
}
.graphics {
  width: 120px;
  height: 120px;
  margin: 14px;
}
.vehicle-img {
  background-image: url('../../assets/img/vehicle.png');
  background-size: cover;
}
.diagnostic-comments {
  width: 100%;
  margin: 10px;
  padding: 18px;
  background-color: #FFF4E1;
  font-size: 16px;
  color: #EA5C0B;
  max-height: 110px;
  overflow: auto;
}
.v-data-table::v-deep th {
  font-size: 18px !important;
  font-weight: 400;
  background-color: #E4EAFF !important;
  padding: 20px 0 20px 20px !important;
}
.v-data-table::v-deep td {
  font-size: 18px !important;
  padding: 20px !important;
}

.diagnostic-text {
  text-align: justify;
  padding: 5px 0;
}
.subtitleText {
  color: grey
}
</style>
