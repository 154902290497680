<template lang="pug">
  .sale-detail()
    SideModal(v-model="isOpen" v-if="sale")
      v-container.white(fluid)
        h2 Ventas
        h1 N° {{sale.id}}
        v-row.mb-4
          v-col(cols="auto")
            div Fecha ingreso
            strong {{sale.created_at | moment('DD/MM/YYYY')}}
          v-col(cols="auto")
            //- div Estado
            //- strong ???
        v-container.mb-4(v-if="sale.customer" fluid style="border: 1px solid #E4E3E2;")
          v-row
            v-col
              div
                strong {{sale.customer.name}} {{sale.customer.last_name}}
              div {{sale.customer.rut}}
              div {{sale.customer.address}}
              div (+56) {{sale.customer.contact | cellFilter}}
              div {{sale.customer.email}}
            v-col(v-if="sale.vehicle")
              div
                strong Vehículo
              div {{sale.vehicle.patent}}
              div {{sale.vehicle.year}}
        v-container.mb-4(fluid style="border: 1px solid #A3A2A1;")
          v-row
            v-col
              div(style="color: #A3A2A1;") Items
              div(v-for="saleItem in sale.sale_items") {{saleItem.piece_name}}
            v-col
              div(style="color: #A3A2A1;") Cantidad
              div(v-for="saleItem in sale.sale_items") {{saleItem.quantity}}
            v-col
              div(style="color: #A3A2A1;") Total
              div(v-for="saleItem in sale.sale_items") {{saleItem.total_price | currency }}
          .total_price
            label Total
            strong  {{sale.total_price | currency}}
</template>

<script>
import { mapActions } from 'vuex'
import { cellFilter } from '@/utils/filters.js'
import SideModal from '../shared/SideModal.vue'

export default {
  components: {
    SideModal
  },
  filters: { cellFilter },
  data () {
    return {
      isOpen: false,
      sale: null
    }
  },
  methods: {
    ...mapActions('sale', ['getSale']),
    async open (saleId) {
      this.isOpen = true
      const response = await this.getSale({ saleId })

      if (response.status === 200) {
        this.sale = response.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.total_price {
  font-size: 18px;
  text-align: right;
  margin-top: 1rem;
}
</style>
