import { defineStore } from 'pinia'
import store from '@/store/index'
import fileDownload from 'js-file-download'

export const useCustomerListStore = defineStore('customerList', {
  state: () => ({
    filters: {
      search: null,
    },
    tableOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ['id'],
      sortDesc: [true],
    },
    loading: false,
    results: [],
    totalItems: 0,
    showCustomerDetailModal: false,
    selectedCustomer: null,
  }),

  getters: {
    queryParams: (state) => {
      const { filters: f, tableOptions: o } = state
      const ordering = o.sortBy
        .map((field, i) => (o.sortDesc[i] ? '-' : '') + field)
        .join(',')
      return {
        paginated: true,
        page: o.page,
        query: f.search,
        ordering,
      }
    },
  },

  actions: {
    async getCustomers() {
      this.loading = true
      const res = await store.dispatch('customer/listCustomers', {
        params: this.queryParams,
      })

      if (res.status !== 200) return

      this.results = res.data.results
      this.totalItems = res.data.count
      this.loading = false
    },

    async getCustomersExcel() {
      const res = await store.dispatch('customer/exportCustomersXls')

      if (res.status !== 200) return
      fileDownload(res.data, 'reporte-clientes.xls')
      return true
    },
  },
})
