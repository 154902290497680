<template lang="pug">
v-dialog(v-model="isOpen" width="300" content-class="elevation-0")
  v-card
    v-img(src="@/assets/img/message-ot.png")
    v-img.checkImg(src="@/assets/img/circle-check-fill.png")
    v-card-title.title ¡Felicidades!
    v-card-subtitle.subtitle Has terminado los trabajos asignados para tí de esta OT.
    v-card-actions
      v-btn.btn(@click="finish()") Finalizar
</template>
<script>
export default {
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    open () {
      this.isOpen = true
    },
    finish () {
      this.$router.push('/works')
      this.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  color: #1A1796;
  font-weight: 800 !important;
  justify-content: center;
  padding-bottom: 30px !important;
  padding-top: 5px !important
}
.subtitle {
  color: #1A1796 !important;
  justify-content: center !important;
  text-align: center;
}
.checkImg {
  position: absolute;
  right: 100px;
  top: 30px;
}
.btn {
  background-color: #407BFF !important;
  color: #fff;
  margin: 20px auto;
}

</style>
