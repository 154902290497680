import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify/index'
import axios from './plugins/axios'
import './styles/main.scss'
import { createPinia, PiniaVuePlugin } from 'pinia'

import './utils/customVueFilters'

Vue.use(require('vue-moment'))
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  vuetify,
  axios,
  pinia,
  render: h => h(App)
}).$mount('#app')
