import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'
import colors from './colors'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

Vue.use(VuetifyToast)

export default new Vuetify({
  theme: {
    themes: {
      light: colors,
    },
  },
})
