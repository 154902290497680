<template lang="pug">
v-container(v-if="OT !== null")
  v-card
    v-expansion-panels(accordion)
      v-expansion-panel
        v-expansion-panel-header
          v-card-title.budgetTitle.pl-1.py-0.pt-1 Historiales
        v-expansion-panel-content
          v-row.mb-5
            v-col(v-if="transitionData.length > 0" cols="12" md="6" lg="6" xl="6")
              v-expansion-panels(accordion)
                v-expansion-panel
                  v-expansion-panel-header.px-3.transitionHeader
                    .w-100.d-flex.justify-space-between.align-center
                      div.transition-img.graphics-small.showDesktop
                      strong.transitionTitle Historial de estado
                      v-chip.flex-grow-0.flex-shrink-1.showDesktop(label :text-color="getStatusTextColor(OT.state)" :color="getStatusColor(OT.state)") {{ OT.state }}
                  v-expansion-panel-content.py-5
                    v-data-table(
                      :items="transitionData"
                      :headers="otStateHeaders"
                      hide-default-footer
                    )
                      template(v-slot:item.date="{ item }")
                        p.mb-1 {{ item.date | moment('DD/MM/YY') }}
                      template(v-slot:item.hour="{ item }")
                        p.mb-1 {{ item.date | moment('hh:mm') }}
                      template(v-slot:item.final_state="{ item }")
                        v-chip.flex-grow-0.flex-shrink-1(label :text-color="getStatusTextColor(item.final_state)" :color="getStatusColor(item.final_state)") {{ item.final_state }}
            v-col(v-else cols="12" md="6" lg="6" xl="6")
              v-expansion-panels(accordion)
                v-expansion-panel
                  v-expansion-panel-header.pl-5.transitionHeader
                    .w-100.d-flex.justify-flex-start.align-center
                      div.transition-img.graphics-small
                      strong.transitionTitle.pl-15  Historial de estado
                  v-expansion-panel-content.pt-5
                    p Aún no hay nada aquí
            v-col(v-if="paymentData.length > 0" cols="12" md="6" lg="6" xl="6")
              v-expansion-panels(accordion)
                v-expansion-panel
                  v-expansion-panel-header.px-3.paymentHeader
                    .w-100.d-flex.justify-space-between.align-center
                      div.payment-img.graphics-small.showDesktop
                      strong.paymentTitle Historial de pagos
                      v-chip.flex-grow-0.flex-shrink-1.showDesktop(label :text-color="getStatusTextColor(payments[0].state)" :color="getStatusColor(payments[0].state)") {{ payments[0].state }}
                      strong.paymentTitle.mr-5.showDesktop Restante {{ (getEstimatesTotal() - getPaymentTotals()) | currency }}
                  v-expansion-panel-content.pt-5
                    v-data-table(
                      :items="paymentData"
                      :headers="paymentHeaders"
                      hide-default-footer
                      mobile-breakpoint="424"
                    )
                      template(v-slot:item.transaction_date="{ item }")
                        p.mb-1(v-if="item.transaction_date") {{ item.transaction_date | moment('DD/MM/YY hh:mm') }}
                        p.mb-1(v-else) {{ item.created_at | moment('DD/MM/YY hh:mm') }}
                      template(v-slot:item.state="{ item }")
                        v-chip.flex-grow-0.flex-shrink-1(label :text-color="getStatusTextColor(item.state)" :color="getStatusColor(item.state)") {{ item.state }}
                      template(v-slot:item.payment_total="{ item }")
                        strong  {{ item.payment_total | currency }}
                        p {{ item.payment_gateway }}
                    v-row.py-0.showMobile
                      v-col.pt-0.text-right
                       div #[strong Restante {{ (getEstimatesTotal() - getPaymentTotals()) | currency }}]
            v-col(v-else  cols="12" md="6" lg="6" xl="6")
              v-expansion-panels(accordion)
                v-expansion-panel
                  v-expansion-panel-header.pl-5.paymentHeader
                    v-row
                      v-col.d-flex.justify-flex-start.align-center(cols="12")
                        div.payment-img.graphics-small
                        strong.paymentTitle.pl-15 Historial de pagos
                  v-expansion-panel-content.pt-5
                    p Aún no hay nada aquí

  </template>
<script>
import { mapGetters } from 'vuex'
import otStatusColors from '../../../shared/otStatusColors'
export default {
  data () {
    return {
      otStateHeaders: [
        { text: 'Fecha', value: 'date', filterable: false, sortable: false },
        { text: 'Hora', value: 'hour', filterable: false, sortable: false },
        { text: 'Estado', value: 'final_state', filterable: false, sortable: false }
      ],
      paymentHeaders: [
        { text: 'Fecha', value: 'transaction_date', filterable: false, sortable: false },
        { text: 'Estado', value: 'state', filterable: false, sortable: false },
        { text: 'Total', value: 'payment_total', filterable: false, sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters('ot', ['OT', 'payments', 'transitions']),
    estimateData () {
      return this.OT ? this.OT.estimate : []
    },
    paymentData () {
      return this.payments?.filter?.(p => p.state !== 'Pendiente') || []
    },
    transitionData () {
      return this.transitions ? this.transitions : []
    }
  },
  methods: {
    getStatusColor (state) {
      return otStatusColors[state] ? otStatusColors[state].color : otStatusColors.default.color
    },
    getStatusTextColor (state) {
      return otStatusColors[state] ? otStatusColors[state].textColor : otStatusColors.default.textColor
    },
    getEstimatesTotal () {
      const TotalValue = 0
      return this.estimateData.reduce((acc, curr) => { return acc + curr.total_price }, TotalValue)
    },
    getPaymentTotals () {
      const aprovedPayments = []
      const payments = this.paymentData

      payments.forEach(element => {
        if (element.state === 'Pagado') {
          aprovedPayments.push(element)
        }
      })
      const PaymentTotal = 0
      return aprovedPayments.reduce((acc, curr) => { return acc + curr.payment_total }, PaymentTotal)
    }
  }
}
</script>
<style lang="scss" scoped>
.transitionTitle {
  color: #3658D3;
}
.transitionHeader {
  background-color: #E4EAFF;
}
.paymentTitle {
  color: #107700;
}
.paymentHeader {
  background-color: #94CD9B;
}
.budgetsHeaders {
  background-color: #E4EAFF;
  color: #969696;
}
.subtotal-budget, .subtotal-items  {
  height: 100%;
}
.graphics-small {
  width: 30px;
  height: 30px;
}
.transition-img {
  background-image: url('../../../assets/img/estados.png');
  background-size: cover;
}
.payment-img {
  background-image: url('../../../assets/img/pagos.png');
  background-size: cover;
}
.showDesktop {
  @media (max-width: 768px) {
    display: none;
  }
}
.showMobile {
  @media (min-width: 768px) {
    display: none;
  }
}
</style>
