const mutations = {
  'SET_OTS' (state, value) {
    state.OTs = value
  },
  'SET_OT' (state, value) {
    state.OT = value
  },
  'SET_BUDGET' (state, value) {
    state.budgets = value
  },
  'SET_EVALUATIONS' (state, value) {
    state.evaluations = value
  },
  'SET_JOBS' (state, value) {
    state.jobs = value
  },
  'SET_DIAGNOSED' (state, value) {
    state.diagnosed = value
  },
  'SET_PAYMENTS' (state, value) {
    state.payments = value
  },
  'SET_TRANSITIONS' (state, value) {
    state.transitions = value
  },
  'SET_REPAIRED' (state, value) {
    state.repaired = value
  },
  'SET_TEMPLATES' (state, value) {
    state.templates = value
  },
  'SET_EVALUATIONS_COUNT' (state, value) {
    state.evaluationsCount = value
  },
  'SET_JOBS_COUNT' (state, value) {
    state.jobsCount = value
  },
  'SET_DIAGNOSED_COUNT' (state, value) {
    state.diagnosedCount = value
  },
  'SET_REPAIRED_COUNT' (state, value) {
    state.repairedCount = value
  },
}

export default mutations
