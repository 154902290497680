<template lang="pug">
v-container(fluid)
  v-row
    v-col.pt-0(cols="12")
      .background
        v-row(justify="space-between")
          v-col(cols="4")
            v-autocomplete(
              v-model="selectedPiece"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              :filter="piecesFilter"
              item-text="text"
              item-value="value"
              hide-details
              no-data-text="No se encontraron piezas"
              return-object
              outlined
              clearable
              dense
              class="elevation-0"
              placeholder="Buscar Piezas"
              prepend-inner-icon="mdi-magnify"
              @click:clear="piecesResult = []; selectedPiece = null"
              @change="handleSelect"
            )
          v-col(cols="4")
            v-text-field.mr-4(
              label="Ingrese N° de factura"
              v-model="invoiceNumber"
              outlined
              :rules="[v => !!v || 'El N° de factura es obligatorio']"
            )
        v-row
          v-icon.my-4.ml-2(style="color: #000;" dark @click="goBack") mdi-arrow-left
          h3.mt-4.ml-3(v-if="localPieces.length > 0") Resumen de tu solicitud: Proveedor {{ localPieces[0].supplier_name }}
        v-data-table.mt-8(:headers="headers" :items="localPieces" hide-default-footer no-data-text="Aun no hay piezas")
          template(v-slot:item.code="{ item }")
            v-text-field.mt-4(outlined dense v-model="item.code" disabled)
          template(v-slot:item.supplier_code="{ item }")
            v-text-field.mt-4(outlined dense v-model="item.supplier_code" disabled)
          template(v-slot:item.category_code="{ item }")
            v-text-field.mt-4(outlined dense v-model="item.category_code" disabled)
          template(v-slot:item.name="{ item }")
            v-text-field.mt-4(outlined dense v-model="item.name" disabled)
          template(v-slot:item.payed_price="{ item, index }")
            v-text-field.mt-4(outlined dense prefix="$" v-model="item.payed_price" class="end-text" :rules="[rules.required]" v-maska:[moneyMaskFormat])
          template(v-slot:item.sale_price="{ index, item }")
            v-text-field.mt-4(outlined dense prefix="$" v-model="item.sale_price" class="end-text" :rules="[rules.required]" :disabled="disabledInput" v-maska:[moneyMaskFormat])
          template(v-slot:item.bought_items="{ item }")
            v-text-field.mt-4(outlined dense type="number" min="1" v-model="item.bought_items" class="end-text" :rules="[rules.required, rules.greaterThanZero, rules.isNumber]")
          template(v-slot:item.actions="{ item }")
            v-btn(icon @click="deletePiece(item)" color="red")
              v-icon mdi-trash-can
        div.float-right.mt-5.d-flex.flex-column.align-center(v-if="localPieces.length > 0")
          v-btn(color="primary" :disabled="disableSave" @click="openModal") Enviar solicitud
          small.mt-1(v-if="disableSave && localPieces.length > 0" style="color: red") Revise todos los campos
  SupplierSolicitudeConfirmationModal(ref="confirmModal" v-if="localPieces.length > 0")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SupplierSolicitudeConfirmationModal from './SupplierSolicitudeConfirmationModal.vue'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../utils/mask'
export default {
  components: {
    SupplierSolicitudeConfirmationModal
  },
  directives: { maska: vMaska },
  data () {
    return {
      noPieces: false,
      headers: [
        { text: 'Código repuesto', value: 'code', class: 'blue lighten-5', sortable: false, },
        { text: 'Código proveedor', value: 'supplier_code', class: 'blue lighten-5', sortable: false },
        { text: 'Código categoría', value: 'category_code', class: 'blue lighten-5', sortable: false },
        { text: 'Nombre pieza', value: 'name', class: 'blue lighten-5', sortable: false },
        { text: 'Precio compra', value: 'payed_price', class: 'blue lighten-5', filterable: false, sortable: false },
        { text: 'Precio Venta', value: 'sale_price', class: 'blue lighten-5', filterable: false, sortable: false },
        { text: 'Stock', value: 'bought_items', class: 'blue lighten-5', filterable: false, sortable: false },
        { text: 'Acciones', value: 'actions', class: 'blue lighten-5', filterable: false, align: 'center' },
      ],
      localPieces: [],
      invoiceNumber: '',
      piecesResult: [],
      rules: {
        required: value => !!value || 'Requerido',
        greaterThanZero: value => value > 0 || 'Debe ser mayor a 0',
        isNumber: value => !isNaN(value) || 'Debe ser un número',
      },
      selectedPiece: null,
      search: '',
      isLoading: false,
      indexSelected: null,
      valueToFormat: null,
      showModal: false,
      moneyMaskFormat
    }
  },
  computed: {
    ...mapGetters('inventory', ['pieces']),
    disableSave () {
      return this.localPieces.some(piece => piece.bought_items < 0) || this.localPieces.some(piece => piece.bought_items === 0) || this.localPieces.some(piece => piece.bought_items === '');
    },
    disabledInput() {
      const user = JSON.parse(localStorage.getItem('user'))
      return !user.groups.includes('Supervisor')
    },
    items() {
      return this.piecesResult.map((p) => {
        return {
          value: `${p.piece} - ${p.supplier}`,
          code: p.piece_code,
          id: p.piece,
          supplier_id: p.supplier,
          text: `${p.piece_name} - ${p.brand_name || 'Sin marca'} - ${p.supplier_name}`,
          supplier_code: p.supplier_piece_code,
          category_code: p.category_code,
          name: p.piece_name,
          brand_name: p.brand,
          supplier_name: p.supplier_name,
          payed_price: p.purchase_price,
          sale_price: p.piece_sale_price,
          bought_items: 0,
        }
      })
    },
  },
  methods: {
    ...mapActions('inventory', ['getListSupplierPieces']),
    piecesFilter(item, queryText, itemText) {

      const pieceCode = item.code
      const categoryCode = item.category_code
      const supplierCode = item.supplier_code
      const search = queryText.toLowerCase()

      if (pieceCode && pieceCode.includes(search)) {
        return true
      }
      if (categoryCode && categoryCode.includes(search)) {
        return true
      }
      if (supplierCode && supplierCode.includes(search)) {
        return true
      }
      return false
    },
    deletePiece (item) {
      const index = this.localPieces.indexOf(item)
      if (index > -1) {
        this.localPieces.splice(index, 1)
        this.indexSelected = null
        this.valueToFormat = null
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    clearable () {
      this.search = ''
    },
    handleSelect() {
      if (this.selectedPiece === null) return
      if (this.localPieces.length > 0) {
        if (this.localPieces.filter(piece => piece.id === this.selectedPiece.id).length > 0) {
          this.$nextTick(() => {
            this.selectedPiece = null
            this.search = ''
          })
          return
        }
      }
      this.localPieces.push(this.selectedPiece)
      this.$nextTick(() => {
        this.selectedPiece = null
        this.search = ''
      })
    },
    openModal() {
      this.$refs.confirmModal.open({
        localPieces: this.localPieces,
        invoiceNumber: this.invoiceNumber
      })
    }
  },
  watch: {
    search(val) {
      if (val && val.length >= 3) {
        if (this.items.length > 0) return

        if (this.isLoading) return

        this.isLoading = true

        const params = {
          search: val,
          supplier: this.localPieces.length > 0 ? this.localPieces[0].supplier_id : null,
        }

        this.getListSupplierPieces({ params })
          .then((response) => {
            this.piecesResult = response.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      } else {
        this.piecesResult = []
      }

    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  background-color: #fff;
  border-radius: 10px;
  height: 500px;
}
.subtitle {
  color: #3658D3;
}
.interaction-img {
  background-image: url(../../assets/img/interaction.png);
  width: 80%;
  height: 50%;
  margin: 30px auto;
  background-position: center;
}
.headerColor {
  background: blue !important;
}
.cactus {
  background-image: url(../../assets/img/cactus.png);
  width: 280px;
  height: 170px;
  background-position: center;
  margin: 3rem auto 0 auto;
}
.resume {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-data-table::v-deep td {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.v-data-table__wrapper {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

</style>
<style scoped>
.end-text >>> input {
  text-align: end !important;
}
</style>