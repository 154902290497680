<template lang="pug">
div
  v-app-bar(v-if="!$route.meta.public" app color="#4C7AEC" flat dark height="90")
    v-app-bar-nav-icon(@click.stop="mobileDrawer = !mobileDrawer")
    v-spacer
    v-img(src="@/assets/logo/logo-white-garage.png" max-height=45 max-width=127)
    v-spacer
    v-btn(icon class="mx-3" @click="$emit('logout')")
      v-icon mdi-logout
    //- v-menu(offset-y left)
      template(v-slot:activator="{ on, attrs }")
        v-btn(icon class="mx-3" v-bind="attrs" v-on="on")
          v-icon mdi-logout
      v-list
        v-list-item
          v-list-item-title(@click="$emit('logout')") Cerrar Sesión
    v-avatar(color="#E4E3E2" size="24" class="mr-5") {{ userInitials }}

  v-navigation-drawer(v-model="mobileDrawer" v-if="!$route.meta.public" app temporary)
    v-list(nav dense)
      span(v-for="(route, index) in routes" :key="index")

        //- Item individual
        span(v-if="!checkChildren(route)")
          v-list-item(link :to="route.url" :active="route.active")
            v-list-item-icon
              v-icon(:dense="route.small") {{route.icon}}
            v-list-item-title {{route.text}}

        //- Item submenú
        v-list-group(v-else :prepend-icon="route.icon" no-action v-model="route.active" active-class="list-group-active")
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title(v-text="route.text")
          v-list-item(v-for="child in route.children" :key="child.text")
            v-list-item-content
              router-link.nav-item-text(:to="child.url") {{child.text}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mobileDrawer: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    userInitials() {
      return this.user
        ? this.user.first_name.charAt(0) + this.user.last_name.charAt(0)
        : ''
    },
  },
  methods: {
    checkChildren(route) {
      return Object.prototype.hasOwnProperty.call(route, 'children')
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-item-text {
  color: #000000DE;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  &.router-link-active {
    color: #4C7AEC;
  }
}
</style>
