<template lang="pug">
#payment-view
  v-container(fluid style="height: 100%;")
    v-row(style="min-height: 100%")
      v-col(cols="6" offset="1")
        FinalPaymentForm
      v-col(cols="4")
        CustomerData
</template>

<script>
import FinalPaymentForm from '../components/payment/FinalPaymentForm.vue'
import CustomerData from '../components/customer/CustomerData.vue'

export default {
  components: {
    FinalPaymentForm,
    CustomerData
  }
}
</script>

<style lang="scss" scoped>
#payment-view {
  height: 100%;
  background-image: url('../assets/img/mail-background.png');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
