import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const CustomerStore = {
  namespaced: true,
  state: {
    customers: [],
    customer: null
  },
  getters,
  mutations,
  actions
}

export default CustomerStore
