<template lang="pug">
  OTRepairTechnicianForm
</template>
<script>
import OTRepairTechnicianForm from '@/components/OT/OTRepairTechnicianForm.vue'
export default {
  components: {
    OTRepairTechnicianForm
  }
}
</script>
<style lang="scss" scoped>
</style>
