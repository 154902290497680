<template lang="pug">
v-container(fluid)
  div.d-flex.justify-end.mb-5
    v-btn(color="primary" elevation="0" @click="$router.push({ name: 'Nueva Solicitud de Mercadería' })") Nueva Solicitud
  v-row
    v-col
      v-data-table(
        :headers="headers"
        :items="orderStockMovement"
        class="elevation-0"
        hide-default-footer
        no-data-text="No hay solicitudes para mostrar"
        loading-text="Cargando solicitudes..."
        :loading="loading"
      )
        template(v-slot:item.created_at="{ item }")
          p {{ formatDate(item.date) }}
        template(v-slot:item.supplier="{ item }")
          p {{ item.supplier }}
        template(v-slot:item.state="{ item }")
          v-chip(:color="item.state === 'Pendiente' ? 'gray' : 'primary'") {{ item.state }}
        template(v-slot:item.detail="{ item }")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon large v-bind="attrs" v-on="on" @click="goToDetail(item.id)" color="primary")
                v-icon mdi-eye-outline
            span Ver detalles
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(v-if="item.state === 'Pendiente'" icon large v-bind="attrs" v-on="on" @click="goToEdit(item.id)" color="primary")
                v-icon mdi-pencil
            span Editar
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(v-if="item.state === 'Pendiente'" icon large v-bind="attrs" v-on="on" @click="openModalDeleteStockSolicitude(item.id)" color="red")
                v-icon mdi-delete
            span Eliminar
  v-dialog(v-model="dialog" persistent max-width="400")
    v-card.pa-4
      h4.text-center ¿Estás seguro de eliminar esta solicitud?
      v-card-actions.d-flex.justify-space-between.mt-4
        v-btn(color="primary" outlined @click="dialog = false" elevation=0) Cancelar
        v-btn(color="primary" @click="deleteStock" elevation=0) Eliminar
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { text: 'Fecha de Solicitud', value: 'created_at', filterable: false, sortable: false },
        { text: 'Proveedor', value: 'supplier', filterable: false, sortable: false },
        { text: 'Estado', value: 'state', filterable: false, sortable: false },
        { text: 'Detalle', value: 'detail', filterable: false, sortable: false }
      ],
      loading: false,
      orderStockMovement: [],
      page: 1,
      rowCount: 0,
      dialog: false,
      selectedSolicitudeId: null
    }
  },
  methods: {
    ...mapActions('inventory', ['getMerchandiseEntriesRequests', 'deleteMerchandiseEntryRequest']),
    goToDetail(id) {
      this.$router.push({ name: 'Ver detalle de Ingreso de Mercadería', params: { stockID: id } })
    },
    goToEdit(id) {
      this.$router.push({ name: 'Editar Solicitud de Mercadería', params: { stockID: id } })
    },
    openModalDeleteStockSolicitude(id) {
      this.selectedSolicitudeId = id
      this.dialog = true
    },
    fetchData () {
      this.loading = true
      this.getMerchandiseEntriesRequests({ params: { page: this.page } }).then(
        response => {
          this.orderStockMovement = response.data.results
          this.rowCount = response.data.count
          this.loading = false
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(date))
    },
    deleteStock () {
      this.deleteMerchandiseEntryRequest({id: this.selectedSolicitudeId }).then(() => {
        this.dialog = false
        this.fetchData()
      })
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    page () {
      this.fetchData()
    }
  }
}
</script>
