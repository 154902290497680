const mutations = {
  'SET_SALES' (state, value) {
    state.sales = value
  },
  'SET_SALE' (state, value) {
    state.sale = value
  }
}

export default mutations
